@use 'src/scss/inc/vars';
.tf-v1-sidetab {
  z-index: 5 !important;
}
.tf-v1-popover {
  bottom: 125px !important;
  z-index: 5 !important;
  @media(vars.$sm) {
    bottom: 85px !important;
  }
  .tf-v1-popover-button {
    bottom: 70px !important;
    @media(vars.$xs) {
      right: 15px !important;
    }
    @media(vars.$sm) {
      bottom: 25px !important;
    }
    .tf-v1-popover-button-icon {
      transform: rotate(90deg) !important;
    }
  }
}
.typeform-popover {
  .typeform-popover-button {
    width: 42px !important;
    height: 42px !important;
    right: 100px !important;
    bottom: 4.15% !important;
    background-color: white !important;
    box-shadow: 0px 3px 15px rgb(0 0 0 / 10%) !important;
    @media screen and (vars.$max-sm) {
      right: 4% !important;
      bottom: 90% !important;
    };
    .typeform-popover-button-icon {
      color: black;
    }
    svg {
      width: 20px !important;
      height: 20px !important;
    }
    path {
      fill: black !important;
    }
    &:hover {
      background-color: #fafafa !important;
      box-shadow: 0px 3px 15px rgba(0,0,0,0.2) !important;
    }
  }
}
.show-typeform-popover {
  .typeform-popover {
    display: inline-block;
  }
}

.tf-v1-popup {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	.tf-v1-iframe-wrapper, iframe {
		width: 100% !important;
		height: 100% !important;
	}
}

.tf-v1-popup .tf-v1-close {
	color: #000 !important;
	z-index: 999 !important;
	position: fixed !important;
	top: 20px !important;
	right: 20px !important;
}