@use 'src/scss/inc/vars';

.budCap-spent {
	.card-header {
		background-color: vars.$orangeBg !important;
	}
}
.promo-card {
	background: #FFFFFF;
	box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.24), 0px 2px 6px rgba(8, 35, 48, 0.16);
	border-radius: 6px;
	margin-bottom: 24px;
	// @media (vars.$max-sm) {
	// 	margin: 13px 24px 24px;
	// }

	a {
		// float: right;
		align-self: baseline;
	}

	&.Upcoming , &.Active {
		.card-header {
			background-color: vars.$blue;

			h6, .status {
				color: #fff;
			}
		}
	}

	&.budCap-spent {
		.card-header {
			h6, .status {
				color: #fff !important;
			}
		}
	}

  .weekly-cap-label {
    padding: 12px 24px;
    border: 1px solid #FAA902;
    margin: 12px 22px 0;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .25);
    p {
      margin-bottom: unset;
    }
    span {
      font-weight: 500;
    }
  }

	.card-header {
		padding: 7px 24px;
		display: flex;
		align-items: center;
		border-radius: 6px 6px 0 0;
		background:#F5F6F9;

		h6 {
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.4px;
			text-transform: uppercase;
			color: #181818;
			text-transform: uppercase;
			margin-bottom: 0;

			span { font-weight: normal; }
			strong { font-weight: 500; }
			.modal-back { display: none; }
		}

		.status {
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.4px;
			text-transform: uppercase;
			color: #181818;
			margin-left: auto;
		}
	}

	.card-description {

		align-items: center;
		flex-direction: column;
		text-align: center;
		@media (vars.$sm) {
			padding: 15px 23px;
			flex-direction: row;
			text-align: left;
		}

		.col.budget-cap_Reached {
			max-width: 400px;
			width: 100%;
			background-color: rgba(250, 169, 26, 0.2);
			border-radius: 5px;
			padding: 1% 2%;
			@media(vars.$max-mmdd) {
				text-align: center;
			}
			p {
				text-align: left;
				@media(vars.$max-mmdd) {
					padding-bottom: 3%;
				}
			}
			button, a {
				/* font-size: 13px; */
			}
			button { padding: 7px 20px }
		}

		.col.budget-cap_Not-Reached {
			max-width: 400px;
			text-align: start;
			@media(vars.$max-mmdd) {
				text-align: center;
				button {
					margin-top: 5%;
				}
			}
			@media(vars.$mmdd) {
				p {
					float: left;
					text-align: left;
				}
				button {
					float: right;
				}
			}
		}

		.col:last-child {
			// text-align: end; Commenting this out to combat Button (Right) alignment across the board
		}

		.col {
			position: relative;
			width: 100%;
			z-index: 1;

			@media (vars.$max-sm) {
				order: 2;
				padding: 5px 23px;
			}

			@media (vars.$sm) {
				/* max-width: 230px; */
			}

			&:last-child {
				@media (vars.$max-sm) {
					order: 1;
					border-bottom: 1px solid #f1f1ee;
					padding: 23px 15px;
					margin: 0 0 15px;
				}
				@media (vars.$sm) {
					margin-left: auto;
				}
			}
		}

		p {
			font-style: normal;
			font-weight: normal;
			font-size: 13px;
			line-height: 20px;
			letter-spacing: 0.25px;
			color: #060B0D;
			margin-bottom: 0;
		}
	}

	.card-summary {
		padding: 15px 23px 0;

		h6 {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.4px;
			text-transform: uppercase;
			color: #5E6366;
			margin-bottom: 8px;
			@media (vars.$max-sm) {
				text-align: center;
			}
		}

		.promo-summary {
			border: 1px solid #D9D9D9;
			box-sizing: border-box;
			border-radius: 6px;
			@media (vars.$max-sm)  {
				flex-wrap: wrap;
			}

			.col {
				width: 100%;
				max-width: 50%;
				padding: 19px 0;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				@media (vars.$max-sm) {
					border-right: 1px solid #D9D9D9;
					border-bottom: 1px solid #D9D9D9;
				}
				@media (vars.$sm) {
					border-left: 1px solid #D9D9D9;
				}

				&:nth-child(2n) {
					@media (vars.$max-sm) {
						border-right: none;
					}
				}

				&:first-child {
					border-left: 0;
					@media (vars.$max-sm) {

					}
				}

				// REMOVED WHILE WE HIDE THE "First time customers" VALUE
				// &:last-child {
				// 	@media (vars.$max-sm) {
				// 		max-width: 100%;
				// 		border-right: 0;
				// 		border-bottom: 0;
				// 	}
				// }
			}

			h3 {
				font-style: normal;
				font-weight: normal;
				font-size: 24px;
				line-height: 26px;
				text-align: center;
				letter-spacing: 0.1px;
				color: #060B0D;
				margin-bottom: 0;
        @media (vars.$max-sm) {
          font-size: 20px;
        }
        @media (vars.$max-xs) {
          font-size: 16px;
        }
			}

			p {
				font-style: normal;
				font-weight: normal;
				font-size: 10px;
				line-height: 16px;
				text-align: center;
				letter-spacing: 0.1px;
				color: #939393;
				margin-bottom: 0;
        max-width: 70%;
      }
		}
	}

	.card-footer {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 24px;
		padding: 4px 23px;

		button {
			font-style: normal;
			font-weight: normal;
			font-size: 13px;
			line-height: 20px;
			text-align: center;
			letter-spacing: 0.25px;
			color: #181818;
			height: 30px;
			display: flex;
			align-items: center;

			&:hover {
				cursor: pointer;
			}

			.mdi {
				font-size: 24px;
				color: #78909C;
				margin-left: 10px;
			}
		}
	}
}
