@use 'src/scss/inc/vars';

.btn-full { width: 100%; }

.alert {
    border-left: 16px solid vars.$blue;
    border-radius: 8px;
    -webkit-box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    @include vars.padding(top, bottom, left, right);

    & > * {
        max-width: 500px;
        &:last-child { margin-bottom: 0; }
    }

    &.error { border-color: vars.$red; }
}