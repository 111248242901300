@use 'src/scss/inc/vars';

.fullWidth { width: 100%; }
.flex-grow-1 { flex-grow: 1 }

.flex-mobile {
    @media (vars.$max-md) {
        display: flex;
        justify-content: space-between;
    }    
}

.twoCol {
	border-top: 1px solid rgba(6, 60, 85, 0.05);
	margin-top: 3.5%;
	padding-top: 3.5%;
}

.custom-picker {
    position: relative;
    margin-bottom: 3%;
    @media (vars.$max-md) {
        width: 100%;
    }

    label:hover {
        input {
            background-color: #E3E3E3;
        }
    }

    input {
        background-color: #F3F3F3;
        border: none;
        color: black;
        padding: 12px 48px 12px 16px;
        border-radius: 50px;
        font-size: 1em;
        font-family: "Mallory Local", "Rubik Local";
        transition: background-color 300ms cubic-bezier(0.25, 1, 0.5, 1);

        &:focus {
            outline: none;
        }
        &:hover {
            cursor: pointer;
        }
    }
    .mat-icon {
        position: relative;
        top: 5px;
        right: 40px;

        color: grey;

        &:hover {
            cursor: pointer;
        }
    }
}

.dash {
		overflow: hidden;

		.empty-chart { position: absolute; }

		.no-items {
				display: flex;
				flex-direction: column;
				align-items: center;
				color: vars.$analyticsGrey;

				p {
						margin-bottom: 0px;
				}
		}

		.graph-height {
				min-height: 300px;
		}

		.white-box {
				display: block;
				position: absolute;
				top: 80px;
				left: 50%;
				transform: translateX(-50%);
				background-color: white;
				padding: 24px;
				text-align: center;
				color: vars.$analyticsGrey;

				.loadingText { margin: 0; }
		}

		.graph-tile {
				flex: 1 0 48%;
				align-items: center;
				position: relative;
				display: flex;
				background: #fff;
				color: inherit;
				border: 1px solid #E0E0E0;
				box-sizing: border-box;
				box-shadow: 0px 2px 6px rgba(125, 146, 156, 0.08), 0px 1px 2px rgba(8, 35, 48, 0.07);
				border-radius: 6px;
				padding: 16px;
				margin-bottom: 16px;

				&:first-child {
						@media (vars.$md) {
								display: inline-block;
						}
						width: 100%;
				}

				&:hover {
						text-decoration: none;
						border: 1px solid vars.$blue;
						cursor: pointer;
				}

				@media (vars.$max-md) { display: block }
				@media (vars.$xs) { padding: 15px 16px; margin-bottom: 20px; }
				@media (vars.$md) {
						flex: 1 0 20%;
						margin: 0 24px 24px 0;

						&:nth-child(4n) { margin-right: 0; }
						&.active {
								background: #F1F9FD;
								border: 1px solid vars.$blue;
						}
				}
				.twoCol {
						.greyBorder {
								border-left: 1px solid vars.$analyticsGrey;
						}
						.col {
								&:first-child {
										margin-right: 9%;
								}
								display: inline-block;
								.col-title {
										font-size: 0.85em;
										color: vars.$analyticsGrey;
								}
								span {
										display: block;
								}                        
								//flex-grow: 1;
								.textSize2 {
										font-size: 1.2em;
								}
						}
				}            
				.mini-chart {
						width: 80px;
						svg line {
								-webkit-filter: drop-shadow( 0 5px 6px vars.$blue);
								filter: drop-shadow( 0 5px 6px vars.$blue);
						}
				}
				.charts-wrapper {
						overflow: visible;

						.charts-css {
								min-width: 100px;
						}
				}
				.mobileOverflow {
						overflow: visible;
						svg { overflow: visible; }
				}

				@media (vars.$md) {

				}
		}

		.info-tile {
				//position: relative;
				//display: block;
				background: #fff;
				color: inherit;
				border: 1px solid #E0E0E0;
				box-sizing: border-box;
				box-shadow: 0px 2px 6px rgba(125, 146, 156, 0.08), 0px 1px 2px rgba(8, 35, 48, 0.07);
				border-radius: 6px;
				padding: 16px;
				margin: 0 0 16px 0;
				flex: 1;

				@media (vars.$xs) {
						//padding: 24px 16px;
						//margin-bottom: 24px;
				}
				@media (vars.$md) {
						//flex: 1 0 17%;
						//margin: 0 24px 24px 0;
						//padding: 24px 16px;
				}
		}

		.attention {
				border: 2px solid vars.$red;
		}

		& > section {
				position: relative;
				border-bottom: 10px solid #F5F6F9;

				&:last-child { border-bottom: 0; }

				.third {
						border-bottom: 10px solid #F5F6F9;

						@media (vars.$md) {
								border-right: 10px solid #F5F6F9;
								flex: 1 0 50%;
								&:nth-child(2n),
								&:last-child { border-right-width: 0; }
						}
						@media (vars.$lg) {
								border-right: 10px solid #F5F6F9;
								flex: 1 0 22%;
								&:nth-child(2n) { border-right-width: 10px; }
								&:nth-child(3n),
								&:last-child { border-right-width: 0; }
						}
				}
		}

		.label {
				position: relative;
				color: vars.$analyticsGrey;
				text-transform: uppercase;
				font-weight: 400;

				@media (vars.$sm) { font-size: 0.9rem; }
				.mdi {
						font-size: 1.4em;
						vertical-align: middle;
						padding-top: 2px;
						margin-left: 2px;

						.info-view-details {
							margin-left: -100px;
							@media (vars.$max-md) {
								right: 0;
								left: 0;							
							}
						}

						.align-top {
							top: calc(10% - 180px) !important;
						}
						

						.info {
								display: none;
								position: absolute;
								text-transform: none;
								min-width: 300px;
								top: 20px;
								color: vars.$black;
								background-color: #fff;
								padding: 24px;
								border-radius: 8px;
								z-index: 3;
								-webkit-box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
												box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
								h6 { margin-bottom: 8px; }
								p:last-child { margin: 0; }
						}
						&:hover,
						&:focus {
								outline: none;
								.info { display: block; }
						}
				}
		}
		.graph-tile,
		.info-tile {
				.title {
						font-size: 1.77rem;
						line-height: 1.77rem;
						margin: 4px 0;
						span { white-space: nowrap; }
						small { line-height: 1.5rem; }
						.mdi:before {
								position: relative;
								top: -6px;
								vertical-align: bottom;
						}
				}
		}
		.desc {
				color: vars.$analyticsGrey;
				margin: 0;
				@media (vars.$sm) { font-size: 0.9rem; }
		}


		.comparison {
				color: vars.$analyticsGrey;
				font-size: 1rem;
		}

		@media (vars.$md) {
				// display: flex;
				.graph-tiles {
						width: 25%;
						margin-right: 24px;
						padding: 23px;
				}
				.mini-chart {
						display: none;
				}
		}
}

.op-quality {
		h3 { line-height: 1.5em; margin: 0 16px 0 0; }
		.heading {
				display: flex;
				align-content: center;

				.attention {
						font-size: 0.9rem;
						font-weight: 600;
						line-height: 1.6rem;
						padding: 8px 5px 8px 12px;
						border: 0;
						border-radius: 50px;
						white-space: nowrap;
						&.red { background-color: #e0202040; color: #e02020; }
						&.green { background-color: #3EA13D40; color: #3EA13D; }
						span { display: inline-block; line-height: 1.1rem; height: 1.3rem; }
						.mdi {
								// height: 1.1rem;
								font-size: 32px;
								// line-height: 23px;
								vertical-align: middle;
								margin-left: 8px;

								&:before { line-height: inherit; }
						}
				}
		}

		.sub-heading { padding: 14px 0 34px 0; }

		@media (vars.$md) {
				.info-tile-container {
					display: flex;
					justify-content: space-between;
					.info-tile { margin-bottom: 0; }
					.info-tile:last-child { margin-right: 0; }
				}
		}
}

.top-selling-items {
	.description {
		line-height: 1.5em;
	}
}

.header-data {
	h3 {
		.label {
			display: inline-block;
			position: relative;
			top: -4px;
			font-size: 0.6em;
			color: vars.$black;
		}
	}
}

.ranking-bubble {
	margin-right: 9px;
	background-color: vars.$greyLight;
	border-radius: 100%;
	padding: 0 11px;
}


.tabs {
		//border-bottom: solid 1px #ccc;
		button { padding: 12px 32px; }
}

.padded { padding: 24px 16px; }

ol {
		position: relative;
		counter-reset: list;
		list-style: none;
		padding: 0;
		margin: 0;
		li {
				line-height: 24px;
				padding-right: 32px;
				margin-left: 32px;
				margin-bottom: 12px;
				&:before {
						content: counter(list);
						counter-increment: list;
						position: absolute;
						left: 0;
						display: inline-block;
						background-color: #F2F2F2;
						border-radius: 24px;
						width: 24px;
						line-height: 24px;
						text-align: center;
						margin-right: 12px;
				}
				span:last-child {
						position: absolute;
						right: 0;
						font-weight: 600;
				}
		}
}



