@use 'src/scss/inc/vars';

.tab-group {
	display: flex;
	flex-wrap: wrap;
	@include vars.margin();

	button {
		border-radius: 10px;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: 0.44px;
		margin-bottom: 8px;
	}

	&.day-selector {
		button {
			// min-width: 160px;
			// display: flex;
			// align-items: center;
			// justify-content: center;
			// height: 40px;
			border: 1px solid #D9D9D9;
			padding: 8px 24px;

			@media (vars.$sm) { padding: 8px 38px; }

			&:first-child { border-radius: 10px 0px 0px 10px; }
			&:last-child { border-radius: 0px 10px 10px 0px; }

			&.active { background-color: vars.$btnBlue; color: #fff; }
		}
	}
}

.tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px;
    width: 100%;
    button {
      padding: 6px 24px;

	  &:hover { color: vars.$blue; }
      &.active {
        color: inherit;
        font-weight: 500;
        border-bottom: solid 2px vars.$blue;
      }
    }

  }
