
.site-logo {
	padding: 56px 0 35px;
	display: flex;
	img {
		width: 48px;
		height: auto;
		display: block;
		margin: 0 auto;
	}
}
