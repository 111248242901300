.toggle {
	font-size: 0.94rem;
	letter-spacing: 0.3px;
	width: 100% !important;

	input {
		display: none;
		-webkit-appearance: none;
		opacity: 0;
		width: 0;
		height: 0;
	}

	input:checked + span {
		background:rgba(30,190,254,0.2);
		&:before {
			left: 14px;
			background:#1EBEFE;
		}
	}

	span {
		width: 34px;
		height: 14px;
		background:#5E6366;
		border-radius: 100px;
		position: relative;
		transition: ease all 0.2s;
		margin-right: 11px;

		&:before {
			content: "";
			position: absolute;
			width: 20px;
			height: 20px;
			background: #F7F9FA;
			box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.24), 0px 2px 6px rgba(8, 35, 48, 0.16);
			border-radius: 100%;
			left: 0;
			transition: ease all 0.2s;
			top: -3px;
		}
	}

	&.disabled {
		span, input:checked + span {
			background: #F2F2F2;
			&:before { background: #C1C2C2; }
		}
	}
}
