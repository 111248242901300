@use 'src/scss/inc/vars';

.avatar {
  display: inline-block;
  height: 24px;
  width: 24px;
  border-radius: 24px;
  color: white;
  text-align: center;
  margin-right: 7px;
  background-color: vars.$coal;
}

.avatar-40 {
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  color: white;
  text-align: center;
  border: 1px solid vars.$greyLight;
	padding: 1px;

  &.no-image {
    background-color: vars.$greyLight;
  }
}