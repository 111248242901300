/*
 * Line Chart Correction
 */
.charts-css.line {
  --line-size: 3px;

  // Area
  &:not(.reverse) {
    &:not(.reverse-data) {
      tbody tr td {
          svg {
            position: absolute;
            width: 100%;
            height: 100%;
          }
          &:before { content: none; display: none; }
      }
    }
  }

}