@use 'src/scss/inc/vars';

.promos {
	.modal {
		width: 100% !important;
		max-width: 1024px !important;
		.body {
			max-height: 100% !important;
			.promo-card:first-child {
				.card-description {
					.col:last-child {
						display: none;
						width: 0;
					}
				}
			}			
			.promo-card:not(:first-child) {
				.card-summary {
					padding-bottom: 3%;
				}
				.status {
					flex-grow: 1;
					text-align: end;
					text-transform: uppercase;
				}
				.btn {
					padding: 1%;
					margin-bottom: 2%;
				}
			}
		}
	}
}

.cofuded-promo-modal .modal {
	height: auto !important;
	.header {
		padding-bottom: 0 !important;
		border-bottom: 0 !important;
	}
}

.promo-modal-close {
	position: absolute;
	right: 10px;
	top: 4px;
	font-size: 12px;
	align-items: center;

	@media (vars.$max-sm) {
		top: 10px;
	}

	&:hover {
		cursor: pointer;
	}

	.mdi {
	    font-size: 20px;
	    margin-left: 8px;
	    color: #000;
	}
}
.alert-overlay.promos {
	display: block !important;

	.alert-dialog {
		max-width: 824px;
		position: relative;
		background: #fff;
		border-radius: 6px 6px 0 0;
		padding: 0 0 20px;
		@media (vars.$sm) {
			margin: 40px auto;
		}
	}

	.promo-card {
		box-shadow: none;
		@media (vars.$max-sm) {
			margin: 0 0 25px;
		}
		p {
			margin: 0;
		}

		&.Active {
			.status {
				margin-right: 25px;
			}
		}

		&.Ended {
			.status {
				margin-right: 25px;
			}
		}
		&.Stopped {
			.status {
				margin-right: 25px;
			}
		}

		&.Stopped {
			.status {
				margin-right: 25px;
			}
		}

		.card-header {
			h6 {
				@media (vars.$max-sm) {
					display: flex;
					font-size: 18px;
					line-height: 28px;
					letter-spacing: 0.15px;
				}
				.modal-back {
					@media (vars.$max-sm) {
						display: block;
						.mdi {
							font-size: 20px;
							margin: 0 10px 0 0;
						}
					}
				}

				span {
					@media (vars.$max-sm) {
						font-weight: inherit;
						margin: 0 0 0 10px;
					}
				}
			}
		}
	}

	.modal-promo-card {
		.card-summary-header {
			align-items: center;
			margin-bottom: 10px;
			display: flex;
			flex-direction: column;
			@media (vars.$sm) {
				flex-direction: row;
			}
			h6 {
				margin-bottom: 0;
			}

			.status {
				font-size: 10px;
				line-height: 16px;
				text-align: center;
				letter-spacing: 0.4px;
				background: #EAF6FD;
				border-radius: 99px;
				padding: 2px 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 10px;
				text-transform: capitalize;
				margin-left: 50px;
				@media (vars.$sm) {
					margin-left: 20px;
					margin-top: 0;
				}
			}

			.btn {
				margin-top: 20px;
				margin-bottom: 20px;
				float: right;
				background: #fff;
				font-weight: normal;
				color: #000;
				font-size: 10px;
				line-height: 16px;
				letter-spacing: 0.4px;
				width: 100%;
				max-width: 79px;
				min-width: 79px;
				padding: 3px 8px;
				@media (vars.$sm) {
					margin-top: 0;
					margin-bottom: 0;
					margin-left: auto;
				}
			}
		}
	}

}

.restaurant-list {
	ul {
		text-align: left;
		padding: 0 20px 20px 40px;
		text-transform: capitalize;			
	}	
}
