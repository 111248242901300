@use "../inc/vars";
@use "./px-rem" as px;

.safari_mobile_only { // originally implemented for Online Sales iframe fix for iOs Safari - ticket RSSP-2610
	padding-bottom: 220px;
}

/* generic */
.container {
	margin: 0 auto;
	width: 100%;
	padding: 0 24px;
	@media (vars.$sm) {
		padding: 0 64px;
	}
}

::-webkit-file-upload-button {
	background: rgba(6, 60, 85, 0.05);
	color: rgba(6, 60, 85, 0.05);
	padding: 1em;
	border: none;
}

.file-upload {

	.file-details {
		text-align: left;
		margin-bottom: 3%;
		border-radius: 10px;
		border: 1px solid vars.$btn-border;
		padding: 2%;
		font-size: 0.9em;
		.file-name {
			display: flex;
			align-items: center;
			i {
				font-size: 1.8em;
				color: grey;
				margin-right: 1.2%;
			}
			span {
				text-transform: capitalize;
			}
		}
	}

	.upload-success {
		border: 2px solid vars.$green;
	}
	.upload-failed {
		border: 2px solid vars.$red;
	}

	.file-upload-buttons {
		display: flex;
		float: right;
		flex-wrap: wrap;
		gap: 10px;
	}

	.file-area {
		width: 100%;
		position: relative;
		background: rgba(6, 60, 85, 0.05);
		border-radius: 14px;
		padding: 5%;
		margin-bottom: 3%;
		/* font-size: 18px; */
		.grey-text {
			color: #878D91;
		}



		p {
			margin-bottom: 5px;
		}

		.select-input {
			margin: 0 20%;
			border: 1px solid vars.$btn-border;
			border-radius: 60px;
			padding: 1% 10%;
			font-size: 0.9em;
			font-weight: 500;
		}

		.file-dummy {
			width: 100%;
			padding: 50px 30px;
			border: 2px dashed vars.$btn-border;
			background-color: #fff;
			text-align: center;
			transition: background 0.3s ease-in-out;
			.success {
				display: none;
			}
		}
		&:hover {
			.file-dummy {
				border: 2px dashed #1abc9c;
			}
		}
		input[type=file] {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			cursor: pointer;
		}
		input[type=file]:valid + .file-dummy {
			border-color: #1abc9c;
		}
		input[type=file]:valid + .file-dummy .success {
			display: inline-block;
		}
		input[type=file]:valid + .file-dummy .default {
			display: none;
		}
	}


}

.clearfix {
	overflow: auto;
	&:after {
		content: "";
		clear: both;
		display: table;
	}
}

.fullPageWidth { width: 100%;}


.modal-terms-conditions {
	.modal-body {
		text-align: left;
	}
}

.fLeft { float: left }
.fRight { float: right }

button {
	&:disabled {
		background-color: rgba(0, 0, 0, 0.2) !important;
		color: rgba(0, 0, 0, 0.4) !important;
	}
}

.devErMsg {
	color: vars.$red;
	text-align: right;
	@media (vars.$max-smm) {
		text-align: center;
    font-size: 0.8em;
	}
}

.text-end { text-align: end;}

.capitalize { text-transform: capitalize }

.lock-screen { overflow: hidden }

.centerContainer {
	max-width: 640px;
	margin: 0 auto;

	&.large { max-width: 832px; }
	&.extraLarge { max-width: 1024px; }
}

.absolute { position: absolute; }
.relative { position: relative; }

.flex { display: flex; }
.align-center { align-items: center; }
@media (vars.$md) {
	.flexDesktop { display: flex; justify-content: space-between; flex-wrap: wrap; }
}

.block { display: block; }
.inline-block { display: inline-block; }

.textCenter { text-align: center; }
.textLeft { text-align: left; }

@media (vars.$max-xs) {
	.textRight-mobile { text-align: right; }
}

.error-text { color: vars.$red; }

.padded { padding: 16px; }

.margin { margin-top: 16px; margin-bottom: 16px; }
.marginSmall { margin-bottom: 8px; }
.marginTop { margin-top: 16px; }
.marginBottom {
  margin-bottom: 16px;
  &--10 {
    margin-bottom: 10px;
  }
}
.marginTopLarge { margin-top: 64px; }
.noMargin { margin: 0 !important; }

.padding-small { padding: 8px 0;}
.padding-small-top { padding-top: 8px;}
.padding-small-bottom { padding-bottom: 8px;}
.padding-medium { padding: 12px 0;}
.padding-medium-top { padding-top: 12px;}
.padding-medium-bottom { padding-bottom: 12px;}
.noPadding { padding: 0 !important; }

.text-small { font-size: 0.75em;}

.countBadge {
  color: #fff;
	background-color: vars.$blue;
  font-size: 0.7em;
  position: absolute;
  min-width: auto;
  top: -4px;
  right: 0;
  width: 18px;
  height: 18px;
  text-align: center;
  border-radius: 18px;
  line-height: 18px;

  -webkit-transition: all 300ms cubic-bezier(0.25, 1, 0.5, 1);
       -o-transition: all 300ms cubic-bezier(0.25, 1, 0.5, 1);
          transition: all 300ms cubic-bezier(0.25, 1, 0.5, 1);
}

.pill {
	border-radius: 15px;
	color: #E02020;
	min-width: 85px;
	display: inline-block;
	font-size: 0.85em;
	text-transform: capitalize;
	font-weight: 500;
	position: relative;
	text-align: center;
}

.search-input {
	position: relative;
	font-size: 1.5em;
	margin-right: 1%;
	width: 100%;
	max-width: 508px;
	input {
		color: #596368;

		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 20px 40px;
		gap: 8px;
		width: 100%;
		/*width: 508px;*/
		height: 40px;

		background: rgba(6, 60, 85, 0.05);
		border-radius: 60px;

		flex: none;
		order: 0;
		align-self: stretch;
		flex-grow: 1;
		outline: none;
		border: none;
	}
	i {
		position: absolute;
		top: 0.3em;
		font-size: inherit;
		left: 0.5em;
		color: #121212;
	}
}

.redBg {
	background-color: rgba(224, 32, 32, 0.1);
	color: vars.$red;
}

.greenBg {
	background-color: rgba(60, 179, 113, 0.3);
	color: vars.$green;
}

.font-normal { font-weight: 400 !important;}

.showDesktop,
.showDesktopInline,
.showDesktopSmall,
.showOnDesktop { display: block; }
.showOnMobile { display: none; }

@media (vars.$max-sm) {
	.bold_Mobile { font-weight: 500 !important; }
	.hideDesktop { display: none; }
	.showOnMobile { display:  block; }
	.showOnDesktop { display: none; }
	.showDesktopInline { display: none; }
	.hideOnMobile { display: none; }
}
@media (vars.$sm) {
	.padded { padding: 24px; }
	.margin { margin-top: 24px; margin-bottom: 24px; }
	.marginTop { margin-top: 24px; }
	.marginBottom { margin-bottom: 24px; }

	.hideDesktop { display: block; }
	.showMobileSmall { display: none; }
	.showDesktopSmall { display: block; }
	.showDesktopInline { display: none; }
}
@media (vars.$md) {
	.padded { padding: 48px; }
	.showMobile { display: none; }
	.showDesktop { display: block; }
	.showDesktopInline { display: inline-block !important; }
}

.mat-mdc-form-field-flex {
	align-items: center !important;
}

.h3-mobile {
	@media (vars.$max-smm) {
		font-size: 0.85em;
	}
}

.z-index-5 { z-index: 5;}
.color-black { color: vars.$black }
.line-height-1em { line-height: 1em;}

.link-underline {
	text-decoration: underline;
}

.alert {
  &__error {
    background-color: rgba(vars.$red, 0.15);
    border: 1px rgba(vars.$red, 0.30) solid;
    font-size: px.toRem(13);
    padding: px.toRem(15);
    border-radius: px.toRem(20);
  }
}
input.red-border, div.red-border {
	border: 1px solid vars.$red !important;
	&:focus {
		outline: none;
		border: 1px solid vars.$red !important;
	}
}
