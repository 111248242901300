@use "../inc/vars";

.pagination {
	// position: sticky;
	// bottom: 0;
	// border-top: 1px solid #D9D9D9;

	@media (vars.$md) {
		border-top: 0;
	}
	.desktop-pager {
		display: none;
	}
	@media (vars.$sm) {
		.desktop-pager	{
			width: 100%;
			align-items: center;
			justify-content: flex-end;
			display: flex;
			// margin-top: 12px;

			font-style: normal;
			font-weight: normal;
			// font-size: 12px;
			// line-height: 16px;
			letter-spacing: 0.4px;
			color: #919699;
			display: flex;

			.page-limits {
				position: relative;
				margin-bottom: 0;
			}

			.per-limit {

				&:hover {
					cursor: pointer;
				}
				>div {
					color: #000;
					margin-left: 5px;
				}

				>span {
					font-size: 24px;
					margin: -5px 0;
				}
			}

			.pager-action {
				bottom: 30px;
				width: 100%;
			}

			.pager-showing {
				margin-left: 18px;
				margin-right: 29px;
				margin-bottom: 0;
				color: #000;
			}

			.pager-main {
				display: flex;
				align-items: center;
				button {
					margin: 0 5px;
					padding: 0;
					border: none;
					background: #F1F1EE;
					outline: none;
					width: 35px;
					height: 35px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
					margin-right: 4px;

					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					line-height: 22px;
					letter-spacing: 0.1px;
					color: #5E6366;
					min-width: 10px;

					transition: linear all 0.2s;

					&.icon {
						font-size: 18px;
						color: #78909C;
					}

					&:hover {
						cursor: pointer;
						background: #fff;
						box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.16), 0px 2px 6px rgba(8, 35, 48, 0.16);
					}

					&.active {
						background: #fff;
						box-shadow: none;
					}
				}
				.noPrev {
					&.icon {
						opacity: .6;
					}
					&:hover {
						cursor: pointer;
						background: #F1F1EE;
						box-shadow: unset;
					}
				}
				.noNext {
					&.icon {
						opacity: .6;
					}
					&:hover {
						cursor: pointer;
						background: #F1F1EE;
						box-shadow: unset;
					}
				}
			}
		}
	}
	.mobile-pager {
		border-top: 1px solid vars.$greyLight;
		width: 100%;
		padding: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media (vars.$sm) {
			display: none;
		}

		.mdi {
			color: #181818;
			font-size: 20px;
		}

		.showing {
			font-size: 12px;
			line-height: 16px;
			text-align: center;
			letter-spacing: 0.4px;
			color: #919699;

			span {
				color: #181818;
			}
		}
	}
}
