@use 'src/scss/inc/vars';

.dash-header {
	display: none;
	@include vars.margin();

	@media (vars.$sm) {
		display: flex;
	}
	.header-search {
		width: 100%;
		background-color: #F7F9FA;
		// border: solid 1px #F7F9FA;
		border-radius: 30px;
		padding: 10px 14px;
		margin-right: 25px;

		-webkit-transition: background-color 300ms cubic-bezier(0.25, 1, 0.5, 1);
				 -o-transition: background-color 300ms cubic-bezier(0.25, 1, 0.5, 1);
						transition: background-color 300ms cubic-bezier(0.25, 1, 0.5, 1);

		.mdi:before {
			-webkit-transition: text-shadow 800ms cubic-bezier(0.25, 1, 0.5, 1);
					 -o-transition: text-shadow 800ms cubic-bezier(0.25, 1, 0.5, 1);
							transition: text-shadow 800ms cubic-bezier(0.25, 1, 0.5, 1);
		}

		&:focus-within {
			background-color: #F2F4F5;
			.mdi:before { text-shadow: 1px 1px 5px rgba(0,0,0,0.2); }
		}

		.search-bar {
			width: 100%;
		}

		input {
			padding: 0;
			margin: 0;
			border: none;
			background:none;

			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: 0.44px;
			color: #5E6366;
			width: 100%;
			outline: none;
		}

		span {
			display: flex;
			align-items: center;
			font-size: 20px;
			margin-right: 15px;
		}
	}

	.header-action {
		margin-left: auto;

		.btn {
			text-align: center;
			// min-width: 180px;
			margin-bottom: 0;
		}
	}
}

.dash-header-mobile {
	background: #FFFFFF;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.240999);
	// padding: 13px 17px;
	padding: 16px;
	align-items: center;
	position: relative;
	z-index: 0;
	min-height: 72px;

	margin: -16px -16px 16px;

	h1 { margin: 0; }

	@media (vars.$xs) { margin: -32px -32px 32px; }
	@media (vars.$sm) { display: none; }

	.mdi:before { vertical-align: middle; }

	.header-actions { margin-left: auto; }

	.button {
		background:none;
		color: #060B0D;
		box-shadow: none;
		border-radius: 0;
		padding: 0;
		margin: 0 0 0 15px;
		position: relative;

		span {
			margin: 0;
			font-size: 2rem;
		}
	}

	// .nav-search {}
}

.header-actions { margin-left: auto; }

.mobile-search {
	position: relative;
	top: 0;
	margin: -16px  -16px  16px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.240999);

	.form-inner {
		display: flex;
		flex-direction: column;
	}

	.search-bar {
		background: #fff;
		padding: 16px 9px;
		min-height: 72px;

		button {
		    width: 40px;
		    padding: 0;
		    margin: 0 0 0 -8px;
		    font-size: 22px;
		}

		input {
		    width: 100%;
		    margin: 0;
		    padding: 0;
		    border: 0;
		    font-size: 16px;
		    background: #f3f3f3;
		    border-radius: 100px;
		    padding: 0 15px;
		}
	}

	@media (vars.$xs) { margin: -32px  -32px  32px; }
	@media (vars.$sm) { display: none; }
}

// Dash Headers when in a create / edit screen

.dash-header-image {
	background:#EAF6FD;
	position: relative;
	@include vars.margin();
	margin-top: -16px;
	margin-left: -16px;
	margin-right: -16px;
  @media (vars.$xs) {
    margin-top: -32px;
    margin-left: -32px;
    margin-right: -32px;
  }

	@media (vars.$sm) {
		display: none;
	}

	.icon-back {
		position: absolute;
		float: left;
		left: 15px;
		top: 15px;
		font-size: 25px;
	}


}
.title-header {
	align-items: center;
	display: flex;
	max-width: 640px;
	// padding: 24px;
	@include vars.margin();

	@media (vars.$sm) {
		padding: 0;
	}

	&.single-promo {
		.icon-back {
			@media (vars.$max-sm) {
				display: none;
			}
		}
	}

	.header-left {
		position: relative;
		// align-items: center;
		// width: 100%;
		// margin-left: -40px;

		h1, h2, h3, h4 {
			margin-bottom: 16px;
			&:last-child { margin-bottom: 0; }
		}
		& > p:last-child { margin-bottom: 0; }

		.icon {
			top: 0;
			left: -40px;
			width: 30px;
			height: 30px;
			font-size: 24px;
			margin: 0;
			padding: 4px;
			border: none;
			background: none;
			margin-right: 10px;
			cursor: pointer;
		}

		@media (vars.$sm) {
			// margin-left: -50px;
			.icon {
				position: absolute;
				left: -50px;
				width: 40px;
				// height: 40px;
				font-size: 30px;
			}
		}
	}

	.header-right {
		margin-left: auto;
		display: flex;

		.mdi {
			font-size: 25px;
			color: #060B0D;
			transform: rotate(90deg);
			position: relative;
			float: right;
			cursor: pointer;

			&:before {
				position: relative;
				top: 1px;
			}
		}

		.action-menu {
			right: 0;
			top: 55px;
			min-width: 126px;
		}
	}
}

.promotions {
	box-shadow: none;
	@media (vars.$sm) {
		display: none;
	}

	.header-actions {
		a { min-width: 30px; }
		.mdi { margin: 0; }
		// a {
		// 	background: none;
		// 	box-shadow: none;
		// 	border-radius: 0;
		// 	max-width: 50px;
		// 	min-width: 50px;
		// 	display: flex;

		// 	width: 50px;
		// 	height: 50px;
		// 	padding: 10px;

		// 	.mdi { padding: 10px 0; }
		// 	.mdi:before {
		// 		font-size: 1.6rem;
		// 	}
		// }
		// .mdi {
		// 	font-size: 30px;
		// 	color: #000;
		// }
	}

}

.header:focus-within {
  & ~ .body .user-action {
    display: block;
  }
  .unselect {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 60px;
  }
}
.user-action {
  display: none;
}
.user-action:active {
  display: block;
}
