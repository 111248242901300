@use "../inc/vars" as vars;

form.edit-campaign {
	.range-divider {
		margin: 10px 20px 0 20px;
	}	
	section {

			label { margin: 0; }

			div.form-row {
					margin-bottom: 24px;
			}

			& #firstSection {
					div.form-row:last-child {
							margin-bottom: 0;
					}
			}

			select { margin-left: 8px; }

			input[type=text], input[type=number], input[type=date], select {
					background: none;
					padding: 7px 12px;
					border: 1px solid #C6CACC;
					box-sizing: border-box;
					border-radius: 4px;
					font-family: 'Mallory Local', 'Rubik Local';
					line-height: 24px;
					letter-spacing: .44px;
					color: #181818;
			
					@media (vars.$sm) {
							font-size: 16px;
					}

					&[disabled] {
							background-color: #f3f3f3;
					}
			}
			
			input[type=text]:not(.material-picker-input) { width: 50%;  }
			.material-picker-input { width: 120px; }
			input[type=date] { width: 12em; }
			
			input[type=text] {
					@media (vars.$max-sm) {
							width: 100%;
					}
			}

			input {
					margin-bottom: 6px;
			}

			input {
					padding-right: 20px;
					display: inline;
			
					+ span {
							margin-left: -20px;
					}
			}

			div#restaurantIdsRow {
					width: 50%;

					@media (vars.$max-sm) {
							width: 100%;
					}

					textarea {
							resize: none;
							background-color: transparent;
							border: 1px solid #C6CACC;
							padding: 7px 12px;
							border-radius: 4px;
							font-family: "Mallory Local", "Rubik Local";
							font-size: 13px;
							line-height: 24px;
							letter-spacing: 0.44px;
							
							@media (vars.$sm) {
									font-size: 16px;
							}

							width: 100%;

							&[disabled] {
									background-color: #f3f3f3;
							}
					}

					div#validateButtonContainer {
							width: 100%;
							display: flex;
							align-items: center;

							.success-validation {
									color: green;

									span {
											position: relative;
											top: 2px;
									}
							}

							button {
									margin-right: 12px;
									line-height: 1rem;
									max-height: 6rem;

									&.disabled {
											background-color: lighten(vars.$btnBlue, 20%);
									}
							}
					}
			}
	}
	.time-slots {
		display: flex;	
		flex-wrap: wrap;
		margin-bottom: 20px;
	
		button {
			border: 1px solid #C6CACC;
			border-radius: 5px;
			margin-right: 10px;
			padding: 5px 10px 5px 10px;
	
			&.selected {
				background-color: #6ECFF5;
				border: 1px solid #6ECFF5;
			}
	
			&:hover {
				cursor: pointer;
			}
	
			&:disabled {
				background-color: #f3f3f3 !important;
				border: 1px solid #C6CACC;
			}
	
			&:last-child {
				margin: 0;
			}
		}
	}	
}

.form-error {
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.4px;
	color: #E02020;

	&.whole-form-error {
			&.showMobileSmall {
					margin-bottom: 6px;
			}

			&.showDesktopSmall {
					@media (vars.$sm) {
							display: inline-block;
					}
			}
	}
}

.campaign-container {
  .intro {
    margin: 1rem 0 2rem 0;
    span {
      color: gray;
    }
    h2 {
      margin: .75rem 0 0 0;
      line-height: 2rem;
      max-width: 580px;
    }
  }
  .campaign-form {
    margin-top: 2rem;
    padding: .8rem;
    border: 1px solid #DDE2E5;
    border-radius: 7px;
    > section {
      padding-bottom: unset;
    }
    // input fields
    .input-field {
      position: relative;
      display: flex;
      align-items: flex-start;
      margin-bottom: 1.5rem;
      > article {
        display: flex;
        flex-direction: column;
        min-width: 200px;
        label {
          margin: 0;
        }
        span {
          color: gray;
          font-size: .85rem;
        }
      }
      > div {
        position: relative;
        width: 100%;
        input, textarea {
          width: 100%;
          padding: 0.7rem 2.2rem .7rem 0.5rem;
          border: 1px solid lightgrey;
          border-radius: 10px;
          outline: none;
          margin-bottom: 0;
        }
        textarea {
          resize: none;
          font-family: inherit;
          max-height: 100px;
        }
        .count {
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
          color: lightgray;
        }
      }
      .invalid-feedback {
        position: absolute;
        top: 100%;
        right: 0;
        text-align: right;
        font-size: 0.5rem;
        color: red;
        display: block;
      }
    }

    // Spacer
    .spacer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.5rem;
    }

    // form pills
    .form-pills {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 1.5rem;
      > article {
        min-width: 200px;
      }
      .form-spacer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.7rem;
      }
      .invalid-feedback {
        position: absolute;
        top: 100%;
        right: 0;
        text-align: right;
        font-size: 0.5rem;
        color: red;
        display: block;
      }
    }

    // All h5s in the .campaign-form class
    h5 {
      margin-bottom: .5rem;
    }

    // Form selects
    .form-select {
      position: relative;
      display: flex;
      align-items: flex-start;
      margin-bottom: 1.5rem;
      .form-select-text {
        min-width: 200px;
        label {
          display: inline-block;
          position: relative;
          padding-left: 33px;
          cursor: pointer;
          user-select: none;
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked {
              ~.checkmark {
                background-color: vars.$blue;
                &::after {
                  opacity: 1;
                }
              }

            }
          }
          .checkmark {
            position: absolute;
            top: 1px;
            left: 0;
            height: 22px;
            width: 22px;
            border: 2px solid #eee;
            transition: background-color 0.25s ease;
            border-radius: 50%;
            &::after {
              content: "";
              position: absolute;
              left: 6px;
              top: 3px;
              width: 5px;
              height: 10px;
              border: solid #333;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
              opacity: 0;
              transition: opacity 0.25s ease;
            }
          }
        }
      }
      .form-select-fields {
        display: flex;
        align-items: center;
        input {
          width: 100%;
          padding: 0.7rem 0.5rem;
          border: 1px solid lightgrey;
          border-radius: 10px;
          outline: none;
        }
        span {
          display: block;
          margin: 0 .5rem;
        }
      }
      .form-select-multi-fields {
        width: 100%;
        // transform: translateY(-10px);
        .dropdown-select {
          margin-bottom: 1rem;
          .button {
            background-color: unset;
            border: 1px solid black;
            padding: 12px 90px 12px 16px;
            border-radius: 6px;
          }
        }
        .form-fields-content {
          div.mat-form-field-wrapper {
            padding-bottom: 0;
            div.mat-form-field-flex {
              display: flex;
              align-items: center;
              background-color: unset;
              border: 1px solid black;
              border-radius: 6px;
              padding: 0;
              div.mat-form-field-infix {
                width: 120px;
                padding: 0em 0 0.7em 10px;
              }
            }
            div.mat-form-field-underline {
              display: none;
            }
          }
          app-date-input {
            input {
              width: 100%;
              border: none;
              font-size: inherit;
              outline: none;
            }
          }
          .and {
            display: inline-block;
            margin: 0 7px;
          }
          .input-days {
            display: flex;
            align-items: center;
            input {
              padding: 0.7rem 1.5rem .7rem 0.5rem;
              border: 1px solid lightgrey;
              border-radius: 10px;
              outline: none;
              margin-bottom: 0;
            }
          }
        }

        .invalid-feedback {
          position: absolute;
          top: 100%;
          right: 0;
          text-align: right;
          font-size: 0.5rem;
          color: red;
          display: block;
        }
      }
    }

    // Form dropdowns
    .form-dropdown {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 1.5rem;
      .form-dropdown-text {
        min-width: 200px;
      }
      .form-dropdown-fields {
        div {
          margin: 0;
          div.button {
            padding: 8px 35px 8px 16px;
            border-radius: 6px;
            background-color: unset;
            border: 1px solid black;
            span.mdi {
              transform: translateX(5px);
            }
          }
          div.options {
            padding: 8px 13px 8px 13px;
            min-width: 90px;
            max-height: 230px;
            overflow: scroll;
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: none;  /* Firefox */
            &::-webkit-scrollbar {
              display: none;  /* Safari and Chrome */
            }
          }
        }
        > span {
          display: inline-block;
          margin: 0 5px;
          &:nth-of-type(2) {
            margin: 0 7px;
            &::before {
              transform: translateY(4px);
            }
          }
        }
      }
      .invalid-feedback {
        position: absolute;
        top: 100%;
        right: 0;
        text-align: right;
        font-size: 0.5rem;
        color: red;
        display: block;
      }
    }

    //Form dates
    .form-dates {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 1.5rem;
      .form-dates-text {
        min-width: 200px;
      }
      .form-dates-fields {
        div.mat-form-field-wrapper {
          padding-bottom: 0;
          div.mat-form-field-flex {
            display: flex;
            align-items: center;
            background-color: unset;
            border: 1px solid black;
            border-radius: 6px;
            padding: 0;
            div.mat-form-field-infix {
              width: 120px;
              padding: 0em 0 0.7em 10px;
            }
          }
          div.mat-form-field-underline {
            display: none;
          }
        }
        app-date-input {
          input {
            width: 100%;
            border: none;
            font-size: inherit;
            outline: none;
          }
        }
        > span {
          display: inline-block;
          margin: 0 7px;
          &::before {
            transform: translateY(4px);
          }
        }
      }
      .invalid-feedback {
        position: absolute;
        top: 100%;
        right: 0;
        text-align: right;
        font-size: 0.5rem;
        color: red;
        display: block;
      }
    }

    // All 'add' buttons in .campaign form class
    .add-button {
      border: 1px solid lightgray;
      width: 100%;
      text-align: left;
      border-radius: 7px;
      padding: 0 0.5rem;
      display: flex;
      align-items: center;
      span {
        margin-right: .4rem;
        font-size: 1.3rem;
      }
    }
    // All 'edit' buttons in .campaign form class
    .edit-button {
      font-weight: 600;
      color: vars.$blue;
    }
  }
}

// Pills styling
.pills-container {
  display: flex;
  justify-content: flex-end;
  label {
    margin: 0 8px 0 0;
    &:last-child {
      margin: 0;
    }
    input[type="radio"], input[type="checkbox"] {
      visibility: hidden;
      position: absolute;
      width: 0;
      height: 0;
    }
    div, a {
      position: relative;
      display: inline-block;
      padding: 8px 14px;
      border: 1px solid black;
      border-radius: 6px;
      width: unset;
      height: unset;
      margin-right: 0;
      &:hover { background-color: #252525; color: white; }
      &:active { background-color: #252525; color: white; }
    }
    a { color: inherit; text-decoration: none; }
    input:checked + div,
    a.active {
      color: white;
      background: #000;
      &:after { width: 100%; left: 0; right: initial; }
    }
    input:disabled + div { background-color: lightgray; color: #E8E8E8; }
    input:disabled:checked + div { background-color: lightgray; color: #E8E8E8; border: 1px solid lightgray;}
  }
  &.rounded {
    display: inline-block;
    border: 1px solid lightgray;
    border-radius: 27px;
    padding: 0.25rem;
    label {
      div, a {
        padding: 5px 22px;
        border-radius: 24px;
        border-color: rgba(0,0,0,.03);
      }
    }
  }
  &.colours {
    label {
      border-radius: 4px;
      width: 20px;
      height: 20px;
      input:checked + div {
        background: none;
        border: 1px solid black;
      }
      div {
        width: 26px;
        height: 26px;
        padding: 0;
        display: block;
        transform: translate(-3px, -3px);
        border-radius: 0;
        border: none;
        &:hover, &:active {
          background: none;
          border: 1px solid black;
        }
      }
    }
  }
}


// Check mark toggle styling
.check-toggle {
  label {
    position: relative;
    display: block;
    width: 60px;
    height: 30px;
    cursor: pointer;
    background: lightgray;
    border-radius: 50px;
    transition: all 0.4s ease;
    span {
      position: absolute;
      top: 50%;
      left: 3px;
      transform: translate(0, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      background: white;
      border-radius: 50%;
      transition: all .4s ease;
      &::before {
        font-size: 1.2rem;
        opacity: 0;
        transition: opacity .4s ease;
        transition-delay: .1s;
      }
    }
  }
  input[type="checkbox"] {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    &:checked + label {
      background: #000;
      span {
        left: calc(100% - 27px);
        &::before {
          opacity: 1;
        }
      }
    }
  }
}
