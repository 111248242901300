@use 'src/scss/inc/vars';

.promo-header {
	.header-left {
		align-items: flex-start;

		h2, h3 { font-weight: 600; margin-bottom: 1%; }
		p {
			font-size: 1.1rem;
			margin-bottom: 8px;
			&:last-child { margin-bottom: 0; }
			span {
				max-width: 80%;
			}
		}

		a {
			color: vars.$blue;
			font-size: 13px;
			line-height: 20px;
			letter-spacing: 0.25px;
			text-decoration: none;
		}
		img { max-width: 60px; margin-bottom: 32px; }
		.incentive > *:last-child { padding-bottom: 24px; }
	}
}

.promo-select {
	.promo-main {
		.promo-option {
			display: flex;
			flex-wrap: wrap;
			box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.24), 0px 2px 6px rgba(8, 35, 48, 0.16);
			margin-bottom: 27px;
			border-radius: 10px;
			border: 1px solid #DDE2E5;
		}
		@media (vars.$sm) {
			display: flex;
			flex-wrap: wrap;
			.promo-option {
				flex-basis: 48%;
				flex-basis: calc(50% - 14px);
				&:nth-of-type(2n-1) {
					margin-right: 27px;
				}
			}
		}
	}

	.card-header {
		width: 30%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-right: 5%;
	}

	.card-body {
		padding: 24px; // 5%
		width: 70%;
		p {
			margin-bottom: 3%;
		}
		button {
			img {
				width: 13px;
				display: inline-block;
				position: relative;
				left: 10px;
				top: 1.5px;	
			}
		}
	}

	h3 {
		font-weight: 500;
		font-size: 20px;
		line-height: 26px;
		color: #060B0D;
		margin-bottom: 3%;
	}

	p {
		font-size: 0.95rem;
		line-height: 24px;
		letter-spacing: 0.44px;
		color: #060B0D;
	}

	ul {
		margin-bottom: 26px;
	}

	li {
		font-size: 11px;
		line-height: 14px;
		letter-spacing: 0.4px;
		color: #000000;
		display: flex;

		&:before {
			font-family: "Material Design Icons";
			content: "\F012C";
			width: 24px;
			height: 24px;
		}
	}
}

#incentiveBadge {
	background-color: #B5E6FB;
	position: fixed;
	bottom: 110px;
	left: 50%;
	font-size: 0.9em;
	// font-weight: 500;
	line-height: 24px;
	padding: 8px 16px;
	border-radius: 5px;
	z-index: 6;

	-webkit-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	        transform: translateX(-50%);

	-webkit-box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
					box-shadow: 0px 3px 15px rgba(0,0,0,0.1);

	@media (vars.$sm) {
		left: initial;
		bottom: initial;
		right: 32px;
		top: 24px;
		padding: 8px 32px;
		-webkit-transform: none;
		    -ms-transform: none;
		        transform: none;
	}
}
