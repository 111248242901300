.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled), .mat-mdc-option:focus:not(.mdc-list-item--disabled), .mat-mdc-option.mat-mdc-option-active, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #6ecff5);
}

.mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #ff4081);
}

.mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #6ecff5;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #6ecff5;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff4081;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff4081;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff4081;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff4081;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #6ecff5;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #ff4081;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #6ecff5;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ff4081;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: #181818;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #6ecff5;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #ff4081;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(24, 24, 24, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-content .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #6ecff5;
}
.mat-datepicker-content .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #ff4081;
}
.mat-datepicker-content .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.mat-datepicker-content .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #6ecff5;
  --mdc-filled-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #ff4081;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #6ecff5;
  --mdc-protected-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #ff4081;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.mat-datepicker-content .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #6ecff5;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #ff4081;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.mat-datepicker-content .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-datepicker-content .mat-mdc-button, .mat-datepicker-content .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-button.mat-primary, .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6ecff5;
  --mat-mdc-button-ripple-color: rgba(110, 207, 245, 0.1);
}
.mat-datepicker-content .mat-mdc-button.mat-accent, .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #ff4081;
  --mat-mdc-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-datepicker-content .mat-mdc-button.mat-warn, .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button, .mat-datepicker-content .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-primary, .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-accent, .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-warn, .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6ecff5;
  --mat-mdc-button-ripple-color: rgba(110, 207, 245, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #ff4081;
  --mat-mdc-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #6ecff5;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #ff4081;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
}
.mat-datepicker-content .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-datepicker-content .mat-mdc-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-raised-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #6ecff5;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff4081;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #6ecff5;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #6ecff5;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff4081;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff4081;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff4081;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff4081;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-progress-bar-background {
  fill: #d7eff9;
}

.mat-progress-bar-buffer {
  background-color: #d7eff9;
}

.mat-progress-bar-fill::after {
  background-color: #6ecff5;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbccdc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbccdc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff4081;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #6ecff5;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #ff4081;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #6ecff5;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #ff4081;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field-disabled .mat-form-field-prefix,
.mat-form-field-disabled .mat-form-field-suffix {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #6ecff5;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ff4081;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #ff4081;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #6ecff5;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ff4081;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #6ecff5;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff4081;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #6ecff5;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff4081;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #6ecff5;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff4081;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #6ecff5;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff4081;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #6ecff5;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ff4081;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #6ecff5;
  color: #181818;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: #181818;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(24, 24, 24, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff4081;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff4081;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 64, 129, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff4081;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #6ecff5;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(110, 207, 245, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #6ecff5;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #6ecff5;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #6ecff5;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff4081;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff4081;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #6ecff5;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: #181818;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(110, 207, 245, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ff4081;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 64, 129, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(212, 255, 255, 0.3);
}
.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #6ecff5;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: #181818;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 128, 171, 0.3);
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff4081;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(212, 255, 255, 0.3);
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #6ecff5;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: #181818;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(24, 24, 24, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: #181818;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #181818;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: #181818;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 128, 171, 0.3);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ff4081;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #ff4081;
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: #181818;
  background: #6ecff5;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #ff4081;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(110, 207, 245, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(110, 207, 245, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(110, 207, 245, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #6ecff5;
  color: #181818;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(110, 207, 245, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #181818;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(110, 207, 245, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(110, 207, 245, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 64, 129, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff4081;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 64, 129, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 64, 129, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 64, 129, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #6ecff5;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #ff4081;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-icon.mat-primary {
  color: #6ecff5;
}
.mat-icon.mat-accent {
  color: #ff4081;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: #181818;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #6ecff5;
  color: #181818;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ff4081;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #6ecff5;
  color: #181818;
}
.mat-toolbar.mat-accent {
  background: #ff4081;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

html, *, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background-color: #fff;
  padding: 0;
  margin: 0;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
}

.content {
  position: relative;
  min-height: 100vh;
  -webkit-transition: margin-left 200ms cubic-bezier(0.25, 1, 0.5, 1);
  -o-transition: margin-left 200ms cubic-bezier(0.25, 1, 0.5, 1);
  transition: margin-left 200ms cubic-bezier(0.25, 1, 0.5, 1);
}
.content.blue-bg {
  background-color: #DCF0FB;
}
.content #fixedHeader,
.content .body-loading {
  -webkit-transition: left 200ms cubic-bezier(0.25, 1, 0.5, 1);
  -o-transition: left 200ms cubic-bezier(0.25, 1, 0.5, 1);
  transition: left 200ms cubic-bezier(0.25, 1, 0.5, 1);
}

.wrapper {
  max-width: 1600px;
  margin: 0;
  padding: 16px;
}
@media (min-width: 480px) {
  .wrapper {
    padding: 32px;
  }
}
@media (min-width: 1024px) {
  .wrapper {
    padding: 48px;
  }
}
@media (min-width: 1400px) {
  .wrapper {
    padding: 40px;
  }
}
@media (min-width: 1800px) {
  .wrapper {
    padding: 80px;
  }
}
.wrapper.noWrapper {
  padding: 0;
}
.wrapper.noWrapper, .wrapper.noWrapper .wrapper {
  max-width: initial;
  margin: initial;
}

ul, li {
  padding: 0;
  margin: 0;
}

img {
  border: 0;
  width: 100%;
  display: block;
}

a {
  color: #181818;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

strong {
  font-weight: 500;
}

pre {
  display: block;
  background-color: #f7f7f7;
  font-size: 0.9em;
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 16px;
  margin: 16px 0;
}

code {
  display: inline-block;
  background-color: #f7f7f7;
  font-size: 0.9em;
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 2px 6px;
  margin: 4px;
}

pre code {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  padding: 16px;
}

hr {
  border: 1px solid #E3E5E5;
  margin: 0;
  margin-top: 24px;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  hr {
    margin-top: 32px;
  }
}
@media (min-width: 1024px) {
  hr {
    margin-bottom: 32px;
  }
}

button, .button {
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: 0;
  outline: none;
}

.grey {
  color: #585858;
}

.red {
  color: #E02020;
}

.green {
  color: #3EA13D;
}

.dark-grey {
  color: #596368;
}

.bold {
  font-weight: 700;
}

.semibold {
  font-weight: 500;
}

.wrap {
  white-space: wrap;
}

.textGrey {
  color: #5E6366;
}

.textLowImportance {
  color: rgba(5, 20, 28, 0.4588235294);
}

.textError {
  color: #E94A1F;
}

.textSuccess {
  color: #3F9047;
}

.bgError {
  background-color: #E94A1F;
}

.bgSuccess {
  background-color: #3F9047;
}

.textLeft {
  text-align: left;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.textSmaller {
  font-size: 0.93rem;
}

.textLarge {
  font-size: 1.2rem;
}

.maxWidth600 {
  max-width: 600px;
  margin: 0;
}

.maxWidth700 {
  max-width: 700px;
  margin: 0;
}

.maxWidth1200 {
  max-width: 1200px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.verticalTop {
  vertical-align: top;
}

.verticalMiddle {
  vertical-align: middle;
}

@font-face {
  font-family: "mdi";
  src: url("../../assets/fonts/icons/mdi.svg?5ab46e96ed968dcbb7011ea9624a10e2#mdi") format("svg"), url("../../assets/fonts/icons/mdi.ttf?5ab46e96ed968dcbb7011ea9624a10e2") format("truetype"), url("../../assets/fonts/icons/mdi.woff?5ab46e96ed968dcbb7011ea9624a10e2") format("woff"), url("../../assets/fonts/icons/mdi.woff2?5ab46e96ed968dcbb7011ea9624a10e2") format("woff2");
}
.mdi:before {
  font-family: mdi !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mdi.mdi-account-cash:before {
  content: "\f101";
}

.mdi.mdi-account-plus:before {
  content: "\f102";
}

.mdi.mdi-account:before {
  content: "\f103";
}

.mdi.mdi-alert-outline:before {
  content: "\f104";
}

.mdi.mdi-arrow-down:before {
  content: "\f105";
}

.mdi.mdi-arrow-left-circle:before {
  content: "\f106";
}

.mdi.mdi-arrow-left:before {
  content: "\f107";
}

.mdi.mdi-arrow-right-circle:before {
  content: "\f108";
}

.mdi.mdi-arrow-right:before {
  content: "\f109";
}

.mdi.mdi-arrow-up:before {
  content: "\f10a";
}

.mdi.mdi-bell:before {
  content: "\f10b";
}

.mdi.mdi-book-open-variant:before {
  content: "\f10c";
}

.mdi.mdi-bullhorn:before {
  content: "\f10d";
}

.mdi.mdi-calendar-month-outline:before {
  content: "\f10e";
}

.mdi.mdi-calendar:before {
  content: "\f10f";
}

.mdi.mdi-cash-multiple:before {
  content: "\f110";
}

.mdi.mdi-chart-line:before {
  content: "\f111";
}

.mdi.mdi-check-decagram:before {
  content: "\f112";
}

.mdi.mdi-check:before {
  content: "\f113";
}

.mdi.mdi-chevron-down:before {
  content: "\f114";
}

.mdi.mdi-chevron-left:before {
  content: "\f115";
}

.mdi.mdi-chevron-right:before {
  content: "\f116";
}

.mdi.mdi-chevron-up:before {
  content: "\f117";
}

.mdi.mdi-clock-outline:before {
  content: "\f118";
}

.mdi.mdi-close-circle:before {
  content: "\f119";
}

.mdi.mdi-close:before {
  content: "\f11a";
}

.mdi.mdi-cog:before {
  content: "\f11b";
}

.mdi.mdi-delete:before {
  content: "\f11c";
}

.mdi.mdi-dots-horizontal:before {
  content: "\f11d";
}

.mdi.mdi-dots-vertical:before {
  content: "\f11e";
}

.mdi.mdi-download:before {
  content: "\f11f";
}

.mdi.mdi-emoticon-happy-outline:before {
  content: "\f120";
}

.mdi.mdi-emoticon-sad-outline:before {
  content: "\f121";
}

.mdi.mdi-eraser:before {
  content: "\f122";
}

.mdi.mdi-eye-off-outline:before {
  content: "\f123";
}

.mdi.mdi-eye-off:before {
  content: "\f124";
}

.mdi.mdi-eye-outline:before {
  content: "\f125";
}

.mdi.mdi-eye:before {
  content: "\f126";
}

.mdi.mdi-face-agent:before {
  content: "\f127";
}

.mdi.mdi-file-document:before {
  content: "\f128";
}

.mdi.mdi-filter:before {
  content: "\f129";
}

.mdi.mdi-format-list-numbered:before {
  content: "\f12a";
}

.mdi.mdi-google-analytics:before {
  content: "\f12b";
}

.mdi.mdi-help-circle-outline:before {
  content: "\f12c";
}

.mdi.mdi-history:before {
  content: "\f12d";
}

.mdi.mdi-home-analytics:before {
  content: "\f12e";
}

.mdi.mdi-image-outline:before {
  content: "\f12f";
}

.mdi.mdi-information-outline:before {
  content: "\f130";
}

.mdi.mdi-information:before {
  content: "\f131";
}

.mdi.mdi-laptop:before {
  content: "\f132";
}

.mdi.mdi-list-status:before {
  content: "\f133";
}

.mdi.mdi-logout-variant:before {
  content: "\f134";
}

.mdi.mdi-logout:before {
  content: "\f135";
}

.mdi.mdi-magnify:before {
  content: "\f136";
}

.mdi.mdi-map-marker:before {
  content: "\f137";
}

.mdi.mdi-menu-down:before {
  content: "\f138";
}

.mdi.mdi-menu:before {
  content: "\f139";
}

.mdi.mdi-message-text:before {
  content: "\f13a";
}

.mdi.mdi-minus-circle-outline:before {
  content: "\f13b";
}

.mdi.mdi-page-first:before {
  content: "\f13c";
}

.mdi.mdi-page-last:before {
  content: "\f13d";
}

.mdi.mdi-pencil-outline:before {
  content: "\f13e";
}

.mdi.mdi-pencil:before {
  content: "\f13f";
}

.mdi.mdi-plus-circle:before {
  content: "\f140";
}

.mdi.mdi-plus:before {
  content: "\f141";
}

.mdi.mdi-refresh:before {
  content: "\f142";
}

.mdi.mdi-sale:before {
  content: "\f143";
}

.mdi.mdi-shopping:before {
  content: "\f144";
}

.mdi.mdi-star-circle:before {
  content: "\f145";
}

.mdi.mdi-star-half-full:before {
  content: "\f146";
}

.mdi.mdi-star-outline:before {
  content: "\f147";
}

.mdi.mdi-star:before {
  content: "\f148";
}

.mdi.mdi-store:before {
  content: "\f149";
}

.mdi.mdi-sync:before {
  content: "\f14a";
}

.mdi.mdi-tag:before {
  content: "\f14b";
}

.mdi.mdi-thumb-down-outline:before {
  content: "\f14c";
}

.mdi.mdi-thumb-down:before {
  content: "\f14d";
}

.mdi.mdi-thumb-up-outline:before {
  content: "\f14e";
}

.mdi.mdi-thumb-up:before {
  content: "\f14f";
}

.mdi.mdi-timer-sand-empty:before {
  content: "\f150";
}

.mdi.mdi-tune:before {
  content: "\f151";
}

.mdi.mdi-upload:before {
  content: "\f152";
}

@font-face {
  font-family: "Roboto Local";
  src: url("../../assets/fonts/roboto/roboto-thin-webfont.woff2") format("woff2"), url("../../assets/fonts/roboto/roboto-thin-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Roboto Local";
  src: url("../../assets/fonts/roboto/roboto-regular-webfont.woff2") format("woff2"), url("../../assets/fonts/roboto/roboto-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto Local";
  src: url("../../assets/fonts/roboto/roboto-medium-webfont.woff2") format("woff2"), url("../../assets/fonts/roboto/roboto-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Mallory Local";
  src: url("../../assets/fonts/mallory/mallorympcmpct-bold-webfont.woff2") format("woff2"), url("../../assets/fonts/mallory/mallorympcmpct-bold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Mallory Local";
  src: url("../../assets/fonts/mallory/mallorympcmpct-book-webfont.woff2") format("woff2"), url("../../assets/fonts/mallory/mallorympcmpct-book-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Mallory Local";
  src: url("../../assets/fonts/mallory/mallorympcmpct-medium-webfont.woff2") format("woff2"), url("../../assets/fonts/mallory/mallorympcmpct-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Gothic Local";
  src: url("../../assets/fonts/gothic/sunset-gothic-bold-pro.woff") format("woff"), url("../../assets/fonts/mallory/mallorympcmpct-medium-webfont.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Rubik Local";
  src: url("../../assets/fonts/rubik/rubik-regular-webfont.woff2") format("woff2"), url("../../assets/fonts/rubik/rubik-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Rubik Local";
  src: url("../../assets/fonts/rubik/rubik-medium-webfont.woff2") format("woff2"), url("../../assets/fonts/rubik/rubik-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Rubik Local";
  src: url("../../assets/fonts/rubik/rubik-bold-webfont.woff2") format("woff2"), url("../../assets/fonts/rubik/rubik-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
.mdi:before {
  display: inline-block;
  speak: never;
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  font-family: "Mallory Local", "Rubik Local";
  font-size: 13px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  margin: 0;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 32px;
  }
}
h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong {
  font-weight: 500;
}
h1 .mdi, h2 .mdi, h3 .mdi, h4 .mdi, h5 .mdi, h6 .mdi {
  font-size: 0.9em;
  line-height: 0.8em;
}
h1 .mdi:before, h2 .mdi:before, h3 .mdi:before, h4 .mdi:before, h5 .mdi:before, h6 .mdi:before {
  font-size: 0.9em;
  line-height: 0.8em;
  vertical-align: baseline;
}

h1 strong, h2 strong {
  font-weight: 600;
}

h1 {
  font-size: 2.488rem;
  line-height: 3.488rem;
  font-weight: 600;
} /* font-family: 'Rubik Local', 'sans-serif'; */
h2 {
  font-size: 2.074rem;
  line-height: 2.978rem;
}

h3 {
  font-size: 1.728rem;
  line-height: 2.728rem;
}

h4 {
  font-size: 1.44rem;
  line-height: 2.34rem;
}

h5 {
  font-size: 1.2rem;
  line-height: 2rem;
}

h6 {
  font-size: 1rem;
  line-height: 1.2rem;
}

p {
  font-size: 1rem;
  line-height: 2rem;
  margin: 0;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  p {
    margin-bottom: 32px;
  }
}

button {
  font-size: 1rem;
  line-height: 2rem;
  font-family: "Mallory Local", "Rubik Local" !important;
}

small {
  font-size: 0.833rem;
  line-height: 1.7rem;
}

th {
  font-size: 0.833rem;
  line-height: 1.7rem;
}

.lineHeightSmall {
  line-height: 1.6rem;
}

@media (min-width: 768px) {
  html, body {
    font-size: 14px;
  }
}
@media (min-width: 1024px) {
  html, body {
    font-size: 15px;
  }
}
.safari_mobile_only {
  padding-bottom: 220px;
}

/* generic */
.container {
  margin: 0 auto;
  width: 100%;
  padding: 0 24px;
}
@media (min-width: 768px) {
  .container {
    padding: 0 64px;
  }
}

::-webkit-file-upload-button {
  background: rgba(6, 60, 85, 0.05);
  color: rgba(6, 60, 85, 0.05);
  padding: 1em;
  border: none;
}

.file-upload .file-details {
  text-align: left;
  margin-bottom: 3%;
  border-radius: 10px;
  border: 1px solid #C6CACC;
  padding: 2%;
  font-size: 0.9em;
}
.file-upload .file-details .file-name {
  display: flex;
  align-items: center;
}
.file-upload .file-details .file-name i {
  font-size: 1.8em;
  color: grey;
  margin-right: 1.2%;
}
.file-upload .file-details .file-name span {
  text-transform: capitalize;
}
.file-upload .upload-success {
  border: 2px solid #3EA13D;
}
.file-upload .upload-failed {
  border: 2px solid #E02020;
}
.file-upload .file-upload-buttons {
  display: flex;
  float: right;
  flex-wrap: wrap;
  gap: 10px;
}
.file-upload .file-area {
  width: 100%;
  position: relative;
  background: rgba(6, 60, 85, 0.05);
  border-radius: 14px;
  padding: 5%;
  margin-bottom: 3%;
  /* font-size: 18px; */
}
.file-upload .file-area .grey-text {
  color: #878D91;
}
.file-upload .file-area p {
  margin-bottom: 5px;
}
.file-upload .file-area .select-input {
  margin: 0 20%;
  border: 1px solid #C6CACC;
  border-radius: 60px;
  padding: 1% 10%;
  font-size: 0.9em;
  font-weight: 500;
}
.file-upload .file-area .file-dummy {
  width: 100%;
  padding: 50px 30px;
  border: 2px dashed #C6CACC;
  background-color: #fff;
  text-align: center;
  transition: background 0.3s ease-in-out;
}
.file-upload .file-area .file-dummy .success {
  display: none;
}
.file-upload .file-area:hover .file-dummy {
  border: 2px dashed #1abc9c;
}
.file-upload .file-area input[type=file] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}
.file-upload .file-area input[type=file]:valid + .file-dummy {
  border-color: #1abc9c;
}
.file-upload .file-area input[type=file]:valid + .file-dummy .success {
  display: inline-block;
}
.file-upload .file-area input[type=file]:valid + .file-dummy .default {
  display: none;
}

.clearfix {
  overflow: auto;
}
.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

.fullPageWidth {
  width: 100%;
}

.modal-terms-conditions .modal-body {
  text-align: left;
}

.fLeft {
  float: left;
}

.fRight {
  float: right;
}

button:disabled {
  background-color: rgba(0, 0, 0, 0.2) !important;
  color: rgba(0, 0, 0, 0.4) !important;
}

.devErMsg {
  color: #E02020;
  text-align: right;
}
@media (max-width: 600px) {
  .devErMsg {
    text-align: center;
    font-size: 0.8em;
  }
}

.text-end {
  text-align: end;
}

.capitalize {
  text-transform: capitalize;
}

.lock-screen {
  overflow: hidden;
}

.centerContainer {
  max-width: 640px;
  margin: 0 auto;
}
.centerContainer.large {
  max-width: 832px;
}
.centerContainer.extraLarge {
  max-width: 1024px;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

@media (min-width: 1024px) {
  .flexDesktop {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.textCenter {
  text-align: center;
}

.textLeft {
  text-align: left;
}

@media (max-width: 480px) {
  .textRight-mobile {
    text-align: right;
  }
}
.error-text {
  color: #E02020;
}

.padded {
  padding: 16px;
}

.margin {
  margin-top: 16px;
  margin-bottom: 16px;
}

.marginSmall {
  margin-bottom: 8px;
}

.marginTop {
  margin-top: 16px;
}

.marginBottom {
  margin-bottom: 16px;
}
.marginBottom--10 {
  margin-bottom: 10px;
}

.marginTopLarge {
  margin-top: 64px;
}

.noMargin {
  margin: 0 !important;
}

.padding-small {
  padding: 8px 0;
}

.padding-small-top {
  padding-top: 8px;
}

.padding-small-bottom {
  padding-bottom: 8px;
}

.padding-medium {
  padding: 12px 0;
}

.padding-medium-top {
  padding-top: 12px;
}

.padding-medium-bottom {
  padding-bottom: 12px;
}

.noPadding {
  padding: 0 !important;
}

.text-small {
  font-size: 0.75em;
}

.countBadge {
  color: #fff;
  background-color: #6ECFF5;
  font-size: 0.7em;
  position: absolute;
  min-width: auto;
  top: -4px;
  right: 0;
  width: 18px;
  height: 18px;
  text-align: center;
  border-radius: 18px;
  line-height: 18px;
  -webkit-transition: all 300ms cubic-bezier(0.25, 1, 0.5, 1);
  -o-transition: all 300ms cubic-bezier(0.25, 1, 0.5, 1);
  transition: all 300ms cubic-bezier(0.25, 1, 0.5, 1);
}

.pill {
  border-radius: 15px;
  color: #E02020;
  min-width: 85px;
  display: inline-block;
  font-size: 0.85em;
  text-transform: capitalize;
  font-weight: 500;
  position: relative;
  text-align: center;
}

.search-input {
  position: relative;
  font-size: 1.5em;
  margin-right: 1%;
  width: 100%;
  max-width: 508px;
}
.search-input input {
  color: #596368;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 40px;
  gap: 8px;
  width: 100%;
  /*width: 508px;*/
  height: 40px;
  background: rgba(6, 60, 85, 0.05);
  border-radius: 60px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  outline: none;
  border: none;
}
.search-input i {
  position: absolute;
  top: 0.3em;
  font-size: inherit;
  left: 0.5em;
  color: #121212;
}

.redBg {
  background-color: rgba(224, 32, 32, 0.1);
  color: #E02020;
}

.greenBg {
  background-color: rgba(60, 179, 113, 0.3);
  color: #3EA13D;
}

.font-normal {
  font-weight: 400 !important;
}

.showDesktop,
.showDesktopInline,
.showDesktopSmall,
.showOnDesktop {
  display: block;
}

.showOnMobile {
  display: none;
}

@media (max-width: 768px) {
  .bold_Mobile {
    font-weight: 500 !important;
  }
  .hideDesktop {
    display: none;
  }
  .showOnMobile {
    display: block;
  }
  .showOnDesktop {
    display: none;
  }
  .showDesktopInline {
    display: none;
  }
  .hideOnMobile {
    display: none;
  }
}
@media (min-width: 768px) {
  .padded {
    padding: 24px;
  }
  .margin {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .marginTop {
    margin-top: 24px;
  }
  .marginBottom {
    margin-bottom: 24px;
  }
  .hideDesktop {
    display: block;
  }
  .showMobileSmall {
    display: none;
  }
  .showDesktopSmall {
    display: block;
  }
  .showDesktopInline {
    display: none;
  }
}
@media (min-width: 1024px) {
  .padded {
    padding: 48px;
  }
  .showMobile {
    display: none;
  }
  .showDesktop {
    display: block;
  }
  .showDesktopInline {
    display: inline-block !important;
  }
}
.mat-mdc-form-field-flex {
  align-items: center !important;
}

@media (max-width: 600px) {
  .h3-mobile {
    font-size: 0.85em;
  }
}

.z-index-5 {
  z-index: 5;
}

.color-black {
  color: #181818;
}

.line-height-1em {
  line-height: 1em;
}

.link-underline {
  text-decoration: underline;
}

.alert__error {
  background-color: rgba(224, 32, 32, 0.15);
  border: 1px rgba(224, 32, 32, 0.3) solid;
  font-size: 0.8125rem;
  padding: 0.9375rem;
  border-radius: 1.25rem;
}

input.red-border, div.red-border {
  border: 1px solid #E02020 !important;
}
input.red-border:focus, div.red-border:focus {
  outline: none;
  border: 1px solid #E02020 !important;
}

.page-loading {
  position: fixed;
  width: 100%;
  min-height: 100vh;
  background: #DCF0FB;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 7;
  animation: fromwhite 300ms;
}

@keyframes fromwhite {
  from {
    background-color: #fff;
  }
  to {
    background-color: #DCF0FB;
  }
}
.body-loading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: 100vh;
  background: #DCF0FB;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.btn .loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn.primary .loader {
  background: #000;
}
.btn.primary .loader .lds-ellipsis div {
  background: #fff;
}
.btn.secondary .loader {
  background: #fff;
}
.btn.secondary .loader .lds-ellipsis div {
  background: #000;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #585858;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.loadingText {
  text-align: center;
  -webkit-animation: fade 800ms infinite;
  animation: fade 800ms infinite;
  margin: 0;
  margin-top: 24px;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .loadingText {
    margin-top: 32px;
  }
}
@media (min-width: 1024px) {
  .loadingText {
    margin-bottom: 32px;
  }
}

@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  51% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  51% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@media (min-width: 1024px) {
  .pagination {
    border-top: 0;
  }
}
.pagination .desktop-pager {
  display: none;
}
@media (min-width: 768px) {
  .pagination .desktop-pager {
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.4px;
    color: #919699;
    display: flex;
  }
  .pagination .desktop-pager .page-limits {
    position: relative;
    margin-bottom: 0;
  }
  .pagination .desktop-pager .per-limit:hover {
    cursor: pointer;
  }
  .pagination .desktop-pager .per-limit > div {
    color: #000;
    margin-left: 5px;
  }
  .pagination .desktop-pager .per-limit > span {
    font-size: 24px;
    margin: -5px 0;
  }
  .pagination .desktop-pager .pager-action {
    bottom: 30px;
    width: 100%;
  }
  .pagination .desktop-pager .pager-showing {
    margin-left: 18px;
    margin-right: 29px;
    margin-bottom: 0;
    color: #000;
  }
  .pagination .desktop-pager .pager-main {
    display: flex;
    align-items: center;
  }
  .pagination .desktop-pager .pager-main button {
    margin: 0 5px;
    padding: 0;
    border: none;
    background: #F1F1EE;
    outline: none;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #5E6366;
    min-width: 10px;
    transition: linear all 0.2s;
  }
  .pagination .desktop-pager .pager-main button.icon {
    font-size: 18px;
    color: #78909C;
  }
  .pagination .desktop-pager .pager-main button:hover {
    cursor: pointer;
    background: #fff;
    box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.16), 0px 2px 6px rgba(8, 35, 48, 0.16);
  }
  .pagination .desktop-pager .pager-main button.active {
    background: #fff;
    box-shadow: none;
  }
  .pagination .desktop-pager .pager-main .noPrev.icon {
    opacity: 0.6;
  }
  .pagination .desktop-pager .pager-main .noPrev:hover {
    cursor: pointer;
    background: #F1F1EE;
    box-shadow: unset;
  }
  .pagination .desktop-pager .pager-main .noNext.icon {
    opacity: 0.6;
  }
  .pagination .desktop-pager .pager-main .noNext:hover {
    cursor: pointer;
    background: #F1F1EE;
    box-shadow: unset;
  }
}
.pagination .mobile-pager {
  border-top: 1px solid #E3E5E5;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .pagination .mobile-pager {
    display: none;
  }
}
.pagination .mobile-pager .mdi {
  color: #181818;
  font-size: 20px;
}
.pagination .mobile-pager .showing {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #919699;
}
.pagination .mobile-pager .showing span {
  color: #181818;
}

.gap-10 {
  gap: 10px;
}

.gap-5 {
  gap: 5px;
}

.gap-8 {
  gap: 8px;
}

.gap-10 {
  gap: 10px;
}

.gap-16 {
  gap: 16px;
}

.grow-1 {
  flex-grow: 1;
}

@media (max-width: 600px) {
  .flex-2-mobile {
    flex: 2 !important;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row .col {
  flex: 1;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.inline-flex-row {
  display: inline-flex;
  flex-direction: row;
}

.inline-flex-column {
  display: inline-flex;
  flex-direction: column;
}

.inline-flex-row-reverse {
  display: inline-flex;
  flex-direction: row-reverse;
}

.inline-flex-column-reverse {
  display: inline-flex;
  flex-direction: column-reverse;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-self-end {
  justify-content: end;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.align-stretch {
  align-items: stretch;
}

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-baseline {
  align-self: baseline;
}

.self-stretch {
  align-self: stretch;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

.flex-7 {
  flex: 7;
}

.flex-8 {
  flex: 8;
}

.flex-9 {
  flex: 9;
}

.flex-10 {
  flex: 10;
}

.flex-11 {
  flex: 11;
}

.flex-12 {
  flex: 12;
}

.flex-13 {
  flex: 13;
}

.flex-14 {
  flex: 14;
}

.flex-15 {
  flex: 15;
}

.flex-g1 {
  flex-grow: 1;
}

.flex-g2 {
  flex-grow: 2;
}

.flex-g3 {
  flex-grow: 3;
}

.flex-g4 {
  flex-grow: 4;
}

.flex-g5 {
  flex-grow: 5;
}

.flex-g6 {
  flex-grow: 6;
}

.flex-g7 {
  flex-grow: 7;
}

.flex-g8 {
  flex-grow: 8;
}

.flex-g9 {
  flex-grow: 9;
}

.flex-g10 {
  flex-grow: 10;
}

.flex-s1 {
  flex-shrink: 1;
}

.flex-s2 {
  flex-shrink: 2;
}

.flex-s3 {
  flex-shrink: 3;
}

.flex-s4 {
  flex-shrink: 4;
}

.flex-s5 {
  flex-shrink: 5;
}

.flex-s6 {
  flex-shrink: 6;
}

.flex-s7 {
  flex-shrink: 7;
}

.flex-s8 {
  flex-shrink: 8;
}

.flex-s9 {
  flex-shrink: 9;
}

.flex-s10 {
  flex-shrink: 10;
}

.grid {
  display: grid;
}

.grid-3-col-auto {
  grid-template-columns: auto auto auto;
}
@media (max-width: 1024px) {
  .grid-3-col-auto {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 600px) {
  .grid-3-col-auto {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid-2-col-auto {
  grid-template-columns: auto auto;
}

.gap {
  gap: 16px;
}

.grid-col-gap {
  grid-column-gap: 16px;
}

.fullWidth {
  width: 100%;
}

.flex-grow-1 {
  flex-grow: 1;
}

@media (max-width: 1024px) {
  .flex-mobile {
    display: flex;
    justify-content: space-between;
  }
}

.twoCol {
  border-top: 1px solid rgba(6, 60, 85, 0.05);
  margin-top: 3.5%;
  padding-top: 3.5%;
}

.custom-picker {
  position: relative;
  margin-bottom: 3%;
}
@media (max-width: 1024px) {
  .custom-picker {
    width: 100%;
  }
}
.custom-picker label:hover input {
  background-color: #E3E3E3;
}
.custom-picker input {
  background-color: #F3F3F3;
  border: none;
  color: black;
  padding: 12px 48px 12px 16px;
  border-radius: 50px;
  font-size: 1em;
  font-family: "Mallory Local", "Rubik Local";
  transition: background-color 300ms cubic-bezier(0.25, 1, 0.5, 1);
}
.custom-picker input:focus {
  outline: none;
}
.custom-picker input:hover {
  cursor: pointer;
}
.custom-picker .mat-icon {
  position: relative;
  top: 5px;
  right: 40px;
  color: grey;
}
.custom-picker .mat-icon:hover {
  cursor: pointer;
}

.dash {
  overflow: hidden;
}
.dash .empty-chart {
  position: absolute;
}
.dash .no-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #828282;
}
.dash .no-items p {
  margin-bottom: 0px;
}
.dash .graph-height {
  min-height: 300px;
}
.dash .white-box {
  display: block;
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 24px;
  text-align: center;
  color: #828282;
}
.dash .white-box .loadingText {
  margin: 0;
}
.dash .graph-tile {
  flex: 1 0 48%;
  align-items: center;
  position: relative;
  display: flex;
  background: #fff;
  color: inherit;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgba(125, 146, 156, 0.08), 0px 1px 2px rgba(8, 35, 48, 0.07);
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 16px;
}
.dash .graph-tile:first-child {
  width: 100%;
}
@media (min-width: 1024px) {
  .dash .graph-tile:first-child {
    display: inline-block;
  }
}
.dash .graph-tile:hover {
  text-decoration: none;
  border: 1px solid #6ECFF5;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .dash .graph-tile {
    display: block;
  }
}
@media (min-width: 480px) {
  .dash .graph-tile {
    padding: 15px 16px;
    margin-bottom: 20px;
  }
}
@media (min-width: 1024px) {
  .dash .graph-tile {
    flex: 1 0 20%;
    margin: 0 24px 24px 0;
  }
  .dash .graph-tile:nth-child(4n) {
    margin-right: 0;
  }
  .dash .graph-tile.active {
    background: #F1F9FD;
    border: 1px solid #6ECFF5;
  }
}
.dash .graph-tile .twoCol .greyBorder {
  border-left: 1px solid #828282;
}
.dash .graph-tile .twoCol .col {
  display: inline-block;
}
.dash .graph-tile .twoCol .col:first-child {
  margin-right: 9%;
}
.dash .graph-tile .twoCol .col .col-title {
  font-size: 0.85em;
  color: #828282;
}
.dash .graph-tile .twoCol .col span {
  display: block;
}
.dash .graph-tile .twoCol .col .textSize2 {
  font-size: 1.2em;
}
.dash .graph-tile .mini-chart {
  width: 80px;
}
.dash .graph-tile .mini-chart svg line {
  -webkit-filter: drop-shadow(0 5px 6px #6ECFF5);
  filter: drop-shadow(0 5px 6px #6ECFF5);
}
.dash .graph-tile .charts-wrapper {
  overflow: visible;
}
.dash .graph-tile .charts-wrapper .charts-css {
  min-width: 100px;
}
.dash .graph-tile .mobileOverflow {
  overflow: visible;
}
.dash .graph-tile .mobileOverflow svg {
  overflow: visible;
}
.dash .info-tile {
  background: #fff;
  color: inherit;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgba(125, 146, 156, 0.08), 0px 1px 2px rgba(8, 35, 48, 0.07);
  border-radius: 6px;
  padding: 16px;
  margin: 0 0 16px 0;
  flex: 1;
}
.dash .attention {
  border: 2px solid #E02020;
}
.dash > section {
  position: relative;
  border-bottom: 10px solid #F5F6F9;
}
.dash > section:last-child {
  border-bottom: 0;
}
.dash > section .third {
  border-bottom: 10px solid #F5F6F9;
}
@media (min-width: 1024px) {
  .dash > section .third {
    border-right: 10px solid #F5F6F9;
    flex: 1 0 50%;
  }
  .dash > section .third:nth-child(2n), .dash > section .third:last-child {
    border-right-width: 0;
  }
}
@media (min-width: 1400px) {
  .dash > section .third {
    border-right: 10px solid #F5F6F9;
    flex: 1 0 22%;
  }
  .dash > section .third:nth-child(2n) {
    border-right-width: 10px;
  }
  .dash > section .third:nth-child(3n), .dash > section .third:last-child {
    border-right-width: 0;
  }
}
.dash .label {
  position: relative;
  color: #828282;
  text-transform: uppercase;
  font-weight: 400;
}
@media (min-width: 768px) {
  .dash .label {
    font-size: 0.9rem;
  }
}
.dash .label .mdi {
  font-size: 1.4em;
  vertical-align: middle;
  padding-top: 2px;
  margin-left: 2px;
}
.dash .label .mdi .info-view-details {
  margin-left: -100px;
}
@media (max-width: 1024px) {
  .dash .label .mdi .info-view-details {
    right: 0;
    left: 0;
  }
}
.dash .label .mdi .align-top {
  top: calc(10% - 180px) !important;
}
.dash .label .mdi .info {
  display: none;
  position: absolute;
  text-transform: none;
  min-width: 300px;
  top: 20px;
  color: #181818;
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  z-index: 3;
  -webkit-box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}
.dash .label .mdi .info h6 {
  margin-bottom: 8px;
}
.dash .label .mdi .info p:last-child {
  margin: 0;
}
.dash .label .mdi:hover, .dash .label .mdi:focus {
  outline: none;
}
.dash .label .mdi:hover .info, .dash .label .mdi:focus .info {
  display: block;
}
.dash .graph-tile .title,
.dash .info-tile .title {
  font-size: 1.77rem;
  line-height: 1.77rem;
  margin: 4px 0;
}
.dash .graph-tile .title span,
.dash .info-tile .title span {
  white-space: nowrap;
}
.dash .graph-tile .title small,
.dash .info-tile .title small {
  line-height: 1.5rem;
}
.dash .graph-tile .title .mdi:before,
.dash .info-tile .title .mdi:before {
  position: relative;
  top: -6px;
  vertical-align: bottom;
}
.dash .desc {
  color: #828282;
  margin: 0;
}
@media (min-width: 768px) {
  .dash .desc {
    font-size: 0.9rem;
  }
}
.dash .comparison {
  color: #828282;
  font-size: 1rem;
}
@media (min-width: 1024px) {
  .dash .graph-tiles {
    width: 25%;
    margin-right: 24px;
    padding: 23px;
  }
  .dash .mini-chart {
    display: none;
  }
}

.op-quality h3 {
  line-height: 1.5em;
  margin: 0 16px 0 0;
}
.op-quality .heading {
  display: flex;
  align-content: center;
}
.op-quality .heading .attention {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.6rem;
  padding: 8px 5px 8px 12px;
  border: 0;
  border-radius: 50px;
  white-space: nowrap;
}
.op-quality .heading .attention.red {
  background-color: rgba(224, 32, 32, 0.2509803922);
  color: #e02020;
}
.op-quality .heading .attention.green {
  background-color: rgba(62, 161, 61, 0.2509803922);
  color: #3EA13D;
}
.op-quality .heading .attention span {
  display: inline-block;
  line-height: 1.1rem;
  height: 1.3rem;
}
.op-quality .heading .attention .mdi {
  font-size: 32px;
  vertical-align: middle;
  margin-left: 8px;
}
.op-quality .heading .attention .mdi:before {
  line-height: inherit;
}
.op-quality .sub-heading {
  padding: 14px 0 34px 0;
}
@media (min-width: 1024px) {
  .op-quality .info-tile-container {
    display: flex;
    justify-content: space-between;
  }
  .op-quality .info-tile-container .info-tile {
    margin-bottom: 0;
  }
  .op-quality .info-tile-container .info-tile:last-child {
    margin-right: 0;
  }
}

.top-selling-items .description {
  line-height: 1.5em;
}

.header-data h3 .label {
  display: inline-block;
  position: relative;
  top: -4px;
  font-size: 0.6em;
  color: #181818;
}

.ranking-bubble {
  margin-right: 9px;
  background-color: #E3E5E5;
  border-radius: 100%;
  padding: 0 11px;
}

.tabs button {
  padding: 12px 32px;
}

.padded {
  padding: 24px 16px;
}

ol {
  position: relative;
  counter-reset: list;
  list-style: none;
  padding: 0;
  margin: 0;
}
ol li {
  line-height: 24px;
  padding-right: 32px;
  margin-left: 32px;
  margin-bottom: 12px;
}
ol li:before {
  content: counter(list);
  counter-increment: list;
  position: absolute;
  left: 0;
  display: inline-block;
  background-color: #F2F2F2;
  border-radius: 24px;
  width: 24px;
  line-height: 24px;
  text-align: center;
  margin-right: 12px;
}
ol li span:last-child {
  position: absolute;
  right: 0;
  font-weight: 600;
}

.site-logo {
  padding: 56px 0 35px;
  display: flex;
}
.site-logo img {
  width: 48px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.page-login {
  background: #DCF0FB;
  min-height: 100vh;
}
.page-login h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.25px;
  color: #000;
  text-align: center;
}
.page-login h6 {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.25px;
}
.page-login p {
  color: #585858;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.1px;
  text-align: center;
}
.page-login .landing-image {
  margin-bottom: 11px;
}
.page-login .landing-image img {
  width: 100%;
  height: auto;
  display: block;
}
.page-login .col {
  width: 100%;
}
@media (min-width: 768px) {
  .page-login .col.left {
    max-width: 312px;
  }
}
.page-login .col.right {
  display: none;
}
@media (min-width: 768px) {
  .page-login .col.right {
    max-width: calc(100% - 312px);
    margin-left: 64px;
    display: block;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 1400px) {
  .page-login .col.right {
    max-width: 65%;
    padding-left: 130px;
    margin-left: auto;
  }
}
.page-login .col.right p {
  font-size: 14px;
  text-align: center;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

.form-wrapper {
  /*input:focus + label, input:not(:placeholder-shown) + label {
  	opacity:1;
  	transform: translateY(-60%) translateX(0px);
  	font-size: 12px;
  }*/
  /* For IE Browsers*/
}
.form-wrapper .form-error {
  background: #EEC1C1;
  border: 1px solid #E02020;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 17px 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: #181818;
  margin-bottom: 24px;
}
.form-wrapper .form-error-main {
  position: fixed;
  background: #FFFFFF;
  border: 1px solid #E02020;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.24), 0px 2px 6px rgba(8, 35, 48, 0.16);
  border-radius: 6px;
  top: 0;
  left: 0;
  padding: 16px 16px 16px 72px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.44px;
  width: 100%;
  max-width: 320px;
  top: 12px;
  left: 18px;
  z-index: 999;
  color: #E02020;
}
@media (min-width: 768px) {
  .form-wrapper .form-error-main {
    max-width: 420px;
    text-align: left;
  }
}
.form-wrapper .form-error-main .mdi {
  position: absolute;
  left: 22px;
  font-size: 25px;
}
.form-wrapper .form-error-main.something-wrong {
  position: relative;
  padding: 16px;
  left: 0;
  top: 0;
  margin-bottom: 16px;
}
.form-wrapper .form-error-main.something-wrong:before {
  display: none;
}
.form-wrapper .form-error-main.something-wrong a {
  font-weight: bold;
  text-decoration: none;
}
.form-wrapper .form-row {
  padding: 10px 12px;
  position: relative;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #C6CACC;
  min-height: 56px;
  display: flex;
  flex-direction: column;
  transition: ease all 0.2s;
  margin: 0;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .form-wrapper .form-row {
    margin-bottom: 32px;
  }
}
.form-wrapper .form-row.focused {
  border: 1px solid #181818;
}
.form-wrapper .form-row.submit {
  display: flex;
  flex-direction: column;
}
.form-wrapper .form-row.buttons {
  background: none;
  border: none;
  padding: 0;
}
.form-wrapper .form-row.terms {
  background: none;
  border: none;
  padding: 0;
}
.form-wrapper .form-row.select label {
  opacity: 1;
  transform: translateY(-60%) translateX(0px);
  font-size: 12px;
}
.form-wrapper .frm_error {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #E02020;
  position: absolute;
  bottom: -20px;
}
.form-wrapper .frm_error strong {
  font-weight: 600;
}
.form-wrapper .eye {
  position: absolute;
  right: 9px;
  top: 19px;
  z-index: 2;
}
.form-wrapper .eye .mdi {
  font-size: 1.25em;
}
.form-wrapper .password-error {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.4px;
  color: #000000;
  top: -20px;
  position: relative;
  margin: 0 0 0 5px;
}
.form-wrapper .password-error.frm_error {
  color: #E02020;
  text-align: center !important;
}
.form-wrapper .password-error div {
  margin-bottom: 4px;
  transition: opacity 2s ease-out, height 2s ease-out;
  height: auto;
  color: #E02020;
}
.form-wrapper .password-error div .mdi {
  margin-right: 14px;
}
.form-wrapper .password-error div.complete, .form-wrapper .password-error div.strong {
  height: 0;
  opacity: 0;
  color: #6ECFF5;
}
.form-wrapper input {
  width: 100%;
  margin: 0;
  padding: 0;
  box-shadow: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
  border: none;
  box-shadow: none;
  color: #181818;
  margin-top: 15px;
  background: none;
  z-index: 2;
}
.form-wrapper input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: transparent;
}
.form-wrapper input::-moz-placeholder { /* Firefox 19+ */
  color: transparent;
}
.form-wrapper input:-ms-input-placeholder { /* IE 10+ */
  color: transparent;
}
.form-wrapper input:-moz-placeholder { /* Firefox 18- */
  color: transparent;
}
.form-wrapper select {
  width: 100%;
  background: none;
  margin: 0 0 0 -8px;
  padding: 0 4px;
  box-shadow: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
  border: none;
  box-shadow: none;
  color: #181818;
  margin-top: 15px;
  transition-timing-function: ease-in-our;
  transition: 0.3s;
  text-indent: 1px;
  text-overflow: "";
}
.form-wrapper select::-ms-expand {
  display: none;
}
.form-wrapper select:focus + label, .form-wrapper select:not(:placeholder-shown) + label {
  opacity: 1;
  transform: translateY(-60%) translateX(0px);
  font-size: 12px;
}
.form-wrapper input:focus + label, .form-wrapper input:not(:-ms-input-placeholder) + label {
  opacity: 1;
  transform: translateY(-60%) translateX(0px);
  font-size: 12px;
}
.form-wrapper .suggestions {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #C6CACC;
  border-radius: 4px;
  padding: 16px 0;
  max-height: 240px;
  top: 59px;
  left: -1px;
  right: -1px;
  z-index: 2;
  overflow: auto;
}
.form-wrapper .suggestions ul {
  padding: 0;
  margin: 0;
}
.form-wrapper .suggestions ul li {
  padding: 8px 24px;
}
.form-wrapper .suggestions ul li:hover {
  color: #6ECFF5;
}
.form-wrapper input:focus ~ .suggestions {
  display: block;
}
.form-wrapper label {
  position: absolute;
  left: 12px;
  top: 17px;
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 1;
  transform: translateY(-60%) translateX(0px);
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: #5E6366;
  z-index: 1;
}
.form-wrapper .form-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-wrapper .form-link {
  text-align: center;
  margin-top: 15px;
}
.form-wrapper .form-link a {
  font-size: 0.9rem;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
}
.form-wrapper .terms {
  min-height: 10px;
}
.form-wrapper .terms p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #585858;
  padding: 0;
}
.form-wrapper .terms p a {
  color: #6DCEF5;
  text-decoration: none;
}
.form-wrapper .google > span {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 24px;
  margin-bottom: 16px;
}
.form-wrapper .google > span:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #C6CACC;
  display: block;
}
.form-wrapper .google > span > span {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #5E6366;
  background: #DCF0FB;
  width: 24px;
}
.form-wrapper .google button {
  background: #fff;
  color: #000;
  border: 1px solid #C6CACC;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-wrapper .google button > span {
  margin-right: 11px;
}
.form-wrapper .google button img {
  width: 100%;
  max-width: 18px;
  height: auto;
  display: block;
}
.form-wrapper .google a {
  background: #fff;
  color: #000;
  border: 1px solid #C6CACC;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-wrapper .password-strength {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  position: absolute;
  bottom: -20px;
}
.form-wrapper .password-strength.weak {
  color: #E02020;
}
.form-wrapper .password-strength.good {
  color: #3EA23D;
}

.mdc-floating-label {
  padding-bottom: 5px;
}

form {
  flex-direction: column;
}

form section {
  position: relative;
  border-bottom: 2px solid #E3E5E5;
  padding-bottom: 24px;
  margin: 0;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  form section {
    padding-bottom: 32px;
  }
}
@media (min-width: 1024px) {
  form section {
    padding-bottom: 48px;
  }
}
@media (min-width: 1400px) {
  form section {
    padding-bottom: 64px;
  }
}
@media (min-width: 768px) {
  form section {
    margin-bottom: 32px;
  }
}
@media (min-width: 1024px) {
  form section {
    margin-bottom: 48px;
  }
}
@media (min-width: 1400px) {
  form section {
    margin-bottom: 64px;
  }
}
form section:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}
form section h1, form section h2, form section h3, form section h4, form section h5 {
  margin-bottom: 0;
}
form section h6 {
  font-size: 0.95rem;
  margin-bottom: 8px;
}
form section label {
  display: inline-block;
  margin: 0 24px 16px 0;
}
form section label:last-child {
  padding-bottom: 0;
  margin: 0;
}
form section label span {
  display: inline-block;
  vertical-align: middle;
  white-space: break-spaces;
}
form section label:hover span {
  color: #5E6366;
}
form section label:active span {
  color: #6ECFF5;
}
form section input {
  display: block;
}
form section input[type=radio] {
  display: none;
  -webkit-appearance: none;
  opacity: 0;
  width: 0;
  height: 0;
}
form section input[type=radio] + span {
  width: 20px;
  height: 20px;
  border: 2px solid #C6CACC;
  border-radius: 20px;
  line-height: 0;
  padding: 3px;
  margin-right: 16px;
}
form section input[type=radio] + span span {
  background-color: #fff;
  width: 10px;
  height: 10px;
  line-height: 0;
  border-radius: 10px;
}
form section input[type=radio]:checked + span {
  border-color: #6ECFF5;
}
form section input[type=radio]:checked + span span {
  background-color: #6ECFF5;
}
form section input[type=radio]:disabled + span span, form section input[type=radio]:disabled + span {
  background-color: #F5F6F9;
}
form section input[type=date], form section input[type=text], form section input[type=number] {
  margin-bottom: 24px;
}
form section input[type=date]:last-child, form section input[type=text]:last-child, form section input[type=number]:last-child {
  margin-bottom: 0;
}
form section input:disabled {
  background-color: #F5F6F9;
  color: #5E6366;
}

.btn {
  position: relative;
  display: inline-block;
  text-decoration: none;
  outline: none;
  font-weight: 700;
  border: 0;
  border-radius: 5px;
  padding: 12px 16px;
  cursor: pointer;
  color: #192D35;
  background-color: #6ECFF5;
  background: #6ECFF5 -o-radial-gradient(circle, transparent 1%, #6ECFF5 1%) center/15000%;
  background: #6ECFF5 radial-gradient(circle, transparent 1%, #6ECFF5 1%) center/15000%;
  background-position: center;
  -webkit-transition: background 600ms;
  -o-transition: background 600ms;
  transition: background 600ms;
  min-width: 132px;
  text-align: center;
}
.btn.light {
  background: unset;
  border: 1px solid #C6CACC;
}
.btn.outline {
  color: #6ECFF5;
  background: #fff radial-gradient(circle, transparent 1%, #fff 1%) center/15000%;
  border: 1px solid white;
}
.btn:hover {
  text-decoration: none;
  background-color: #cdeffc;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
}
.btn:hover.light {
  background: #F5F6F9 radial-gradient(circle, transparent 1%, #F5F6F9 1%) center/15000%;
}
.btn:hover.outline {
  background-color: #F5F6F9;
}
.btn:active {
  background-color: #e5f6fd;
  background-size: 100%;
  transition: background 0s;
}
.btn:active.light {
  background-color: #e5e8ef;
}
.btn:active.outline {
  background-color: #eff0f5;
}
.btn.secondary {
  border-radius: 100px;
}
.btn.secondary:before {
  border-radius: 100px;
}
.btn.grey {
  background-color: #E3E5E5;
}
.btn.small {
  font-size: 0.85em;
  padding: 7.5px 12px;
  width: 100%;
  min-width: 100%;
}
.btn.btn-link {
  background-color: transparent;
}
.btn.btn-link:before {
  content: none;
  display: none;
}
.btn .mdi {
  display: inline-block;
  margin-left: 6px;
  margin-top: 3px;
  vertical-align: top;
  line-height: 1rem;
}
.btn .mdi:before {
  font-size: 1.2rem;
  vertical-align: middle;
}

.btn-inverted {
  color: #FFFFFF;
  background-color: #181818;
  padding: 2%;
}
.btn-inverted:hover {
  color: #181818 !important;
}

.btn-group {
  position: relative;
  z-index: 1;
}
.btn-group .btn {
  margin: 0 16px 16px 0;
}
.btn-group .btn:last-child {
  margin-right: 0;
}

.page-toolbar .btn {
  margin-bottom: 0;
}

.filters .btn {
  font-weight: 400;
}

.toggle {
  font-size: 0.94rem;
  letter-spacing: 0.3px;
  width: 100% !important;
}
.toggle input {
  display: none;
  -webkit-appearance: none;
  opacity: 0;
  width: 0;
  height: 0;
}
.toggle input:checked + span {
  background: rgba(30, 190, 254, 0.2);
}
.toggle input:checked + span:before {
  left: 14px;
  background: #1EBEFE;
}
.toggle span {
  width: 34px;
  height: 14px;
  background: #5E6366;
  border-radius: 100px;
  position: relative;
  transition: ease all 0.2s;
  margin-right: 11px;
}
.toggle span:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: #F7F9FA;
  box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.24), 0px 2px 6px rgba(8, 35, 48, 0.16);
  border-radius: 100%;
  left: 0;
  transition: ease all 0.2s;
  top: -3px;
}
.toggle.disabled span, .toggle.disabled input:checked + span {
  background: #F2F2F2;
}
.toggle.disabled span:before, .toggle.disabled input:checked + span:before {
  background: #C1C2C2;
}

.beta-select {
  width: 180px;
  height: 40px;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  background: url("../../assets/icon/select-dd-arrow.png") no-repeat 94% 17px;
  background-size: 12px 6px;
  border: 1px solid #C6CACC;
  display: inline-block;
  position: relative;
  border-radius: 4px;
}
.beta-select select {
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  width: 180px;
  font-size: 16px;
  border: 0;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 6%;
}
.beta-select select:focus {
  outline: none;
}
.beta-select select::-ms-expand {
  display: none;
}

.tab-group {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .tab-group {
    margin-bottom: 32px;
  }
}
.tab-group button {
  border-radius: 10px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.44px;
  margin-bottom: 8px;
}
.tab-group.day-selector button {
  border: 1px solid #D9D9D9;
  padding: 8px 24px;
}
@media (min-width: 768px) {
  .tab-group.day-selector button {
    padding: 8px 38px;
  }
}
.tab-group.day-selector button:first-child {
  border-radius: 10px 0px 0px 10px;
}
.tab-group.day-selector button:last-child {
  border-radius: 0px 10px 10px 0px;
}
.tab-group.day-selector button.active {
  background-color: #6ECFF5;
  color: #fff;
}

.tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
  width: 100%;
}
.tabs button {
  padding: 6px 24px;
}
.tabs button:hover {
  color: #6ECFF5;
}
.tabs button.active {
  color: inherit;
  font-weight: 500;
  border-bottom: solid 2px #6ECFF5;
}

input[type=date]::-webkit-calendar-picker-indicator {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  padding: 12px 12px 12px calc(100% - 30px);
  margin: 0;
  box-sizing: border-box;
  cursor: pointer;
}

.input-range {
  margin: 0;
  margin-bottom: 24px;
  padding-bottom: 8px;
}
@media (min-width: 1024px) {
  .input-range {
    margin-bottom: 32px;
  }
}
@media (min-width: 1024px) {
  .input-range {
    padding-bottom: 0;
  }
}
.input-range input[type=date] {
  padding-right: 22px;
}
.input-range:last-child {
  margin-bottom: 0;
}
.input-range span {
  display: block;
  margin: 0 0 8px;
}
.input-range label {
  position: relative;
  display: inline-block;
  margin: 0 0 8px;
}
.input-range label.moz {
  display: none;
}
@media not all and (min-resolution: 0.001dpcm) {
  .input-range label.moz {
    display: inline-block;
  }
}
.input-range label.moz input {
  margin-bottom: 0;
}
.input-range label.none-moz {
  display: inline-block;
}
@media not all and (min-resolution: 0.001dpcm) {
  .input-range label.none-moz {
    display: none;
  }
}
.input-range label .mdi {
  width: 100%;
  text-align: right;
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 20px;
}
.input-range label .mdi:hover {
  cursor: pointer;
}
@media (min-width: 480px) {
  .input-range span {
    display: inline-block;
    margin: 0 16px 8px;
  }
}

.cofunded-input-range input[type=date] {
  padding-right: 40px;
}

.select {
  position: relative;
  display: inline-block;
  text-align: left;
  cursor: pointer;
  margin: 0;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .select {
    margin-bottom: 32px;
  }
}
.select .button {
  background-color: #F3F3F3;
  padding: 10px 48px 10px 16px;
  border-radius: 50px;
  -webkit-transition: background-color 300ms cubic-bezier(0.25, 1, 0.5, 1);
  -o-transition: background-color 300ms cubic-bezier(0.25, 1, 0.5, 1);
  transition: background-color 300ms cubic-bezier(0.25, 1, 0.5, 1);
}
.select .button:hover {
  background-color: #ebebeb;
}
.select .button:active {
  background-color: #F3F3F3;
  transition: none;
}
.select .mdi {
  position: absolute;
  color: #78909C;
  top: 10px;
  right: 16px;
  display: inline-block;
  font-size: 1.3rem;
}
.select .button:focus + .options,
.select .button:focus-within + .options {
  display: block;
}
.select:after {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.select:focus-within:after {
  content: "";
}

.options {
  display: none;
  position: absolute;
  left: 0;
  min-width: 180px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(125, 146, 156, 0.12), 0px 1px 2px rgba(8, 35, 48, 0.1);
  overflow: hidden;
  z-index: 3;
}
.options:active {
  display: block;
}
.options a, .options label {
  width: 100%;
  display: block;
  color: inherit;
  padding: 12px 16px;
  cursor: pointer;
}
.options a:hover, .options label:hover {
  text-decoration: none;
  background-color: #F5F6F9;
}
.options a:active, .options label:active {
  background-color: #ebedf3;
}
.options a.active, .options label.active {
  background-color: #F5F6F9;
  border-left: 5px solid #6ECFF5;
  padding-left: 8px;
}

.select-mf {
  position: relative;
  display: inline-block;
  text-align: left;
  cursor: pointer;
}
.select-mf .options {
  display: block;
}
.select-mf .select {
  margin-bottom: 0;
}
.select-mf input {
  float: right;
  display: inline-block !important;
  -webkit-appearance: checkbox !important;
}

.restaurant-select .selectAll {
  font-size: 0.95rem;
  padding: 8px 12px;
  margin-bottom: 8px;
}
.restaurant-select .selectAll span {
  display: inline-block;
  margin-right: 16px;
}
.restaurant-select .selectAll label {
  display: inline-block;
  vertical-align: middle;
}
.restaurant-select input[type=checkbox] {
  display: none;
  -webkit-appearance: none;
}
.restaurant-select input[type=checkbox]:checked + label {
  background: #181818;
}
.restaurant-select input[type=checkbox]:checked + label:before {
  opacity: 1;
}
.restaurant-select input[type=checkbox] + label {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #181818;
  position: relative;
  transition: ease all 0.2s;
}
.restaurant-select input[type=checkbox] + label:before {
  content: "";
  width: 5px;
  height: 13px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg);
  opacity: 0;
  transition: ease all 0.2s;
  position: absolute;
  left: 7px;
  top: 0;
}
.restaurant-select label {
  width: 20px;
}

.restaurant {
  -webkit-box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
  border-radius: 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 12px;
  margin-bottom: 8px;
}
.restaurant .title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: #181818;
}
.restaurant .action {
  margin-left: auto;
}

.btn-full {
  width: 100%;
}

.alert {
  border-left: 16px solid #6ECFF5;
  border-radius: 8px;
  -webkit-box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
}
@media (min-width: 768px) {
  .alert {
    padding-top: 32px;
  }
}
@media (min-width: 1024px) {
  .alert {
    padding-top: 48px;
  }
}
@media (min-width: 768px) {
  .alert {
    padding-bottom: 32px;
  }
}
@media (min-width: 1024px) {
  .alert {
    padding-bottom: 48px;
  }
}
@media (min-width: 768px) {
  .alert {
    padding-left: 32px;
  }
}
@media (min-width: 1024px) {
  .alert {
    padding-left: 48px;
  }
}
@media (min-width: 768px) {
  .alert {
    padding-right: 32px;
  }
}
@media (min-width: 1024px) {
  .alert {
    padding-right: 48px;
  }
}
.alert > * {
  max-width: 500px;
}
.alert > *:last-child {
  margin-bottom: 0;
}
.alert.error {
  border-color: #E02020;
}

form.edit-campaign .range-divider {
  margin: 10px 20px 0 20px;
}
form.edit-campaign section label {
  margin: 0;
}
form.edit-campaign section div.form-row {
  margin-bottom: 24px;
}
form.edit-campaign section #firstSection div.form-row:last-child {
  margin-bottom: 0;
}
form.edit-campaign section select {
  margin-left: 8px;
}
form.edit-campaign section input[type=text], form.edit-campaign section input[type=number], form.edit-campaign section input[type=date], form.edit-campaign section select {
  background: none;
  padding: 7px 12px;
  border: 1px solid #C6CACC;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: "Mallory Local", "Rubik Local";
  line-height: 24px;
  letter-spacing: 0.44px;
  color: #181818;
}
@media (min-width: 768px) {
  form.edit-campaign section input[type=text], form.edit-campaign section input[type=number], form.edit-campaign section input[type=date], form.edit-campaign section select {
    font-size: 16px;
  }
}
form.edit-campaign section input[type=text][disabled], form.edit-campaign section input[type=number][disabled], form.edit-campaign section input[type=date][disabled], form.edit-campaign section select[disabled] {
  background-color: #f3f3f3;
}
form.edit-campaign section input[type=text]:not(.material-picker-input) {
  width: 50%;
}
form.edit-campaign section .material-picker-input {
  width: 120px;
}
form.edit-campaign section input[type=date] {
  width: 12em;
}
@media (max-width: 768px) {
  form.edit-campaign section input[type=text] {
    width: 100%;
  }
}
form.edit-campaign section input {
  margin-bottom: 6px;
}
form.edit-campaign section input {
  padding-right: 20px;
  display: inline;
}
form.edit-campaign section input + span {
  margin-left: -20px;
}
form.edit-campaign section div#restaurantIdsRow {
  width: 50%;
}
@media (max-width: 768px) {
  form.edit-campaign section div#restaurantIdsRow {
    width: 100%;
  }
}
form.edit-campaign section div#restaurantIdsRow textarea {
  resize: none;
  background-color: transparent;
  border: 1px solid #C6CACC;
  padding: 7px 12px;
  border-radius: 4px;
  font-family: "Mallory Local", "Rubik Local";
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.44px;
  width: 100%;
}
@media (min-width: 768px) {
  form.edit-campaign section div#restaurantIdsRow textarea {
    font-size: 16px;
  }
}
form.edit-campaign section div#restaurantIdsRow textarea[disabled] {
  background-color: #f3f3f3;
}
form.edit-campaign section div#restaurantIdsRow div#validateButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
}
form.edit-campaign section div#restaurantIdsRow div#validateButtonContainer .success-validation {
  color: green;
}
form.edit-campaign section div#restaurantIdsRow div#validateButtonContainer .success-validation span {
  position: relative;
  top: 2px;
}
form.edit-campaign section div#restaurantIdsRow div#validateButtonContainer button {
  margin-right: 12px;
  line-height: 1rem;
  max-height: 6rem;
}
form.edit-campaign section div#restaurantIdsRow div#validateButtonContainer button.disabled {
  background-color: #cdeffc;
}
form.edit-campaign .time-slots {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
form.edit-campaign .time-slots button {
  border: 1px solid #C6CACC;
  border-radius: 5px;
  margin-right: 10px;
  padding: 5px 10px 5px 10px;
}
form.edit-campaign .time-slots button.selected {
  background-color: #6ECFF5;
  border: 1px solid #6ECFF5;
}
form.edit-campaign .time-slots button:hover {
  cursor: pointer;
}
form.edit-campaign .time-slots button:disabled {
  background-color: #f3f3f3 !important;
  border: 1px solid #C6CACC;
}
form.edit-campaign .time-slots button:last-child {
  margin: 0;
}

.form-error {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #E02020;
}
.form-error.whole-form-error.showMobileSmall {
  margin-bottom: 6px;
}
@media (min-width: 768px) {
  .form-error.whole-form-error.showDesktopSmall {
    display: inline-block;
  }
}

.campaign-container .intro {
  margin: 1rem 0 2rem 0;
}
.campaign-container .intro span {
  color: gray;
}
.campaign-container .intro h2 {
  margin: 0.75rem 0 0 0;
  line-height: 2rem;
  max-width: 580px;
}
.campaign-container .campaign-form {
  margin-top: 2rem;
  padding: 0.8rem;
  border: 1px solid #DDE2E5;
  border-radius: 7px;
}
.campaign-container .campaign-form > section {
  padding-bottom: unset;
}
.campaign-container .campaign-form .input-field {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}
.campaign-container .campaign-form .input-field > article {
  display: flex;
  flex-direction: column;
  min-width: 200px;
}
.campaign-container .campaign-form .input-field > article label {
  margin: 0;
}
.campaign-container .campaign-form .input-field > article span {
  color: gray;
  font-size: 0.85rem;
}
.campaign-container .campaign-form .input-field > div {
  position: relative;
  width: 100%;
}
.campaign-container .campaign-form .input-field > div input, .campaign-container .campaign-form .input-field > div textarea {
  width: 100%;
  padding: 0.7rem 2.2rem 0.7rem 0.5rem;
  border: 1px solid lightgrey;
  border-radius: 10px;
  outline: none;
  margin-bottom: 0;
}
.campaign-container .campaign-form .input-field > div textarea {
  resize: none;
  font-family: inherit;
  max-height: 100px;
}
.campaign-container .campaign-form .input-field > div .count {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: lightgray;
}
.campaign-container .campaign-form .input-field .invalid-feedback {
  position: absolute;
  top: 100%;
  right: 0;
  text-align: right;
  font-size: 0.5rem;
  color: red;
  display: block;
}
.campaign-container .campaign-form .spacer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.campaign-container .campaign-form .form-pills {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.campaign-container .campaign-form .form-pills > article {
  min-width: 200px;
}
.campaign-container .campaign-form .form-pills .form-spacer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.7rem;
}
.campaign-container .campaign-form .form-pills .invalid-feedback {
  position: absolute;
  top: 100%;
  right: 0;
  text-align: right;
  font-size: 0.5rem;
  color: red;
  display: block;
}
.campaign-container .campaign-form h5 {
  margin-bottom: 0.5rem;
}
.campaign-container .campaign-form .form-select {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}
.campaign-container .campaign-form .form-select .form-select-text {
  min-width: 200px;
}
.campaign-container .campaign-form .form-select .form-select-text label {
  display: inline-block;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  user-select: none;
}
.campaign-container .campaign-form .form-select .form-select-text label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.campaign-container .campaign-form .form-select .form-select-text label input:checked ~ .checkmark {
  background-color: #6ECFF5;
}
.campaign-container .campaign-form .form-select .form-select-text label input:checked ~ .checkmark::after {
  opacity: 1;
}
.campaign-container .campaign-form .form-select .form-select-text label .checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 22px;
  width: 22px;
  border: 2px solid #eee;
  transition: background-color 0.25s ease;
  border-radius: 50%;
}
.campaign-container .campaign-form .form-select .form-select-text label .checkmark::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #333;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.25s ease;
}
.campaign-container .campaign-form .form-select .form-select-fields {
  display: flex;
  align-items: center;
}
.campaign-container .campaign-form .form-select .form-select-fields input {
  width: 100%;
  padding: 0.7rem 0.5rem;
  border: 1px solid lightgrey;
  border-radius: 10px;
  outline: none;
}
.campaign-container .campaign-form .form-select .form-select-fields span {
  display: block;
  margin: 0 0.5rem;
}
.campaign-container .campaign-form .form-select .form-select-multi-fields {
  width: 100%;
}
.campaign-container .campaign-form .form-select .form-select-multi-fields .dropdown-select {
  margin-bottom: 1rem;
}
.campaign-container .campaign-form .form-select .form-select-multi-fields .dropdown-select .button {
  background-color: unset;
  border: 1px solid black;
  padding: 12px 90px 12px 16px;
  border-radius: 6px;
}
.campaign-container .campaign-form .form-select .form-select-multi-fields .form-fields-content div.mat-form-field-wrapper {
  padding-bottom: 0;
}
.campaign-container .campaign-form .form-select .form-select-multi-fields .form-fields-content div.mat-form-field-wrapper div.mat-form-field-flex {
  display: flex;
  align-items: center;
  background-color: unset;
  border: 1px solid black;
  border-radius: 6px;
  padding: 0;
}
.campaign-container .campaign-form .form-select .form-select-multi-fields .form-fields-content div.mat-form-field-wrapper div.mat-form-field-flex div.mat-form-field-infix {
  width: 120px;
  padding: 0em 0 0.7em 10px;
}
.campaign-container .campaign-form .form-select .form-select-multi-fields .form-fields-content div.mat-form-field-wrapper div.mat-form-field-underline {
  display: none;
}
.campaign-container .campaign-form .form-select .form-select-multi-fields .form-fields-content app-date-input input {
  width: 100%;
  border: none;
  font-size: inherit;
  outline: none;
}
.campaign-container .campaign-form .form-select .form-select-multi-fields .form-fields-content .and {
  display: inline-block;
  margin: 0 7px;
}
.campaign-container .campaign-form .form-select .form-select-multi-fields .form-fields-content .input-days {
  display: flex;
  align-items: center;
}
.campaign-container .campaign-form .form-select .form-select-multi-fields .form-fields-content .input-days input {
  padding: 0.7rem 1.5rem 0.7rem 0.5rem;
  border: 1px solid lightgrey;
  border-radius: 10px;
  outline: none;
  margin-bottom: 0;
}
.campaign-container .campaign-form .form-select .form-select-multi-fields .invalid-feedback {
  position: absolute;
  top: 100%;
  right: 0;
  text-align: right;
  font-size: 0.5rem;
  color: red;
  display: block;
}
.campaign-container .campaign-form .form-dropdown {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.campaign-container .campaign-form .form-dropdown .form-dropdown-text {
  min-width: 200px;
}
.campaign-container .campaign-form .form-dropdown .form-dropdown-fields div {
  margin: 0;
}
.campaign-container .campaign-form .form-dropdown .form-dropdown-fields div div.button {
  padding: 8px 35px 8px 16px;
  border-radius: 6px;
  background-color: unset;
  border: 1px solid black;
}
.campaign-container .campaign-form .form-dropdown .form-dropdown-fields div div.button span.mdi {
  transform: translateX(5px);
}
.campaign-container .campaign-form .form-dropdown .form-dropdown-fields div div.options {
  padding: 8px 13px 8px 13px;
  min-width: 90px;
  max-height: 230px;
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.campaign-container .campaign-form .form-dropdown .form-dropdown-fields div div.options::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.campaign-container .campaign-form .form-dropdown .form-dropdown-fields > span {
  display: inline-block;
  margin: 0 5px;
}
.campaign-container .campaign-form .form-dropdown .form-dropdown-fields > span:nth-of-type(2) {
  margin: 0 7px;
}
.campaign-container .campaign-form .form-dropdown .form-dropdown-fields > span:nth-of-type(2)::before {
  transform: translateY(4px);
}
.campaign-container .campaign-form .form-dropdown .invalid-feedback {
  position: absolute;
  top: 100%;
  right: 0;
  text-align: right;
  font-size: 0.5rem;
  color: red;
  display: block;
}
.campaign-container .campaign-form .form-dates {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.campaign-container .campaign-form .form-dates .form-dates-text {
  min-width: 200px;
}
.campaign-container .campaign-form .form-dates .form-dates-fields div.mat-form-field-wrapper {
  padding-bottom: 0;
}
.campaign-container .campaign-form .form-dates .form-dates-fields div.mat-form-field-wrapper div.mat-form-field-flex {
  display: flex;
  align-items: center;
  background-color: unset;
  border: 1px solid black;
  border-radius: 6px;
  padding: 0;
}
.campaign-container .campaign-form .form-dates .form-dates-fields div.mat-form-field-wrapper div.mat-form-field-flex div.mat-form-field-infix {
  width: 120px;
  padding: 0em 0 0.7em 10px;
}
.campaign-container .campaign-form .form-dates .form-dates-fields div.mat-form-field-wrapper div.mat-form-field-underline {
  display: none;
}
.campaign-container .campaign-form .form-dates .form-dates-fields app-date-input input {
  width: 100%;
  border: none;
  font-size: inherit;
  outline: none;
}
.campaign-container .campaign-form .form-dates .form-dates-fields > span {
  display: inline-block;
  margin: 0 7px;
}
.campaign-container .campaign-form .form-dates .form-dates-fields > span::before {
  transform: translateY(4px);
}
.campaign-container .campaign-form .form-dates .invalid-feedback {
  position: absolute;
  top: 100%;
  right: 0;
  text-align: right;
  font-size: 0.5rem;
  color: red;
  display: block;
}
.campaign-container .campaign-form .add-button {
  border: 1px solid lightgray;
  width: 100%;
  text-align: left;
  border-radius: 7px;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
}
.campaign-container .campaign-form .add-button span {
  margin-right: 0.4rem;
  font-size: 1.3rem;
}
.campaign-container .campaign-form .edit-button {
  font-weight: 600;
  color: #6ECFF5;
}

.pills-container {
  display: flex;
  justify-content: flex-end;
}
.pills-container label {
  margin: 0 8px 0 0;
}
.pills-container label:last-child {
  margin: 0;
}
.pills-container label input[type=radio], .pills-container label input[type=checkbox] {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}
.pills-container label div, .pills-container label a {
  position: relative;
  display: inline-block;
  padding: 8px 14px;
  border: 1px solid black;
  border-radius: 6px;
  width: unset;
  height: unset;
  margin-right: 0;
}
.pills-container label div:hover, .pills-container label a:hover {
  background-color: #252525;
  color: white;
}
.pills-container label div:active, .pills-container label a:active {
  background-color: #252525;
  color: white;
}
.pills-container label a {
  color: inherit;
  text-decoration: none;
}
.pills-container label input:checked + div,
.pills-container label a.active {
  color: white;
  background: #000;
}
.pills-container label input:checked + div:after,
.pills-container label a.active:after {
  width: 100%;
  left: 0;
  right: initial;
}
.pills-container label input:disabled + div {
  background-color: lightgray;
  color: #E8E8E8;
}
.pills-container label input:disabled:checked + div {
  background-color: lightgray;
  color: #E8E8E8;
  border: 1px solid lightgray;
}
.pills-container.rounded {
  display: inline-block;
  border: 1px solid lightgray;
  border-radius: 27px;
  padding: 0.25rem;
}
.pills-container.rounded label div, .pills-container.rounded label a {
  padding: 5px 22px;
  border-radius: 24px;
  border-color: rgba(0, 0, 0, 0.03);
}
.pills-container.colours label {
  border-radius: 4px;
  width: 20px;
  height: 20px;
}
.pills-container.colours label input:checked + div {
  background: none;
  border: 1px solid black;
}
.pills-container.colours label div {
  width: 26px;
  height: 26px;
  padding: 0;
  display: block;
  transform: translate(-3px, -3px);
  border-radius: 0;
  border: none;
}
.pills-container.colours label div:hover, .pills-container.colours label div:active {
  background: none;
  border: 1px solid black;
}

.check-toggle label {
  position: relative;
  display: block;
  width: 60px;
  height: 30px;
  cursor: pointer;
  background: lightgray;
  border-radius: 50px;
  transition: all 0.4s ease;
}
.check-toggle label span {
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translate(0, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 50%;
  transition: all 0.4s ease;
}
.check-toggle label span::before {
  font-size: 1.2rem;
  opacity: 0;
  transition: opacity 0.4s ease;
  transition-delay: 0.1s;
}
.check-toggle input[type=checkbox] {
  visibility: hidden;
  opacity: 0;
  position: absolute;
}
.check-toggle input[type=checkbox]:checked + label {
  background: #000;
}
.check-toggle input[type=checkbox]:checked + label span {
  left: calc(100% - 27px);
}
.check-toggle input[type=checkbox]:checked + label span::before {
  opacity: 1;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: 48px;
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0.7rem 12px;
  pointer-events: none;
  border: 2px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  font-size: 0.9375rem;
  opacity: 0.65;
}
.form-floating > .form-control {
  padding: 0.7rem 12px;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.25rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.25rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.7 !important;
  transform: scale(0.75) translateY(-0.6rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.7 !important;
  transform: scale(0.75) translateY(-0.6rem) translateX(0.15rem);
}

.sidebar {
  display: none;
  position: fixed;
  top: 0;
  background: #F5F6F9;
  width: 100%;
  max-width: 75px;
  height: 100%;
  min-height: 100vh;
  padding: 35px 17px;
  z-index: 5;
  -webkit-transition: max-width 200ms cubic-bezier(0.25, 1, 0.5, 1);
  -o-transition: max-width 200ms cubic-bezier(0.25, 1, 0.5, 1);
  transition: max-width 200ms cubic-bezier(0.25, 1, 0.5, 1);
}
.sidebar:hover {
  max-width: 250px;
}
.sidebar:hover .action-menu {
  display: block;
}
@media (min-width: 768px) {
  .sidebar {
    display: flex;
  }
  .sidebar + .content {
    margin-left: 75px;
  }
  .sidebar + .content #fixedHeader,
  .sidebar + .content .body-loading {
    left: 75px;
  }
}
@media (min-width: 1024px) {
  .sidebar:hover + .content {
    margin-left: 250px;
  }
  .sidebar:hover + .content #fixedHeader,
  .sidebar:hover + .content .body-loading {
    left: 250px;
  }
}
@media (min-width: 1400px) {
  .sidebar {
    max-width: 250px;
  }
  .sidebar + .content {
    margin-left: 250px;
  }
  .sidebar + .content #fixedHeader,
  .sidebar + .content .body-loading {
    left: 250px;
  }
  .sidebar .action-menu {
    display: block;
  }
}
.sidebar + .content {
  background-color: inherit;
}
.sidebar .sidebar-body {
  margin: 0;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .sidebar .sidebar-body {
    margin-bottom: 32px;
  }
}
.sidebar .sidebar-inner {
  width: 250px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.sidebar .site-logo {
  width: 100%;
  margin: 0 auto 20px;
  padding: 0;
}
.sidebar .link-item {
  margin-bottom: 8px;
}
.sidebar a {
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #181818;
  text-decoration: none;
  padding: 10px;
  border-radius: 100px;
  width: 100%;
  min-width: 40px;
  border: none;
  box-shadow: none;
  height: 40px;
  margin: 0;
  text-align: left;
  -webkit-transition: border-radius 150ms cubic-bezier(0.42, 0, 0.58, 1), max-width 150ms cubic-bezier(0.42, 0, 0.58, 1), background-color 150ms cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: border-radius 150ms cubic-bezier(0.42, 0, 0.58, 1), max-width 150ms cubic-bezier(0.42, 0, 0.58, 1), background-color 150ms cubic-bezier(0.42, 0, 0.58, 1);
  transition: border-radius 150ms cubic-bezier(0.42, 0, 0.58, 1), max-width 150ms cubic-bezier(0.42, 0, 0.58, 1), background-color 150ms cubic-bezier(0.42, 0, 0.58, 1);
}
.sidebar a:before {
  color: #181818;
  font-size: 20px;
}
.sidebar a:hover {
  background-color: #cdeffc;
  border-radius: 11px;
}
.sidebar a span {
  margin-left: 20px;
  min-width: 170px;
}
.sidebar a .nav-item-badge {
  padding: 0.3em 0.75em;
  background-color: #3F9047;
  margin: 0 0.5em;
  color: #FFFFFF;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 700;
  display: inline;
}
.sidebar a.active {
  background: #6ECFF5;
  font-weight: 500;
}
.sidebar a.active .nav-item-badge, .sidebar a:hover .nav-item-badge {
  background-color: #FFFFFF;
  color: #3F9047;
}
.sidebar .sidebar-footer {
  margin-top: auto;
  position: relative;
  width: 100%;
  max-width: 40px;
  -webkit-transition: max-width 200ms cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: max-width 200ms cubic-bezier(0.42, 0, 0.58, 1);
  transition: max-width 200ms cubic-bezier(0.42, 0, 0.58, 1);
}
.sidebar .sidebar-footer .user-profile {
  align-items: center;
}
.sidebar .sidebar-footer .avatar {
  width: 100%;
  max-width: 40px;
  height: 40px;
  min-width: 40px;
  margin-right: 14px;
  border-radius: 100%;
  background-color: #6ECFF5;
  font-style: normal;
  font-size: 22px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar .sidebar-footer .profile-user {
  min-width: 138px;
}
.sidebar .sidebar-footer .profile-user .name {
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #000000;
}
.sidebar .sidebar-footer .profile-user .role {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.4px;
  color: #000000;
}

.dash-header {
  display: none;
  margin: 0;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .dash-header {
    margin-bottom: 32px;
  }
}
@media (min-width: 768px) {
  .dash-header {
    display: flex;
  }
}
.dash-header .header-search {
  width: 100%;
  background-color: #F7F9FA;
  border-radius: 30px;
  padding: 10px 14px;
  margin-right: 25px;
  -webkit-transition: background-color 300ms cubic-bezier(0.25, 1, 0.5, 1);
  -o-transition: background-color 300ms cubic-bezier(0.25, 1, 0.5, 1);
  transition: background-color 300ms cubic-bezier(0.25, 1, 0.5, 1);
}
.dash-header .header-search .mdi:before {
  -webkit-transition: text-shadow 800ms cubic-bezier(0.25, 1, 0.5, 1);
  -o-transition: text-shadow 800ms cubic-bezier(0.25, 1, 0.5, 1);
  transition: text-shadow 800ms cubic-bezier(0.25, 1, 0.5, 1);
}
.dash-header .header-search:focus-within {
  background-color: #F2F4F5;
}
.dash-header .header-search:focus-within .mdi:before {
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.dash-header .header-search .search-bar {
  width: 100%;
}
.dash-header .header-search input {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: #5E6366;
  width: 100%;
  outline: none;
}
.dash-header .header-search span {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-right: 15px;
}
.dash-header .header-action {
  margin-left: auto;
}
.dash-header .header-action .btn {
  text-align: center;
  margin-bottom: 0;
}

.dash-header-mobile {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.240999);
  padding: 16px;
  align-items: center;
  position: relative;
  z-index: 0;
  min-height: 72px;
  margin: -16px -16px 16px;
}
.dash-header-mobile h1 {
  margin: 0;
}
@media (min-width: 480px) {
  .dash-header-mobile {
    margin: -32px -32px 32px;
  }
}
@media (min-width: 768px) {
  .dash-header-mobile {
    display: none;
  }
}
.dash-header-mobile .mdi:before {
  vertical-align: middle;
}
.dash-header-mobile .header-actions {
  margin-left: auto;
}
.dash-header-mobile .button {
  background: none;
  color: #060B0D;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  margin: 0 0 0 15px;
  position: relative;
}
.dash-header-mobile .button span {
  margin: 0;
  font-size: 2rem;
}

.header-actions {
  margin-left: auto;
}

.mobile-search {
  position: relative;
  top: 0;
  margin: -16px -16px 16px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.240999);
}
.mobile-search .form-inner {
  display: flex;
  flex-direction: column;
}
.mobile-search .search-bar {
  background: #fff;
  padding: 16px 9px;
  min-height: 72px;
}
.mobile-search .search-bar button {
  width: 40px;
  padding: 0;
  margin: 0 0 0 -8px;
  font-size: 22px;
}
.mobile-search .search-bar input {
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 16px;
  background: #f3f3f3;
  border-radius: 100px;
  padding: 0 15px;
}
@media (min-width: 480px) {
  .mobile-search {
    margin: -32px -32px 32px;
  }
}
@media (min-width: 768px) {
  .mobile-search {
    display: none;
  }
}

.dash-header-image {
  background: #EAF6FD;
  position: relative;
  margin: 0;
  margin-bottom: 24px;
  margin-top: -16px;
  margin-left: -16px;
  margin-right: -16px;
}
@media (min-width: 1024px) {
  .dash-header-image {
    margin-bottom: 32px;
  }
}
@media (min-width: 480px) {
  .dash-header-image {
    margin-top: -32px;
    margin-left: -32px;
    margin-right: -32px;
  }
}
@media (min-width: 768px) {
  .dash-header-image {
    display: none;
  }
}
.dash-header-image .icon-back {
  position: absolute;
  float: left;
  left: 15px;
  top: 15px;
  font-size: 25px;
}

.title-header {
  align-items: center;
  display: flex;
  max-width: 640px;
  margin: 0;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .title-header {
    margin-bottom: 32px;
  }
}
@media (min-width: 768px) {
  .title-header {
    padding: 0;
  }
}
@media (max-width: 768px) {
  .title-header.single-promo .icon-back {
    display: none;
  }
}
.title-header .header-left {
  position: relative;
}
.title-header .header-left h1, .title-header .header-left h2, .title-header .header-left h3, .title-header .header-left h4 {
  margin-bottom: 16px;
}
.title-header .header-left h1:last-child, .title-header .header-left h2:last-child, .title-header .header-left h3:last-child, .title-header .header-left h4:last-child {
  margin-bottom: 0;
}
.title-header .header-left > p:last-child {
  margin-bottom: 0;
}
.title-header .header-left .icon {
  top: 0;
  left: -40px;
  width: 30px;
  height: 30px;
  font-size: 24px;
  margin: 0;
  padding: 4px;
  border: none;
  background: none;
  margin-right: 10px;
  cursor: pointer;
}
@media (min-width: 768px) {
  .title-header .header-left .icon {
    position: absolute;
    left: -50px;
    width: 40px;
    font-size: 30px;
  }
}
.title-header .header-right {
  margin-left: auto;
  display: flex;
}
.title-header .header-right .mdi {
  font-size: 25px;
  color: #060B0D;
  transform: rotate(90deg);
  position: relative;
  float: right;
  cursor: pointer;
}
.title-header .header-right .mdi:before {
  position: relative;
  top: 1px;
}
.title-header .header-right .action-menu {
  right: 0;
  top: 55px;
  min-width: 126px;
}

.promotions {
  box-shadow: none;
}
@media (min-width: 768px) {
  .promotions {
    display: none;
  }
}
.promotions .header-actions a {
  min-width: 30px;
}
.promotions .header-actions .mdi {
  margin: 0;
}

.header:focus-within ~ .body .user-action {
  display: block;
}
.header:focus-within .unselect {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 60px;
}

.user-action {
  display: none;
}

.user-action:active {
  display: block;
}

#fixedHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
}
@media (max-width: 768px) {
  #fixedHeader {
    position: absolute;
  }
}

#spaceGhost {
  visibility: hidden;
}

table.table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  margin: 0;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  table.table {
    margin-bottom: 32px;
  }
}
table.table th, table.table td {
  position: relative;
  font-weight: 400;
  line-height: 24px;
  padding: 8px;
  border-bottom: 1px solid #E3E5E5;
}
table.table th:first-child, table.table td:first-child {
  padding-left: 0;
}
table.table th:first-child, table.table td:first-child {
  padding-right: 0;
}
table.table th {
  color: #5E6366;
  border-width: 2px;
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
}
table.table tbody tr:hover {
  background-color: #F5F6F9;
}
table.table .date {
  min-width: 160px;
}
table.table h1, table.table h2, table.table h3, table.table h4, table.table h5, table.table h6, table.table p {
  margin-bottom: 0;
}
table.table .date {
  min-width: 160px;
}
table.table a {
  display: block;
}
table.table .action > a {
  position: relative;
  display: inline-block;
  color: inherit;
  text-decoration: none;
  margin: 0 8px;
  white-space: nowrap;
  cursor: pointer;
}
table.table .action > a span {
  position: relative;
  top: 2px;
  font-size: 1.3rem;
  vertical-align: middle;
}
table.table .action > a span:before {
  margin-top: -2px;
}
table.table .action > a:after {
  content: "";
  position: absolute;
  background-color: #181818;
  width: 0;
  height: 2px;
  right: 0;
  bottom: -2px;
  cursor: pointer;
  -webkit-transition: width 200ms cubic-bezier(0.25, 1, 0.5, 1);
  -o-transition: width 200ms cubic-bezier(0.25, 1, 0.5, 1);
  transition: width 200ms cubic-bezier(0.25, 1, 0.5, 1);
}
table.table .action > a:hover:after {
  left: 0;
  width: 100%;
}
table.table .action > a:active:after {
  left: initial;
  width: 0;
}
table.table .near-bottom .action-menu {
  top: initial;
  bottom: 34px;
}
@media (max-width: 960px) {
  table.table .wrap {
    width: 80px;
  }
}

.table-wrapper {
  width: 100%;
  overflow-x: auto;
}

.showDesktopCell,
.showDesktopSmallCell {
  display: none;
}

@media (min-width: 768px) {
  .showMobileSmallCell {
    display: none;
  }
  .showDesktopSmallCell {
    display: table-cell;
  }
}
@media (min-width: 1024px) {
  .showMobileCell {
    display: none;
  }
  .showDesktopCell {
    display: table-cell;
  }
}
table.table thead {
  display: none;
}
@media (min-width: 768px) {
  table.table thead {
    display: table-row-group;
  }
}
table.table thead tr, table.table thead tr:hover {
  background-color: transparent;
}
table.table.show-header thead {
  display: block !important;
}
@media (max-width: 600px) {
  table.table.no-td-border td {
    border: none !important;
  }
}
table.table caption {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #060B0D;
  text-align: left;
  margin-bottom: 16px;
}
@media (max-width: 480px) {
  table.table tr {
    border-bottom: 1px solid #E3E5E5;
  }
}
table.table td {
  display: none;
}
table.table td.show {
  display: table-cell;
}
@media (max-width: 768px) {
  table.table td.small {
    display: inline-block;
    width: 8%;
    padding-right: 0;
  }
  table.table td.name {
    display: inline-block;
    width: calc(100% - 80px);
    padding-right: 0;
  }
  table.table td.nett {
    display: inline-block;
    width: 15%;
    padding-right: 0;
  }
  table.table td.general {
    display: inline-block;
    width: 45%;
    padding: 0;
    line-height: 1.2em;
  }
  table.table td.action {
    display: table-cell;
    display: inline-block;
    width: 80px;
    border-bottom: 0;
  }
  table.table td.action .button {
    line-height: 24px;
  }
}
@media (min-width: 768px) {
  table.table td {
    display: table-cell;
  }
}
table.table td span {
  outline: none;
}
table.table td a:hover {
  text-decoration: none;
}
table.table td.avatar-order {
  border: none;
}
@media (max-width: 480px) {
  table.table td.avatar-order {
    display: none;
    width: 0;
  }
}
table.table td.nett {
  font-weight: 500;
}
table.table td.nett em {
  font-style: normal;
}
@media all and (max-width: 480px) {
  table.table td.nett {
    text-align: right;
  }
}
@media all and (min-width: 768px) {
  table.table td.nett {
    display: table-cell;
  }
}
@media all and (max-width: 768px) {
  table.table td.nett {
    border-bottom: none;
  }
}
table.table td.duetoyou {
  font-weight: 500;
}
table.table td.duetoyou em {
  font-style: normal;
}
@media (max-width: 480px) {
  table.table td.duetoyou {
    text-align: right;
  }
}
@media (min-width: 768px) {
  table.table td.duetoyou {
    display: table-cell;
  }
}
@media (max-width: 768px) {
  table.table td.duetoyou {
    border-bottom: none;
  }
}
@media (max-width: 768px) {
  table.table td.issue_status {
    border-bottom: none;
  }
}
table.table td.name {
  height: auto;
  align-items: center;
  border: none;
}
@media (min-width: 768px) {
  table.table td.name {
    display: table-cell;
    border-bottom: 1px solid #E3E5E6;
    padding: 0;
  }
}
table.table td.name a {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: #181818;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  table.table td.name a {
    font-size: 12px;
  }
}
table.table td.name .role {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
}
@media (min-width: 768px) {
  table.table td.name .role {
    display: none;
  }
}
table.table td.name .mdi {
  margin-left: auto;
  color: #C6CACC;
  font-size: 20px;
}
@media (min-width: 768px) {
  table.table td.name .mdi {
    display: none;
  }
}
table.table td .cb label {
  position: relative;
  float: left;
  width: 100%;
  display: flex;
}
table.table td .cb span {
  right: 10px;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 2px;
}
table.table td .cb input {
  display: none;
}
table.table td .cb input:checked + span {
  background: #000;
}
table.table td .cb input:checked + span:before {
  content: "";
  position: absolute;
  left: 7px;
  width: 6px;
  height: 11px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(45deg);
  top: 2px;
}
table.table td.showMobileSmallCell {
  display: table-cell;
}
table.table td.showDesktopCell, table.table td.showDesktopSmallCell {
  display: none;
}
@media (min-width: 768px) {
  table.table td.showMobileSmallCell {
    display: none;
  }
  table.table td.showDesktopSmallCell {
    display: table-cell;
  }
}
@media (min-width: 1024px) {
  table.table td.showMobileCell {
    display: none;
  }
  table.table td.showDesktopCell {
    display: table-cell;
  }
}
table.table td .status {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  position: relative;
}
table.table td .status:hover:before {
  visibility: visible;
}
table.table td .status.status-active {
  background: #3EA23D;
}
table.table td .status.status-pending {
  background: #E02020;
}
table.table td .status.status-inactive {
  background: #FAA91A;
}
table.table td .status:before {
  content: attr(data-status);
  position: absolute;
  top: -10px;
  left: -85px;
  height: 24px;
  background: #fff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  visibility: hidden;
  padding: 0 15px;
  transition: visibility 0.15s cubic-bezier(0.42, 0, 0.58, 1);
}
table.table .col-filter {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px 0 0;
}
table.table .col-filter .mdi {
  margin-left: auto;
  font-size: 20px;
}
table.table .col-filter.small {
  max-width: 200px;
}
table.table .table-filter {
  position: absolute;
  top: 50px;
  background: #FFFFFF;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  padding: 5px 8px;
  z-index: 8;
  width: 100%;
}
table.table .table-filter.small {
  max-width: 190px;
}
table.table .table-filter .search-type-inner {
  display: flex;
  flex-direction: column;
}
table.table .table-filter .search-type {
  width: 100%;
}
table.table .table-filter .loader {
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
table.table .table-filter .loader .lds-ellipsis > div {
  background: #4d4d4d;
}
table.table .table-filter label {
  display: flex;
  align-items: center;
}
table.table .table-filter label:hover {
  cursor: pointer;
}
table.table .table-filter label input {
  width: 0;
  height: 0;
  opacity: 0;
  display: none;
}
table.table .table-filter label input:checked + span {
  background: #F5F6F9;
}
table.table .table-filter label span {
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: #5E6366;
  background: #fff;
  padding: 7px 12px;
  border-radius: 5px;
}

.action-menu {
  position: absolute;
  background: #fff;
  border-radius: 5px;
  padding: 8px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
  z-index: 3;
  transition-timing-function: ease-in;
  transition: 0.8s;
}
table .action-menu {
  width: 180px;
  right: 0;
}
table td a .action-menu {
  display: block;
}
.action-menu .action-body {
  display: flex;
  flex-direction: column;
}
.action-menu .link {
  line-height: 24px;
  letter-spacing: 0.44px;
  color: #5E6366;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: none;
  outline: none;
  min-height: 35px;
  border-radius: 5px;
  text-align: left;
  padding: 0 12px;
  width: 100%;
  cursor: pointer;
  -webkit-transition: all 200ms cubic-bezier(0.25, 1, 0.5, 1);
  -o-transition: all 200ms cubic-bezier(0.25, 1, 0.5, 1);
  transition: all 200ms cubic-bezier(0.25, 1, 0.5, 1);
}
.action-menu .link:hover {
  background: #F5F6F9;
}
.action-menu a.link, .action-menu button.link {
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #000000;
  padding: 4px 15px;
  width: 100%;
  text-decoration: none;
}
.action-menu .action-footer {
  border-top: 1px solid #D9D9D9;
}
.action-menu .action-footer button {
  display: flex;
}
.action-menu .action-footer span {
  margin-right: 16px;
  font-size: 20px;
}
.action-menu.user-role-assignment {
  left: 0;
  top: -25px !important;
}
@media (max-width: 1024px) {
  .action-menu.user-role-assignment {
    top: -35px !important;
  }
}
.action-menu.user-role-assignment .loader {
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.action-menu.user-role-assignment .loader .lds-ellipsis > div {
  background: #4d4d4d;
}
.action-menu.user-role-assignment label {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.action-menu.user-role-assignment label input {
  display: none;
  height: 0;
  width: 0;
  opacity: 0;
}
.action-menu.type-filter {
  right: 0;
  top: 55px !important;
  width: 200px;
}
.action-menu.type-filter input {
  display: none;
  height: 0;
  width: 0;
  opacity: 0;
}
.action-menu.type-filter input:checked + label:before {
  background: #000;
}
.action-menu.type-filter input:checked + label:after {
  content: "";
  position: absolute;
  right: 17px;
  width: 6px;
  height: 11px;
  border-right: 2px solid #C6CACC;
  border-bottom: 2px solid #C6CACC;
  transform: rotate(45deg);
}
.action-menu.type-filter .link {
  display: flex;
  align-items: center;
}
.action-menu.type-filter label {
  transform: none !important;
  position: relative;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
}
.action-menu.type-filter label:before {
  content: "";
  position: absolute;
  right: 10px;
  width: 20px;
  height: 20px;
  border: 1px solid #C6CACC;
  border-radius: 2px;
}
.action-menu.promo-action {
  top: 28px;
}

.alert-overlay {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(24, 24, 24, 0.3);
  overflow: auto;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert-overlay .alert-dialog.dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0 80px;
  width: 100%;
  max-width: 440px;
}
.alert-overlay .modal-wrapper {
  background: #fff;
  width: 90vw;
  max-width: 640px;
  box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.24), 0px 2px 6px rgba(8, 35, 48, 0.16);
  border-radius: 6px;
  padding: 24px;
  margin: 0 auto;
}
.alert-overlay h3 {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #060B0D;
  word-break: break-all;
  margin-bottom: 7px;
}
.alert-overlay p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #060B0D;
  margin-bottom: 77px;
}

.restaurant-list-overlay {
  align-items: normal;
}
.restaurant-list-overlay .alert-dialog {
  margin: 60px 0;
  width: 100%;
  max-width: 363px;
}
.restaurant-list-overlay .modal-wrapper {
  padding: 4px;
}
.restaurant-list-overlay .modal-header {
  padding: 9px 12px;
  border-bottom: 1px solid #F1F1EE;
  margin-bottom: 16px;
}
.restaurant-list-overlay .modal-header h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #060B0D;
  margin: 0;
}
.restaurant-list-overlay .modal-header .close {
  font-size: 12px;
  margin-left: auto;
  align-items: center;
}
.restaurant-list-overlay .modal-header .close:hover {
  cursor: pointer;
}
.restaurant-list-overlay .modal-header .close .mdi {
  font-size: 20px;
  margin-left: 8px;
}

.restaurant-list-main .restaurant-list {
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #F1F1EE;
  padding: 8px 12px;
  margin: 0 0 8px 0;
  cursor: pointer;
}
.restaurant-list-main .restaurant-list:hover {
  background: #EAF6FD;
}
.restaurant-list-main input {
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.restaurant-list-main .form-group input {
  display: block;
  -webkit-appearance: initial;
  -moz-appearance: initial;
}
.restaurant-list-main .res-img {
  width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: 48px;
  margin-right: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.restaurant-list-main .col-inner {
  flex-direction: column;
  width: 100%;
}
.restaurant-list-main h6 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #060B0D;
  margin-bottom: 0;
}
.restaurant-list-main address {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #060B0D;
  display: flex;
  min-width: 160px;
  align-items: center;
}

.toolbar {
  background-color: #fff;
  /* box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.9); */
  border-bottom: 4px solid #F5F6F9;
}
.toolbar .flex {
  align-items: center;
}
.toolbar .wrapper {
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0;
}
@media (min-width: 768px) {
  .toolbar .wrapper {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
.toolbar .tabs {
  margin: 0;
}
.toolbar .tabs a {
  display: inline-block;
  /* color: inherit; */
  text-align: center;
  text-decoration: none;
  margin-right: 32px;
  font-weight: 500;
}
.toolbar .tabs a:before {
  content: "";
  position: absolute;
  display: inline-block;
  bottom: 0;
  width: 0;
  height: 2px;
  background: #6DCEF5;
  border-radius: 5px 5px 0px 0px;
  transform: translateX(-50%) scale(1.9);
  transition: width 300ms cubic-bezier(0.25, 1, 0.5, 1);
}
.toolbar .tabs a:hover {
  color: #6ECFF5;
}
.toolbar .tabs a.active:before {
  width: 32px;
}
.toolbar address {
  position: relative;
  display: block;
  font-style: normal;
  line-height: inherit;
  padding-left: 20px;
}
@media (min-width: 1024px) {
  .toolbar address {
    display: inline-block;
  }
}
.toolbar address .mdi {
  position: absolute;
  left: 0;
  color: #585858;
  font-size: 1.2em;
  line-height: 1.4rem;
  margin-right: 8px;
}
.toolbar .rating-stars {
  color: #585858;
  display: inline-block;
  padding-right: 8px;
  margin-bottom: -1px;
}
@media (min-width: 1024px) {
  .toolbar .rating-stars {
    padding-left: 16px;
  }
}
.toolbar .rating-stars span {
  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  line-height: 1em;
}
.toolbar .rating-desc {
  display: inline-block;
  letter-spacing: 0.4px;
  font-size: inherit;
}

.page-toolbar.analytic-toolbar {
  border-bottom: 1px solid #F1F1EE;
}
@media (min-width: 768px) {
  .page-toolbar.analytic-toolbar {
    box-shadow: none;
  }
}
@media (max-width: 480px) {
  .page-toolbar.analytic-toolbar {
    flex-direction: column;
  }
}
.page-toolbar.analytics-toolbar-menu {
  top: 102px;
  padding: 19px 47px 23px;
}
@media (min-width: 768px) {
  .page-toolbar.analytics-toolbar-menu {
    top: 102px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.240999);
  }
}
.page-toolbar .toolbar-action {
  margin-left: auto;
}
@media (max-width: 768px) {
  .page-toolbar .toolbar-action {
    display: none;
  }
}
.page-toolbar .toolbar-address {
  position: relative;
  cursor: pointer;
  -webkit-transition: opacity 300ms cubic-bezier(0.25, 1, 0.5, 1);
  -o-transition: opacity 300ms cubic-bezier(0.25, 1, 0.5, 1);
  transition: opacity 300ms cubic-bezier(0.25, 1, 0.5, 1);
}
.page-toolbar .toolbar-address h6 {
  display: inline;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .page-toolbar .toolbar-address h6 {
    margin: 0 16px 0 0;
  }
}
.page-toolbar .toolbar-address button {
  cursor: pointer;
}
@media (max-width: 768px) {
  .page-toolbar .toolbar-address button {
    display: none;
  }
}
.page-toolbar .toolbar-address:after {
  content: "";
  position: absolute;
  bottom: -10px;
  display: block;
  background-color: #585858;
  width: 0;
  height: 2px;
  -webkit-transition: width 300ms cubic-bezier(0.25, 1, 0.5, 1);
  -o-transition: width 300ms cubic-bezier(0.25, 1, 0.5, 1);
  transition: width 300ms cubic-bezier(0.25, 1, 0.5, 1);
}
.page-toolbar .toolbar-address:hover {
  opacity: 0.8;
}
.page-toolbar .toolbar-address:hover:after {
  width: 32px;
}

.analytic-toolbar {
  justify-content: space-between;
  padding: 24px 32px;
}
@media (min-width: 768px) {
  .analytic-toolbar {
    padding: 24px 48px;
  }
}
@media (min-width: 1024px) {
  .analytic-toolbar {
    padding: 24px 64px;
  }
}
@media (min-width: 1400px) {
  .analytic-toolbar {
    padding: 24px 128px;
  }
}
.analytic-toolbar .col-left {
  width: 100%;
  align-items: center;
}
.analytic-toolbar .res-img {
  margin-right: 14px;
}
.analytic-toolbar .res-img img {
  width: 100%;
  max-width: 56px;
}
.analytic-toolbar .col-right {
  display: none;
}
@media (min-width: 768px) {
  .analytic-toolbar .col-right {
    display: flex;
  }
}
.analytic-toolbar .col-inner {
  flex-direction: column;
  width: 100%;
}
.analytic-toolbar h6 {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.15px;
  color: #060B0D;
}
.analytic-toolbar .promo-button-container {
  align-items: center;
}
.analytic-toolbar .btn {
  margin-bottom: 0;
}
.analytic-toolbar .btn-switch {
  background: #fff;
  color: #6CDEF5;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: none;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.1px;
  line-height: inherit;
}
.analytic-toolbar .btn.blue {
  border: none;
  background: none;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: #6ECFF5;
  padding: 0;
}
@media (min-width: 768px) {
  .analytic-toolbar .btn.blue {
    display: none;
  }
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #fff;
  padding: 12px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.0869482);
  z-index: 6;
}
@media (min-width: 768px) {
  .bottom-nav {
    display: none;
  }
}
.bottom-nav a {
  position: relative;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #181818;
  text-decoration: none;
  margin: 0 4px;
  width: 100%;
  max-width: 20%;
  display: flex;
}
.bottom-nav a:first-child {
  margin: 0 4px 0 0;
}
.bottom-nav a:last-child {
  margin: 0 0 0 4px;
}
.bottom-nav a .mdi {
  font-size: 25px;
  margin-bottom: 4px;
}
.bottom-nav a.active {
  color: #6ECFF5;
}
.bottom-nav .mobile-menu {
  justify-content: space-between;
}

.mobile-menu-overlay {
  position: fixed;
  top: 0;
  right: 0;
  float: right;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(92, 92, 92, 0.6);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 999;
  cursor: pointer;
}
.mobile-menu-overlay .mobile-menu-container {
  width: 100%;
  max-width: 80%;
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  right: 0;
  z-index: 9999;
}
.mobile-menu-overlay .mobile-menu-container .countBadge {
  top: 14px;
  right: 14px;
}
.mobile-menu-overlay .menu-header {
  display: flex;
  align-items: center;
}
.mobile-menu-overlay .menu-header .close {
  margin-left: auto;
}
.mobile-menu-overlay .menu-header .close .mdi {
  font-size: 25px;
}
.mobile-menu-overlay .site-logo {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 35px 0;
}
.mobile-menu-overlay .site-logo img {
  width: 100%;
  max-width: 55px;
  height: auto;
  display: block;
}
.mobile-menu-overlay .menu-body {
  padding: 0 16px;
}
.mobile-menu-overlay .menu-body button, .mobile-menu-overlay .menu-body a {
  width: 100%;
  text-align: left;
  padding: 14px 15px;
  border-left: 0;
  border-top: 0;
  border-right: 0;
  background: none;
  font-size: 16px;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin: 0 0 5px;
  max-width: 100%;
  flex-direction: row;
  font-weight: normal;
  border-radius: 11px;
}
.mobile-menu-overlay .menu-body button .mdi, .mobile-menu-overlay .menu-body a .mdi {
  margin-right: 10px;
  margin-bottom: 0;
  font-size: 15px;
}
.mobile-menu-overlay .menu-body button.active, .mobile-menu-overlay .menu-body a.active {
  background: #6ECFF5;
  color: #181818;
}
.mobile-menu-overlay .menu-footer {
  margin-top: auto;
  border-top: 1px solid #E5E5E5;
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
}
.mobile-menu-overlay .menu-footer .user-profile {
  align-items: center;
}
.mobile-menu-overlay .menu-footer .avatar {
  width: 40px;
  height: 40px;
  margin-right: 14px;
  border-radius: 100%;
  font-style: normal;
  font-size: 22px;
  padding-top: 8px;
  text-align: center;
  color: #ffffff;
}
.mobile-menu-overlay .menu-footer .profile-user .name {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #000000;
  margin-bottom: 5px;
}
.mobile-menu-overlay .menu-footer .profile-user .role {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.4px;
  color: #000000;
}
.mobile-menu-overlay .menu-footer button.link {
  margin: 15px 0 0;
  padding: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  box-shadow: none;
}
.mobile-menu-overlay .menu-footer button.link .mdi {
  font-size: 20px;
  margin-right: 15px;
}

.mobile-menu-overlay span.mobile-expanded-menu-item-badge,
.bottom-nav span.mobile-nav-item-badge {
  background-color: #3F9047;
  color: #FFFFFF;
}

.bottom-nav span.mobile-nav-item-badge {
  padding: 0.01rem 0.25rem;
  border-radius: 12px;
  font-size: 7.5px;
  font-weight: 600;
  position: absolute;
  top: -10px;
  right: -2px;
}

.mobile-menu-overlay span.mobile-expanded-menu-item-badge {
  padding: 0.3rem 0.75rem;
  margin: 0 0.5rem;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 700;
}

.mobile-menu-overlay a.active span.mobile-expanded-menu-item-badge {
  background-color: #FFFFFF;
  color: #3F9047;
}

.no-content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  flex-direction: column;
}
.no-content .nc-image {
  max-width: 300px;
}
.no-content p, .no-content h4 {
  margin-bottom: 24px;
}
.no-content.promo-blank {
  min-height: 60vh;
  padding: 0 20px;
}

.terms-dialog {
  align-items: flex-start;
}
.terms-dialog .modal-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .terms-dialog .modal-wrapper {
    max-width: 960px;
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
.terms-dialog ol {
  counter-reset: item;
  margin: 0px 0 0 28px;
}
.terms-dialog ol li {
  display: block;
  padding: 0 0 16px 0;
  margin: 0;
  position: relative;
}
.terms-dialog ol li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
  padding-right: 24px;
  position: absolute;
  left: -24px;
  min-width: 60px;
  background: none;
}
.terms-dialog ol li ol {
  padding-top: 24px;
}
.terms-dialog ol li ol li {
  font-size: 16px;
  line-height: 160%;
  margin-left: 0;
  font-weight: normal;
}
.terms-dialog ol li ol li:before {
  left: -36px;
}
@media (min-width: 1024px) {
  .terms-dialog ol li ol li:before {
    left: -60px;
  }
}
.dash-form .form-wrapper {
  max-width: 640px;
  flex-wrap: wrap;
}
.dash-form .form-group {
  flex-wrap: wrap;
  width: 100%;
}
.dash-form .form-group .row-label {
  text-align: left;
}
.dash-form .form-group.half-group {
  max-width: 100%;
}
@media (min-width: 1024px) {
  .dash-form .form-group.half-group {
    max-width: calc(50% - 14px);
  }
}
.dash-form .form-group.half-row > *:not(.row-label) {
  width: 100%;
}
@media (min-width: 1024px) {
  .dash-form .form-group.half-row > *:not(.row-label) {
    max-width: calc(50% - 14px);
  }
}
.dash-form .form-group.half-row .row-label {
  max-width: 100% !important;
}
.dash-form .form-group.user-roles-group .row-label .mdi {
  margin-left: auto;
  font-size: 20px;
}
.dash-form .row-label {
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #060B0D;
  margin-bottom: 8px;
}
.dash-form .input-icon:hover {
  cursor: pointer;
}
.dash-form .input-icon .mdi {
  position: absolute;
  top: 18px;
  right: 10px;
  font-size: 20px;
}
.dash-form .input-icon span.edit {
  position: absolute;
  top: 22px;
  right: 10px;
  font-size: 12px;
  line-height: 16px;
  color: #6ECFF5;
}
.dash-form .form-row {
  width: 100%;
}
.dash-form .form-row.blue-bg {
  background: #DCF0FB;
}
.dash-form .form-row.blue-bg input {
  background: none;
}
.dash-form .form-row.blue-bg.no-bg {
  background: none;
}
.dash-form .form-row.blue-bg.no-bg .mdi {
  display: none !important;
}
.dash-form .form-row.clickable {
  cursor: pointer;
}
.dash-form .form-row.clickable:hover {
  cursor: pointer;
}
.dash-form .form-row.clickable input {
  cursor: pointer;
}
.dash-form .form-row.clickable input:hover {
  cursor: pointer;
}
.dash-form .user-roles {
  position: absolute;
  top: 70px;
  left: 0;
  background: #FFFFFF;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  padding: 5px 8px;
  z-index: 8;
  width: 100%;
}
.dash-form .user-roles .user-role-inner {
  display: flex;
  flex-direction: column;
}
.dash-form .user-roles .search-type {
  width: 100%;
}
.dash-form .user-roles label {
  display: flex;
  align-items: center;
  position: relative;
  left: 0;
  top: 0;
  border-radius: 5px;
}
.dash-form .user-roles label:hover {
  cursor: pointer;
  background: #F5F6F9;
}
.dash-form .user-roles label input {
  width: 0;
  height: 0;
  opacity: 0;
  display: none;
}
.dash-form .user-roles label input:checked + span {
  background: #F5F6F9;
}
.dash-form .user-roles label span {
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: #5E6366;
  background: #fff;
  padding: 7px 12px;
  border-radius: 5px;
}
.dash-form .saving-progress {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dash-form .saving-progress .lds-ellipsis {
  transform: scale(0.5);
  width: 20px;
  height: 20px;
  top: -13px;
}
.dash-form .disabled .form-row {
  background-color: #FAFAFA;
  border-color: #E0E4E6;
}
.dash-form .disabled .form-row input {
  color: #868A8C;
}

.page-overlay {
  position: fixed;
  background: #fff;
  transform: translate(-50%, -50%);
}
.page-overlay .search-filter .types-filter {
  position: absolute;
  right: 0;
  top: 17px;
  padding-right: 15px;
  display: flex;
  z-index: 3;
}
.page-overlay .search-filter .types-filter .types {
  font-size: 14px;
  line-height: 24px;
  padding: 0 10px 0 0;
  display: none;
}
@media (min-width: 1024px) {
  .page-overlay .search-filter .types-filter .types {
    display: flex;
  }
}
.page-overlay .search-filter .types-filter .mdi {
  transition: ease all 0.2s;
  font-size: 20px;
}
.page-overlay .search-filter .types-filter .mdi:hover {
  cursor: pointer;
}
.page-overlay .form-result {
  border: none;
  box-shadow: none;
  padding: 0;
}
.page-overlay .form-result .result-row {
  display: flex;
  margin-bottom: 8px;
}
.page-overlay .form-result .result-row .list-row {
  background: none;
  border: none;
  box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.07), 0px 2px 6px rgba(8, 35, 48, 0.08);
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: #181818;
  text-align: left;
  position: relative;
  padding: 16px 64px 16px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.page-overlay .form-result .result-row .list-row:before {
  content: "";
  position: absolute;
  right: 10px;
  width: 20px;
  height: 20px;
  border: 1px solid #C6CACC;
  border-radius: 2px;
  top: 25px;
}
.page-overlay .form-result .result-row .list-row .res-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #5E6366;
}
.page-overlay .form-result .result-row .list-row.checked:before {
  background: #000;
}
.page-overlay .form-result .result-row .list-row.checked:after {
  content: "";
  position: absolute;
  right: 17px;
  width: 6px;
  height: 11px;
  border-right: 2px solid #C6CACC;
  border-bottom: 2px solid #C6CACC;
  transform: rotate(45deg);
  top: 27px;
}
.page-overlay .selected-pills {
  position: relative;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #C6CACC;
  flex-wrap: wrap;
  padding: 35px 10px 10px;
}
.page-overlay .selected-pills .label {
  position: absolute;
  left: 12px;
  top: 17px;
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: #5E6366;
  z-index: 1;
  opacity: 1;
  transform: translateY(-60%) translateX(0px);
  font-size: 12px;
}
.page-overlay .selected-pills .item-selected {
  font-size: 12px;
  border: 1px solid #C6CACC;
  border-radius: 100px;
  padding: 7px 15px;
  margin: 0 10px 10px 0;
  align-items: center;
}
.page-overlay .selected-pills .item-selected .mdi {
  font-size: 20px;
  margin-right: 10px;
}
.page-overlay .selected-pills .item-selected .hidden {
  color: #DCDCDC;
}

.toast-flash {
  position: fixed;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.24), 0px 2px 6px rgba(8, 35, 48, 0.16);
  border-radius: 6px;
  padding: 16px 16px 16px 55px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.44px;
  width: 100%;
  max-width: calc(100% - 32px);
  margin: 0 16px;
  top: 40px;
  display: flex;
  align-items: center;
  z-index: 9999;
}
@media (min-width: 768px) {
  .toast-flash {
    right: 40px;
    max-width: 400px;
  }
}
.toast-flash .mdi {
  position: absolute;
  left: 20px;
  font-size: 1.2rem;
  color: #FFFFFF;
  background-color: #6ECFF5;
  border-radius: 100%;
  padding: 0.5%;
}
.toast-flash.error {
  border: 1px solid #E02020;
  color: #E02020;
}
.toast-flash.success {
  border: 1px solid #6ECFF5;
}
.toast-flash .toast-inner:before {
  font-family: "Material Design Icons";
  position: absolute;
  left: 22px;
  font-size: 25px;
}

.impersonating {
  width: calc(100% - 32px);
  position: fixed;
  display: flex;
  align-items: center;
  background-color: #6ECFF5;
  color: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.240999);
  left: 50%;
  transform: translateX(-50%);
  bottom: 80px;
  padding: 8px 20px;
  z-index: 5;
  font-size: 0.85rem;
  letter-spacing: 0.25px;
  font-style: normal;
  font-weight: bold;
  border-radius: 42px;
  height: 42px;
}
@media (min-width: 768px) {
  .impersonating {
    width: auto;
    left: initial;
    right: 106px;
    bottom: 35px;
    transform: none;
  }
}
.impersonating .imp-person {
  margin-left: 16px;
}
.impersonating button {
  margin-left: auto;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #FFFFFF;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
}

.avatar {
  display: inline-block;
  height: 24px;
  width: 24px;
  border-radius: 24px;
  color: white;
  text-align: center;
  margin-right: 7px;
  background-color: #585858;
}

.avatar-40 {
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  color: white;
  text-align: center;
  border: 1px solid #E3E5E5;
  padding: 1px;
}
.avatar-40.no-image {
  background-color: #E3E5E5;
}

/* Mobile Sort and Filter control styles */
.body-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.mobile_sort-filter {
  display: none;
}
@media (max-width: 768px) {
  .mobile_sort-filter {
    display: block;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    /* height: 455px; */
    height: auto;
    z-index: 1001;
    left: 0;
    margin-bottom: 0;
    padding-bottom: 8%;
  }
}
.mobile_sort-filter .apply-btn {
  display: flex;
  justify-content: space-around;
}
.mobile_sort-filter .apply-btn .btn {
  width: 92%;
  text-transform: uppercase;
}
.mobile_sort-filter .sf-header {
  padding: 3% 4%;
  margin-bottom: 3%;
  border-bottom: 3px solid #E3E5E5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobile_sort-filter .sf-header button {
  color: #6ECFF5;
  text-transform: capitalize;
  cursor: pointer;
}
.mobile_sort-filter .sf-header h5 {
  margin: 0;
}
.mobile_sort-filter .sf-header .mdi {
  font-size: 1.6em;
  cursor: pointer;
  color: #000;
  position: relative;
  top: 1px;
  left: 2px;
}
.mobile_sort-filter .control-component {
  padding: 0 5%;
}
.mobile_sort-filter .control-component h5 {
  margin-bottom: 1%;
}

.tooltip {
  position: relative;
}
.tooltip .mdi {
  font-size: 1.4em;
  vertical-align: middle;
  padding-top: 2px;
  margin-left: 2px;
}
.tooltip .mdi .info {
  display: none;
  position: absolute;
  text-transform: none;
  top: 20px;
  color: #181818;
  background-color: #fff;
  border-radius: 8px;
  z-index: 3;
  -webkit-box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}
.tooltip .mdi .info h6 {
  margin-bottom: 8px;
}
.tooltip .mdi .info p:last-child {
  margin: 0;
}
@media (max-width: 480px) {
  .tooltip .mdi .info {
    padding: 16px;
    min-width: 270px;
  }
}
@media (min-width: 480px) {
  .tooltip .mdi .info {
    padding: 24px;
    min-width: 300px;
  }
}
.tooltip .mdi:hover, .tooltip .mdi:focus {
  outline: none;
}
.tooltip .mdi:hover .info, .tooltip .mdi:focus .info {
  display: block;
}

.ios-select .absolute {
  display: none;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  html.ios {
    position: fixed;
    height: 100%;
    overflow: hidden;
  }
  .ios body {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
  .ios-select {
    border: none;
  }
  .ios-select .absolute {
    inset: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    border-radius: 20px;
  }
  .ios-select .absolute .mdi {
    font-size: 1.25rem;
    display: flex;
    align-items: center;
  }
  .ios-select select {
    opacity: 0;
  }
}
@media all and (max-width: 768px) {
  .mat-form-field-type-mat-date-range-input {
    width: 100%;
  }
}
.mat-form-field-type-mat-date-range-input .mat-form-field-flex {
  border-radius: 65px !important;
  background-color: #f3f3f3 !important;
  padding: 0 !important;
}
.mat-form-field-type-mat-date-range-input .mat-form-field-flex .mat-form-field-infix {
  min-width: 225px;
  top: -3px;
  left: 23px;
}
.mat-form-field-type-mat-date-range-input .mat-form-field-flex .mat-form-field-suffix {
  right: 5px;
  top: 3px;
}
.mat-form-field-type-mat-date-range-input .mat-form-field-underline {
  width: unset;
}

.mat-calendar-body-selected {
  background: #6ECFF5;
}

.mat-calendar-body-in-preview .mat-calendar-body-cell-content {
  background: none !important;
}

.mat-calendar-body-preview-start .mat-calendar-body-cell-content, .mat-calendar-body-preview-end .mat-calendar-body-cell-content {
  background: #6ECFF5 !important;
}

.md-drppicker .btn {
  color: #000 !important;
}

.md-drppicker .btn, .md-drppicker .btn:focus, .md-drppicker .btn:hover,
.md-drppicker .ranges ul li button.active, .md-drppicker td.active, .md-drppicker td.active:hover {
  background-color: #6ECFF5 !important;
  color: #000;
  box-shadow: none !important;
  text-transform: capitalize !important;
}

.popup-modal .mat-form-field-wrapper {
  padding: 0;
}
.popup-modal .mat-form-field {
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 8px;
  position: relative;
  padding: 3%;
}
.popup-modal .mat-icon-button {
  width: 20px;
  height: 20px;
  line-height: 20px;
}
.popup-modal .mat-datepicker-toggle {
  /*top: 0;
  right: 0;
  position: absolute;*/
}
.popup-modal .mat-form-field-underline {
  visibility: hidden;
  border: none;
}
.popup-modal .mat-form-field-infix {
  border: none !important;
  outline: none;
  stroke: none;
  padding: 0 !important;
}

.promo-header .header-left {
  align-items: flex-start;
}
.promo-header .header-left h2, .promo-header .header-left h3 {
  font-weight: 600;
  margin-bottom: 1%;
}
.promo-header .header-left p {
  font-size: 1.1rem;
  margin-bottom: 8px;
}
.promo-header .header-left p:last-child {
  margin-bottom: 0;
}
.promo-header .header-left p span {
  max-width: 80%;
}
.promo-header .header-left a {
  color: #6ECFF5;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-decoration: none;
}
.promo-header .header-left img {
  max-width: 60px;
  margin-bottom: 32px;
}
.promo-header .header-left .incentive > *:last-child {
  padding-bottom: 24px;
}

.promo-select .promo-main .promo-option {
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.24), 0px 2px 6px rgba(8, 35, 48, 0.16);
  margin-bottom: 27px;
  border-radius: 10px;
  border: 1px solid #DDE2E5;
}
@media (min-width: 768px) {
  .promo-select .promo-main {
    display: flex;
    flex-wrap: wrap;
  }
  .promo-select .promo-main .promo-option {
    flex-basis: 48%;
    flex-basis: calc(50% - 14px);
  }
  .promo-select .promo-main .promo-option:nth-of-type(2n-1) {
    margin-right: 27px;
  }
}
.promo-select .card-header {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5%;
}
.promo-select .card-body {
  padding: 24px;
  width: 70%;
}
.promo-select .card-body p {
  margin-bottom: 3%;
}
.promo-select .card-body button img {
  width: 13px;
  display: inline-block;
  position: relative;
  left: 10px;
  top: 1.5px;
}
.promo-select h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #060B0D;
  margin-bottom: 3%;
}
.promo-select p {
  font-size: 0.95rem;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: #060B0D;
}
.promo-select ul {
  margin-bottom: 26px;
}
.promo-select li {
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.4px;
  color: #000000;
  display: flex;
}
.promo-select li:before {
  font-family: "Material Design Icons";
  content: "\f012c";
  width: 24px;
  height: 24px;
}

#incentiveBadge {
  background-color: #B5E6FB;
  position: fixed;
  bottom: 110px;
  left: 50%;
  font-size: 0.9em;
  line-height: 24px;
  padding: 8px 16px;
  border-radius: 5px;
  z-index: 6;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  #incentiveBadge {
    left: initial;
    bottom: initial;
    right: 32px;
    top: 24px;
    padding: 8px 32px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

/* 3rd party Ang Material Carousel styling here */
.carousel {
  background-color: #6ECFF5;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.24), 0px 2px 6px rgba(8, 35, 48, 0.16);
  margin-bottom: 3%;
  min-height: 12em;
}
@media (max-width: 1024px) {
  .carousel {
    min-height: 16em;
  }
}
.carousel > button:first-of-type {
  color: #6ECFF5;
  visibility: hidden;
}
.carousel > button:first-of-type:before {
  display: inline-block;
  font-size: 2em;
  color: #181818;
  font-family: "mdi";
  content: "\f105";
}
@media (min-width: 1024px) {
  .carousel > button:first-of-type:before {
    visibility: visible;
  }
}
.carousel > button:last-of-type {
  color: #6ECFF5;
  visibility: hidden;
}
.carousel > button:last-of-type:before {
  font-size: 2em;
  color: #181818;
  font-family: "mdi";
  content: "\f107";
}
@media (min-width: 1024px) {
  .carousel > button:last-of-type:before {
    visibility: visible;
    width: 0;
  }
}

.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #181818;
}

.btn-inverted {
  border-radius: 50px;
  padding: 2% 0;
  min-width: 155px;
  line-height: 22px;
}
@media (min-width: 1024px) {
  .btn-inverted {
    padding: 2%;
  }
}
.btn-inverted .mdi {
  position: relative;
}

@media (max-width: 1024px) {
  .btn-desktop {
    display: none;
  }
}

@media (min-width: 1024px) {
  .dashboardPage-banner .btn-tablet {
    display: none;
  }
}

.page-notice {
  position: relative;
  padding: 2% 1% 1% 1%;
  margin-bottom: 3%;
  border-radius: 4px;
  overflow: hidden;
  z-index: 1;
}
@media (max-width: 1024px) {
  .page-notice {
    padding: 4% 1% 1% 6%;
  }
}
.page-notice.notice-blue {
  background: #6ECFF5;
}
.page-notice.notice-green {
  background: rgba(62, 161, 61, 0.2);
  border: solid 1px #3EA13D;
}
.page-notice.notice-red {
  border: solid 1px #F35357;
}
.page-notice .notice-left {
  width: 100%;
}
@media (min-width: 768px) {
  .page-notice .notice-left {
    max-width: calc(100% - 177px);
  }
}
.page-notice .notice-right {
  width: 100%;
}
@media (min-width: 768px) {
  .page-notice .notice-right {
    max-width: 177px;
  }
}
.page-notice .notice-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .page-notice .notice-center {
    padding: 1%;
  }
}
.page-notice .notice-center img {
  align-self: center;
  width: 90px;
  display: inline-block;
}
.page-notice .notice-center a, .page-notice .notice-center button {
  align-self: center;
  position: relative;
}
@media (min-width: 1400px) {
  .page-notice .notice-center a, .page-notice .notice-center button {
    top: 35%;
  }
}
.page-notice .notice-center .center-content {
  margin: 0 3%;
  display: inline-block;
}
.page-notice .notice-center .center-content h1 {
  margin-bottom: 0;
  padding-bottom: 0;
}
.page-notice .notice-dismis {
  position: absolute;
  right: 19px;
  top: 19px;
  background: #78909C;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 8;
}
.page-notice .notice-dismis .mdi {
  color: #fff;
  font-weight: bold;
}
.page-notice h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #060B0D;
  margin-bottom: 8px;
}
.page-notice p {
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 20px;
  letter-spacing: 0.44px;
  color: #060B0D;
  margin-bottom: 15px;
}
.page-notice .btn.light {
  background: #fff;
  box-shadow: none;
}

.confetti {
  width: 8px;
  height: 8px;
  background-color: #f2d74e;
  position: absolute;
  top: -30px;
  left: 50%;
  -webkit-animation: confetti 4000ms ease-in-out -2s infinite;
  animation: confetti 4000ms ease-in-out -2s infinite;
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
}

.confetti:nth-child(1) {
  background-color: #f2d74e;
  left: 10%;
  -webkit-animation-delay: 0;
  animation-delay: 0;
}

.confetti:nth-child(2) {
  background-color: #95c3de;
  left: 20%;
  -webkit-animation-delay: -260ms;
  animation-delay: -260ms;
  top: -120px;
}

.confetti:nth-child(3) {
  background-color: #ff9a91;
  left: 30%;
  -webkit-animation-delay: -760ms;
  animation-delay: -760ms;
  top: -100px;
}

.confetti:nth-child(4) {
  background-color: #f2d74e;
  left: 40%;
  -webkit-animation-delay: -470ms;
  animation-delay: -470ms;
  top: -180px;
}

.confetti:nth-child(5) {
  background-color: #95c3de;
  left: 50%;
  -webkit-animation-delay: -2220ms;
  animation-delay: -2220ms;
  top: -80px;
}

.confetti:nth-child(6) {
  background-color: #ff9a91;
  left: 60%;
  -webkit-animation-delay: -150ms;
  animation-delay: -150ms;
  top: -50px;
}

.confetti:nth-child(7) {
  background-color: #f2d74e;
  left: 70%;
  -webkit-animation-delay: -2650ms;
  animation-delay: -2650ms;
  top: -250px;
}

.confetti:nth-child(8) {
  background-color: #95c3de;
  left: 80%;
  -webkit-animation-delay: -1300ms;
  animation-delay: -1300ms;
}

.confetti:nth-child(9) {
  background-color: #ff9a91;
  left: 90%;
  -webkit-animation-delay: -3000ms;
  animation-delay: -3000ms;
  top: -100px;
}

.confetti:nth-child(10) {
  background-color: #f2d74e;
  left: 100%;
  -webkit-animation-delay: -1750ms;
  animation-delay: -1750ms;
  top: -160px;
}

@-webkit-keyframes confetti {
  0% {
    -webkit-transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
    transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
  }
  25% {
    -webkit-transform: rotateZ(5deg) rotateY(360deg) translate(-3vw, 10vh);
    transform: rotateZ(5deg) rotateY(360deg) translate(-3vw, 10vh);
  }
  50% {
    -webkit-transform: rotateZ(15deg) rotateY(720deg) translate(3vw, 30vh);
    transform: rotateZ(15deg) rotateY(720deg) translate(3vw, 30vh);
  }
  75% {
    -webkit-transform: rotateZ(5deg) rotateY(1080deg) translate(-7vw, 40vh);
    transform: rotateZ(5deg) rotateY(1080deg) translate(-7vw, 40vh);
  }
  100% {
    -webkit-transform: rotateZ(15deg) rotateY(1440deg) translate(5vw, 55vh);
    transform: rotateZ(15deg) rotateY(1440deg) translate(5vw, 55vh);
  }
}
@keyframes confetti {
  0% {
    -webkit-transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
    transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
  }
  25% {
    -webkit-transform: rotateZ(5deg) rotateY(360deg) translate(-3vw, 10vh);
    transform: rotateZ(5deg) rotateY(360deg) translate(-3vw, 10vh);
  }
  50% {
    -webkit-transform: rotateZ(15deg) rotateY(720deg) translate(3vw, 30vh);
    transform: rotateZ(15deg) rotateY(720deg) translate(3vw, 30vh);
  }
  75% {
    -webkit-transform: rotateZ(5deg) rotateY(1080deg) translate(-7vw, 40vh);
    transform: rotateZ(5deg) rotateY(1080deg) translate(-7vw, 40vh);
  }
  100% {
    -webkit-transform: rotateZ(15deg) rotateY(1440deg) translate(5vw, 55vh);
    transform: rotateZ(15deg) rotateY(1440deg) translate(5vw, 55vh);
  }
}
.promo-form-body .frm_error_main {
  width: 100%;
}
.promo-form-body .frm_error {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #E02020;
  bottom: -20px;
  padding: 16px 0;
}
.promo-form-body .promo-type input[type=text] {
  margin-bottom: 26px;
}
@media (min-width: 768px) {
  .promo-form-body .promo-type input[type=text] {
    max-width: 50%;
  }
}
.promo-form-body .promo-type input[type=text].last-child {
  margin-bottom: 0;
}
.promo-form-body .spend-action {
  position: relative;
  margin-top: 16px;
}
.promo-form-body .spend-action input {
  max-width: 100%;
  margin-bottom: 0;
  cursor: pointer;
}
.promo-form-body .spend-action .spend-val {
  position: relative;
  width: 200px;
}
.promo-form-body .spend-action .spend-val .mdi {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}
.promo-form-body .spend-action .min-spend-main {
  position: absolute;
  top: 40px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  padding: 5px 8px;
  z-index: 8;
  right: 0;
}
.promo-form-body .spend-action .min-spend-main label {
  margin-bottom: 0;
}
.promo-form-body .spend-action .min-spend-main-note {
  font-size: 12px;
  padding: 8px 0 0 0;
  position: absolute;
}
.promo-form-body .spend-action .min-spend input {
  -webkit-appearance: none;
  display: none;
}
.promo-form-body .spend-action .min-spend label {
  width: 100%;
  position: relative;
  float: left;
  padding: 8px;
  border-radius: 4px;
}
.promo-form-body .spend-action .min-spend label:hover {
  cursor: pointer;
  background: #f3f3f3;
}
.promo-form-body .form-row-title {
  width: 100%;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #060B0D;
  margin-bottom: 8px;
}
.promo-form-body .mov {
  margin-bottom: 46px;
}
.promo-form-body .mov .frm_error {
  bottom: -36px;
}
.promo-form-body .date-range {
  margin: 0;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .promo-form-body .date-range {
    margin-bottom: 32px;
  }
}
.promo-form-body .date-range span {
  display: inline-block;
  margin: 0 16px 8px;
}
.promo-form-body .date-range label {
  position: relative;
  display: inline-block;
  margin: 0 0 8px;
}
.promo-form-body .date-range label.moz {
  display: none;
}
@media not all and (min-resolution: 0.001dpcm) {
  .promo-form-body .date-range label.moz {
    display: inline-block;
  }
}
.promo-form-body .date-range label.none-moz {
  display: inline-block;
}
@media not all and (min-resolution: 0.001dpcm) {
  .promo-form-body .date-range label.none-moz {
    display: none;
  }
}
.promo-form-body .date-range label .mdi {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 20px;
}
.promo-form-body .date-range label .mdi:hover {
  cursor: pointer;
}
.promo-form-body input:not(.mat-start-date, .mat-end-date, .promo-search-input) {
  background: none;
  padding: 7px 12px;
  border-radius: 10px;
  border: 1px solid #C6CACC;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  letter-spacing: 0.44px;
  color: #181818;
}
.promo-form-body .day-group {
  display: flex;
}
.promo-form-body .day-group .day {
  margin: 0 8px 0 0;
}
.promo-form-body .day-group .day input {
  display: none;
  -webkit-appearance: none;
  opacity: 0;
}
.promo-form-body .day-group .day input:checked + label {
  background: #6ECFF5;
  border: 1px solid #6ECFF5;
  color: #181818;
}
.promo-form-body .day-group .day label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: 1px solid #D9D9D9;
}
.promo-form-body .time-group label {
  max-width: 140px;
}
.promo-form-body .time-group label input {
  max-width: 100%;
  margin: 0;
}
.promo-form-body .time-group button {
  border: 1px solid #C6CACC;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 12px;
  margin: 0 8px 8px 0;
}
.promo-form-body .time-group button.active {
  background: #6ECFF5;
  border-color: #6ECFF5;
  color: #fff;
}
.promo-form-body .h6 input {
  display: none;
  -webkit-appearance: none;
}
.promo-form-body .h6 input:checked + label {
  background: #181818;
}
.promo-form-body .h6 input:checked + label:before {
  opacity: 1;
}
.promo-form-body .h6 label {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #181818;
  display: flex;
  position: relative;
  transition: ease all 0.2s;
}
.promo-form-body .h6 label:before {
  content: "";
  width: 5px;
  height: 13px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg);
  opacity: 0;
  transition: ease all 0.2s;
  position: absolute;
  left: 7px;
  top: 0;
}
.promo-form-body .restaurant {
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
  border-radius: 5px;
  align-items: center;
  padding: 8px 12px;
  margin-bottom: 8px;
  position: relative;
}
.promo-form-body .restaurant .action {
  margin-left: auto;
}
.promo-form-body .restaurant .action > article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.promo-form-body .restaurant .action input {
  display: none;
  -webkit-appearance: none;
}
.promo-form-body .restaurant .action input:checked + label {
  background: #181818;
}
.promo-form-body .restaurant .action input:checked + label:before {
  opacity: 1;
}
.promo-form-body .terms-row {
  margin: 0;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .promo-form-body .terms-row {
    margin-bottom: 32px;
  }
}
.promo-form-body .terms-row h5 {
  padding-bottom: 10px;
}
.promo-form-body .terms-row label {
  display: flex;
  align-items: center;
}
.promo-form-body .terms-row .terms-optin {
  margin-right: 24px;
}
.promo-form-body .terms-row a {
  color: #1EBEFE;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.promo-form-body .terms-row input {
  display: none;
  -webkit-appearance: none;
}
.promo-form-body .terms-row input:checked + span {
  background: #181818;
}
.promo-form-body .terms-row input:checked + span:before {
  opacity: 1;
}
.promo-form-body .terms-row span {
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 3px;
  margin: 0 10px 0 0;
  border: 1px solid #181818;
  display: flex;
  position: relative;
  transition: ease all 0.2s;
}
.promo-form-body .terms-row span:before {
  content: "";
  width: 5px;
  height: 13px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg);
  opacity: 0;
  transition: ease all 0.2s;
  position: absolute;
  left: 7px;
  top: 0;
}
.promo-form-body .disabled {
  color: #5E6366;
}
.promo-form-body .disabled input {
  color: #868A8C;
  background-color: #FAFAFA;
  border-color: #E0E4E6;
  pointer-events: none;
}

.promo-page-header h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #000000;
}
.promo-page-header h5 span {
  color: #bfc3c5;
}

.budCap-spent .card-header {
  background-color: #FAA91A !important;
}

.promo-card {
  background: #FFFFFF;
  box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.24), 0px 2px 6px rgba(8, 35, 48, 0.16);
  border-radius: 6px;
  margin-bottom: 24px;
}
.promo-card a {
  align-self: baseline;
}
.promo-card.Upcoming .card-header, .promo-card.Active .card-header {
  background-color: #6ECFF5;
}
.promo-card.Upcoming .card-header h6, .promo-card.Upcoming .card-header .status, .promo-card.Active .card-header h6, .promo-card.Active .card-header .status {
  color: #fff;
}
.promo-card.budCap-spent .card-header h6, .promo-card.budCap-spent .card-header .status {
  color: #fff !important;
}
.promo-card .weekly-cap-label {
  padding: 12px 24px;
  border: 1px solid #FAA902;
  margin: 12px 22px 0;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}
.promo-card .weekly-cap-label p {
  margin-bottom: unset;
}
.promo-card .weekly-cap-label span {
  font-weight: 500;
}
.promo-card .card-header {
  padding: 7px 24px;
  display: flex;
  align-items: center;
  border-radius: 6px 6px 0 0;
  background: #F5F6F9;
}
.promo-card .card-header h6 {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #181818;
  text-transform: uppercase;
  margin-bottom: 0;
}
.promo-card .card-header h6 span {
  font-weight: normal;
}
.promo-card .card-header h6 strong {
  font-weight: 500;
}
.promo-card .card-header h6 .modal-back {
  display: none;
}
.promo-card .card-header .status {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #181818;
  margin-left: auto;
}
.promo-card .card-description {
  align-items: center;
  flex-direction: column;
  text-align: center;
}
@media (min-width: 768px) {
  .promo-card .card-description {
    padding: 15px 23px;
    flex-direction: row;
    text-align: left;
  }
}
.promo-card .card-description .col.budget-cap_Reached {
  max-width: 400px;
  width: 100%;
  background-color: rgba(250, 169, 26, 0.2);
  border-radius: 5px;
  padding: 1% 2%;
}
@media (max-width: 1280px) {
  .promo-card .card-description .col.budget-cap_Reached {
    text-align: center;
  }
}
.promo-card .card-description .col.budget-cap_Reached p {
  text-align: left;
}
@media (max-width: 1280px) {
  .promo-card .card-description .col.budget-cap_Reached p {
    padding-bottom: 3%;
  }
}
.promo-card .card-description .col.budget-cap_Reached button, .promo-card .card-description .col.budget-cap_Reached a {
  /* font-size: 13px; */
}
.promo-card .card-description .col.budget-cap_Reached button {
  padding: 7px 20px;
}
.promo-card .card-description .col.budget-cap_Not-Reached {
  max-width: 400px;
  text-align: start;
}
@media (max-width: 1280px) {
  .promo-card .card-description .col.budget-cap_Not-Reached {
    text-align: center;
  }
  .promo-card .card-description .col.budget-cap_Not-Reached button {
    margin-top: 5%;
  }
}
@media (min-width: 1280px) {
  .promo-card .card-description .col.budget-cap_Not-Reached p {
    float: left;
    text-align: left;
  }
  .promo-card .card-description .col.budget-cap_Not-Reached button {
    float: right;
  }
}
.promo-card .card-description .col {
  position: relative;
  width: 100%;
  z-index: 1;
}
@media (max-width: 768px) {
  .promo-card .card-description .col {
    order: 2;
    padding: 5px 23px;
  }
}
@media (min-width: 768px) {
  .promo-card .card-description .col {
    /* max-width: 230px; */
  }
}
@media (max-width: 768px) {
  .promo-card .card-description .col:last-child {
    order: 1;
    border-bottom: 1px solid #f1f1ee;
    padding: 23px 15px;
    margin: 0 0 15px;
  }
}
@media (min-width: 768px) {
  .promo-card .card-description .col:last-child {
    margin-left: auto;
  }
}
.promo-card .card-description p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #060B0D;
  margin-bottom: 0;
}
.promo-card .card-summary {
  padding: 15px 23px 0;
}
.promo-card .card-summary h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #5E6366;
  margin-bottom: 8px;
}
@media (max-width: 768px) {
  .promo-card .card-summary h6 {
    text-align: center;
  }
}
.promo-card .card-summary .promo-summary {
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 6px;
}
@media (max-width: 768px) {
  .promo-card .card-summary .promo-summary {
    flex-wrap: wrap;
  }
}
.promo-card .card-summary .promo-summary .col {
  width: 100%;
  max-width: 50%;
  padding: 19px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 768px) {
  .promo-card .card-summary .promo-summary .col {
    border-right: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
  }
}
@media (min-width: 768px) {
  .promo-card .card-summary .promo-summary .col {
    border-left: 1px solid #D9D9D9;
  }
}
@media (max-width: 768px) {
  .promo-card .card-summary .promo-summary .col:nth-child(2n) {
    border-right: none;
  }
}
.promo-card .card-summary .promo-summary .col:first-child {
  border-left: 0;
}
.promo-card .card-summary .promo-summary h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #060B0D;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .promo-card .card-summary .promo-summary h3 {
    font-size: 20px;
  }
}
@media (max-width: 480px) {
  .promo-card .card-summary .promo-summary h3 {
    font-size: 16px;
  }
}
.promo-card .card-summary .promo-summary p {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #939393;
  margin-bottom: 0;
  max-width: 70%;
}
.promo-card .card-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 24px;
  padding: 4px 23px;
}
.promo-card .card-footer button {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #181818;
  height: 30px;
  display: flex;
  align-items: center;
}
.promo-card .card-footer button:hover {
  cursor: pointer;
}
.promo-card .card-footer button .mdi {
  font-size: 24px;
  color: #78909C;
  margin-left: 10px;
}

.promos .modal {
  width: 100% !important;
  max-width: 1024px !important;
}
.promos .modal .body {
  max-height: 100% !important;
}
.promos .modal .body .promo-card:first-child .card-description .col:last-child {
  display: none;
  width: 0;
}
.promos .modal .body .promo-card:not(:first-child) .card-summary {
  padding-bottom: 3%;
}
.promos .modal .body .promo-card:not(:first-child) .status {
  flex-grow: 1;
  text-align: end;
  text-transform: uppercase;
}
.promos .modal .body .promo-card:not(:first-child) .btn {
  padding: 1%;
  margin-bottom: 2%;
}

.cofuded-promo-modal .modal {
  height: auto !important;
}
.cofuded-promo-modal .modal .header {
  padding-bottom: 0 !important;
  border-bottom: 0 !important;
}

.promo-modal-close {
  position: absolute;
  right: 10px;
  top: 4px;
  font-size: 12px;
  align-items: center;
}
@media (max-width: 768px) {
  .promo-modal-close {
    top: 10px;
  }
}
.promo-modal-close:hover {
  cursor: pointer;
}
.promo-modal-close .mdi {
  font-size: 20px;
  margin-left: 8px;
  color: #000;
}

.alert-overlay.promos {
  display: block !important;
}
.alert-overlay.promos .alert-dialog {
  max-width: 824px;
  position: relative;
  background: #fff;
  border-radius: 6px 6px 0 0;
  padding: 0 0 20px;
}
@media (min-width: 768px) {
  .alert-overlay.promos .alert-dialog {
    margin: 40px auto;
  }
}
.alert-overlay.promos .promo-card {
  box-shadow: none;
}
@media (max-width: 768px) {
  .alert-overlay.promos .promo-card {
    margin: 0 0 25px;
  }
}
.alert-overlay.promos .promo-card p {
  margin: 0;
}
.alert-overlay.promos .promo-card.Active .status {
  margin-right: 25px;
}
.alert-overlay.promos .promo-card.Ended .status {
  margin-right: 25px;
}
.alert-overlay.promos .promo-card.Stopped .status {
  margin-right: 25px;
}
.alert-overlay.promos .promo-card.Stopped .status {
  margin-right: 25px;
}
@media (max-width: 768px) {
  .alert-overlay.promos .promo-card .card-header h6 {
    display: flex;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.15px;
  }
}
@media (max-width: 768px) {
  .alert-overlay.promos .promo-card .card-header h6 .modal-back {
    display: block;
  }
  .alert-overlay.promos .promo-card .card-header h6 .modal-back .mdi {
    font-size: 20px;
    margin: 0 10px 0 0;
  }
}
@media (max-width: 768px) {
  .alert-overlay.promos .promo-card .card-header h6 span {
    font-weight: inherit;
    margin: 0 0 0 10px;
  }
}
.alert-overlay.promos .modal-promo-card .card-summary-header {
  align-items: center;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .alert-overlay.promos .modal-promo-card .card-summary-header {
    flex-direction: row;
  }
}
.alert-overlay.promos .modal-promo-card .card-summary-header h6 {
  margin-bottom: 0;
}
.alert-overlay.promos .modal-promo-card .card-summary-header .status {
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  background: #EAF6FD;
  border-radius: 99px;
  padding: 2px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  text-transform: capitalize;
  margin-left: 50px;
}
@media (min-width: 768px) {
  .alert-overlay.promos .modal-promo-card .card-summary-header .status {
    margin-left: 20px;
    margin-top: 0;
  }
}
.alert-overlay.promos .modal-promo-card .card-summary-header .btn {
  margin-top: 20px;
  margin-bottom: 20px;
  float: right;
  background: #fff;
  font-weight: normal;
  color: #000;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.4px;
  width: 100%;
  max-width: 79px;
  min-width: 79px;
  padding: 3px 8px;
}
@media (min-width: 768px) {
  .alert-overlay.promos .modal-promo-card .card-summary-header .btn {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
  }
}

.restaurant-list ul {
  text-align: left;
  padding: 0 20px 20px 40px;
  text-transform: capitalize;
}

.action-disabled {
  margin-right: 8px;
}
.action-disabled .mdi {
  position: relative;
  font-size: 1.3rem;
  vertical-align: middle;
}

@media (max-width: 768px) {
  .promo-campaign-list th {
    display: none;
  }
}
@media (max-width: 768px) {
  .promo-campaign-list td {
    display: none;
  }
  .promo-campaign-list td.promos, .promo-campaign-list td.status, .promo-campaign-list td.action {
    display: table-cell;
  }
}
.promo-campaign-list td .promo {
  display: flex;
  flex-direction: column;
}
.promo-campaign-list td .promo h6 {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
}
.promo-campaign-list td .promo span {
  font-weight: normal;
  font-size: 9px;
  line-height: 12px;
}

.custom-popover {
  background-color: #FFFFFF;
  padding: 0.9375rem;
  min-width: 16.25rem;
  min-height: 3.125rem;
  border-radius: 0.875rem;
  border: 1px rgba(15, 72, 94, 0.15) solid;
  box-shadow: 0px 9px 15px 0px rgba(15, 72, 94, 0.1490196078);
  overflow: auto;
  max-height: 20rem;
  display: flex;
  flex-direction: column;
}
.custom-popover__header {
  flex: 0 0 auto;
  margin-bottom: 0.625rem;
}
.custom-popover__list {
  padding: 0.625rem 0;
  flex: 1 1 100%;
  overflow: auto;
}
.custom-popover__item {
  cursor: pointer;
  text-decoration: none !important;
  width: 100%;
  padding: 0.625rem 0;
  display: block;
}
.custom-popover__footer {
  flex: 0 0 auto;
  padding-top: 0.625rem;
}
.custom-popover__footer .btn {
  width: 100%;
}
.custom-popover--auto {
  min-height: 0;
  min-width: 0;
  padding: 1.25rem;
}
.custom-popover h6 {
  font-size: 0.9375rem;
  line-height: 1.125rem;
  font-weight: 700;
  margin-bottom: 0.625rem;
}
.custom-popover .form-group {
  margin-bottom: 0.9375rem;
}
.custom-popover .form-control {
  padding: 0.625rem 0.75rem;
  margin: 0;
  border-radius: 0.5rem;
  border: 1px rgba(15, 72, 94, 0.15) solid;
  background-color: #FFFFFF;
  font-weight: 400;
  font-size: 0.9375rem;
  width: 100%;
}

.full-screen-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(24, 24, 24, 0.15);
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: none;
}
.full-screen-modal__wrapper {
  display: block;
  max-height: 100%;
  max-width: 100%;
  height: 90vh;
  width: 90vw;
  max-width: 100rem;
  background-color: #FFFFFF;
  border: 1px rgba(15, 72, 94, 0.15) solid;
  border-radius: 1.25rem;
  overflow: hidden;
}
.full-screen-modal__wrapper .form-control {
  padding: 0.9375rem 0.75rem;
  margin: 0;
  border-radius: 0.875rem;
  border: 2px rgba(15, 72, 94, 0.15) solid;
  background-color: #FFFFFF;
  font-weight: 400;
  font-size: 0.9375rem;
  width: 100%;
}
.full-screen-modal__wrapper .form-control--56 {
  width: 3.5rem;
}
.full-screen-modal__wrapper .form-control--90 {
  width: 5.625rem;
}
.full-screen-modal__wrapper .form-control--auto {
  width: auto;
}
.full-screen-modal .btn {
  background-color: #181818;
  color: #FFFFFF;
  line-height: 1;
  min-width: 0;
  border-radius: 20px;
  padding: 8px 15px;
}
.full-screen-modal .btn--outlined {
  background-color: #FFFFFF;
  border: 2px rgba(24, 24, 24, 0.54) solid;
  color: #181818;
}
.full-screen-modal .btn--primary {
  background-color: #6ECFF5;
  color: #181818;
}
.full-screen-modal .btn--secondary {
  background-color: rgba(15, 72, 94, 0.1);
  color: #181818;
}
.full-screen-modal .btn--small {
  font-size: 0.75rem;
}

.time-period {
  margin: 0;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .time-period {
    margin-bottom: 32px;
  }
}
@media (min-width: 768px) {
  .time-period {
    flex-direction: row;
    align-items: center;
  }
}
.time-period h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #000000;
  margin: 0;
}
.time-period .col-left {
  display: none;
  order: 2;
}
@media (min-width: 768px) {
  .time-period .col-left {
    display: block;
    order: 1;
    padding: 0;
  }
}
.time-period .col-right {
  margin-left: auto;
  position: relative;
  order: 1;
  width: 100%;
}
@media (min-width: 768px) {
  .time-period .col-right {
    order: 2;
    width: auto;
    margin-left: auto;
  }
}
.time-period .col-right .action-menu {
  top: 45px;
}
.time-period button {
  position: relative;
  width: 100%;
  min-width: 203px;
  border: 1px solid #C6CACC;
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  padding: 4px 12px 7px;
  text-align: left;
  align-items: center;
}
@media (min-width: 768px) {
  .time-period button {
    max-width: 203px;
  }
}
.time-period .btn-label-wrapper {
  flex-direction: column;
}
.time-period .button-label {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #5E6366;
}
.time-period .selected-time {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.44px;
  color: #181818;
}
.time-period .mdi {
  font-size: 20px;
  margin-left: auto;
}
.time-period input {
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.time-period .link {
  display: flex;
  align-items: center;
}

.graph-card {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16), 0px 1px 2px rgba(8, 35, 48, 0.24);
  border-radius: 6px;
  padding: 16px 24px;
  margin-bottom: 24px;
}
.graph-card.mobile {
  display: block;
}
@media (min-width: 768px) {
  .graph-card.mobile {
    display: none;
  }
}
.graph-card.desktop {
  display: none;
}
@media (min-width: 768px) {
  .graph-card.desktop {
    display: block;
  }
}
.graph-card .card-total-overview {
  flex-direction: column;
}
.graph-card .card-total-overview h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #5E6366;
  margin-bottom: 9px;
}
.graph-card .card-total-overview h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 0.1px;
  color: #060B0D;
}
.graph-card .card-total-overview h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #939393;
}

.orders-container .tool-row {
  width: 100%;
}
.orders-container .tool-row .column {
  float: left;
  margin-right: 1%;
}
.orders-container .tool-row .column .col-date-range {
  width: 33%;
}
.orders-container .tool-row .column .col-dropdown {
  width: 15%;
}
@media (max-width: 768px) {
  .orders-container .tool-row .column {
    width: 100%;
  }
}
.orders-container .tool-row .last-updated {
  float: right;
}
.orders-container .issue {
  border-radius: 15px;
  color: #E02020;
  min-width: 85px;
  display: inline-block;
  font-size: 0.85em;
  text-transform: capitalize;
  font-weight: 500;
  position: relative;
  text-align: center;
}
@media (min-width: 768px) {
  .orders-container .issue {
    left: -15px;
  }
}
.orders-container .success {
  color: green !important;
  background-color: lime !important;
}
.orders-container .cust-name-col .avatar, .orders-container .cust-name-col span {
  float: left;
}
.orders-container .order-status-tag {
  position: relative;
  top: -7px;
}
@media (min-width: 768px) {
  .orders-container {
    padding: 0;
  }
}

.tf-v1-sidetab {
  z-index: 5 !important;
}

.tf-v1-popover {
  bottom: 125px !important;
  z-index: 5 !important;
}
@media (min-width: 768px) {
  .tf-v1-popover {
    bottom: 85px !important;
  }
}
.tf-v1-popover .tf-v1-popover-button {
  bottom: 70px !important;
}
@media (min-width: 480px) {
  .tf-v1-popover .tf-v1-popover-button {
    right: 15px !important;
  }
}
@media (min-width: 768px) {
  .tf-v1-popover .tf-v1-popover-button {
    bottom: 25px !important;
  }
}
.tf-v1-popover .tf-v1-popover-button .tf-v1-popover-button-icon {
  transform: rotate(90deg) !important;
}

.typeform-popover .typeform-popover-button {
  width: 42px !important;
  height: 42px !important;
  right: 100px !important;
  bottom: 4.15% !important;
  background-color: white !important;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1) !important;
}
@media screen and (max-width: 768px) {
  .typeform-popover .typeform-popover-button {
    right: 4% !important;
    bottom: 90% !important;
  }
}
.typeform-popover .typeform-popover-button .typeform-popover-button-icon {
  color: black;
}
.typeform-popover .typeform-popover-button svg {
  width: 20px !important;
  height: 20px !important;
}
.typeform-popover .typeform-popover-button path {
  fill: black !important;
}
.typeform-popover .typeform-popover-button:hover {
  background-color: #fafafa !important;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2) !important;
}

.show-typeform-popover .typeform-popover {
  display: inline-block;
}

.tf-v1-popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.tf-v1-popup .tf-v1-iframe-wrapper, .tf-v1-popup iframe {
  width: 100% !important;
  height: 100% !important;
}

.tf-v1-popup .tf-v1-close {
  color: #000 !important;
  z-index: 999 !important;
  position: fixed !important;
  top: 20px !important;
  right: 20px !important;
}

/*
 * Charts.css v1.0.0 (https://ChartsCSS.org/)
 * Copyright 2020 Rami Yushuvaev
 * Licensed under MIT
 */
.charts-css {
  --color-1: rgba(240, 50, 50, 0.75);
  --color-2: rgba(255, 180, 50, 0.75);
  --color-3: rgba(255, 220, 90, 0.75);
  --color-4: rgba(100, 210, 80, 0.75);
  --color-5: rgba(90, 165, 255, 0.75);
  --color-6: rgba(170, 90, 240, 0.75);
  --color-7: rgba(180, 180, 180, 0.75);
  --color-8: rgba(110, 110, 110, 0.75);
  --color-9: rgba(170, 150, 110, 0.75);
  --color-10: rgba(130, 50, 20, 0.75);
  --chart-bg-color: #f5f5f5;
  --heading-size: 0px;
  --primary-axis-color: rgba(0, 0, 0, 1);
  --primary-axis-style: solid;
  --primary-axis-width: 1px;
  --secondary-axes-color: rgba(0, 0, 0, .15);
  --secondary-axes-style: solid;
  --secondary-axes-width: 1px;
  --data-axes-color: rgba(0, 0, 0, .15);
  --data-axes-style: solid;
  --data-axes-width: 1px;
  --legend-border-color: rgb(200, 200, 200);
  --legend-border-style: solid;
  --legend-border-width: 1px;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  border: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

/*
 * Chart custom properties
 */
@property --color,
@property --color-1,
@property --color-2,
@property --color-3,
@property --color-4,
@property --color-5,
@property --color-6,
@property --color-7,
@property --color-8,
@property --color-9,
@property --color-10,
@property --chart-bg-color {
  syntax: "<color>";
  initial-value: transparent;
  inherits: true;
}
@property --heading-size {
  syntax: "<length>";
  initial-value: 0;
  inherits: true;
}
@property --labels-size {
  syntax: "<length>";
  initial-value: 0;
  inherits: true;
}
@property --labels-align {
  syntax: "<string>";
  initial-value: center;
  inherits: true;
}
@property --primary-axis-width,
@property --secondary-axes-width,
@property --data-axes-width,
@property --legend-border-width {
  syntax: "<length>";
  initial-value: 1px;
  inherits: true;
}
@property --primary-axis-style,
@property --secondary-axes-style,
@property --data-axes-style,
@property --legend-border-style {
  syntax: "<line-style>";
  initial-value: solid;
  inherits: true;
}
@property --primary-axis-color,
@property --secondary-axes-color,
@property --data-axes-color,
@property --legend-border-color {
  syntax: "<color>";
  initial-value: transparent;
  inherits: true;
}
@property --start,
@property --end,
@property --size,
@property --line-size {
  syntax: "<number>";
  initial-value: 0;
  inherits: true;
}
/*
 * Chart wrapper element
 */
.charts-css, .charts-css::after, .charts-css::before,
.charts-css *,
.charts-css *::after,
.charts-css *::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/*
 * Reset table element
 */
table.charts-css {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  overflow: initial;
  background-color: transparent;
}

table.charts-css caption,
table.charts-css colgroup,
table.charts-css thead,
table.charts-css tbody,
table.charts-css tr,
table.charts-css th,
table.charts-css td {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
}

table.charts-css colgroup,
table.charts-css thead,
table.charts-css tfoot {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
}

table.charts-css tbody {
  position: relative;
}

/*
 * Reset list elements
 */
ul.charts-css,
ol.charts-css {
  list-style-type: none;
}

ul.charts-css li,
ol.charts-css li {
  margin: 0;
  padding: 0;
  border: 0;
}

/*
 * Chart heading
 */
.charts-css:not(.show-heading) caption {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
}

.charts-css.show-heading {
  --heading-size: 1.5rem;
}

.charts-css.show-heading caption {
  display: block;
  width: 100%;
  height: var(--heading-size);
}

/*
 * Chart colors
 */
.charts-css.bar tbody tr:nth-of-type(10n + 1) td,
.charts-css.bar.multiple tbody tr td:nth-of-type(10n + 1),
.charts-css.column tbody tr:nth-of-type(10n + 1) td,
.charts-css.column.multiple tbody tr td:nth-of-type(10n + 1),
.charts-css.area tbody tr td:nth-of-type(10n + 1)::before,
.charts-css.line tbody tr td:nth-of-type(10n + 1)::before {
  background: var(--color, var(--color-1));
}

.charts-css.pie tbody tr:nth-of-type(10n + 1) td,
.charts-css.pie.multiple tbody tr td:nth-of-type(10n + 1) {
  --c: var(--color, var(--color-1, transparent) );
}

.charts-css.bar tbody tr:nth-of-type(10n + 2) td,
.charts-css.bar.multiple tbody tr td:nth-of-type(10n + 2),
.charts-css.column tbody tr:nth-of-type(10n + 2) td,
.charts-css.column.multiple tbody tr td:nth-of-type(10n + 2),
.charts-css.area tbody tr td:nth-of-type(10n + 2)::before,
.charts-css.line tbody tr td:nth-of-type(10n + 2)::before {
  background: var(--color, var(--color-2));
}

.charts-css.pie tbody tr:nth-of-type(10n + 2) td,
.charts-css.pie.multiple tbody tr td:nth-of-type(10n + 2) {
  --c: var(--color, var(--color-2, transparent) );
}

.charts-css.bar tbody tr:nth-of-type(10n + 3) td,
.charts-css.bar.multiple tbody tr td:nth-of-type(10n + 3),
.charts-css.column tbody tr:nth-of-type(10n + 3) td,
.charts-css.column.multiple tbody tr td:nth-of-type(10n + 3),
.charts-css.area tbody tr td:nth-of-type(10n + 3)::before,
.charts-css.line tbody tr td:nth-of-type(10n + 3)::before {
  background: var(--color, var(--color-3));
}

.charts-css.pie tbody tr:nth-of-type(10n + 3) td,
.charts-css.pie.multiple tbody tr td:nth-of-type(10n + 3) {
  --c: var(--color, var(--color-3, transparent) );
}

.charts-css.bar tbody tr:nth-of-type(10n + 4) td,
.charts-css.bar.multiple tbody tr td:nth-of-type(10n + 4),
.charts-css.column tbody tr:nth-of-type(10n + 4) td,
.charts-css.column.multiple tbody tr td:nth-of-type(10n + 4),
.charts-css.area tbody tr td:nth-of-type(10n + 4)::before,
.charts-css.line tbody tr td:nth-of-type(10n + 4)::before {
  background: var(--color, var(--color-4));
}

.charts-css.pie tbody tr:nth-of-type(10n + 4) td,
.charts-css.pie.multiple tbody tr td:nth-of-type(10n + 4) {
  --c: var(--color, var(--color-4, transparent) );
}

.charts-css.bar tbody tr:nth-of-type(10n + 5) td,
.charts-css.bar.multiple tbody tr td:nth-of-type(10n + 5),
.charts-css.column tbody tr:nth-of-type(10n + 5) td,
.charts-css.column.multiple tbody tr td:nth-of-type(10n + 5),
.charts-css.area tbody tr td:nth-of-type(10n + 5)::before,
.charts-css.line tbody tr td:nth-of-type(10n + 5)::before {
  background: var(--color, var(--color-5));
}

.charts-css.pie tbody tr:nth-of-type(10n + 5) td,
.charts-css.pie.multiple tbody tr td:nth-of-type(10n + 5) {
  --c: var(--color, var(--color-5, transparent) );
}

.charts-css.bar tbody tr:nth-of-type(10n + 6) td,
.charts-css.bar.multiple tbody tr td:nth-of-type(10n + 6),
.charts-css.column tbody tr:nth-of-type(10n + 6) td,
.charts-css.column.multiple tbody tr td:nth-of-type(10n + 6),
.charts-css.area tbody tr td:nth-of-type(10n + 6)::before,
.charts-css.line tbody tr td:nth-of-type(10n + 6)::before {
  background: var(--color, var(--color-6));
}

.charts-css.pie tbody tr:nth-of-type(10n + 6) td,
.charts-css.pie.multiple tbody tr td:nth-of-type(10n + 6) {
  --c: var(--color, var(--color-6, transparent) );
}

.charts-css.bar tbody tr:nth-of-type(10n + 7) td,
.charts-css.bar.multiple tbody tr td:nth-of-type(10n + 7),
.charts-css.column tbody tr:nth-of-type(10n + 7) td,
.charts-css.column.multiple tbody tr td:nth-of-type(10n + 7),
.charts-css.area tbody tr td:nth-of-type(10n + 7)::before,
.charts-css.line tbody tr td:nth-of-type(10n + 7)::before {
  background: var(--color, var(--color-7));
}

.charts-css.pie tbody tr:nth-of-type(10n + 7) td,
.charts-css.pie.multiple tbody tr td:nth-of-type(10n + 7) {
  --c: var(--color, var(--color-7, transparent) );
}

.charts-css.bar tbody tr:nth-of-type(10n + 8) td,
.charts-css.bar.multiple tbody tr td:nth-of-type(10n + 8),
.charts-css.column tbody tr:nth-of-type(10n + 8) td,
.charts-css.column.multiple tbody tr td:nth-of-type(10n + 8),
.charts-css.area tbody tr td:nth-of-type(10n + 8)::before,
.charts-css.line tbody tr td:nth-of-type(10n + 8)::before {
  background: var(--color, var(--color-8));
}

.charts-css.pie tbody tr:nth-of-type(10n + 8) td,
.charts-css.pie.multiple tbody tr td:nth-of-type(10n + 8) {
  --c: var(--color, var(--color-8, transparent) );
}

.charts-css.bar tbody tr:nth-of-type(10n + 9) td,
.charts-css.bar.multiple tbody tr td:nth-of-type(10n + 9),
.charts-css.column tbody tr:nth-of-type(10n + 9) td,
.charts-css.column.multiple tbody tr td:nth-of-type(10n + 9),
.charts-css.area tbody tr td:nth-of-type(10n + 9)::before,
.charts-css.line tbody tr td:nth-of-type(10n + 9)::before {
  background: var(--color, var(--color-9));
}

.charts-css.pie tbody tr:nth-of-type(10n + 9) td,
.charts-css.pie.multiple tbody tr td:nth-of-type(10n + 9) {
  --c: var(--color, var(--color-9, transparent) );
}

.charts-css.bar tbody tr:nth-of-type(10n + 10) td,
.charts-css.bar.multiple tbody tr td:nth-of-type(10n + 10),
.charts-css.column tbody tr:nth-of-type(10n + 10) td,
.charts-css.column.multiple tbody tr td:nth-of-type(10n + 10),
.charts-css.area tbody tr td:nth-of-type(10n + 10)::before,
.charts-css.line tbody tr td:nth-of-type(10n + 10)::before {
  background: var(--color, var(--color-10));
}

.charts-css.pie tbody tr:nth-of-type(10n + 10) td,
.charts-css.pie.multiple tbody tr td:nth-of-type(10n + 10) {
  --c: var(--color, var(--color-10, transparent) );
}

/*
 * Chart data
 */
.charts-css.hide-data .data,
.charts-css.hide-data .data:not(:focus):not(:focus-within) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
}

.charts-css.show-data-on-hover .data {
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  opacity: 0;
}

.charts-css.show-data-on-hover tr:hover .data {
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  opacity: 1;
}

.charts-css.radial.show-data-on-hover tbody:hover .data, .charts-css.pie.show-data-on-hover tbody:hover .data, .charts-css.polar.show-data-on-hover tbody:hover .data, .charts-css.radar.show-data-on-hover tbody:hover .data {
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  opacity: 1;
}

/*
 * Chart labels
 */
.charts-css.bar:not(.show-labels) {
  --labels-size: 0;
}

.charts-css.bar:not(.show-labels) tbody tr th {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
}

.charts-css.bar.show-labels {
  --labels-size: 80px;
}

.charts-css.bar.show-labels tbody tr th {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: var(--labels-align, center);
  -ms-flex-align: var(--labels-align, center);
  align-items: var(--labels-align, center);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.charts-css.bar.show-labels tr.hide-label th,
.charts-css.bar.show-labels th.hide-label {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
}

.charts-css.column:not(.show-labels), .charts-css.area:not(.show-labels), .charts-css.line:not(.show-labels) {
  --labels-size: 0;
}

.charts-css.column:not(.show-labels) tbody tr th, .charts-css.area:not(.show-labels) tbody tr th, .charts-css.line:not(.show-labels) tbody tr th {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
}

.charts-css.column.show-labels, .charts-css.area.show-labels, .charts-css.line.show-labels {
  --labels-size: 1.5rem;
}

.charts-css.column.show-labels tbody tr th, .charts-css.area.show-labels tbody tr th, .charts-css.line.show-labels tbody tr th {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: var(--labels-align, center);
  -ms-flex-align: var(--labels-align, center);
  align-items: var(--labels-align, center);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.charts-css.column.show-labels tr.hide-label th,
.charts-css.column.show-labels th.hide-label, .charts-css.area.show-labels tr.hide-label th,
.charts-css.area.show-labels th.hide-label, .charts-css.line.show-labels tr.hide-label th,
.charts-css.line.show-labels th.hide-label {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
}

.charts-css.bar.labels-align-start tbody tr th, .charts-css.column.labels-align-start tbody tr th, .charts-css.area.labels-align-start tbody tr th, .charts-css.line.labels-align-start tbody tr th {
  -webkit-box-align: var(--labels-align, flex-start);
  -ms-flex-align: var(--labels-align, flex-start);
  align-items: var(--labels-align, flex-start);
}

.charts-css.bar.labels-align-end tbody tr th, .charts-css.column.labels-align-end tbody tr th, .charts-css.area.labels-align-end tbody tr th, .charts-css.line.labels-align-end tbody tr th {
  -webkit-box-align: var(--labels-align, flex-end);
  -ms-flex-align: var(--labels-align, flex-end);
  align-items: var(--labels-align, flex-end);
}

.charts-css.bar.labels-align-center tbody tr th, .charts-css.column.labels-align-center tbody tr th, .charts-css.area.labels-align-center tbody tr th, .charts-css.line.labels-align-center tbody tr th {
  -webkit-box-align: var(--labels-align, center);
  -ms-flex-align: var(--labels-align, center);
  align-items: var(--labels-align, center);
}

/*
 * Chart axes
 */
.charts-css.column.show-primary-axis:not(.reverse) tbody tr, .charts-css.area.show-primary-axis:not(.reverse) tbody tr, .charts-css.line.show-primary-axis:not(.reverse) tbody tr {
  -webkit-border-after: var(--primary-axis-width) var(--primary-axis-style) var(--primary-axis-color);
  border-block-end: var(--primary-axis-width) var(--primary-axis-style) var(--primary-axis-color);
}

.charts-css.column.show-primary-axis.reverse tbody tr, .charts-css.area.show-primary-axis.reverse tbody tr, .charts-css.line.show-primary-axis.reverse tbody tr {
  -webkit-border-before: var(--primary-axis-width) var(--primary-axis-style) var(--primary-axis-color);
  border-block-start: var(--primary-axis-width) var(--primary-axis-style) var(--primary-axis-color);
}

.charts-css.column.show-1-secondary-axes:not(.reverse) tbody tr, .charts-css.area.show-1-secondary-axes:not(.reverse) tbody tr, .charts-css.line.show-1-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 100%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-1-secondary-axes.reverse tbody tr, .charts-css.area.show-1-secondary-axes.reverse tbody tr, .charts-css.line.show-1-secondary-axes.reverse tbody tr {
  background-size: 100% 100%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(0deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-2-secondary-axes:not(.reverse) tbody tr, .charts-css.area.show-2-secondary-axes:not(.reverse) tbody tr, .charts-css.line.show-2-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 50%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-2-secondary-axes.reverse tbody tr, .charts-css.area.show-2-secondary-axes.reverse tbody tr, .charts-css.line.show-2-secondary-axes.reverse tbody tr {
  background-size: 100% 50%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(0deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-3-secondary-axes:not(.reverse) tbody tr, .charts-css.area.show-3-secondary-axes:not(.reverse) tbody tr, .charts-css.line.show-3-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 33.333333%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-3-secondary-axes.reverse tbody tr, .charts-css.area.show-3-secondary-axes.reverse tbody tr, .charts-css.line.show-3-secondary-axes.reverse tbody tr {
  background-size: 100% 33.333333%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(0deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-4-secondary-axes:not(.reverse) tbody tr, .charts-css.area.show-4-secondary-axes:not(.reverse) tbody tr, .charts-css.line.show-4-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 25%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-4-secondary-axes.reverse tbody tr, .charts-css.area.show-4-secondary-axes.reverse tbody tr, .charts-css.line.show-4-secondary-axes.reverse tbody tr {
  background-size: 100% 25%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(0deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-5-secondary-axes:not(.reverse) tbody tr, .charts-css.area.show-5-secondary-axes:not(.reverse) tbody tr, .charts-css.line.show-5-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 20%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-5-secondary-axes.reverse tbody tr, .charts-css.area.show-5-secondary-axes.reverse tbody tr, .charts-css.line.show-5-secondary-axes.reverse tbody tr {
  background-size: 100% 20%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(0deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-6-secondary-axes:not(.reverse) tbody tr, .charts-css.area.show-6-secondary-axes:not(.reverse) tbody tr, .charts-css.line.show-6-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 16.666667%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-6-secondary-axes.reverse tbody tr, .charts-css.area.show-6-secondary-axes.reverse tbody tr, .charts-css.line.show-6-secondary-axes.reverse tbody tr {
  background-size: 100% 16.666667%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(0deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-7-secondary-axes:not(.reverse) tbody tr, .charts-css.area.show-7-secondary-axes:not(.reverse) tbody tr, .charts-css.line.show-7-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 14.285714%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-7-secondary-axes.reverse tbody tr, .charts-css.area.show-7-secondary-axes.reverse tbody tr, .charts-css.line.show-7-secondary-axes.reverse tbody tr {
  background-size: 100% 14.285714%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(0deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-8-secondary-axes:not(.reverse) tbody tr, .charts-css.area.show-8-secondary-axes:not(.reverse) tbody tr, .charts-css.line.show-8-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 12.5%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-8-secondary-axes.reverse tbody tr, .charts-css.area.show-8-secondary-axes.reverse tbody tr, .charts-css.line.show-8-secondary-axes.reverse tbody tr {
  background-size: 100% 12.5%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(0deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-9-secondary-axes:not(.reverse) tbody tr, .charts-css.area.show-9-secondary-axes:not(.reverse) tbody tr, .charts-css.line.show-9-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 11.111111%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-9-secondary-axes.reverse tbody tr, .charts-css.area.show-9-secondary-axes.reverse tbody tr, .charts-css.line.show-9-secondary-axes.reverse tbody tr {
  background-size: 100% 11.111111%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(0deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-10-secondary-axes:not(.reverse) tbody tr, .charts-css.area.show-10-secondary-axes:not(.reverse) tbody tr, .charts-css.line.show-10-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 10%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-10-secondary-axes.reverse tbody tr, .charts-css.area.show-10-secondary-axes.reverse tbody tr, .charts-css.line.show-10-secondary-axes.reverse tbody tr {
  background-size: 100% 10%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(0deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-data-axes tbody tr, .charts-css.area.show-data-axes tbody tr, .charts-css.line.show-data-axes tbody tr {
  -webkit-border-end: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
  border-inline-end: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.column.show-data-axes:not(.reverse-data) tbody tr:first-of-type, .charts-css.area.show-data-axes:not(.reverse-data) tbody tr:first-of-type, .charts-css.line.show-data-axes:not(.reverse-data) tbody tr:first-of-type {
  -webkit-border-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
  border-inline-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.column.show-data-axes.reverse-data tbody tr:last-of-type, .charts-css.area.show-data-axes.reverse-data tbody tr:last-of-type, .charts-css.line.show-data-axes.reverse-data tbody tr:last-of-type {
  -webkit-border-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
  border-inline-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.column.show-dataset-axes tbody tr td, .charts-css.area.show-dataset-axes tbody tr td, .charts-css.line.show-dataset-axes tbody tr td {
  -webkit-border-end: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
  border-inline-end: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.column.show-dataset-axes:not(.reverse-data) tbody tr:first-of-type td, .charts-css.area.show-dataset-axes:not(.reverse-data) tbody tr:first-of-type td, .charts-css.line.show-dataset-axes:not(.reverse-data) tbody tr:first-of-type td {
  -webkit-border-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
  border-inline-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.column.show-dataset-axes.reverse-data tbody tr:last-of-type td, .charts-css.area.show-dataset-axes.reverse-data tbody tr:last-of-type td, .charts-css.line.show-dataset-axes.reverse-data tbody tr:last-of-type td {
  -webkit-border-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
  border-inline-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.bar.show-primary-axis:not(.reverse) tbody tr {
  -webkit-border-start: var(--primary-axis-width) var(--primary-axis-style) var(--primary-axis-color);
  border-inline-start: var(--primary-axis-width) var(--primary-axis-style) var(--primary-axis-color);
}

.charts-css.bar.show-primary-axis.reverse tbody tr {
  -webkit-border-end: var(--primary-axis-width) var(--primary-axis-style) var(--primary-axis-color);
  border-inline-end: var(--primary-axis-width) var(--primary-axis-style) var(--primary-axis-color);
}

.charts-css.bar.show-1-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(-90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-1-secondary-axes.reverse tbody tr {
  background-size: 100% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-2-secondary-axes:not(.reverse) tbody tr {
  background-size: 50% 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(-90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-2-secondary-axes.reverse tbody tr {
  background-size: 50% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-3-secondary-axes:not(.reverse) tbody tr {
  background-size: 33.333333% 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(-90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-3-secondary-axes.reverse tbody tr {
  background-size: 33.333333% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-4-secondary-axes:not(.reverse) tbody tr {
  background-size: 25% 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(-90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-4-secondary-axes.reverse tbody tr {
  background-size: 25% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-5-secondary-axes:not(.reverse) tbody tr {
  background-size: 20% 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(-90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-5-secondary-axes.reverse tbody tr {
  background-size: 20% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-6-secondary-axes:not(.reverse) tbody tr {
  background-size: 16.666667% 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(-90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-6-secondary-axes.reverse tbody tr {
  background-size: 16.666667% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-7-secondary-axes:not(.reverse) tbody tr {
  background-size: 14.285714% 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(-90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-7-secondary-axes.reverse tbody tr {
  background-size: 14.285714% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-8-secondary-axes:not(.reverse) tbody tr {
  background-size: 12.5% 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(-90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-8-secondary-axes.reverse tbody tr {
  background-size: 12.5% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-9-secondary-axes:not(.reverse) tbody tr {
  background-size: 11.111111% 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(-90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-9-secondary-axes.reverse tbody tr {
  background-size: 11.111111% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-10-secondary-axes:not(.reverse) tbody tr {
  background-size: 10% 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(-90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-10-secondary-axes.reverse tbody tr {
  background-size: 10% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-data-axes tbody tr {
  -webkit-border-after: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
  border-block-end: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.bar.show-data-axes:not(.reverse-data) tbody tr:first-of-type {
  -webkit-border-before: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
  border-block-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.bar.show-data-axes.reverse-data tbody tr:last-of-type {
  -webkit-border-before: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
  border-block-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.bar.show-dataset-axes tbody tr td {
  -webkit-border-after: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
  border-block-end: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.bar.show-dataset-axes:not(.reverse-data) tbody tr:first-of-type td {
  -webkit-border-before: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
  border-block-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.bar.show-dataset-axes.reverse-data tbody tr:last-of-type td {
  -webkit-border-before: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
  border-block-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.radial.show-primary-axis tbody, .charts-css.pie.show-primary-axis tbody, .charts-css.polar.show-primary-axis tbody, .charts-css.radar.show-primary-axis tbody {
  border: var(--primary-axis-width) var(--primary-axis-style) var(--primary-axis-color);
}

.charts-css.radial.show-1-secondary-axes tbody::after, .charts-css.pie.show-1-secondary-axes tbody::after, .charts-css.polar.show-1-secondary-axes tbody::after, .charts-css.radar.show-1-secondary-axes tbody::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(closest-side, transparent 0, transparent calc(50% - var(--secondary-axes-width)), var(--secondary-axes-color) calc(50% - var(--secondary-axes-width)), var(--secondary-axes-color) 50%, transparent calc(50% + var(--secondary-axes-width)), transparent calc(50% + var(--secondary-axes-width)));
  z-index: 2;
}

.charts-css.radial.show-2-secondary-axes tbody::after, .charts-css.pie.show-2-secondary-axes tbody::after, .charts-css.polar.show-2-secondary-axes tbody::after, .charts-css.radar.show-2-secondary-axes tbody::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(closest-side, transparent 0, transparent calc(33.3333333333% - var(--secondary-axes-width)), var(--secondary-axes-color) calc(33.3333333333% - var(--secondary-axes-width)), var(--secondary-axes-color) 33.3333333333%, transparent calc(33.3333333333% + var(--secondary-axes-width)), transparent calc(33.3333333333% + var(--secondary-axes-width)));
  z-index: 2;
}

.charts-css.radial.show-3-secondary-axes tbody::after, .charts-css.pie.show-3-secondary-axes tbody::after, .charts-css.polar.show-3-secondary-axes tbody::after, .charts-css.radar.show-3-secondary-axes tbody::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(closest-side, transparent 0, transparent calc(25% - var(--secondary-axes-width)), var(--secondary-axes-color) calc(25% - var(--secondary-axes-width)), var(--secondary-axes-color) 25%, transparent calc(25% + var(--secondary-axes-width)), transparent calc(25% + var(--secondary-axes-width)));
  z-index: 2;
}

.charts-css.radial.show-4-secondary-axes tbody::after, .charts-css.pie.show-4-secondary-axes tbody::after, .charts-css.polar.show-4-secondary-axes tbody::after, .charts-css.radar.show-4-secondary-axes tbody::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(closest-side, transparent 0, transparent calc(20% - var(--secondary-axes-width)), var(--secondary-axes-color) calc(20% - var(--secondary-axes-width)), var(--secondary-axes-color) 20%, transparent calc(20% + var(--secondary-axes-width)), transparent calc(20% + var(--secondary-axes-width)));
  z-index: 2;
}

.charts-css.radial.show-5-secondary-axes tbody::after, .charts-css.pie.show-5-secondary-axes tbody::after, .charts-css.polar.show-5-secondary-axes tbody::after, .charts-css.radar.show-5-secondary-axes tbody::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(closest-side, transparent 0, transparent calc(16.6666666667% - var(--secondary-axes-width)), var(--secondary-axes-color) calc(16.6666666667% - var(--secondary-axes-width)), var(--secondary-axes-color) 16.6666666667%, transparent calc(16.6666666667% + var(--secondary-axes-width)), transparent calc(16.6666666667% + var(--secondary-axes-width)));
  z-index: 2;
}

.charts-css.radial.show-6-secondary-axes tbody::after, .charts-css.pie.show-6-secondary-axes tbody::after, .charts-css.polar.show-6-secondary-axes tbody::after, .charts-css.radar.show-6-secondary-axes tbody::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(closest-side, transparent 0, transparent calc(14.2857142857% - var(--secondary-axes-width)), var(--secondary-axes-color) calc(14.2857142857% - var(--secondary-axes-width)), var(--secondary-axes-color) 14.2857142857%, transparent calc(14.2857142857% + var(--secondary-axes-width)), transparent calc(14.2857142857% + var(--secondary-axes-width)));
  z-index: 2;
}

.charts-css.radial.show-7-secondary-axes tbody::after, .charts-css.pie.show-7-secondary-axes tbody::after, .charts-css.polar.show-7-secondary-axes tbody::after, .charts-css.radar.show-7-secondary-axes tbody::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(closest-side, transparent 0, transparent calc(12.5% - var(--secondary-axes-width)), var(--secondary-axes-color) calc(12.5% - var(--secondary-axes-width)), var(--secondary-axes-color) 12.5%, transparent calc(12.5% + var(--secondary-axes-width)), transparent calc(12.5% + var(--secondary-axes-width)));
  z-index: 2;
}

.charts-css.radial.show-8-secondary-axes tbody::after, .charts-css.pie.show-8-secondary-axes tbody::after, .charts-css.polar.show-8-secondary-axes tbody::after, .charts-css.radar.show-8-secondary-axes tbody::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(closest-side, transparent 0, transparent calc(11.1111111111% - var(--secondary-axes-width)), var(--secondary-axes-color) calc(11.1111111111% - var(--secondary-axes-width)), var(--secondary-axes-color) 11.1111111111%, transparent calc(11.1111111111% + var(--secondary-axes-width)), transparent calc(11.1111111111% + var(--secondary-axes-width)));
  z-index: 2;
}

.charts-css.radial.show-9-secondary-axes tbody::after, .charts-css.pie.show-9-secondary-axes tbody::after, .charts-css.polar.show-9-secondary-axes tbody::after, .charts-css.radar.show-9-secondary-axes tbody::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(closest-side, transparent 0, transparent calc(10% - var(--secondary-axes-width)), var(--secondary-axes-color) calc(10% - var(--secondary-axes-width)), var(--secondary-axes-color) 10%, transparent calc(10% + var(--secondary-axes-width)), transparent calc(10% + var(--secondary-axes-width)));
  z-index: 2;
}

.charts-css.radial.show-10-secondary-axes tbody::after, .charts-css.pie.show-10-secondary-axes tbody::after, .charts-css.polar.show-10-secondary-axes tbody::after, .charts-css.radar.show-10-secondary-axes tbody::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(closest-side, transparent 0, transparent calc(9.0909090909% - var(--secondary-axes-width)), var(--secondary-axes-color) calc(9.0909090909% - var(--secondary-axes-width)), var(--secondary-axes-color) 9.0909090909%, transparent calc(9.0909090909% + var(--secondary-axes-width)), transparent calc(9.0909090909% + var(--secondary-axes-width)));
  z-index: 2;
}

/*
 * Chart legend
 */
.charts-css.legend {
  padding: 1rem;
  border: var(--legend-border-width) var(--legend-border-style) var(--legend-border-color);
  list-style: none;
  font-size: 1rem;
}

.charts-css.legend li {
  line-height: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.charts-css.legend li::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
  border-width: 2px;
  border-style: solid;
}

.charts-css.legend li:nth-child(1)::before {
  background-color: var(--color-1, transparent);
  border-color: var(--border-color-1, var(--border-color, #000));
}

.charts-css.legend li:nth-child(2)::before {
  background-color: var(--color-2, transparent);
  border-color: var(--border-color-2, var(--border-color, #000));
}

.charts-css.legend li:nth-child(3)::before {
  background-color: var(--color-3, transparent);
  border-color: var(--border-color-3, var(--border-color, #000));
}

.charts-css.legend li:nth-child(4)::before {
  background-color: var(--color-4, transparent);
  border-color: var(--border-color-4, var(--border-color, #000));
}

.charts-css.legend li:nth-child(5)::before {
  background-color: var(--color-5, transparent);
  border-color: var(--border-color-5, var(--border-color, #000));
}

.charts-css.legend li:nth-child(6)::before {
  background-color: var(--color-6, transparent);
  border-color: var(--border-color-6, var(--border-color, #000));
}

.charts-css.legend li:nth-child(7)::before {
  background-color: var(--color-7, transparent);
  border-color: var(--border-color-7, var(--border-color, #000));
}

.charts-css.legend li:nth-child(8)::before {
  background-color: var(--color-8, transparent);
  border-color: var(--border-color-8, var(--border-color, #000));
}

.charts-css.legend li:nth-child(9)::before {
  background-color: var(--color-9, transparent);
  border-color: var(--border-color-9, var(--border-color, #000));
}

.charts-css.legend li:nth-child(10)::before {
  background-color: var(--color-10, transparent);
  border-color: var(--border-color-10, var(--border-color, #000));
}

.charts-css:not(.legend-inline) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.charts-css.legend-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.charts-css.legend-inline li {
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

.charts-css.legend-circle li::before {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

.charts-css.legend-ellipse li::before {
  width: 2rem;
  height: 1rem;
  border-radius: 50%;
}

.charts-css.legend-square li::before {
  width: 1rem;
  height: 1rem;
  border-radius: 3px;
}

.charts-css.legend-rhombus li::before {
  width: 1rem;
  height: 1rem;
  border-radius: 3px;
  -webkit-transform: rotate(45deg) scale(0.85);
  transform: rotate(45deg) scale(0.85);
}

.charts-css.legend-rectangle li::before {
  width: 2rem;
  height: 1rem;
  border-radius: 3px;
}

.charts-css.legend-line li::before {
  width: 2rem;
  height: 3px;
  border-radius: 2px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

/*
 * Chart tooltips
 */
.charts-css .tooltip {
  position: absolute;
  z-index: 1;
  bottom: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 5px 10px;
  border-radius: 6px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  background-color: #555;
  color: #fff;
  text-align: center;
  font-size: 0.9rem;
}

.charts-css .tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent;
}

.charts-css td:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/*
 * Bar Chart
 */
.charts-css.bar tbody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  height: calc(100% - var(--heading-size));
}

.charts-css.bar tbody tr {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  overflow-wrap: anywhere;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.charts-css.bar tbody tr th {
  position: absolute;
  top: 0;
  bottom: 0;
}

.charts-css.bar tbody tr td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: calc(100% * var(--end, var(--size, 1)));
  height: 100%;
  position: relative;
}

.charts-css.bar:not(.reverse) tbody tr {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-margin-start: var(--labels-size);
  margin-inline-start: var(--labels-size);
}

.charts-css.bar:not(.reverse) tbody tr th {
  left: calc(-1 * var(--labels-size) - var(--primary-axis-width));
  width: var(--labels-size);
}

.charts-css.bar:not(.reverse) tbody tr td {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.charts-css.bar.reverse tbody tr {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-margin-end: var(--labels-size);
  margin-inline-end: var(--labels-size);
}

.charts-css.bar.reverse tbody tr th {
  right: calc(-1 * var(--labels-size) - var(--primary-axis-width));
  width: var(--labels-size);
}

.charts-css.bar.reverse tbody tr td {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.charts-css.bar:not(.stacked) tbody tr td {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}

.charts-css.bar.stacked tbody tr td {
  -webkit-box-flex: unset;
  -ms-flex-positive: unset;
  flex-grow: unset;
  -ms-flex-negative: unset;
  flex-shrink: unset;
  -ms-flex-preferred-size: unset;
  flex-basis: unset;
}

.charts-css.bar.stacked.reverse-datasets tbody tr {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.charts-css.bar:not(.reverse-data) tbody {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.charts-css.bar.reverse-data tbody {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.charts-css.bar:not(.reverse-datasets):not(.stacked) tbody tr {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.charts-css.bar:not(.reverse-datasets).stacked:not(.reverse) tbody tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.charts-css.bar:not(.reverse-datasets).stacked.reverse tbody tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.charts-css.bar.reverse-datasets:not(.stacked) tbody tr {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.charts-css.bar.reverse-datasets.stacked:not(.reverse) tbody tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.charts-css.bar.reverse-datasets.stacked.reverse tbody tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.charts-css.bar.data-spacing-1 tbody tr {
  -webkit-padding-before: 1px;
  padding-block-start: 1px;
  -webkit-padding-after: 1px;
  padding-block-end: 1px;
}

.charts-css.bar.data-spacing-2 tbody tr {
  -webkit-padding-before: 2px;
  padding-block-start: 2px;
  -webkit-padding-after: 2px;
  padding-block-end: 2px;
}

.charts-css.bar.data-spacing-3 tbody tr {
  -webkit-padding-before: 3px;
  padding-block-start: 3px;
  -webkit-padding-after: 3px;
  padding-block-end: 3px;
}

.charts-css.bar.data-spacing-4 tbody tr {
  -webkit-padding-before: 4px;
  padding-block-start: 4px;
  -webkit-padding-after: 4px;
  padding-block-end: 4px;
}

.charts-css.bar.data-spacing-5 tbody tr {
  -webkit-padding-before: 5px;
  padding-block-start: 5px;
  -webkit-padding-after: 5px;
  padding-block-end: 5px;
}

.charts-css.bar.data-spacing-6 tbody tr {
  -webkit-padding-before: 6px;
  padding-block-start: 6px;
  -webkit-padding-after: 6px;
  padding-block-end: 6px;
}

.charts-css.bar.data-spacing-7 tbody tr {
  -webkit-padding-before: 7px;
  padding-block-start: 7px;
  -webkit-padding-after: 7px;
  padding-block-end: 7px;
}

.charts-css.bar.data-spacing-8 tbody tr {
  -webkit-padding-before: 8px;
  padding-block-start: 8px;
  -webkit-padding-after: 8px;
  padding-block-end: 8px;
}

.charts-css.bar.data-spacing-9 tbody tr {
  -webkit-padding-before: 9px;
  padding-block-start: 9px;
  -webkit-padding-after: 9px;
  padding-block-end: 9px;
}

.charts-css.bar.data-spacing-10 tbody tr {
  -webkit-padding-before: 10px;
  padding-block-start: 10px;
  -webkit-padding-after: 10px;
  padding-block-end: 10px;
}

.charts-css.bar.data-spacing-11 tbody tr {
  -webkit-padding-before: 11px;
  padding-block-start: 11px;
  -webkit-padding-after: 11px;
  padding-block-end: 11px;
}

.charts-css.bar.data-spacing-12 tbody tr {
  -webkit-padding-before: 12px;
  padding-block-start: 12px;
  -webkit-padding-after: 12px;
  padding-block-end: 12px;
}

.charts-css.bar.data-spacing-13 tbody tr {
  -webkit-padding-before: 13px;
  padding-block-start: 13px;
  -webkit-padding-after: 13px;
  padding-block-end: 13px;
}

.charts-css.bar.data-spacing-14 tbody tr {
  -webkit-padding-before: 14px;
  padding-block-start: 14px;
  -webkit-padding-after: 14px;
  padding-block-end: 14px;
}

.charts-css.bar.data-spacing-15 tbody tr {
  -webkit-padding-before: 15px;
  padding-block-start: 15px;
  -webkit-padding-after: 15px;
  padding-block-end: 15px;
}

.charts-css.bar.data-spacing-16 tbody tr {
  -webkit-padding-before: 16px;
  padding-block-start: 16px;
  -webkit-padding-after: 16px;
  padding-block-end: 16px;
}

.charts-css.bar.data-spacing-17 tbody tr {
  -webkit-padding-before: 17px;
  padding-block-start: 17px;
  -webkit-padding-after: 17px;
  padding-block-end: 17px;
}

.charts-css.bar.data-spacing-18 tbody tr {
  -webkit-padding-before: 18px;
  padding-block-start: 18px;
  -webkit-padding-after: 18px;
  padding-block-end: 18px;
}

.charts-css.bar.data-spacing-19 tbody tr {
  -webkit-padding-before: 19px;
  padding-block-start: 19px;
  -webkit-padding-after: 19px;
  padding-block-end: 19px;
}

.charts-css.bar.data-spacing-20 tbody tr {
  -webkit-padding-before: 20px;
  padding-block-start: 20px;
  -webkit-padding-after: 20px;
  padding-block-end: 20px;
}

.charts-css.bar.datasets-spacing-1 tbody tr td {
  -webkit-margin-before: 1px;
  margin-block-start: 1px;
  -webkit-margin-after: 1px;
  margin-block-end: 1px;
}

.charts-css.bar.datasets-spacing-2 tbody tr td {
  -webkit-margin-before: 2px;
  margin-block-start: 2px;
  -webkit-margin-after: 2px;
  margin-block-end: 2px;
}

.charts-css.bar.datasets-spacing-3 tbody tr td {
  -webkit-margin-before: 3px;
  margin-block-start: 3px;
  -webkit-margin-after: 3px;
  margin-block-end: 3px;
}

.charts-css.bar.datasets-spacing-4 tbody tr td {
  -webkit-margin-before: 4px;
  margin-block-start: 4px;
  -webkit-margin-after: 4px;
  margin-block-end: 4px;
}

.charts-css.bar.datasets-spacing-5 tbody tr td {
  -webkit-margin-before: 5px;
  margin-block-start: 5px;
  -webkit-margin-after: 5px;
  margin-block-end: 5px;
}

.charts-css.bar.datasets-spacing-6 tbody tr td {
  -webkit-margin-before: 6px;
  margin-block-start: 6px;
  -webkit-margin-after: 6px;
  margin-block-end: 6px;
}

.charts-css.bar.datasets-spacing-7 tbody tr td {
  -webkit-margin-before: 7px;
  margin-block-start: 7px;
  -webkit-margin-after: 7px;
  margin-block-end: 7px;
}

.charts-css.bar.datasets-spacing-8 tbody tr td {
  -webkit-margin-before: 8px;
  margin-block-start: 8px;
  -webkit-margin-after: 8px;
  margin-block-end: 8px;
}

.charts-css.bar.datasets-spacing-9 tbody tr td {
  -webkit-margin-before: 9px;
  margin-block-start: 9px;
  -webkit-margin-after: 9px;
  margin-block-end: 9px;
}

.charts-css.bar.datasets-spacing-10 tbody tr td {
  -webkit-margin-before: 10px;
  margin-block-start: 10px;
  -webkit-margin-after: 10px;
  margin-block-end: 10px;
}

.charts-css.bar.datasets-spacing-11 tbody tr td {
  -webkit-margin-before: 11px;
  margin-block-start: 11px;
  -webkit-margin-after: 11px;
  margin-block-end: 11px;
}

.charts-css.bar.datasets-spacing-12 tbody tr td {
  -webkit-margin-before: 12px;
  margin-block-start: 12px;
  -webkit-margin-after: 12px;
  margin-block-end: 12px;
}

.charts-css.bar.datasets-spacing-13 tbody tr td {
  -webkit-margin-before: 13px;
  margin-block-start: 13px;
  -webkit-margin-after: 13px;
  margin-block-end: 13px;
}

.charts-css.bar.datasets-spacing-14 tbody tr td {
  -webkit-margin-before: 14px;
  margin-block-start: 14px;
  -webkit-margin-after: 14px;
  margin-block-end: 14px;
}

.charts-css.bar.datasets-spacing-15 tbody tr td {
  -webkit-margin-before: 15px;
  margin-block-start: 15px;
  -webkit-margin-after: 15px;
  margin-block-end: 15px;
}

.charts-css.bar.datasets-spacing-16 tbody tr td {
  -webkit-margin-before: 16px;
  margin-block-start: 16px;
  -webkit-margin-after: 16px;
  margin-block-end: 16px;
}

.charts-css.bar.datasets-spacing-17 tbody tr td {
  -webkit-margin-before: 17px;
  margin-block-start: 17px;
  -webkit-margin-after: 17px;
  margin-block-end: 17px;
}

.charts-css.bar.datasets-spacing-18 tbody tr td {
  -webkit-margin-before: 18px;
  margin-block-start: 18px;
  -webkit-margin-after: 18px;
  margin-block-end: 18px;
}

.charts-css.bar.datasets-spacing-19 tbody tr td {
  -webkit-margin-before: 19px;
  margin-block-start: 19px;
  -webkit-margin-after: 19px;
  margin-block-end: 19px;
}

.charts-css.bar.datasets-spacing-20 tbody tr td {
  -webkit-margin-before: 20px;
  margin-block-start: 20px;
  -webkit-margin-after: 20px;
  margin-block-end: 20px;
}

/*
 * Column Chart
 */
.charts-css.column tbody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  height: calc(100% - var(--heading-size));
}

.charts-css.column tbody tr {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  overflow-wrap: anywhere;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.charts-css.column tbody tr th {
  position: absolute;
  right: 0;
  left: 0;
}

.charts-css.column tbody tr td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: calc(100% * var(--end, var(--size, 1)));
  position: relative;
}

.charts-css.column:not(.reverse) tbody tr {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-margin-after: var(--labels-size);
  margin-block-end: var(--labels-size);
}

.charts-css.column:not(.reverse) tbody tr th {
  bottom: calc(-1 * var(--labels-size) - var(--primary-axis-width));
  height: var(--labels-size);
}

.charts-css.column:not(.reverse) tbody tr td {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.charts-css.column.reverse tbody tr {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-margin-before: var(--labels-size);
  margin-block-start: var(--labels-size);
}

.charts-css.column.reverse tbody tr th {
  top: calc(-1 * var(--labels-size) - var(--primary-axis-width));
  height: var(--labels-size);
}

.charts-css.column.reverse tbody tr td {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.charts-css.column:not(.stacked) tbody tr td {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}

.charts-css.column.stacked tbody tr td {
  -webkit-box-flex: unset;
  -ms-flex-positive: unset;
  flex-grow: unset;
  -ms-flex-negative: unset;
  flex-shrink: unset;
  -ms-flex-preferred-size: unset;
  flex-basis: unset;
}

.charts-css.column.stacked.reverse-datasets tbody tr {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.charts-css.column:not(.reverse-data) tbody {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.charts-css.column.reverse-data tbody {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.charts-css.column:not(.reverse-datasets):not(.stacked) tbody tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.charts-css.column:not(.reverse-datasets).stacked:not(.reverse) tbody tr {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.charts-css.column:not(.reverse-datasets).stacked.reverse tbody tr {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.charts-css.column.reverse-datasets:not(.stacked) tbody tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.charts-css.column.reverse-datasets.stacked:not(.reverse) tbody tr {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.charts-css.column.reverse-datasets.stacked.reverse tbody tr {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.charts-css.column.data-spacing-1 tbody tr {
  -webkit-padding-start: 1px;
  padding-inline-start: 1px;
  -webkit-padding-end: 1px;
  padding-inline-end: 1px;
}

.charts-css.column.data-spacing-2 tbody tr {
  -webkit-padding-start: 2px;
  padding-inline-start: 2px;
  -webkit-padding-end: 2px;
  padding-inline-end: 2px;
}

.charts-css.column.data-spacing-3 tbody tr {
  -webkit-padding-start: 3px;
  padding-inline-start: 3px;
  -webkit-padding-end: 3px;
  padding-inline-end: 3px;
}

.charts-css.column.data-spacing-4 tbody tr {
  -webkit-padding-start: 4px;
  padding-inline-start: 4px;
  -webkit-padding-end: 4px;
  padding-inline-end: 4px;
}

.charts-css.column.data-spacing-5 tbody tr {
  -webkit-padding-start: 5px;
  padding-inline-start: 5px;
  -webkit-padding-end: 5px;
  padding-inline-end: 5px;
}

.charts-css.column.data-spacing-6 tbody tr {
  -webkit-padding-start: 6px;
  padding-inline-start: 6px;
  -webkit-padding-end: 6px;
  padding-inline-end: 6px;
}

.charts-css.column.data-spacing-7 tbody tr {
  -webkit-padding-start: 7px;
  padding-inline-start: 7px;
  -webkit-padding-end: 7px;
  padding-inline-end: 7px;
}

.charts-css.column.data-spacing-8 tbody tr {
  -webkit-padding-start: 8px;
  padding-inline-start: 8px;
  -webkit-padding-end: 8px;
  padding-inline-end: 8px;
}

.charts-css.column.data-spacing-9 tbody tr {
  -webkit-padding-start: 9px;
  padding-inline-start: 9px;
  -webkit-padding-end: 9px;
  padding-inline-end: 9px;
}

.charts-css.column.data-spacing-10 tbody tr {
  -webkit-padding-start: 10px;
  padding-inline-start: 10px;
  -webkit-padding-end: 10px;
  padding-inline-end: 10px;
}

.charts-css.column.data-spacing-11 tbody tr {
  -webkit-padding-start: 11px;
  padding-inline-start: 11px;
  -webkit-padding-end: 11px;
  padding-inline-end: 11px;
}

.charts-css.column.data-spacing-12 tbody tr {
  -webkit-padding-start: 12px;
  padding-inline-start: 12px;
  -webkit-padding-end: 12px;
  padding-inline-end: 12px;
}

.charts-css.column.data-spacing-13 tbody tr {
  -webkit-padding-start: 13px;
  padding-inline-start: 13px;
  -webkit-padding-end: 13px;
  padding-inline-end: 13px;
}

.charts-css.column.data-spacing-14 tbody tr {
  -webkit-padding-start: 14px;
  padding-inline-start: 14px;
  -webkit-padding-end: 14px;
  padding-inline-end: 14px;
}

.charts-css.column.data-spacing-15 tbody tr {
  -webkit-padding-start: 15px;
  padding-inline-start: 15px;
  -webkit-padding-end: 15px;
  padding-inline-end: 15px;
}

.charts-css.column.data-spacing-16 tbody tr {
  -webkit-padding-start: 16px;
  padding-inline-start: 16px;
  -webkit-padding-end: 16px;
  padding-inline-end: 16px;
}

.charts-css.column.data-spacing-17 tbody tr {
  -webkit-padding-start: 17px;
  padding-inline-start: 17px;
  -webkit-padding-end: 17px;
  padding-inline-end: 17px;
}

.charts-css.column.data-spacing-18 tbody tr {
  -webkit-padding-start: 18px;
  padding-inline-start: 18px;
  -webkit-padding-end: 18px;
  padding-inline-end: 18px;
}

.charts-css.column.data-spacing-19 tbody tr {
  -webkit-padding-start: 19px;
  padding-inline-start: 19px;
  -webkit-padding-end: 19px;
  padding-inline-end: 19px;
}

.charts-css.column.data-spacing-20 tbody tr {
  -webkit-padding-start: 20px;
  padding-inline-start: 20px;
  -webkit-padding-end: 20px;
  padding-inline-end: 20px;
}

.charts-css.column.datasets-spacing-1 tbody tr td {
  -webkit-margin-start: 1px;
  margin-inline-start: 1px;
  -webkit-margin-end: 1px;
  margin-inline-end: 1px;
}

.charts-css.column.datasets-spacing-2 tbody tr td {
  -webkit-margin-start: 2px;
  margin-inline-start: 2px;
  -webkit-margin-end: 2px;
  margin-inline-end: 2px;
}

.charts-css.column.datasets-spacing-3 tbody tr td {
  -webkit-margin-start: 3px;
  margin-inline-start: 3px;
  -webkit-margin-end: 3px;
  margin-inline-end: 3px;
}

.charts-css.column.datasets-spacing-4 tbody tr td {
  -webkit-margin-start: 4px;
  margin-inline-start: 4px;
  -webkit-margin-end: 4px;
  margin-inline-end: 4px;
}

.charts-css.column.datasets-spacing-5 tbody tr td {
  -webkit-margin-start: 5px;
  margin-inline-start: 5px;
  -webkit-margin-end: 5px;
  margin-inline-end: 5px;
}

.charts-css.column.datasets-spacing-6 tbody tr td {
  -webkit-margin-start: 6px;
  margin-inline-start: 6px;
  -webkit-margin-end: 6px;
  margin-inline-end: 6px;
}

.charts-css.column.datasets-spacing-7 tbody tr td {
  -webkit-margin-start: 7px;
  margin-inline-start: 7px;
  -webkit-margin-end: 7px;
  margin-inline-end: 7px;
}

.charts-css.column.datasets-spacing-8 tbody tr td {
  -webkit-margin-start: 8px;
  margin-inline-start: 8px;
  -webkit-margin-end: 8px;
  margin-inline-end: 8px;
}

.charts-css.column.datasets-spacing-9 tbody tr td {
  -webkit-margin-start: 9px;
  margin-inline-start: 9px;
  -webkit-margin-end: 9px;
  margin-inline-end: 9px;
}

.charts-css.column.datasets-spacing-10 tbody tr td {
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
}

.charts-css.column.datasets-spacing-11 tbody tr td {
  -webkit-margin-start: 11px;
  margin-inline-start: 11px;
  -webkit-margin-end: 11px;
  margin-inline-end: 11px;
}

.charts-css.column.datasets-spacing-12 tbody tr td {
  -webkit-margin-start: 12px;
  margin-inline-start: 12px;
  -webkit-margin-end: 12px;
  margin-inline-end: 12px;
}

.charts-css.column.datasets-spacing-13 tbody tr td {
  -webkit-margin-start: 13px;
  margin-inline-start: 13px;
  -webkit-margin-end: 13px;
  margin-inline-end: 13px;
}

.charts-css.column.datasets-spacing-14 tbody tr td {
  -webkit-margin-start: 14px;
  margin-inline-start: 14px;
  -webkit-margin-end: 14px;
  margin-inline-end: 14px;
}

.charts-css.column.datasets-spacing-15 tbody tr td {
  -webkit-margin-start: 15px;
  margin-inline-start: 15px;
  -webkit-margin-end: 15px;
  margin-inline-end: 15px;
}

.charts-css.column.datasets-spacing-16 tbody tr td {
  -webkit-margin-start: 16px;
  margin-inline-start: 16px;
  -webkit-margin-end: 16px;
  margin-inline-end: 16px;
}

.charts-css.column.datasets-spacing-17 tbody tr td {
  -webkit-margin-start: 17px;
  margin-inline-start: 17px;
  -webkit-margin-end: 17px;
  margin-inline-end: 17px;
}

.charts-css.column.datasets-spacing-18 tbody tr td {
  -webkit-margin-start: 18px;
  margin-inline-start: 18px;
  -webkit-margin-end: 18px;
  margin-inline-end: 18px;
}

.charts-css.column.datasets-spacing-19 tbody tr td {
  -webkit-margin-start: 19px;
  margin-inline-start: 19px;
  -webkit-margin-end: 19px;
  margin-inline-end: 19px;
}

.charts-css.column.datasets-spacing-20 tbody tr td {
  -webkit-margin-start: 20px;
  margin-inline-start: 20px;
  -webkit-margin-end: 20px;
  margin-inline-end: 20px;
}

/*
 * Area Chart
 */
.charts-css.area tbody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  height: calc(100% - var(--heading-size));
}

.charts-css.area tbody tr {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  overflow-wrap: anywhere;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.charts-css.area tbody tr th {
  position: absolute;
  right: 0;
  left: 0;
}

.charts-css.area tbody tr td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.charts-css.area tbody tr td::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.charts-css.area tbody tr td::after {
  content: "";
  width: 100%;
}

.charts-css.area:not(.reverse) tbody tr {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-margin-after: var(--labels-size);
  margin-block-end: var(--labels-size);
}

.charts-css.area:not(.reverse) tbody tr th {
  bottom: calc(-1 * var(--labels-size) - var(--primary-axis-width));
  height: var(--labels-size);
}

.charts-css.area:not(.reverse) tbody tr td {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.charts-css.area.reverse tbody tr {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-margin-before: var(--labels-size);
  margin-block-start: var(--labels-size);
}

.charts-css.area.reverse tbody tr th {
  top: calc(-1 * var(--labels-size) - var(--primary-axis-width));
  height: var(--labels-size);
}

.charts-css.area.reverse tbody tr td {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.charts-css.area:not(.reverse-data) tbody {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.charts-css.area.reverse-data tbody {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.charts-css.area:not(.reverse-datasets) tbody tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.charts-css.area.reverse-datasets tbody tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.charts-css.area:not(.reverse):not(.reverse-data) tbody tr td {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.charts-css.area:not(.reverse):not(.reverse-data) tbody tr td::before {
  -webkit-clip-path: polygon(0% calc(100% * (1 - var(--start, var(--end, var(--size))))), 100% calc(100% * (1 - var(--end, var(--size)))), 100% 100%, 0% 100%);
  clip-path: polygon(0% calc(100% * (1 - var(--start, var(--end, var(--size))))), 100% calc(100% * (1 - var(--end, var(--size)))), 100% 100%, 0% 100%);
}

.charts-css.area:not(.reverse):not(.reverse-data) tbody tr td .data {
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}

.charts-css.area:not(.reverse):not(.reverse-data) tbody tr td::after {
  height: calc(100% * var(--end, var(--size)));
}

.charts-css.area:not(.reverse).reverse-data tbody tr td {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.charts-css.area:not(.reverse).reverse-data tbody tr td::before {
  -webkit-clip-path: polygon(0% calc(100% * (1 - var(--end, var(--size)))), 100% calc(100% * (1 - var(--start, var(--end, var(--size))))), 100% 100%, 0% 100%);
  clip-path: polygon(0% calc(100% * (1 - var(--end, var(--size)))), 100% calc(100% * (1 - var(--start, var(--end, var(--size))))), 100% 100%, 0% 100%);
}

.charts-css.area:not(.reverse).reverse-data tbody tr td .data {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.charts-css.area:not(.reverse).reverse-data tbody tr td::after {
  height: calc(100% * var(--end, var(--size)));
}

.charts-css.area.reverse:not(.reverse-data) tbody tr td {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.charts-css.area.reverse:not(.reverse-data) tbody tr td::before {
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% * var(--end, var(--size))), 0% calc(100% * var(--start, var(--end, var(--size)))));
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% * var(--end, var(--size))), 0% calc(100% * var(--start, var(--end, var(--size)))));
}

.charts-css.area.reverse:not(.reverse-data) tbody tr td .data {
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}

.charts-css.area.reverse.reverse-data tbody tr td {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.charts-css.area.reverse.reverse-data tbody tr td::before {
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% * var(--start, var(--end, var(--size)))), 0% calc(100% * var(--end, var(--size))));
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% * var(--start, var(--end, var(--size)))), 0% calc(100% * var(--end, var(--size))));
}

.charts-css.area.reverse.reverse-data tbody tr td .data {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.charts-css.area.reverse.reverse-data tbody tr td::after {
  height: calc(100% * (1 - var(--end, var(--size))));
}

.charts-css.area.data-spacing-1 tbody tr td::before, .charts-css.area.datasets-spacing-1 tbody tr td::before {
  -webkit-margin-start: 1px;
  margin-inline-start: 1px;
  -webkit-margin-end: 1px;
  margin-inline-end: 1px;
}

.charts-css.area.data-spacing-2 tbody tr td::before, .charts-css.area.datasets-spacing-2 tbody tr td::before {
  -webkit-margin-start: 2px;
  margin-inline-start: 2px;
  -webkit-margin-end: 2px;
  margin-inline-end: 2px;
}

.charts-css.area.data-spacing-3 tbody tr td::before, .charts-css.area.datasets-spacing-3 tbody tr td::before {
  -webkit-margin-start: 3px;
  margin-inline-start: 3px;
  -webkit-margin-end: 3px;
  margin-inline-end: 3px;
}

.charts-css.area.data-spacing-4 tbody tr td::before, .charts-css.area.datasets-spacing-4 tbody tr td::before {
  -webkit-margin-start: 4px;
  margin-inline-start: 4px;
  -webkit-margin-end: 4px;
  margin-inline-end: 4px;
}

.charts-css.area.data-spacing-5 tbody tr td::before, .charts-css.area.datasets-spacing-5 tbody tr td::before {
  -webkit-margin-start: 5px;
  margin-inline-start: 5px;
  -webkit-margin-end: 5px;
  margin-inline-end: 5px;
}

.charts-css.area.data-spacing-6 tbody tr td::before, .charts-css.area.datasets-spacing-6 tbody tr td::before {
  -webkit-margin-start: 6px;
  margin-inline-start: 6px;
  -webkit-margin-end: 6px;
  margin-inline-end: 6px;
}

.charts-css.area.data-spacing-7 tbody tr td::before, .charts-css.area.datasets-spacing-7 tbody tr td::before {
  -webkit-margin-start: 7px;
  margin-inline-start: 7px;
  -webkit-margin-end: 7px;
  margin-inline-end: 7px;
}

.charts-css.area.data-spacing-8 tbody tr td::before, .charts-css.area.datasets-spacing-8 tbody tr td::before {
  -webkit-margin-start: 8px;
  margin-inline-start: 8px;
  -webkit-margin-end: 8px;
  margin-inline-end: 8px;
}

.charts-css.area.data-spacing-9 tbody tr td::before, .charts-css.area.datasets-spacing-9 tbody tr td::before {
  -webkit-margin-start: 9px;
  margin-inline-start: 9px;
  -webkit-margin-end: 9px;
  margin-inline-end: 9px;
}

.charts-css.area.data-spacing-10 tbody tr td::before, .charts-css.area.datasets-spacing-10 tbody tr td::before {
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
}

.charts-css.area.data-spacing-11 tbody tr td::before, .charts-css.area.datasets-spacing-11 tbody tr td::before {
  -webkit-margin-start: 11px;
  margin-inline-start: 11px;
  -webkit-margin-end: 11px;
  margin-inline-end: 11px;
}

.charts-css.area.data-spacing-12 tbody tr td::before, .charts-css.area.datasets-spacing-12 tbody tr td::before {
  -webkit-margin-start: 12px;
  margin-inline-start: 12px;
  -webkit-margin-end: 12px;
  margin-inline-end: 12px;
}

.charts-css.area.data-spacing-13 tbody tr td::before, .charts-css.area.datasets-spacing-13 tbody tr td::before {
  -webkit-margin-start: 13px;
  margin-inline-start: 13px;
  -webkit-margin-end: 13px;
  margin-inline-end: 13px;
}

.charts-css.area.data-spacing-14 tbody tr td::before, .charts-css.area.datasets-spacing-14 tbody tr td::before {
  -webkit-margin-start: 14px;
  margin-inline-start: 14px;
  -webkit-margin-end: 14px;
  margin-inline-end: 14px;
}

.charts-css.area.data-spacing-15 tbody tr td::before, .charts-css.area.datasets-spacing-15 tbody tr td::before {
  -webkit-margin-start: 15px;
  margin-inline-start: 15px;
  -webkit-margin-end: 15px;
  margin-inline-end: 15px;
}

.charts-css.area.data-spacing-16 tbody tr td::before, .charts-css.area.datasets-spacing-16 tbody tr td::before {
  -webkit-margin-start: 16px;
  margin-inline-start: 16px;
  -webkit-margin-end: 16px;
  margin-inline-end: 16px;
}

.charts-css.area.data-spacing-17 tbody tr td::before, .charts-css.area.datasets-spacing-17 tbody tr td::before {
  -webkit-margin-start: 17px;
  margin-inline-start: 17px;
  -webkit-margin-end: 17px;
  margin-inline-end: 17px;
}

.charts-css.area.data-spacing-18 tbody tr td::before, .charts-css.area.datasets-spacing-18 tbody tr td::before {
  -webkit-margin-start: 18px;
  margin-inline-start: 18px;
  -webkit-margin-end: 18px;
  margin-inline-end: 18px;
}

.charts-css.area.data-spacing-19 tbody tr td::before, .charts-css.area.datasets-spacing-19 tbody tr td::before {
  -webkit-margin-start: 19px;
  margin-inline-start: 19px;
  -webkit-margin-end: 19px;
  margin-inline-end: 19px;
}

.charts-css.area.data-spacing-20 tbody tr td::before, .charts-css.area.datasets-spacing-20 tbody tr td::before {
  -webkit-margin-start: 20px;
  margin-inline-start: 20px;
  -webkit-margin-end: 20px;
  margin-inline-end: 20px;
}

/*
 * Line Chart
 */
.charts-css.line {
  --line-size: 3px;
}

.charts-css.line tbody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  height: calc(100% - var(--heading-size));
}

.charts-css.line tbody tr {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  overflow-wrap: anywhere;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.charts-css.line tbody tr th {
  position: absolute;
  right: 0;
  left: 0;
}

.charts-css.line tbody tr td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.charts-css.line tbody tr td::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.charts-css.line tbody tr td::after {
  content: "";
  width: 100%;
}

.charts-css.line:not(.reverse) tbody tr {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-margin-after: var(--labels-size);
  margin-block-end: var(--labels-size);
}

.charts-css.line:not(.reverse) tbody tr th {
  bottom: calc(-1 * var(--labels-size) - var(--primary-axis-width));
  height: var(--labels-size);
}

.charts-css.line:not(.reverse) tbody tr td {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.charts-css.line.reverse tbody tr {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-margin-before: var(--labels-size);
  margin-block-start: var(--labels-size);
}

.charts-css.line.reverse tbody tr th {
  top: calc(-1 * var(--labels-size) - var(--primary-axis-width));
  height: var(--labels-size);
}

.charts-css.line.reverse tbody tr td {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.charts-css.line:not(.reverse-data) tbody {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.charts-css.line.reverse-data tbody {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.charts-css.line:not(.reverse-datasets) tbody tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.charts-css.line.reverse-datasets tbody tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.charts-css.line:not(.reverse):not(.reverse-data) tbody tr td {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.charts-css.line:not(.reverse):not(.reverse-data) tbody tr td::before {
  -webkit-clip-path: polygon(0% calc(100% * (1 - var(--start, var(--end, var(--size))))), 100% calc(100% * (1 - var(--end, var(--size)))), 100% calc(100% * (1 - var(--end, var(--size))) - var(--line-size)), 0% calc(100% * (1 - var(--start, var(--end, var(--size)))) - var(--line-size)));
  clip-path: polygon(0% calc(100% * (1 - var(--start, var(--end, var(--size))))), 100% calc(100% * (1 - var(--end, var(--size)))), 100% calc(100% * (1 - var(--end, var(--size))) - var(--line-size)), 0% calc(100% * (1 - var(--start, var(--end, var(--size)))) - var(--line-size)));
}

.charts-css.line:not(.reverse):not(.reverse-data) tbody tr td .data {
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}

.charts-css.line:not(.reverse):not(.reverse-data) tbody tr td::after {
  height: calc(100% * var(--end, var(--size)));
}

.charts-css.line:not(.reverse).reverse-data tbody tr td {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.charts-css.line:not(.reverse).reverse-data tbody tr td::before {
  -webkit-clip-path: polygon(0% calc(100% * (1 - var(--end, var(--size)))), 100% calc(100% * (1 - var(--start, var(--end, var(--size))))), 100% calc(100% * (1 - var(--start, var(--end, var(--size)))) - var(--line-size)), 0% calc(100% * (1 - var(--end, var(--size))) - var(--line-size)));
  clip-path: polygon(0% calc(100% * (1 - var(--end, var(--size)))), 100% calc(100% * (1 - var(--start, var(--end, var(--size))))), 100% calc(100% * (1 - var(--start, var(--end, var(--size)))) - var(--line-size)), 0% calc(100% * (1 - var(--end, var(--size))) - var(--line-size)));
}

.charts-css.line:not(.reverse).reverse-data tbody tr td .data {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.charts-css.line:not(.reverse).reverse-data tbody tr td::after {
  height: calc(100% * var(--end, var(--size)));
}

.charts-css.line.reverse:not(.reverse-data) tbody tr td {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.charts-css.line.reverse:not(.reverse-data) tbody tr td::before {
  -webkit-clip-path: polygon(0% calc(100% * var(--start, var(--end, var(--size))) - var(--line-size)), 100% calc(100% * var(--end, var(--size)) - var(--line-size)), 100% calc(100% * var(--end, var(--size))), 0% calc(100% * var(--start, var(--end, var(--size)))));
  clip-path: polygon(0% calc(100% * var(--start, var(--end, var(--size))) - var(--line-size)), 100% calc(100% * var(--end, var(--size)) - var(--line-size)), 100% calc(100% * var(--end, var(--size))), 0% calc(100% * var(--start, var(--end, var(--size)))));
}

.charts-css.line.reverse:not(.reverse-data) tbody tr td .data {
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}

.charts-css.line.reverse.reverse-data tbody tr td {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.charts-css.line.reverse.reverse-data tbody tr td::before {
  -webkit-clip-path: polygon(0% calc(100% * var(--end, var(--size)) - var(--line-size)), 100% calc(100% * var(--start, var(--end, var(--size))) - var(--line-size)), 100% calc(100% * var(--start, var(--end, var(--size)))), 0% calc(100% * var(--end, var(--size))));
  clip-path: polygon(0% calc(100% * var(--end, var(--size)) - var(--line-size)), 100% calc(100% * var(--start, var(--end, var(--size))) - var(--line-size)), 100% calc(100% * var(--start, var(--end, var(--size)))), 0% calc(100% * var(--end, var(--size))));
}

.charts-css.line.reverse.reverse-data tbody tr td .data {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.charts-css.line.reverse.reverse-data tbody tr td::after {
  height: calc(100% * (1 - var(--end, var(--size))));
}

.charts-css.line.data-spacing-1 tbody tr td::before, .charts-css.line.datasets-spacing-1 tbody tr td::before {
  -webkit-margin-start: 1px;
  margin-inline-start: 1px;
  -webkit-margin-end: 1px;
  margin-inline-end: 1px;
}

.charts-css.line.data-spacing-2 tbody tr td::before, .charts-css.line.datasets-spacing-2 tbody tr td::before {
  -webkit-margin-start: 2px;
  margin-inline-start: 2px;
  -webkit-margin-end: 2px;
  margin-inline-end: 2px;
}

.charts-css.line.data-spacing-3 tbody tr td::before, .charts-css.line.datasets-spacing-3 tbody tr td::before {
  -webkit-margin-start: 3px;
  margin-inline-start: 3px;
  -webkit-margin-end: 3px;
  margin-inline-end: 3px;
}

.charts-css.line.data-spacing-4 tbody tr td::before, .charts-css.line.datasets-spacing-4 tbody tr td::before {
  -webkit-margin-start: 4px;
  margin-inline-start: 4px;
  -webkit-margin-end: 4px;
  margin-inline-end: 4px;
}

.charts-css.line.data-spacing-5 tbody tr td::before, .charts-css.line.datasets-spacing-5 tbody tr td::before {
  -webkit-margin-start: 5px;
  margin-inline-start: 5px;
  -webkit-margin-end: 5px;
  margin-inline-end: 5px;
}

.charts-css.line.data-spacing-6 tbody tr td::before, .charts-css.line.datasets-spacing-6 tbody tr td::before {
  -webkit-margin-start: 6px;
  margin-inline-start: 6px;
  -webkit-margin-end: 6px;
  margin-inline-end: 6px;
}

.charts-css.line.data-spacing-7 tbody tr td::before, .charts-css.line.datasets-spacing-7 tbody tr td::before {
  -webkit-margin-start: 7px;
  margin-inline-start: 7px;
  -webkit-margin-end: 7px;
  margin-inline-end: 7px;
}

.charts-css.line.data-spacing-8 tbody tr td::before, .charts-css.line.datasets-spacing-8 tbody tr td::before {
  -webkit-margin-start: 8px;
  margin-inline-start: 8px;
  -webkit-margin-end: 8px;
  margin-inline-end: 8px;
}

.charts-css.line.data-spacing-9 tbody tr td::before, .charts-css.line.datasets-spacing-9 tbody tr td::before {
  -webkit-margin-start: 9px;
  margin-inline-start: 9px;
  -webkit-margin-end: 9px;
  margin-inline-end: 9px;
}

.charts-css.line.data-spacing-10 tbody tr td::before, .charts-css.line.datasets-spacing-10 tbody tr td::before {
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
}

.charts-css.line.data-spacing-11 tbody tr td::before, .charts-css.line.datasets-spacing-11 tbody tr td::before {
  -webkit-margin-start: 11px;
  margin-inline-start: 11px;
  -webkit-margin-end: 11px;
  margin-inline-end: 11px;
}

.charts-css.line.data-spacing-12 tbody tr td::before, .charts-css.line.datasets-spacing-12 tbody tr td::before {
  -webkit-margin-start: 12px;
  margin-inline-start: 12px;
  -webkit-margin-end: 12px;
  margin-inline-end: 12px;
}

.charts-css.line.data-spacing-13 tbody tr td::before, .charts-css.line.datasets-spacing-13 tbody tr td::before {
  -webkit-margin-start: 13px;
  margin-inline-start: 13px;
  -webkit-margin-end: 13px;
  margin-inline-end: 13px;
}

.charts-css.line.data-spacing-14 tbody tr td::before, .charts-css.line.datasets-spacing-14 tbody tr td::before {
  -webkit-margin-start: 14px;
  margin-inline-start: 14px;
  -webkit-margin-end: 14px;
  margin-inline-end: 14px;
}

.charts-css.line.data-spacing-15 tbody tr td::before, .charts-css.line.datasets-spacing-15 tbody tr td::before {
  -webkit-margin-start: 15px;
  margin-inline-start: 15px;
  -webkit-margin-end: 15px;
  margin-inline-end: 15px;
}

.charts-css.line.data-spacing-16 tbody tr td::before, .charts-css.line.datasets-spacing-16 tbody tr td::before {
  -webkit-margin-start: 16px;
  margin-inline-start: 16px;
  -webkit-margin-end: 16px;
  margin-inline-end: 16px;
}

.charts-css.line.data-spacing-17 tbody tr td::before, .charts-css.line.datasets-spacing-17 tbody tr td::before {
  -webkit-margin-start: 17px;
  margin-inline-start: 17px;
  -webkit-margin-end: 17px;
  margin-inline-end: 17px;
}

.charts-css.line.data-spacing-18 tbody tr td::before, .charts-css.line.datasets-spacing-18 tbody tr td::before {
  -webkit-margin-start: 18px;
  margin-inline-start: 18px;
  -webkit-margin-end: 18px;
  margin-inline-end: 18px;
}

.charts-css.line.data-spacing-19 tbody tr td::before, .charts-css.line.datasets-spacing-19 tbody tr td::before {
  -webkit-margin-start: 19px;
  margin-inline-start: 19px;
  -webkit-margin-end: 19px;
  margin-inline-end: 19px;
}

.charts-css.line.data-spacing-20 tbody tr td::before, .charts-css.line.datasets-spacing-20 tbody tr td::before {
  -webkit-margin-start: 20px;
  margin-inline-start: 20px;
  -webkit-margin-end: 20px;
  margin-inline-end: 20px;
}

/*
 * Radial Chart
 */
.charts-css.radial tbody {
  display: block;
  width: 100%;
  height: 0;
  -webkit-padding-after: 100%;
  padding-block-end: 100%;
  border-radius: 50%;
  background-color: var(--chart-bg-color);
}

.charts-css.radial tbody tr {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
}

/*
 * Pie Chart
 */
.charts-css.pie tbody {
  display: block;
  width: 100%;
  height: 0;
  -webkit-padding-after: 100%;
  padding-block-end: 100%;
  border-radius: 50%;
  background-color: var(--chart-bg-color);
}

.charts-css.pie tbody tr th {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
}

.charts-css.pie tbody tr td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(transparent 0 calc(1turn * var(--start)), var(--c, transparent) calc(1turn * var(--start, 0)) calc(1turn * var(--end)), transparent calc(1turn * var(--end)) 1turn);
}

.charts-css.pie tbody tr td::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.charts-css.pie tbody tr td::after {
  content: "";
  width: 100%;
}

.charts-css.pie tbody tr td .data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(calc(0.5turn * var(--start, 0) + 0.5turn * var(--end, 0)));
  transform: rotate(calc(0.5turn * var(--start, 0) + 0.5turn * var(--end, 0)));
}

/*
 * Polar Chart
 */
.charts-css.polar tbody {
  display: block;
  width: 100%;
  height: 0;
  -webkit-padding-after: 100%;
  padding-block-end: 100%;
  border-radius: 50%;
  background-color: var(--chart-bg-color);
}

.charts-css.polar tbody tr {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
}

/*
 * Radar Chart
 */
.charts-css.radar tbody {
  display: block;
  width: 100%;
  height: 0;
  -webkit-padding-after: 100%;
  padding-block-end: 100%;
  border-radius: 50%;
  background-color: var(--chart-bg-color);
}

.charts-css.radar tbody tr {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
}

/*
 * Line Chart Correction
 */
.charts-css.line {
  --line-size: 3px;
}
.charts-css.line:not(.reverse):not(.reverse-data) tbody tr td svg {
  position: absolute;
  width: 100%;
  height: 100%;
}
.charts-css.line:not(.reverse):not(.reverse-data) tbody tr td:before {
  content: none;
  display: none;
}

.charts-css tbody tr:hover {
  background: none;
}
.charts-css tbody th {
  letter-spacing: normal;
  color: inherit;
  white-space: nowrap;
  font-weight: normal;
}
.charts-css.column {
  height: 252px;
  padding: 0 20px 10px 30px;
}
@media (min-width: 480px) {
  .charts-css.column {
    height: 300px;
    padding: 0 40px 54px 50px;
  }
}
.charts-css.column tbody th {
  background-color: #fff;
  z-index: 1;
}
.charts-css.column tbody td {
  animation: fade-in 600ms linear;
}
.charts-css.column .data {
  position: absolute;
  left: -6px;
  display: block;
  background: #fff;
  font-size: 0.7rem;
  line-height: 0.85rem;
  padding: 2px;
  margin-top: 8px;
  white-space: nowrap;
  -webkit-box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}
.charts-css.area, .charts-css.line {
  height: 252px;
  padding: 0 20px 10px 30px;
}
@media (min-width: 480px) {
  .charts-css.area, .charts-css.line {
    height: 300px;
    padding: 0 40px 54px 50px;
  }
}
.charts-css.area tbody tr th span, .charts-css.line tbody tr th span {
  position: absolute;
  right: 0;
  transform: translateX(50%);
  white-space: nowrap;
  font-weight: normal;
}
.charts-css.area tbody tr td, .charts-css.line tbody tr td {
  animation: fade-in 600ms linear;
  z-index: initial;
}
.charts-css.area tbody tr:first-child, .charts-css.line tbody tr:first-child {
  max-width: 0;
}
.charts-css.area .data, .charts-css.line .data {
  position: relative;
  display: block;
  background: #fff;
  font-size: 0.7rem;
  line-height: 0.85rem;
  padding: 2px;
  white-space: nowrap;
  z-index: 6;
  -webkit-box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}
.charts-css.area .code, .charts-css.line .code {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 3px;
  vertical-align: middle;
  margin: 0 8px 2px 0;
}
.charts-css .tooltip {
  display: none;
  font-size: 0.85rem;
  line-height: 1.1rem;
  text-align: left;
}
.charts-css .tooltip > div {
  margin-bottom: 8px;
}
.charts-css .tooltip > div:last-child {
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .charts-css .tooltip {
    display: block;
  }
}

.mini-chart .charts-css.line {
  height: 60px;
  padding: 0;
}
.mini-chart .charts-css.column {
  height: 60px;
  padding: 0;
}
.mini-chart .tooltip,
.mini-chart .white-box {
  display: none !important;
}

@keyframes moving-bars {
  from {
    opacity: 0;
    transform: translateY(30%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.YAxis {
  background-color: #fff;
  position: absolute;
  min-width: 20px;
  left: 0;
  height: 224px;
  font-size: 0.8rem;
  text-align: right;
  padding-right: 8px;
  margin-top: -0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 480px) {
  .YAxis {
    min-width: 50px;
  }
}

.wideChart .YAxis {
  position: absolute;
}

.wideChart .charts-css {
  width: 100%;
  max-width: 100%;
  padding: 0 40px 54px 50px;
}
.wideChart .charts-css tbody th span {
  position: absolute;
  right: 50%;
  width: 80px;
  font-size: 0.8rem;
  text-align: right;
  transform: translateX(-4px) rotate(-45deg);
  transform-origin: 100% 0;
  white-space: nowrap;
}
.wideChart .charts-css.area tbody th span, .wideChart .charts-css.line tbody th span {
  right: 0;
  transform: translateX(-4px) rotate(-45deg);
}

.charts-css.trimLabels tbody tr:nth-child(2n) th {
  display: none;
}

.charts-css.legend {
  border: 0;
  padding: 0;
}
.charts-css.legend li {
  display: inline-block;
  margin-right: 16px;
}
.charts-css.legend li:last-child {
  margin-right: 0;
}
@media (min-width: 768px) {
  .charts-css.legend {
    display: block;
    text-align: center;
  }
}

.charts-wrapper {
  position: relative;
  padding-top: 32px;
  width: 100%;
  margin: 0 auto;
}
.charts-wrapper.wideChart {
  max-width: 100%;
}

.md-drppicker .ranges ul li button.active:hover,
.md-drppicker td.active:hover,
.md-drppicker .btn:hover, .md-drppicker .ranges ul li button.active,
.md-drppicker td.active,
.md-drppicker .btn {
  color: white !important;
  background-color: black !important;
}

/*
 #596368
 #DDE2E5
 #E94A1F - red sold out text
*/
.accordion-custom .unavailable {
  color: #E94A1F;
}
@media (max-width: 768px) {
  .accordion-custom .extra-bot-space {
    padding-bottom: 12%;
  }
}
.accordion-custom .toolbar-mobile-bottom {
  position: fixed;
  bottom: 60px;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: space-around;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.09);
  padding: 2.7% 0;
  z-index: 9;
  background: white;
  border-bottom: 1px solid #E3E5E5;
}
.accordion-custom .toolbar-mobile-bottom .btn {
  width: 35%;
  padding: 3.5% 1%;
}
.accordion-custom .no-results {
  margin-top: 5%;
}
.accordion-custom form section {
  padding-bottom: 0;
}
.accordion-custom .test-checkbox {
  top: 10px !important;
  left: 5px;
  position: absolute !important;
}
.accordion-custom .test-checkbox input[type=checkbox] {
  top: -2px;
  left: -2px;
}
.accordion-custom input[type=checkbox] {
  margin: 0;
  width: 20px;
  height: 20px;
  position: relative;
  top: 4px;
  border-radius: 4px;
  border: none;
  outline: none;
}
.accordion-custom .label-checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  top: 0;
  border-radius: 4px;
}
.accordion-custom .bot-grey-border {
  border-bottom: 1px solid #E3E5E5;
}
.accordion-custom .bot-grey-border .mdi {
  top: 8px;
}
.accordion-custom .menu-toolbar {
  display: flex;
  margin-bottom: 0;
}
.accordion-custom .menu-toolbar-mobile {
  display: block;
  margin-bottom: 5%;
}
.accordion-custom .menu-toolbar-mobile .tm-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.accordion-custom .menu-toolbar-mobile .tm-header .mobile-back-arrow {
  font-size: 2.5em;
  position: relative;
  top: 12px;
  margin-right: 3%;
}
.accordion-custom .menu-toolbar-mobile .tm-header h3 {
  font-weight: 600;
  position: relative;
  left: 2px;
}
.accordion-custom .menu-toolbar-mobile .tm-icons i {
  background: #E3E5E5;
  border-radius: 50%;
  padding: 1px 6px;
  font-size: 1.8em;
}
.accordion-custom .menu-toolbar-mobile .tm-icons label {
  margin: 0 4px;
}
.accordion-custom .menu-toolbar-mobile .tm-icons .mdi:before {
  position: relative;
  top: 1px;
}
.accordion-custom .select-filter {
  padding: 1.5%;
  display: flex;
  align-items: center;
}
.accordion-custom .select-filter .btn-color-black {
  background-color: #000 !important;
  color: #fff !important;
}
@media (max-width: 768px) {
  .accordion-custom .select-filter {
    margin: 2% 0;
    flex-wrap: wrap;
  }
}
@media (max-width: 768px) {
  .accordion-custom .select-filter .edit-btn {
    display: none;
  }
}
@media (max-width: 768px) {
  .accordion-custom .select-filter strong {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media (max-width: 768px) {
  .accordion-custom .select-filter .btn {
    flex: 1;
  }
}
.accordion-custom .search-input {
  display: inline-block;
}
.accordion-custom .btn {
  line-height: 1px;
}
.accordion-custom .btn-filter {
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;
  font-weight: normal;
  min-width: 75px;
  height: 40px;
  background: rgba(6, 60, 85, 0.05);
  border-radius: 60px;
  flex: none;
  order: 4;
  align-self: stretch;
  flex-grow: 0;
  margin-left: 8px;
}
.accordion-custom .btn-filter-inverse {
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;
  font-weight: normal;
  min-width: 75px;
  height: 40px;
  background: none;
  border: 1px solid rgba(6, 60, 85, 0.2);
  border-radius: 60px;
  flex: none;
  order: 4;
  align-self: stretch;
  flex-grow: 0;
  margin-left: 8px;
}
.accordion-custom .btn-filter-inverse:focus {
  background: #6ECFF5 radial-gradient(circle, transparent 1%, #6ECFF5 1%) center/15000%;
}
@media (max-width: 768px) {
  .accordion-custom .btn-filter-inverse {
    padding: 1% 2%;
    min-width: auto;
    height: 32px;
    margin-left: 0;
    margin-right: 3%;
  }
}
.accordion-custom .accordion-custom-inner .flex {
  justify-content: space-between;
}

.mat-expansion-panel-spacing {
  margin: 1% 0 !important;
}

.mat-expansion-panel-header:not([aria-disabled=true]) {
  cursor: default !important;
}

/* PARENT FOR ALL SEARCH RESULTS STUFF */
.accordion-search-results {
  margin-top: 35px;
}
.accordion-search-results .variant-count {
  font-weight: 600;
  text-transform: capitalize;
}
.accordion-search-results .variant-count em {
  font-style: normal;
  color: #149DD3;
  font-weight: 600;
}
.accordion-search-results em.keyword {
  font-style: normal;
  color: #149DD3;
  font-weight: 600;
}
@media (max-width: 1024px) {
  .accordion-search-results .mat-expansion-panel-header {
    height: 5em;
  }
}

.variant-count {
  display: block;
  font-size: 0.9em;
  font-weight: normal;
}
@media (max-width: 768px) {
  .variant-count {
    color: #5E6366;
  }
}
.variant-count em {
  font-style: normal;
  font-weight: 100;
}
.variant-count .dot {
  margin: 0;
  display: block;
  visibility: hidden;
  line-height: 1px;
}
.variant-count .mdi-eye-off {
  color: #E02020;
  position: relative;
  top: 1.5px;
}
.variant-count .mdi {
  margin-right: 2.5px;
}

.mat-expansion-panel {
  box-shadow: none !important;
}
.mat-expansion-panel:not(.mat-expanded) {
  margin-bottom: 2%;
}
.mat-expansion-panel .mat-expansion-panel-header {
  font: inherit !important;
}
.mat-expansion-panel .mat-expansion-panel {
  border-bottom: 1px solid #DDE2E5;
  border-radius: unset !important;
}
.mat-expansion-panel .mat-expansion-panel:not(.mat-expanded) {
  margin-bottom: 0;
}
.mat-expansion-panel .mat-expansion-panel .mat-expansion-panel-header {
  font-family: inherit !important;
  font-weight: normal;
  padding: 1.5% 2%;
  margin-bottom: 0;
  height: 5em;
}
.mat-expansion-panel .mat-expansion-panel .mat-expansion-panel-header:hover {
  background: none !important;
}
.mat-expansion-panel .mat-expansion-panel .mat-expansion-panel-header strong {
  font-weight: 600;
}
@media (max-width: 1024px) {
  .mat-expansion-panel .mat-expansion-panel .mat-expansion-panel-header {
    height: 6.5em;
    padding: 1.5% 13px;
  }
}
.mat-expansion-panel .accordion-main-header {
  background: rgba(6, 60, 85, 0.05) !important;
  border-radius: 8px;
}
@media (min-width: 768px) {
  .mat-expansion-panel .accordion-main-header {
    height: 64px !important;
  }
}
@media (max-width: 768px) {
  .mat-expansion-panel .accordion-main-header {
    height: auto !important;
    padding: 8px 15px;
  }
}
.mat-expansion-panel .accordion-main-header:focus {
  background: rgba(6, 60, 85, 0.05) !important;
}
.mat-expansion-panel .accordion-main-header:hover {
  background: rgba(6, 60, 85, 0.05) !important;
}
.mat-expansion-panel .accordion-main-header strong {
  font-size: 1.3em;
}
@media (max-width: 768px) {
  .mat-expansion-panel .accordion-main-header strong {
    font-size: 0.9em;
    align-self: center;
  }
}
.mat-expansion-panel .accordion-main-header strong em {
  font-style: normal;
}
.mat-expansion-panel .accordion-main-header .accordion-main-header {
  border-radius: unset;
}
.mat-expansion-panel .mat-expansion-panel-content {
  font-family: inherit !important;
  border: none !important;
  border-radius: none;
}
.mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-content {
  font: inherit !important;
  background: rgba(6, 60, 85, 0.05) !important;
  border: unset !important;
}
.mat-expansion-panel .mat-content {
  justify-content: space-between;
  align-items: center;
}
.mat-expansion-panel .multi-filter-checkbox {
  flex-grow: 2;
}
@media (max-width: 768px) {
  .mat-expansion-panel .multi-filter-checkbox {
    margin-right: 20px;
  }
}
.mat-expansion-panel .stretchCol {
  flex-grow: 20;
}
.mat-expansion-panel .subItem-name {
  align-self: center;
}
.mat-expansion-panel .subItem-name .menu-title {
  font-weight: 600;
  text-transform: capitalize;
}
.mat-expansion-panel .subItem-name .menu-title em {
  font-style: normal;
  color: #149DD3;
}
@media (max-width: 768px) {
  .mat-expansion-panel .subItem-name {
    width: 90%;
    font-size: 0.9em;
  }
}
.mat-expansion-panel .subItem-price {
  color: #596368;
  align-self: center;
}
.mat-expansion-panel .pencil {
  border-radius: 100%;
  cursor: pointer;
  align-content: center;
  width: 50px;
  height: 50px;
}
@media (max-width: 768px) {
  .mat-expansion-panel .pencil {
    display: flex;
    margin: 0;
    padding: 0;
  }
}
.mat-expansion-panel .pencil i {
  width: 30px;
  height: 30px;
  padding: 5px 10px;
  border-radius: 100%;
}
.mat-expansion-panel .pencil i:hover {
  background-color: #E3E5E5;
}
.mat-expansion-panel i.mdi {
  position: relative;
  font-size: 1.3em;
}
@media (max-width: 768px) {
  .mat-expansion-panel i.mdi {
    align-self: center;
  }
}
.mat-expansion-panel .mdi-pencil-outline {
  top: 3px;
}
.mat-expansion-panel .mdi-eye-off, .mat-expansion-panel .mdi-eye-outline {
  top: 3px;
}
.mat-expansion-panel .subItem-avail {
  align-self: center;
  text-transform: capitalize;
}
.mat-expansion-panel .mat-expansion-panel-body {
  padding: 0;
}
.mat-expansion-panel .mat-expansion-panel-body .accordion-subchild-row {
  padding: 1.3% 3%;
  border-top: 1px solid #DDE2E5;
}
@media (max-width: 768px) {
  .mat-expansion-panel .mat-expansion-panel-body .accordion-subchild-row {
    padding: 2% 3%;
  }
}
.mat-expansion-panel .mat-expansion-panel-body .accordion-subchild-row .subItem-name {
  font-weight: 600;
}
@media (max-width: 768px) {
  .mat-expansion-panel .mat-expansion-panel-body .accordion-subchild-row .subItem-name {
    font-weight: 500;
  }
}

.marginBottom0 {
  margin-bottom: 0 !important;
}

.custom-picker {
  position: relative;
  margin-bottom: 5%;
}
@media (max-width: 1024px) {
  .custom-picker {
    width: 100%;
  }
}
.custom-picker label:hover input {
  background-color: #E3E3E3;
}
.custom-picker input {
  min-width: 260px;
  display: inline-block;
  background-color: #F3F3F3;
  border: none;
  color: black;
  padding: 12px 48px 12px 16px;
  border-radius: 50px;
  font-size: 1em;
  font-family: "Mallory Local", "Rubik Local";
  transition: background-color 300ms cubic-bezier(0.25, 1, 0.5, 1);
}
.custom-picker input:focus {
  outline: none;
}
.custom-picker input:hover {
  cursor: pointer;
}
.custom-picker .mat-icon {
  position: relative;
  top: 5px;
  right: 40px;
  color: grey;
}
.custom-picker .mat-icon:hover {
  cursor: pointer;
}