%color-override {
    color: white !important;
    background-color: black !important;
}

.md-drppicker .ranges ul li button.active,
.md-drppicker td.active,
.md-drppicker .btn {
    @extend %color-override;

    &:hover {
        @extend %color-override;
    }
}
