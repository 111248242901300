@use 'src/scss/inc/vars';

.toolbar {
	background-color: #fff;
	/* box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.9); */
	border-bottom: 4px solid vars.$greyWhite;
	.flex { align-items: center; }
	.wrapper {
		justify-content: space-between;
		padding-top: 20px;
		padding-bottom: 20px;
		margin: 0;
		@media (vars.$sm) { padding-top: 16px; padding-bottom: 16px; }
	}
  .tabs {
		margin: 0;
		a {
			display: inline-block;
			/* color: inherit; */
			text-align: center;
			text-decoration: none;
			margin-right: 32px;
			font-weight: 500;
			&:before {
				content: '';
				position: absolute;
				display: inline-block;
				bottom: 0;
				width: 0;
				height: 2px;
				background:#6DCEF5;
				border-radius: 5px 5px 0px 0px;
				transform: translateX(-50%) scale(1.9);
				transition: width 300ms cubic-bezier(0.25, 1, 0.5, 1);
			}
			&:hover { color: vars.$blue; }
			&.active:before { width: 32px; }
		}
	}

	address {
		position: relative;
		display: block;
		font-style: normal;
		line-height: inherit;
		padding-left: 20px;
		@media (vars.$md) { display: inline-block; }

		.mdi {
			position: absolute;
			left: 0;
			color: vars.$coal;
			font-size: 1.2em;
			line-height: 1.4rem;
			margin-right: 8px;
		}
		@media (vars.$md) {
		}
	}

	.rating-stars {
		color: #585858;
		display: inline-block;
		padding-right: 8px;
		margin-bottom: -1px;

		@media (vars.$md) { padding-left: 16px; }

		span {
			display: inline-block;
			vertical-align: middle;
			font-size: 1em;
			line-height: 1em;
		}
	}

	.rating-desc {
		display: inline-block;
		letter-spacing: 0.4px;
		font-size: inherit;
	}
}

.page-toolbar {
	&.analytic-toolbar {
		border-bottom: 1px solid #F1F1EE;
		@media (vars.$sm) {
			box-shadow: none;
		}

		@media (max-width: 480px) {
			flex-direction: column;
		}

	}

	&.analytics-toolbar-menu {
		top: 102px;
		padding: 19px 47px 23px;
		@media (vars.$sm) {
			top: 102px;
			box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.240999);
		}
	}

	.toolbar-action {
		margin-left: auto;

		@media (vars.$max-sm) {
			display: none;
		}
	}

	.toolbar-address {
		position: relative;
		cursor: pointer;
		h6 { display: inline; margin-bottom: 8px; }
		@media (vars.$sm) {
			h6 { margin: 0 16px 0 0; }
		}
		button {
			cursor: pointer;
			@media (vars.$max-sm) {
				display: none;
			}
		}

		@include vars.transitionPrefix(opacity, 300ms);

		&:after {
			content: '';
			position: absolute;
			bottom: -10px;
			display: block;
			background-color: vars.$coal;
			width: 0;
			height: 2px;
			@include vars.transitionPrefix(width, 300ms);
		}
		&:hover {
			opacity: 0.8;
			&:after { width: 32px; }
		}
	}
}

.analytic-toolbar {
	justify-content: space-between;

	padding: 24px 32px;

	@media (vars.$sm) { padding: 24px 48px; }
	@media (vars.$md) { padding: 24px 64px; }
	@media (vars.$lg) { padding: 24px 128px; }

	.col-left {
		width: 100%;
		align-items: center;
	}
	.res-img {
		margin-right: 14px;
		img {
			width: 100%;
			max-width: 56px;
		}
	}

	.col-right {
		display: none;
		@media (vars.$sm) {
			display: flex;
		}
	}

	.col-inner {
		flex-direction: column;
		width: 100%;
	}

	h6 {
		font-weight: 500;
		font-size: 18px;
		// line-height: 28px;
		letter-spacing: 0.15px;
		color: #060B0D;
	}

	.promo-button-container {
		align-items: center;
	}

	.btn { margin-bottom: 0; }

	.btn-switch {
		background: #fff;
		color: #6CDEF5;
		box-sizing: border-box;
		border-radius: 5px;
		box-shadow: none;
		font-weight: 700;
		font-size: 12px;
		// line-height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		letter-spacing: 0.1px;
		line-height: inherit;
	}

	.btn.blue {
		border: none;
		background:none;
		justify-content: flex-start;
		font-weight: 500;
		font-size: 12px;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: 0.1px;
		color: vars.$blue;
		padding: 0;
		@media (vars.$sm) {
			display: none;
		}
	}
}
