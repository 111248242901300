@use "../inc/vars";

.mdc-floating-label { padding-bottom: 5px; }

form { flex-direction: column; } // for menu deals flex

form section {
	// background: green; // remove
	position: relative;
	border-bottom: 2px solid vars.$greyLight;
	@include vars.paddingLarge();
	@include vars.marginLarge();

	&:last-child {
		border-bottom: 0;
		margin-bottom: 0;
	}

	h1, h2, h3, h4, h5 { margin-bottom: 0; }
	h6 { font-size: 0.95rem; margin-bottom: 8px; }

	label {
		display: inline-block;
		// word-break: keep-all;
		// white-space: nowrap;
		margin: 0 24px 16px 0;

		&:last-child { padding-bottom: 0; margin: 0; }

		span {
			display: inline-block;
			vertical-align: middle;
			white-space: break-spaces;
		}

		&:hover span { color: vars.$greyMedium; }
		&:active span { color: vars.$blue; }
	}

	input {
		display: block;
		&[type=radio] {
			display: none;
			-webkit-appearance: none;
			opacity: 0;
			width: 0;
			height: 0;

			& + span {
				width: 20px;
				height: 20px;
				border: 2px solid #C6CACC;
				border-radius: 20px;
				line-height: 0;
				padding: 3px;
				margin-right: 16px;

				// @include vars.transitionPrefix(border, 400ms);

				span {
					background-color: #fff;
					width: 10px;
					height: 10px;
					line-height: 0;
					border-radius: 10px;

					// @include vars.transitionPrefix(background-color, 800ms);

				}
			}
			&:checked + span {
				border-color: vars.$blue;
				span { background-color: vars.$blue; }
			}
			&:disabled + span span,
			&:disabled + span { background-color: vars.$greyWhite; }
		}

    &[type="date"],
		&[type="text"],
		&[type="number"] {
			margin-bottom: 24px;

			&:last-child { margin-bottom: 0; }
		}

		&:disabled { background-color: vars.$greyWhite; color: vars.$greyMedium; }
	}
}
