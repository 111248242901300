@use 'src/scss/inc/vars';

.tooltip {
	position: relative;
	.mdi {
		font-size: 1.4em;
		vertical-align: middle;
		padding-top: 2px;
		margin-left: 2px;

		.info {
				display: none;
				position: absolute;
				text-transform: none;
				top: 20px;
				color: vars.$black;
				background-color: #fff;
				border-radius: 8px;
				z-index: 3;
				-webkit-box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
								box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
				h6 { margin-bottom: 8px; }
				p:last-child { margin: 0; }
				@media (vars.$max-xs) {
					padding: 16px;
					min-width: 270px;
				}
				@media (vars.$xs) {
					padding: 24px;
					min-width: 300px;
				}				
		}
		&:hover,
		&:focus {
				outline: none;
				.info { display: block; }
		}
	}	
}