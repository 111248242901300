@use "../../inc/vars";

input:-webkit-autofill,
input:-webkit-autofill:focus {
	transition: background-color 0s 600000s, color 0s 600000s !important;
}

.form-wrapper {

	.form-error {
		background: #EEC1C1;
		border: 1px solid #E02020;
		box-sizing: border-box;
		border-radius: 5px;
		padding: 17px 12px;


		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.44px;
		color: #181818;
		margin-bottom: 24px;

	}

	.form-error-main {
		position: fixed;
		background: #FFFFFF;
		border: 1px solid #E02020;
		box-sizing: border-box;
		box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.24), 0px 2px 6px rgba(8, 35, 48, 0.16);
		border-radius: 6px;
		top: 0;
		left: 0;
		padding: 16px 16px 16px 72px;

		font-style: normal;
		font-weight: normal;
		letter-spacing: 0.44px;
		width: 100%;
		max-width: 320px;
		top: 12px;
		left: 18px;
		z-index: 999;
		color: #E02020;

		@media (vars.$sm) {
			max-width: 420px;
			text-align: left;
		}

		.mdi {
			position: absolute;
			left: 22px;
			font-size: 25px;
		}

		&.something-wrong {
			position: relative;
			padding: 16px;
			left: 0;
		    top: 0;
		    margin-bottom: 16px;

			&:before {
				display: none;
			}

			a {
				font-weight: bold;
				text-decoration: none;
			}
		}
	}

	.form-row {
		// margin-bottom: 24px;
		padding: 10px 12px;
		position: relative;
		background: #fff;
		border-radius: 4px;
		border: 1px solid #C6CACC;
		min-height: 56px;
		display: flex;
		flex-direction: column;
		transition: ease all 0.2s;
		@include vars.margin();

		&.focused {
			border: 1px solid #181818;
		}

		&.submit {
			display: flex;
			flex-direction: column;
		}

		&.buttons {
			background: none;
			border: none;
			padding: 0;
		}

		&.terms {
			background: none;
			border: none;
			padding: 0;
		}

		// &:last-child { margin-bottom: 0; }

	}

	.form-row.select {
		label {
			opacity: 1;
			transform: translateY(-60%) translateX(0px);
			font-size: 12px;
		}
	}

	.frm_error {
		// @include roboto();
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.4px;
		color: #E02020;
		position: absolute;
		bottom: -20px;
		strong {
			font-weight: 600;
		}
	}

	.eye {
		position: absolute;
    right: 9px;
    top: 19px;
		z-index: 2;
		.mdi {
			font-size: 1.25em;
		}
	}

	.password-error {
		// @include roboto();
		font-family: Rubik;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		line-height: 14px;
		letter-spacing: 0.4px;
		color: #000000;
	    top: -20px;
	    position: relative;
	    margin: 0 0 0 5px;

	    &.frm_error {
	    	color: #E02020;
	    	text-align: center !important;
	    }

		div {
			margin-bottom: 4px;
			transition: opacity 2s ease-out, height 2s ease-out;
			height: auto;
			color: #E02020;
			.mdi {
				margin-right: 14px;
			}
			&.complete, &.strong {
				height: 0;
				opacity: 0;
				color: vars.$blue;
			}
		}


	}

	input {
		width: 100%;
		margin: 0;
		padding: 0;
		box-shadow: none;
		outline: none;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.44px;
		border: none;
		box-shadow: none;
		color: #181818;
		margin-top: 15px;
		//transition-timing-function: ease-in-our;
    //transition: 0.3s;
    background:none;
    z-index: 2;

		&:focus {
			// color: vars.$blue;
		}

		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: transparent;
		}
		&::-moz-placeholder { /* Firefox 19+ */
		  color: transparent;
		}
		&:-ms-input-placeholder { /* IE 10+ */
		  color: transparent;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: transparent;
		}
	}

	select {
		width: 100%;
		background: none;
		margin: 0 0 0 -8px;
		padding: 0 4px;
		box-shadow: none;
		outline: none;

		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.44px;
		border: none;
		box-shadow: none;
		color: #181818;
		margin-top: 15px;
		transition-timing-function: ease-in-our;
	  	transition: 0.3s;
	    text-indent: 1px;
	    text-overflow: ''

	}

	select::-ms-expand {
	    display: none;
	}

	/*input:focus + label, input:not(:placeholder-shown) + label {
		opacity:1;
		transform: translateY(-60%) translateX(0px);
		font-size: 12px;
	}*/

	select:focus + label, select:not(:placeholder-shown) + label {
		opacity:1;
		transform: translateY(-60%) translateX(0px);
		font-size: 12px;
	}

	/* For IE Browsers*/
	input:focus + label, input:not(:-ms-input-placeholder) + label {
		opacity:1;
		transform: translateY(-60%) translateX(0px);
		font-size: 12px;
	}

	.suggestions {
		display: none;
		position: absolute;
		background-color: #fff;
		border: 1px solid #C6CACC;
		border-radius: 4px;
		padding: 16px 0;
		max-height: 240px;
		top: 59px;
		left: -1px;
		right: -1px;
		z-index: 2;
		overflow: auto;

		ul {
			padding: 0;
			margin: 0;
			li {
				padding: 8px 24px;
				&:hover { color: vars.$blue; }
			}
		}
	}
	input:focus ~ .suggestions {
		display: block;
	}

	label {
		position:absolute;
		left:12px;
		top:17px;
		transition: 0.2s;
		transition-timing-function: ease;
		transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);

		opacity:1;
		transform: translateY(-60%) translateX(0px);
		font-size: 12px;		

		font-style: normal;
		font-weight: normal;
		line-height: 24px;
		letter-spacing: 0.44px;
		color: #5E6366;
		z-index: 1;
	}

	// button {
	// 	// width: 100%;
	// 	background: #000;
	// 	border-radius: 5px;

	// 	font-style: normal;
	// 	font-weight: bold;
	// 	font-size: 14px;
	// 	line-height: 24px;
	// 	text-align: center;
	// 	letter-spacing: 0.1px;
	// 	color: #fff;
	// 	text-align: center;
	// 	padding: 16px 10px;
	// 	outline: none;
	// 	position: relative;

	// 	&:hover {
	// 		cursor: pointer;
	// 	}
	// }

	.form-loader {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #000;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.form-link {
		text-align: center;
		margin-top: 15px;
		a {
			font-size: 0.9rem;
			line-height: 24px;
			text-align: center;
			letter-spacing: 0.1px;
		}
	}

	.terms {
		min-height: 10px;
		p {

			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.1px;
			color: #585858;
			padding: 0;

			a {
				color: #6DCEF5;
				text-decoration: none;
			}
		}
	}

	.google {
		> span {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			min-height: 24px;
			margin-bottom: 16px;

			&:before {
				content: '';
				width: 100%;
				height: 1px;
				background: #C6CACC;
				display: block;
			}

			>span {
				position: absolute;

				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 24px;
				text-align: center;
				letter-spacing: 0.1px;
				color: #5E6366;
				background: #DCF0FB;
				width: 24px;

			}
		}

		button {
			background: #fff;
			color: #000;
			border: 1px solid #C6CACC;
			display: flex;
			align-items: center;
			justify-content: center;

			>span {
				margin-right: 11px;
			}
			img {
				width: 100%;
				max-width: 18px;
				height: auto;
				display: block;
			}
		}
		a {
			background: #fff;
			color: #000;
			border: 1px solid #C6CACC;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.password-strength {
	    font-family: "Roboto", sans-serif;
	    font-style: normal;
	    font-weight: normal;
	    font-size: 12px;
	    line-height: 16px;
	    letter-spacing: 0.4px;

	    position: absolute;
	    bottom: -20px;

	    &.weak {
	    	color: #E02020;
	    }

	    &.good {
	    	color: #3EA23D;
	    }
	}
}
