@use 'src/scss/inc/vars';

.mat-form-field-type-mat-date-range-input {
	@media all and (vars.$max-sm) {
		width: 100%;
	}
	.mat-form-field-flex {
		border-radius: 65px !important;
		background-color: #f3f3f3 !important;
		padding: 0 !important;
		.mat-form-field-infix {
			min-width: 225px;
			top: -3px;
			left: 23px;
		}
		.mat-form-field-suffix {
			right: 5px;
			top: 3px;
		}
	}
  .mat-form-field-underline {
    width: unset;
  }
}
.mat-calendar-body-selected {
	background: vars.$blue;
}
.mat-calendar-body-in-preview {
	.mat-calendar-body-cell-content {
		background: none !important;
	}
}
.mat-calendar-body-preview-start, .mat-calendar-body-preview-end {
	.mat-calendar-body-cell-content {
		background: vars.$blue !important;
	}
}

.md-drppicker .btn { color: #000 !important; }

.md-drppicker .btn, .md-drppicker .btn:focus, .md-drppicker .btn:hover, 
	.md-drppicker .ranges ul li button.active, .md-drppicker td.active, .md-drppicker td.active:hover {
	background-color: vars.$btnBlue !important;
	color: #000;
	box-shadow: none !important;
	text-transform: capitalize !important;
}

.popup-modal {

	.mat-form-field-wrapper {
	  padding: 0;
	}

	.mat-form-field {
	  width: 100%;
	  border: 1px solid lightgrey;
	  border-radius: 8px;
	  position: relative;
	  padding: 3%; 
	}

	.mat-icon-button {
		width: 20px;
		height: 20px;
		line-height: 20px;		
	}

	.mat-datepicker-toggle {
	  /*top: 0;
	  right: 0;
	  position: absolute;*/
	}

	.mat-form-field-underline {
	  visibility: hidden;
	  border: none;  
	}

	.mat-form-field-infix {
	  border: none !important;
	  outline: none;
	  stroke: none;
	  padding: 0 !important;
	}

}


