@use 'src/scss/inc/vars';

.dash-form {
	// @media (vars.$max-sm) {
	// 	padding: 0 20px 20px;
	// }
	.form-wrapper {
		//min-height: calc(100vh - 136px);
		max-width: 640px;
		//flex-direction: column;
		flex-wrap: wrap;
	}

	.form-group {
		flex-wrap: wrap;
		width: 100%;

    .row-label {
      text-align: left;
    }

		&.half-group {
			max-width: 100%;
			@media (vars.$md) {
				max-width: calc(50% - 14px);

				// &:nth-of-type(odd) {
				// 	margin-left: 14px;
				// }
				// &:nth-of-type(even) {
				// 	margin-right: 14px;
				// }
			}
		}

		&.half-row {
			>*:not(.row-label) {
				width: 100%;
				@media (vars.$md) {
					max-width: calc(50% - 14px);

					// &:nth-of-type(even) {
					// 	margin-left: 14px;
					// }
					// &:nth-of-type(odd) {
					// 	margin-right: 14px;
					// }
				}
			}
			.row-label {
				max-width: 100% !important;
			}
		}

		&.user-roles-group {
			.row-label {
				.mdi {
					margin-left: auto;
					font-size: 20px;
				}
			}
		}
	}

	.row-label {
		width: 100%;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.4px;
		color: #060B0D;
		margin-bottom: 8px;
	}

	.input-icon {
		&:hover {
			cursor: pointer;
		}
		.mdi {
			position: absolute;
			top: 18px;
			right: 10px;
			font-size: 20px;
		}

		span.edit {
			position: absolute;
			top: 22px;
			right: 10px;
			font-size: 12px;
			line-height: 16px;
			color: vars.$blue;
		}
	}

	.form-row {
		width: 100%;

		// &.half-row {
		// 	@media (vars.$md) {
		// 		max-width: calc(50% - 14px);
		// 		&:nth-of-type(odd) {
		// 			margin-right: 14px;
		// 		}
		// 		&:nth-of-type(even) {
		// 			margin-left: 14px;
		// 		}
		// 	}
		// }

		&.blue-bg {
			background:#DCF0FB;

			input {
				background:none;
			}

			&.no-bg {
				background:none;

				.mdi {
					display: none !important;
				}
			}
		}

		&.clickable {
			cursor: pointer;

			&:hover {
				cursor: pointer;
			}

			input {
				cursor: pointer;

				&:hover {
					cursor: pointer;
				}
			}
		}
	}

	.user-roles {
		position: absolute;
		top: 70px;
		left: 0;
		background: #FFFFFF;
		box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
		border-radius: 5px;
		padding: 5px 8px;
		z-index: 8;
		width: 100%;

		.user-role-inner {
			display: flex;
			flex-direction: column;
		}

		.search-type {
			width: 100%;
		}

		label {
			display: flex;
			align-items: center;
			position: relative;
			left: 0;
			top: 0;
			border-radius: 5px;

			&:hover {
				cursor: pointer;
				background:#F5F6F9;
			}

			input {
				width: 0;
				height: 0;
				opacity: 0;
				display: none;
			}

			input:checked + span {
				background:#F5F6F9;
			}
			span {
				width: 100%;

				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 0.44px;
				color: #5E6366;
				background: #fff;
				padding: 7px 12px;
				border-radius: 5px;
			}
		}
	}

	// .btn-group {
	// 	margin-top: auto;
	// 	border: none;
	// 	display: flex;
	// 	flex-direction: column;
	// 	align-items: center;
	// 	justify-content: flex-end;
	// 	@media (vars.$md) {
	// 		flex-direction: row;
	// 	}

	// 	button {
	// 		margin: 0 0 14px;
	// 		@media (max-width: 1023px) {
	// 			max-width: 100%;
	// 		}
	// 		@media (vars.$md) {
	// 			& ~ button { margin-left: 16px; } // excludes 1st button
	// 		}

	// 		&:disabled {
	// 			color: rgba(255,255,2550,0.4) !important;
	// 		}
	// 	}

	// 	.sm-hidden {
	// 		display: none;
	// 		@media (vars.$md) {
	// 			display: block;
	// 		}
	// 	}
	// }

	.saving-progress {
		display: flex;
		align-items: center;
		justify-content: center;

		.lds-ellipsis {
			transform: scale(0.5);
			width: 20px;
			height: 20px;
			top: -13px;
			// width: 20px;
			// height: 20px;

			// div {
			// 	width: 5px;
			// 	height: 5px;
			// 	top: 6px;
			// }
		}

	}

	.disabled {
		.form-row {
			background-color: #FAFAFA;
			border-color: #E0E4E6;
			input { color: #868A8C; }
		}
	}
}
