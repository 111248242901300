$mdi-font: "mdi";

@font-face {
    font-family: $mdi-font;
    src: url("../../assets/fonts/icons/mdi.svg?5ab46e96ed968dcbb7011ea9624a10e2#mdi") format("svg"),
url("../../assets/fonts/icons/mdi.ttf?5ab46e96ed968dcbb7011ea9624a10e2") format("truetype"),
url("../../assets/fonts/icons/mdi.woff?5ab46e96ed968dcbb7011ea9624a10e2") format("woff"),
url("../../assets/fonts/icons/mdi.woff2?5ab46e96ed968dcbb7011ea9624a10e2") format("woff2");
}

.mdi:before {
    font-family: mdi !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$mdi-map: (
    "account-cash": "\f101",
    "account-plus": "\f102",
    "account": "\f103",
    "alert-outline": "\f104",
    "arrow-down": "\f105",
    "arrow-left-circle": "\f106",
    "arrow-left": "\f107",
    "arrow-right-circle": "\f108",
    "arrow-right": "\f109",
    "arrow-up": "\f10a",
    "bell": "\f10b",
    "book-open-variant": "\f10c",
    "bullhorn": "\f10d",
    "calendar-month-outline": "\f10e",
    "calendar": "\f10f",
    "cash-multiple": "\f110",
    "chart-line": "\f111",
    "check-decagram": "\f112",
    "check": "\f113",
    "chevron-down": "\f114",
    "chevron-left": "\f115",
    "chevron-right": "\f116",
    "chevron-up": "\f117",
    "clock-outline": "\f118",
    "close-circle": "\f119",
    "close": "\f11a",
    "cog": "\f11b",
    "delete": "\f11c",
    "dots-horizontal": "\f11d",
    "dots-vertical": "\f11e",
    "download": "\f11f",
    "emoticon-happy-outline": "\f120",
    "emoticon-sad-outline": "\f121",
    "eraser": "\f122",
    "eye-off-outline": "\f123",
    "eye-off": "\f124",
    "eye-outline": "\f125",
    "eye": "\f126",
    "face-agent": "\f127",
    "file-document": "\f128",
    "filter": "\f129",
    "format-list-numbered": "\f12a",
    "google-analytics": "\f12b",
    "help-circle-outline": "\f12c",
    "history": "\f12d",
    "home-analytics": "\f12e",
    "image-outline": "\f12f",
    "information-outline": "\f130",
    "information": "\f131",
    "laptop": "\f132",
    "list-status": "\f133",
    "logout-variant": "\f134",
    "logout": "\f135",
    "magnify": "\f136",
    "map-marker": "\f137",
    "menu-down": "\f138",
    "menu": "\f139",
    "message-text": "\f13a",
    "minus-circle-outline": "\f13b",
    "page-first": "\f13c",
    "page-last": "\f13d",
    "pencil-outline": "\f13e",
    "pencil": "\f13f",
    "plus-circle": "\f140",
    "plus": "\f141",
    "refresh": "\f142",
    "sale": "\f143",
    "shopping": "\f144",
    "star-circle": "\f145",
    "star-half-full": "\f146",
    "star-outline": "\f147",
    "star": "\f148",
    "store": "\f149",
    "sync": "\f14a",
    "tag": "\f14b",
    "thumb-down-outline": "\f14c",
    "thumb-down": "\f14d",
    "thumb-up-outline": "\f14e",
    "thumb-up": "\f14f",
    "timer-sand-empty": "\f150",
    "tune": "\f151",
    "upload": "\f152",
);

.mdi.mdi-account-cash:before {
    content: map-get($mdi-map, "account-cash");
}
.mdi.mdi-account-plus:before {
    content: map-get($mdi-map, "account-plus");
}
.mdi.mdi-account:before {
    content: map-get($mdi-map, "account");
}
.mdi.mdi-alert-outline:before {
    content: map-get($mdi-map, "alert-outline");
}
.mdi.mdi-arrow-down:before {
    content: map-get($mdi-map, "arrow-down");
}
.mdi.mdi-arrow-left-circle:before {
    content: map-get($mdi-map, "arrow-left-circle");
}
.mdi.mdi-arrow-left:before {
    content: map-get($mdi-map, "arrow-left");
}
.mdi.mdi-arrow-right-circle:before {
    content: map-get($mdi-map, "arrow-right-circle");
}
.mdi.mdi-arrow-right:before {
    content: map-get($mdi-map, "arrow-right");
}
.mdi.mdi-arrow-up:before {
    content: map-get($mdi-map, "arrow-up");
}
.mdi.mdi-bell:before {
    content: map-get($mdi-map, "bell");
}
.mdi.mdi-book-open-variant:before {
    content: map-get($mdi-map, "book-open-variant");
}
.mdi.mdi-bullhorn:before {
    content: map-get($mdi-map, "bullhorn");
}
.mdi.mdi-calendar-month-outline:before {
    content: map-get($mdi-map, "calendar-month-outline");
}
.mdi.mdi-calendar:before {
    content: map-get($mdi-map, "calendar");
}
.mdi.mdi-cash-multiple:before {
    content: map-get($mdi-map, "cash-multiple");
}
.mdi.mdi-chart-line:before {
    content: map-get($mdi-map, "chart-line");
}
.mdi.mdi-check-decagram:before {
    content: map-get($mdi-map, "check-decagram");
}
.mdi.mdi-check:before {
    content: map-get($mdi-map, "check");
}
.mdi.mdi-chevron-down:before {
    content: map-get($mdi-map, "chevron-down");
}
.mdi.mdi-chevron-left:before {
    content: map-get($mdi-map, "chevron-left");
}
.mdi.mdi-chevron-right:before {
    content: map-get($mdi-map, "chevron-right");
}
.mdi.mdi-chevron-up:before {
    content: map-get($mdi-map, "chevron-up");
}
.mdi.mdi-clock-outline:before {
    content: map-get($mdi-map, "clock-outline");
}
.mdi.mdi-close-circle:before {
    content: map-get($mdi-map, "close-circle");
}
.mdi.mdi-close:before {
    content: map-get($mdi-map, "close");
}
.mdi.mdi-cog:before {
    content: map-get($mdi-map, "cog");
}
.mdi.mdi-delete:before {
    content: map-get($mdi-map, "delete");
}
.mdi.mdi-dots-horizontal:before {
    content: map-get($mdi-map, "dots-horizontal");
}
.mdi.mdi-dots-vertical:before {
    content: map-get($mdi-map, "dots-vertical");
}
.mdi.mdi-download:before {
    content: map-get($mdi-map, "download");
}
.mdi.mdi-emoticon-happy-outline:before {
    content: map-get($mdi-map, "emoticon-happy-outline");
}
.mdi.mdi-emoticon-sad-outline:before {
    content: map-get($mdi-map, "emoticon-sad-outline");
}
.mdi.mdi-eraser:before {
    content: map-get($mdi-map, "eraser");
}
.mdi.mdi-eye-off-outline:before {
    content: map-get($mdi-map, "eye-off-outline");
}
.mdi.mdi-eye-off:before {
    content: map-get($mdi-map, "eye-off");
}
.mdi.mdi-eye-outline:before {
    content: map-get($mdi-map, "eye-outline");
}
.mdi.mdi-eye:before {
    content: map-get($mdi-map, "eye");
}
.mdi.mdi-face-agent:before {
    content: map-get($mdi-map, "face-agent");
}
.mdi.mdi-file-document:before {
    content: map-get($mdi-map, "file-document");
}
.mdi.mdi-filter:before {
    content: map-get($mdi-map, "filter");
}
.mdi.mdi-format-list-numbered:before {
    content: map-get($mdi-map, "format-list-numbered");
}
.mdi.mdi-google-analytics:before {
    content: map-get($mdi-map, "google-analytics");
}
.mdi.mdi-help-circle-outline:before {
    content: map-get($mdi-map, "help-circle-outline");
}
.mdi.mdi-history:before {
    content: map-get($mdi-map, "history");
}
.mdi.mdi-home-analytics:before {
    content: map-get($mdi-map, "home-analytics");
}
.mdi.mdi-image-outline:before {
    content: map-get($mdi-map, "image-outline");
}
.mdi.mdi-information-outline:before {
    content: map-get($mdi-map, "information-outline");
}
.mdi.mdi-information:before {
    content: map-get($mdi-map, "information");
}
.mdi.mdi-laptop:before {
    content: map-get($mdi-map, "laptop");
}
.mdi.mdi-list-status:before {
    content: map-get($mdi-map, "list-status");
}
.mdi.mdi-logout-variant:before {
    content: map-get($mdi-map, "logout-variant");
}
.mdi.mdi-logout:before {
    content: map-get($mdi-map, "logout");
}
.mdi.mdi-magnify:before {
    content: map-get($mdi-map, "magnify");
}
.mdi.mdi-map-marker:before {
    content: map-get($mdi-map, "map-marker");
}
.mdi.mdi-menu-down:before {
    content: map-get($mdi-map, "menu-down");
}
.mdi.mdi-menu:before {
    content: map-get($mdi-map, "menu");
}
.mdi.mdi-message-text:before {
    content: map-get($mdi-map, "message-text");
}
.mdi.mdi-minus-circle-outline:before {
    content: map-get($mdi-map, "minus-circle-outline");
}
.mdi.mdi-page-first:before {
    content: map-get($mdi-map, "page-first");
}
.mdi.mdi-page-last:before {
    content: map-get($mdi-map, "page-last");
}
.mdi.mdi-pencil-outline:before {
    content: map-get($mdi-map, "pencil-outline");
}
.mdi.mdi-pencil:before {
    content: map-get($mdi-map, "pencil");
}
.mdi.mdi-plus-circle:before {
    content: map-get($mdi-map, "plus-circle");
}
.mdi.mdi-plus:before {
    content: map-get($mdi-map, "plus");
}
.mdi.mdi-refresh:before {
    content: map-get($mdi-map, "refresh");
}
.mdi.mdi-sale:before {
    content: map-get($mdi-map, "sale");
}
.mdi.mdi-shopping:before {
    content: map-get($mdi-map, "shopping");
}
.mdi.mdi-star-circle:before {
    content: map-get($mdi-map, "star-circle");
}
.mdi.mdi-star-half-full:before {
    content: map-get($mdi-map, "star-half-full");
}
.mdi.mdi-star-outline:before {
    content: map-get($mdi-map, "star-outline");
}
.mdi.mdi-star:before {
    content: map-get($mdi-map, "star");
}
.mdi.mdi-store:before {
    content: map-get($mdi-map, "store");
}
.mdi.mdi-sync:before {
    content: map-get($mdi-map, "sync");
}
.mdi.mdi-tag:before {
    content: map-get($mdi-map, "tag");
}
.mdi.mdi-thumb-down-outline:before {
    content: map-get($mdi-map, "thumb-down-outline");
}
.mdi.mdi-thumb-down:before {
    content: map-get($mdi-map, "thumb-down");
}
.mdi.mdi-thumb-up-outline:before {
    content: map-get($mdi-map, "thumb-up-outline");
}
.mdi.mdi-thumb-up:before {
    content: map-get($mdi-map, "thumb-up");
}
.mdi.mdi-timer-sand-empty:before {
    content: map-get($mdi-map, "timer-sand-empty");
}
.mdi.mdi-tune:before {
    content: map-get($mdi-map, "tune");
}
.mdi.mdi-upload:before {
    content: map-get($mdi-map, "upload");
}
