@use 'src/scss/inc/vars';

.sidebar {
	display: none;
	position: fixed;
	top: 0;
	background:#F5F6F9;
	width: 100%;
	max-width: 75px;
	height: 100%;
	min-height: 100vh;
	padding: 35px 17px;
	z-index: 5;

	@include vars.transitionPrefix(max-width, 200ms);

	&:hover {
		max-width: 250px;
		.action-menu { display: block; }
	}

	@media (vars.$sm) {
		display: flex;
		& + .content {
			margin-left: 75px;
			#fixedHeader,
			.body-loading { left: 75px; }

		}
	}
	@media (vars.$md) {
		&:hover + .content {
			margin-left: 250px;
			#fixedHeader,
			.body-loading { left: 250px; }
		}
	}
	@media (vars.$lg) {
		max-width: 250px;
		& + .content {
			margin-left: 250px;
			#fixedHeader,
			.body-loading { left: 250px; }
		}
		.action-menu { display: block; }
	}
	& + .content { background-color: inherit; }

	.sidebar-body { @include vars.margin(); }

	.sidebar-inner {
		width: 250px;
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow: hidden;
	}

	.site-logo {
		width: 100%;
		margin: 0 auto 20px;
		padding: 0;
	}

	.link-item {
		margin-bottom: 8px;
	}

	a {
		position: relative;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		display: flex;
		align-items: center;
		letter-spacing: 0.25px;
		color: #181818;
		text-decoration: none;
		padding: 10px;
		border-radius: 100px;
		width: 100%;
		min-width: 40px;
		border: none;
		box-shadow: none;
		height: 40px;
		margin: 0;
		text-align: left;

		@include vars.transitionPrefixMultiple(150ms, border-radius, max-width, background-color);

		&:before { color: #181818; font-size: 20px; }
		&:hover {
      // background-color: #D9D9D9;
      background-color: lighten(vars.$btnBlue, 20%);
      border-radius: 11px;
    }

		span {
			margin-left: 20px;
			min-width: 170px;
		}

		.nav-item-badge {
			padding: 0.3em 0.75em;
			background-color: vars.$success;
			margin: 0 0.5em;
			color: vars.$white;
			border-radius: 50px;
			font-size: 12px;
			font-weight: 700;
			display: inline;
		}
	}

	a.active {
		background: vars.$btnBlue;
		font-weight: 500;
	}

	a.active, a:hover {
		.nav-item-badge {
			background-color: vars.$white;
			color: vars.$success;
		}
	}

	.sidebar-footer {
		margin-top: auto;
		position: relative;
		width: 100%;
		max-width: 40px;
		// transition: max-width 0.15s cubic-bezier(0.42, 0, 0.58, 1);

		@include vars.transitionPrefix(max-width, 200ms, cubic-bezier(0.42, 0, 0.58, 1));

		.user-profile {
			align-items: center;
		}

		.avatar {
			width: 100%;
			max-width: 40px;
			height: 40px;
			min-width: 40px;
			margin-right: 14px;
			border-radius: 100%;
      background-color: vars.$btnBlue;
			font-style: normal;
			font-size: 22px;
			text-align: center;
			color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
		}

		.profile-user {
			// visibility: hidden;
			min-width: 138px;
			.name {
				font-style: normal;
				font-weight: bold;
				font-size: 11px;
				line-height: 14px;
				display: flex;
				align-items: center;
				letter-spacing: 0.4px;
				color: #000000;
			}

			.role {
				font-style: normal;
				font-weight: normal;
				font-size: 11px;
				line-height: 14px;
				letter-spacing: 0.4px;
				color: #000000;
			}
		}
	}
}
