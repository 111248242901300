@use 'src/scss/inc/vars';

html, *, *:before, *:after { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; }
body {
	background-color: #fff;
	padding: 0;
	margin: 0;
	-webkit-text-stroke: 1px rgba(0,0,0,0);
}

.content {
	&.blue-bg {
		background-color: #DCF0FB;
	}
	position: relative;
  min-height: 100vh;
	@include vars.transitionPrefix(margin-left, 200ms);
	#fixedHeader,
	.body-loading { @include vars.transitionPrefix(left, 200ms); }
}

.wrapper {
	max-width: 1600px;
	//margin: 0 auto;
	margin: 0;

	@include vars.screenSpace(padding);

	&.noWrapper {
		padding: 0;
		&, .wrapper { max-width: initial; margin: initial; }
	}
}

ul, li { padding: 0; margin: 0; }

img { border: 0; width: 100%; display: block; }

a {
	color: #{vars.$black};
	text-decoration: none;
	&:hover { text-decoration: underline; }
}
strong { font-weight: 500; }
pre {
	display: block;
	background-color: #f7f7f7;
	font-size: 0.9em;
	border-radius: 3px;
	border: 1px solid #ccc;
	padding: 16px;
	margin: 16px 0;
}
code {
	display: inline-block;
	background-color: #f7f7f7;
	font-size: 0.9em;
	border-radius: 3px;
	border: 1px solid #ccc;
	padding: 2px 6px;
	margin: 4px;
}
pre code {
	background-color: transparent;
	border-radius: 0;
	border: 0;
	padding: 16px;
}
hr { border: 1px solid vars.$greyLight; @include vars.margin(top, bottom); }

button, .button { background: none; border: 0; padding: 0; margin: 0; box-shadow: 0; outline: none; }

.grey { color: vars.$coal; }
.red { color: vars.$red; }
.green { color: vars.$green; }
.dark-grey { color: vars.$greyDark; }

.bold { font-weight: 700; }
.semibold { font-weight: 500; }

.wrap { white-space: wrap; }

.textGrey { color: vars.$greyMedium; }
.textLowImportance { color: vars.$lowImportance}
.textError { color: vars.$error; }
.textSuccess { color: vars.$success; }

.bgError { background-color: vars.$error; }
.bgSuccess { background-color: vars.$success; }


.textLeft { text-align: left; }
.textCenter { text-align: center; }
.textRight { text-align: right; }

.textSmaller { font-size: 0.93rem; }
.textLarge { font-size: 1.2rem; }

.maxWidth600 { max-width: 600px; margin: 0;  } // margin: 0 auto;
.maxWidth700 { max-width: 700px; margin: 0; } // margin: 0 auto;
.maxWidth1200 {  max-width: 1200px; display: flex; width: 100%; justify-content: space-between; }

.verticalTop { vertical-align: top; }
.verticalMiddle { vertical-align: middle; }
