.alert-overlay {
	position: fixed !important;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(24,24,24,0.3);
	overflow: auto;
	z-index: 10000;
	display: flex;
	align-items: center;
	justify-content: center;

	.alert-dialog.dialog {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 40px 0 80px;
		width: 100%;
		max-width: 440px;
		//height: 100%;
	}

	.modal-wrapper {
		background: #fff;
		width: 90vw;
		max-width: 640px;
		box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.24), 0px 2px 6px rgba(8, 35, 48, 0.16);
		border-radius: 6px;
		padding: 24px;
		margin: 0 auto;
	}

	h3 {
		
		font-weight: 500;
		font-size: 22px;
		line-height: 26px;
		color: #060B0D;
		word-break: break-all;
		margin-bottom: 7px;
	}

	p {
		
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 20px;
		letter-spacing: 0.25px;
		color: #060B0D;
		margin-bottom: 77px;
	}

	// .btn-group {
	//     display: grid;
	//     grid-template-columns: 1fr 1fr;
	//     grid-gap: 16px;
	// 		text-align: right;
	// 		// .btn { min-width: 100%; }
	// }

}
.restaurant-list-overlay {
	align-items: normal;

	.alert-dialog {
		margin: 60px 0;
		width: 100%;
		max-width: 363px;
	}
	.modal-wrapper {
		padding: 4px;
	}

	.modal-header {
		padding: 9px 12px;
		border-bottom: 1px solid #F1F1EE;
		margin-bottom: 16px;

		h3 {
			font-weight: 500;
			font-size: 18px;
			line-height: 28px;
			letter-spacing: 0.15px;
			color: #060B0D;
			margin: 0;
		}

		.close {
			font-size: 12px;
			margin-left: auto;
			align-items: center;

			&:hover {
				cursor: pointer;
			}

			.mdi {
				font-size: 20px;
				margin-left: 8px;
			}
		}
	}
}

.restaurant-list-main {
	.restaurant-list {
		width: 100%;
		align-items: center;
		border-bottom: 1px solid #F1F1EE;
		padding: 8px 12px;
		margin: 0 0 8px 0;
		cursor: pointer;

		&:hover { background:#EAF6FD; }
	}

	input {
		display: none;
		-webkit-appearance: none;
			 -moz-appearance: none;
	}
	.form-group input { display: block; -webkit-appearance: initial; -moz-appearance: initial; }

	.res-img {
		width: 48px;
		min-width: 48px;
		height: 48px;
		border-radius: 48px;
		margin-right: 16px;

		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
	.col-inner {
		flex-direction: column;
		width: 100%;
	}

	h6 {
		font-weight: 500;
		font-size: 18px;
		line-height: 28px;
		letter-spacing: 0.15px;
		color: #060B0D;
		margin-bottom: 0;
	}

	address {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.4px;
		color: #060B0D;
		display: flex;
		min-width: 160px;
		align-items: center;
	}
}
