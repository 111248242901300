@use 'src/scss/inc/vars';

.marginBottom0 { margin-bottom: 0 !important }

.custom-picker {
    position: relative;
    margin-bottom: 5%;
    @media (vars.$max-md) {
        width: 100%;
    }

    label:hover {
        input {
            background-color: #E3E3E3;
        }
    }

    input {
        min-width: 260px;
        display: inline-block;
        background-color: #F3F3F3;
        border: none;
        color: black;
        padding: 12px 48px 12px 16px;
        border-radius: 50px;
        font-size: 1em;
        font-family: "Mallory Local", "Rubik Local";
        transition: background-color 300ms cubic-bezier(0.25, 1, 0.5, 1);

        &:focus {
            outline: none;
        }
        &:hover {
            cursor: pointer;
        }
    }
    .mat-icon {
        position: relative;
        top: 5px;
        right: 40px;

        color: grey;

        &:hover {
            cursor: pointer;
        }
    }
}