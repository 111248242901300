@use 'src/scss/inc/vars';

.orders-container {
	.tool-row {
		width: 100%;
		.column {
			float: left;
			margin-right: 1%;
			.col-date-range {
				width: 33%;
			}
			.col-dropdown {
				width: 15%;
			}
			@media (vars.$max-sm) {
				width: 100%;
			}
		}
		.last-updated {
			float: right;
		}
	}
	.issue {
		border-radius: 15px;
		color: #E02020;
		min-width: 85px;
		display: inline-block;
		font-size: 0.85em;
		text-transform: capitalize;
		font-weight: 500;
		position: relative;
		text-align: center;
		@media (vars.$sm) {
			left: -15px;
		}
	}
	.success {
		color: green !important;
		background-color: lime !important;
	}
	.cust-name-col {
		.avatar, span {
			float: left;
		}
	}
	.order-status-tag {
		position: relative;
		top: -7px;
	}
	@media (vars.$sm) {
		padding: 0;
	}
}
