@use 'src/scss/inc/vars';

.impersonating {
	width: calc(100% - 32px);
	position: fixed;
	display: flex;
	align-items: center;
	background-color: vars.$blue;
	color: #FFFFFF;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.240999);
	left: 50%;
	transform: translateX(-50%);
	bottom: 80px;
	padding: 8px 20px;
	z-index: 5;
	
	font-size: 0.85rem;
	letter-spacing: 0.25px;
	font-style: normal;
	font-weight: bold;
	border-radius: 42px;
	height: 42px;

	@media (vars.$sm) {
		width: auto;
		left: initial;
		right: 106px;
		bottom: 35px;
		transform: none;
	}

	.imp-person { margin-left: 16px; }

	button {
		margin-left: auto;
		font-size: 15px;
		line-height: 20px;
		letter-spacing: 0.25px;
		color: #FFFFFF;
		text-transform: uppercase;
		font-style: normal;
		font-weight: bold;
	}
}

// .impersonating-overview {

// 	.page-toolbar { display: flex; }

// 	@media (max-width: $screen-sm) {
// 		padding-top: 72px;
// 		.page-toolbar {
// 			position: relative;
// 			min-height: initial;
// 			z-index: 3;
// 		}
// 	}
// 	@media (min-width: $screen-sm) {
// 		.page-toolbar { width: 100%; padding: 13px 47px; top: 68px; }
// 		.page-body { margin-top: 68px; }
// 	}
// }
