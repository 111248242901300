/*
 * Charts.css v1.0.0 (https://ChartsCSS.org/)
 * Copyright 2020 Rami Yushuvaev
 * Licensed under MIT
 */
 .charts-css {
  --color-1: rgba(240, 50, 50, 0.75);
  --color-2: rgba(255, 180, 50, 0.75);
  --color-3: rgba(255, 220, 90, 0.75);
  --color-4: rgba(100, 210, 80, 0.75);
  --color-5: rgba(90, 165, 255, 0.75);
  --color-6: rgba(170, 90, 240, 0.75);
  --color-7: rgba(180, 180, 180, 0.75);
  --color-8: rgba(110, 110, 110, 0.75);
  --color-9: rgba(170, 150, 110, 0.75);
  --color-10: rgba(130, 50, 20, 0.75);
  --chart-bg-color: #f5f5f5;
  --heading-size: 0px;
  --primary-axis-color: rgba(0, 0, 0, 1);
  --primary-axis-style: solid;
  --primary-axis-width: 1px;
  --secondary-axes-color: rgba(0, 0, 0, .15);
  --secondary-axes-style: solid;
  --secondary-axes-width: 1px;
  --data-axes-color: rgba(0, 0, 0, .15);
  --data-axes-style: solid;
  --data-axes-width: 1px;
  --legend-border-color: rgb(200, 200, 200);
  --legend-border-style: solid;
  --legend-border-width: 1px;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  border: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

/*
 * Chart custom properties
 */
@property --color,
@property --color-1,
@property --color-2,
@property --color-3,
@property --color-4,
@property --color-5,
@property --color-6,
@property --color-7,
@property --color-8,
@property --color-9,
@property --color-10,
@property --chart-bg-color {
  syntax: '<color>';
  initial-value: transparent;
  inherits: true;
}

@property --heading-size {
  syntax: '<length>';
  initial-value: 0;
  inherits: true;
}

@property --labels-size {
  syntax: '<length>';
  initial-value: 0;
  inherits: true;
}

@property --labels-align {
  syntax: '<string>';
  initial-value: center;
  inherits: true;
}

@property --primary-axis-width,
@property --secondary-axes-width,
@property --data-axes-width,
@property --legend-border-width {
  syntax: '<length>';
  initial-value: 1px;
  inherits: true;
}

@property --primary-axis-style,
@property --secondary-axes-style,
@property --data-axes-style,
@property --legend-border-style {
  syntax: '<line-style>';
  initial-value: solid;
  inherits: true;
}

@property --primary-axis-color,
@property --secondary-axes-color,
@property --data-axes-color,
@property --legend-border-color {
  syntax: '<color>';
  initial-value: transparent;
  inherits: true;
}

@property --start,
@property --end,
@property --size,
@property --line-size {
  syntax: '<number>';
  initial-value: 0;
  inherits: true;
}

/*
 * Chart wrapper element
 */

.charts-css, .charts-css::after, .charts-css::before,
.charts-css *,
.charts-css *::after,
.charts-css *::before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

/*
 * Reset table element
 */
table.charts-css {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  overflow: initial;
  background-color: transparent;
}

table.charts-css caption,
table.charts-css colgroup,
table.charts-css thead,
table.charts-css tbody,
table.charts-css tr,
table.charts-css th,
table.charts-css td {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
}

table.charts-css colgroup,
table.charts-css thead,
table.charts-css tfoot {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  white-space: nowrap;
}

table.charts-css tbody {
  position: relative;
}

/*
 * Reset list elements
 */
ul.charts-css,
ol.charts-css {
  list-style-type: none;
}

ul.charts-css li,
ol.charts-css li {
  margin: 0;
  padding: 0;
  border: 0;
}

/*
 * Chart heading
 */
.charts-css:not(.show-heading) caption {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  white-space: nowrap;
}

.charts-css.show-heading {
  --heading-size: 1.5rem;
}

.charts-css.show-heading caption {
  display: block;
  width: 100%;
  height: var(--heading-size);
}

/*
 * Chart colors
 */
.charts-css.bar tbody tr:nth-of-type(10n + 1) td,
.charts-css.bar.multiple tbody tr td:nth-of-type(10n + 1),
.charts-css.column tbody tr:nth-of-type(10n + 1) td,
.charts-css.column.multiple tbody tr td:nth-of-type(10n + 1),
.charts-css.area tbody tr td:nth-of-type(10n + 1)::before,
.charts-css.line tbody tr td:nth-of-type(10n + 1)::before {
  background: var(--color, var(--color-1));
}

.charts-css.pie tbody tr:nth-of-type(10n + 1) td,
.charts-css.pie.multiple tbody tr td:nth-of-type(10n + 1) {
  --c: var(--color, var(--color-1, transparent) );
}

.charts-css.bar tbody tr:nth-of-type(10n + 2) td,
.charts-css.bar.multiple tbody tr td:nth-of-type(10n + 2),
.charts-css.column tbody tr:nth-of-type(10n + 2) td,
.charts-css.column.multiple tbody tr td:nth-of-type(10n + 2),
.charts-css.area tbody tr td:nth-of-type(10n + 2)::before,
.charts-css.line tbody tr td:nth-of-type(10n + 2)::before {
  background: var(--color, var(--color-2));
}

.charts-css.pie tbody tr:nth-of-type(10n + 2) td,
.charts-css.pie.multiple tbody tr td:nth-of-type(10n + 2) {
  --c: var(--color, var(--color-2, transparent) );
}

.charts-css.bar tbody tr:nth-of-type(10n + 3) td,
.charts-css.bar.multiple tbody tr td:nth-of-type(10n + 3),
.charts-css.column tbody tr:nth-of-type(10n + 3) td,
.charts-css.column.multiple tbody tr td:nth-of-type(10n + 3),
.charts-css.area tbody tr td:nth-of-type(10n + 3)::before,
.charts-css.line tbody tr td:nth-of-type(10n + 3)::before {
  background: var(--color, var(--color-3));
}

.charts-css.pie tbody tr:nth-of-type(10n + 3) td,
.charts-css.pie.multiple tbody tr td:nth-of-type(10n + 3) {
  --c: var(--color, var(--color-3, transparent) );
}

.charts-css.bar tbody tr:nth-of-type(10n + 4) td,
.charts-css.bar.multiple tbody tr td:nth-of-type(10n + 4),
.charts-css.column tbody tr:nth-of-type(10n + 4) td,
.charts-css.column.multiple tbody tr td:nth-of-type(10n + 4),
.charts-css.area tbody tr td:nth-of-type(10n + 4)::before,
.charts-css.line tbody tr td:nth-of-type(10n + 4)::before {
  background: var(--color, var(--color-4));
}

.charts-css.pie tbody tr:nth-of-type(10n + 4) td,
.charts-css.pie.multiple tbody tr td:nth-of-type(10n + 4) {
  --c: var(--color, var(--color-4, transparent) );
}

.charts-css.bar tbody tr:nth-of-type(10n + 5) td,
.charts-css.bar.multiple tbody tr td:nth-of-type(10n + 5),
.charts-css.column tbody tr:nth-of-type(10n + 5) td,
.charts-css.column.multiple tbody tr td:nth-of-type(10n + 5),
.charts-css.area tbody tr td:nth-of-type(10n + 5)::before,
.charts-css.line tbody tr td:nth-of-type(10n + 5)::before {
  background: var(--color, var(--color-5));
}

.charts-css.pie tbody tr:nth-of-type(10n + 5) td,
.charts-css.pie.multiple tbody tr td:nth-of-type(10n + 5) {
  --c: var(--color, var(--color-5, transparent) );
}

.charts-css.bar tbody tr:nth-of-type(10n + 6) td,
.charts-css.bar.multiple tbody tr td:nth-of-type(10n + 6),
.charts-css.column tbody tr:nth-of-type(10n + 6) td,
.charts-css.column.multiple tbody tr td:nth-of-type(10n + 6),
.charts-css.area tbody tr td:nth-of-type(10n + 6)::before,
.charts-css.line tbody tr td:nth-of-type(10n + 6)::before {
  background: var(--color, var(--color-6));
}

.charts-css.pie tbody tr:nth-of-type(10n + 6) td,
.charts-css.pie.multiple tbody tr td:nth-of-type(10n + 6) {
  --c: var(--color, var(--color-6, transparent) );
}

.charts-css.bar tbody tr:nth-of-type(10n + 7) td,
.charts-css.bar.multiple tbody tr td:nth-of-type(10n + 7),
.charts-css.column tbody tr:nth-of-type(10n + 7) td,
.charts-css.column.multiple tbody tr td:nth-of-type(10n + 7),
.charts-css.area tbody tr td:nth-of-type(10n + 7)::before,
.charts-css.line tbody tr td:nth-of-type(10n + 7)::before {
  background: var(--color, var(--color-7));
}

.charts-css.pie tbody tr:nth-of-type(10n + 7) td,
.charts-css.pie.multiple tbody tr td:nth-of-type(10n + 7) {
  --c: var(--color, var(--color-7, transparent) );
}

.charts-css.bar tbody tr:nth-of-type(10n + 8) td,
.charts-css.bar.multiple tbody tr td:nth-of-type(10n + 8),
.charts-css.column tbody tr:nth-of-type(10n + 8) td,
.charts-css.column.multiple tbody tr td:nth-of-type(10n + 8),
.charts-css.area tbody tr td:nth-of-type(10n + 8)::before,
.charts-css.line tbody tr td:nth-of-type(10n + 8)::before {
  background: var(--color, var(--color-8));
}

.charts-css.pie tbody tr:nth-of-type(10n + 8) td,
.charts-css.pie.multiple tbody tr td:nth-of-type(10n + 8) {
  --c: var(--color, var(--color-8, transparent) );
}

.charts-css.bar tbody tr:nth-of-type(10n + 9) td,
.charts-css.bar.multiple tbody tr td:nth-of-type(10n + 9),
.charts-css.column tbody tr:nth-of-type(10n + 9) td,
.charts-css.column.multiple tbody tr td:nth-of-type(10n + 9),
.charts-css.area tbody tr td:nth-of-type(10n + 9)::before,
.charts-css.line tbody tr td:nth-of-type(10n + 9)::before {
  background: var(--color, var(--color-9));
}

.charts-css.pie tbody tr:nth-of-type(10n + 9) td,
.charts-css.pie.multiple tbody tr td:nth-of-type(10n + 9) {
  --c: var(--color, var(--color-9, transparent) );
}

.charts-css.bar tbody tr:nth-of-type(10n + 10) td,
.charts-css.bar.multiple tbody tr td:nth-of-type(10n + 10),
.charts-css.column tbody tr:nth-of-type(10n + 10) td,
.charts-css.column.multiple tbody tr td:nth-of-type(10n + 10),
.charts-css.area tbody tr td:nth-of-type(10n + 10)::before,
.charts-css.line tbody tr td:nth-of-type(10n + 10)::before {
  background: var(--color, var(--color-10));
}

.charts-css.pie tbody tr:nth-of-type(10n + 10) td,
.charts-css.pie.multiple tbody tr td:nth-of-type(10n + 10) {
  --c: var(--color, var(--color-10, transparent) );
}

/*
 * Chart data
 */
.charts-css.hide-data .data,
.charts-css.hide-data .data:not(:focus):not(:focus-within) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  white-space: nowrap;
}

.charts-css.show-data-on-hover .data {
  -webkit-transition-duration: .3s;
          transition-duration: .3s;
  opacity: 0;
}

.charts-css.show-data-on-hover tr:hover .data {
  -webkit-transition-duration: .3s;
          transition-duration: .3s;
  opacity: 1;
}

.charts-css.radial.show-data-on-hover tbody:hover .data, .charts-css.pie.show-data-on-hover tbody:hover .data, .charts-css.polar.show-data-on-hover tbody:hover .data, .charts-css.radar.show-data-on-hover tbody:hover .data {
  -webkit-transition-duration: .3s;
          transition-duration: .3s;
  opacity: 1;
}

/*
 * Chart labels
 */
.charts-css.bar:not(.show-labels) {
  --labels-size: 0;
}

.charts-css.bar:not(.show-labels) tbody tr th {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  white-space: nowrap;
}

.charts-css.bar.show-labels {
  --labels-size: 80px;
}

.charts-css.bar.show-labels tbody tr th {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: var(--labels-align, center);
      -ms-flex-align: var(--labels-align, center);
          align-items: var(--labels-align, center);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.charts-css.bar.show-labels tr.hide-label th,
.charts-css.bar.show-labels th.hide-label {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  white-space: nowrap;
}

.charts-css.column:not(.show-labels), .charts-css.area:not(.show-labels), .charts-css.line:not(.show-labels) {
  --labels-size: 0;
}

.charts-css.column:not(.show-labels) tbody tr th, .charts-css.area:not(.show-labels) tbody tr th, .charts-css.line:not(.show-labels) tbody tr th {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  white-space: nowrap;
}

.charts-css.column.show-labels, .charts-css.area.show-labels, .charts-css.line.show-labels {
  --labels-size: 1.5rem;
}

.charts-css.column.show-labels tbody tr th, .charts-css.area.show-labels tbody tr th, .charts-css.line.show-labels tbody tr th {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: var(--labels-align, center);
      -ms-flex-align: var(--labels-align, center);
          align-items: var(--labels-align, center);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.charts-css.column.show-labels tr.hide-label th,
.charts-css.column.show-labels th.hide-label, .charts-css.area.show-labels tr.hide-label th,
.charts-css.area.show-labels th.hide-label, .charts-css.line.show-labels tr.hide-label th,
.charts-css.line.show-labels th.hide-label {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  white-space: nowrap;
}

.charts-css.bar.labels-align-start tbody tr th, .charts-css.column.labels-align-start tbody tr th, .charts-css.area.labels-align-start tbody tr th, .charts-css.line.labels-align-start tbody tr th {
  -webkit-box-align: var(--labels-align, flex-start);
      -ms-flex-align: var(--labels-align, flex-start);
          align-items: var(--labels-align, flex-start);
}

.charts-css.bar.labels-align-end tbody tr th, .charts-css.column.labels-align-end tbody tr th, .charts-css.area.labels-align-end tbody tr th, .charts-css.line.labels-align-end tbody tr th {
  -webkit-box-align: var(--labels-align, flex-end);
      -ms-flex-align: var(--labels-align, flex-end);
          align-items: var(--labels-align, flex-end);
}

.charts-css.bar.labels-align-center tbody tr th, .charts-css.column.labels-align-center tbody tr th, .charts-css.area.labels-align-center tbody tr th, .charts-css.line.labels-align-center tbody tr th {
  -webkit-box-align: var(--labels-align, center);
      -ms-flex-align: var(--labels-align, center);
          align-items: var(--labels-align, center);
}

/*
 * Chart axes
 */
.charts-css.column.show-primary-axis:not(.reverse) tbody tr, .charts-css.area.show-primary-axis:not(.reverse) tbody tr, .charts-css.line.show-primary-axis:not(.reverse) tbody tr {
  -webkit-border-after: var(--primary-axis-width) var(--primary-axis-style) var(--primary-axis-color);
          border-block-end: var(--primary-axis-width) var(--primary-axis-style) var(--primary-axis-color);
}

.charts-css.column.show-primary-axis.reverse tbody tr, .charts-css.area.show-primary-axis.reverse tbody tr, .charts-css.line.show-primary-axis.reverse tbody tr {
  -webkit-border-before: var(--primary-axis-width) var(--primary-axis-style) var(--primary-axis-color);
          border-block-start: var(--primary-axis-width) var(--primary-axis-style) var(--primary-axis-color);
}

.charts-css.column.show-1-secondary-axes:not(.reverse) tbody tr, .charts-css.area.show-1-secondary-axes:not(.reverse) tbody tr, .charts-css.line.show-1-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 100%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-1-secondary-axes.reverse tbody tr, .charts-css.area.show-1-secondary-axes.reverse tbody tr, .charts-css.line.show-1-secondary-axes.reverse tbody tr {
  background-size: 100% 100%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(0deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-2-secondary-axes:not(.reverse) tbody tr, .charts-css.area.show-2-secondary-axes:not(.reverse) tbody tr, .charts-css.line.show-2-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 50%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-2-secondary-axes.reverse tbody tr, .charts-css.area.show-2-secondary-axes.reverse tbody tr, .charts-css.line.show-2-secondary-axes.reverse tbody tr {
  background-size: 100% 50%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(0deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-3-secondary-axes:not(.reverse) tbody tr, .charts-css.area.show-3-secondary-axes:not(.reverse) tbody tr, .charts-css.line.show-3-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 33.333333%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-3-secondary-axes.reverse tbody tr, .charts-css.area.show-3-secondary-axes.reverse tbody tr, .charts-css.line.show-3-secondary-axes.reverse tbody tr {
  background-size: 100% 33.333333%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(0deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-4-secondary-axes:not(.reverse) tbody tr, .charts-css.area.show-4-secondary-axes:not(.reverse) tbody tr, .charts-css.line.show-4-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 25%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-4-secondary-axes.reverse tbody tr, .charts-css.area.show-4-secondary-axes.reverse tbody tr, .charts-css.line.show-4-secondary-axes.reverse tbody tr {
  background-size: 100% 25%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(0deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-5-secondary-axes:not(.reverse) tbody tr, .charts-css.area.show-5-secondary-axes:not(.reverse) tbody tr, .charts-css.line.show-5-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 20%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-5-secondary-axes.reverse tbody tr, .charts-css.area.show-5-secondary-axes.reverse tbody tr, .charts-css.line.show-5-secondary-axes.reverse tbody tr {
  background-size: 100% 20%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(0deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-6-secondary-axes:not(.reverse) tbody tr, .charts-css.area.show-6-secondary-axes:not(.reverse) tbody tr, .charts-css.line.show-6-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 16.666667%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-6-secondary-axes.reverse tbody tr, .charts-css.area.show-6-secondary-axes.reverse tbody tr, .charts-css.line.show-6-secondary-axes.reverse tbody tr {
  background-size: 100% 16.666667%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(0deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-7-secondary-axes:not(.reverse) tbody tr, .charts-css.area.show-7-secondary-axes:not(.reverse) tbody tr, .charts-css.line.show-7-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 14.285714%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-7-secondary-axes.reverse tbody tr, .charts-css.area.show-7-secondary-axes.reverse tbody tr, .charts-css.line.show-7-secondary-axes.reverse tbody tr {
  background-size: 100% 14.285714%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(0deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-8-secondary-axes:not(.reverse) tbody tr, .charts-css.area.show-8-secondary-axes:not(.reverse) tbody tr, .charts-css.line.show-8-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 12.5%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-8-secondary-axes.reverse tbody tr, .charts-css.area.show-8-secondary-axes.reverse tbody tr, .charts-css.line.show-8-secondary-axes.reverse tbody tr {
  background-size: 100% 12.5%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(0deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-9-secondary-axes:not(.reverse) tbody tr, .charts-css.area.show-9-secondary-axes:not(.reverse) tbody tr, .charts-css.line.show-9-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 11.111111%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-9-secondary-axes.reverse tbody tr, .charts-css.area.show-9-secondary-axes.reverse tbody tr, .charts-css.line.show-9-secondary-axes.reverse tbody tr {
  background-size: 100% 11.111111%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(0deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-10-secondary-axes:not(.reverse) tbody tr, .charts-css.area.show-10-secondary-axes:not(.reverse) tbody tr, .charts-css.line.show-10-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 10%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-10-secondary-axes.reverse tbody tr, .charts-css.area.show-10-secondary-axes.reverse tbody tr, .charts-css.line.show-10-secondary-axes.reverse tbody tr {
  background-size: 100% 10%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(0deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.column.show-data-axes tbody tr, .charts-css.area.show-data-axes tbody tr, .charts-css.line.show-data-axes tbody tr {
  -webkit-border-end: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
          border-inline-end: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.column.show-data-axes:not(.reverse-data) tbody tr:first-of-type, .charts-css.area.show-data-axes:not(.reverse-data) tbody tr:first-of-type, .charts-css.line.show-data-axes:not(.reverse-data) tbody tr:first-of-type {
  -webkit-border-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
          border-inline-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.column.show-data-axes.reverse-data tbody tr:last-of-type, .charts-css.area.show-data-axes.reverse-data tbody tr:last-of-type, .charts-css.line.show-data-axes.reverse-data tbody tr:last-of-type {
  -webkit-border-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
          border-inline-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.column.show-dataset-axes tbody tr td, .charts-css.area.show-dataset-axes tbody tr td, .charts-css.line.show-dataset-axes tbody tr td {
  -webkit-border-end: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
          border-inline-end: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.column.show-dataset-axes:not(.reverse-data) tbody tr:first-of-type td, .charts-css.area.show-dataset-axes:not(.reverse-data) tbody tr:first-of-type td, .charts-css.line.show-dataset-axes:not(.reverse-data) tbody tr:first-of-type td {
  -webkit-border-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
          border-inline-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.column.show-dataset-axes.reverse-data tbody tr:last-of-type td, .charts-css.area.show-dataset-axes.reverse-data tbody tr:last-of-type td, .charts-css.line.show-dataset-axes.reverse-data tbody tr:last-of-type td {
  -webkit-border-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
          border-inline-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.bar.show-primary-axis:not(.reverse) tbody tr {
  -webkit-border-start: var(--primary-axis-width) var(--primary-axis-style) var(--primary-axis-color);
          border-inline-start: var(--primary-axis-width) var(--primary-axis-style) var(--primary-axis-color);
}

.charts-css.bar.show-primary-axis.reverse tbody tr {
  -webkit-border-end: var(--primary-axis-width) var(--primary-axis-style) var(--primary-axis-color);
          border-inline-end: var(--primary-axis-width) var(--primary-axis-style) var(--primary-axis-color);
}

.charts-css.bar.show-1-secondary-axes:not(.reverse) tbody tr {
  background-size: 100% 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(-90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-1-secondary-axes.reverse tbody tr {
  background-size: 100% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-2-secondary-axes:not(.reverse) tbody tr {
  background-size: 50% 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(-90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-2-secondary-axes.reverse tbody tr {
  background-size: 50% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-3-secondary-axes:not(.reverse) tbody tr {
  background-size: 33.333333% 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(-90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-3-secondary-axes.reverse tbody tr {
  background-size: 33.333333% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-4-secondary-axes:not(.reverse) tbody tr {
  background-size: 25% 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(-90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-4-secondary-axes.reverse tbody tr {
  background-size: 25% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-5-secondary-axes:not(.reverse) tbody tr {
  background-size: 20% 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(-90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-5-secondary-axes.reverse tbody tr {
  background-size: 20% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-6-secondary-axes:not(.reverse) tbody tr {
  background-size: 16.666667% 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(-90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-6-secondary-axes.reverse tbody tr {
  background-size: 16.666667% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-7-secondary-axes:not(.reverse) tbody tr {
  background-size: 14.285714% 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(-90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-7-secondary-axes.reverse tbody tr {
  background-size: 14.285714% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-8-secondary-axes:not(.reverse) tbody tr {
  background-size: 12.5% 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(-90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-8-secondary-axes.reverse tbody tr {
  background-size: 12.5% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-9-secondary-axes:not(.reverse) tbody tr {
  background-size: 11.111111% 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(-90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-9-secondary-axes.reverse tbody tr {
  background-size: 11.111111% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-10-secondary-axes:not(.reverse) tbody tr {
  background-size: 10% 100%;
  background-image: -webkit-gradient(linear, right top, left top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(-90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-10-secondary-axes.reverse tbody tr {
  background-size: 10% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--secondary-axes-color)), to(transparent));
  background-image: linear-gradient(90deg, var(--secondary-axes-color) var(--secondary-axes-width), transparent var(--secondary-axes-width));
}

.charts-css.bar.show-data-axes tbody tr {
  -webkit-border-after: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
          border-block-end: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.bar.show-data-axes:not(.reverse-data) tbody tr:first-of-type {
  -webkit-border-before: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
          border-block-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.bar.show-data-axes.reverse-data tbody tr:last-of-type {
  -webkit-border-before: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
          border-block-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.bar.show-dataset-axes tbody tr td {
  -webkit-border-after: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
          border-block-end: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.bar.show-dataset-axes:not(.reverse-data) tbody tr:first-of-type td {
  -webkit-border-before: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
          border-block-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.bar.show-dataset-axes.reverse-data tbody tr:last-of-type td {
  -webkit-border-before: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
          border-block-start: var(--data-axes-width) var(--data-axes-style) var(--data-axes-color);
}

.charts-css.radial.show-primary-axis tbody, .charts-css.pie.show-primary-axis tbody, .charts-css.polar.show-primary-axis tbody, .charts-css.radar.show-primary-axis tbody {
  border: var(--primary-axis-width) var(--primary-axis-style) var(--primary-axis-color);
}

.charts-css.radial.show-1-secondary-axes tbody::after, .charts-css.pie.show-1-secondary-axes tbody::after, .charts-css.polar.show-1-secondary-axes tbody::after, .charts-css.radar.show-1-secondary-axes tbody::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(closest-side, transparent 0, transparent calc( 100% / 2 - var( --secondary-axes-width )), var(--secondary-axes-color) calc( 100% / 2 - var( --secondary-axes-width )), var(--secondary-axes-color) calc( 100% / 2), transparent calc( 100% / 2 + var( --secondary-axes-width )), transparent calc( 100% / 2 + var( --secondary-axes-width )));
  z-index: 2;
}

.charts-css.radial.show-2-secondary-axes tbody::after, .charts-css.pie.show-2-secondary-axes tbody::after, .charts-css.polar.show-2-secondary-axes tbody::after, .charts-css.radar.show-2-secondary-axes tbody::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(closest-side, transparent 0, transparent calc( 100% / 3 - var( --secondary-axes-width )), var(--secondary-axes-color) calc( 100% / 3 - var( --secondary-axes-width )), var(--secondary-axes-color) calc( 100% / 3), transparent calc( 100% / 3 + var( --secondary-axes-width )), transparent calc( 100% / 3 + var( --secondary-axes-width )));
  z-index: 2;
}

.charts-css.radial.show-3-secondary-axes tbody::after, .charts-css.pie.show-3-secondary-axes tbody::after, .charts-css.polar.show-3-secondary-axes tbody::after, .charts-css.radar.show-3-secondary-axes tbody::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(closest-side, transparent 0, transparent calc( 100% / 4 - var( --secondary-axes-width )), var(--secondary-axes-color) calc( 100% / 4 - var( --secondary-axes-width )), var(--secondary-axes-color) calc( 100% / 4), transparent calc( 100% / 4 + var( --secondary-axes-width )), transparent calc( 100% / 4 + var( --secondary-axes-width )));
  z-index: 2;
}

.charts-css.radial.show-4-secondary-axes tbody::after, .charts-css.pie.show-4-secondary-axes tbody::after, .charts-css.polar.show-4-secondary-axes tbody::after, .charts-css.radar.show-4-secondary-axes tbody::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(closest-side, transparent 0, transparent calc( 100% / 5 - var( --secondary-axes-width )), var(--secondary-axes-color) calc( 100% / 5 - var( --secondary-axes-width )), var(--secondary-axes-color) calc( 100% / 5), transparent calc( 100% / 5 + var( --secondary-axes-width )), transparent calc( 100% / 5 + var( --secondary-axes-width )));
  z-index: 2;
}

.charts-css.radial.show-5-secondary-axes tbody::after, .charts-css.pie.show-5-secondary-axes tbody::after, .charts-css.polar.show-5-secondary-axes tbody::after, .charts-css.radar.show-5-secondary-axes tbody::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(closest-side, transparent 0, transparent calc( 100% / 6 - var( --secondary-axes-width )), var(--secondary-axes-color) calc( 100% / 6 - var( --secondary-axes-width )), var(--secondary-axes-color) calc( 100% / 6), transparent calc( 100% / 6 + var( --secondary-axes-width )), transparent calc( 100% / 6 + var( --secondary-axes-width )));
  z-index: 2;
}

.charts-css.radial.show-6-secondary-axes tbody::after, .charts-css.pie.show-6-secondary-axes tbody::after, .charts-css.polar.show-6-secondary-axes tbody::after, .charts-css.radar.show-6-secondary-axes tbody::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(closest-side, transparent 0, transparent calc( 100% / 7 - var( --secondary-axes-width )), var(--secondary-axes-color) calc( 100% / 7 - var( --secondary-axes-width )), var(--secondary-axes-color) calc( 100% / 7), transparent calc( 100% / 7 + var( --secondary-axes-width )), transparent calc( 100% / 7 + var( --secondary-axes-width )));
  z-index: 2;
}

.charts-css.radial.show-7-secondary-axes tbody::after, .charts-css.pie.show-7-secondary-axes tbody::after, .charts-css.polar.show-7-secondary-axes tbody::after, .charts-css.radar.show-7-secondary-axes tbody::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(closest-side, transparent 0, transparent calc( 100% / 8 - var( --secondary-axes-width )), var(--secondary-axes-color) calc( 100% / 8 - var( --secondary-axes-width )), var(--secondary-axes-color) calc( 100% / 8), transparent calc( 100% / 8 + var( --secondary-axes-width )), transparent calc( 100% / 8 + var( --secondary-axes-width )));
  z-index: 2;
}

.charts-css.radial.show-8-secondary-axes tbody::after, .charts-css.pie.show-8-secondary-axes tbody::after, .charts-css.polar.show-8-secondary-axes tbody::after, .charts-css.radar.show-8-secondary-axes tbody::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(closest-side, transparent 0, transparent calc( 100% / 9 - var( --secondary-axes-width )), var(--secondary-axes-color) calc( 100% / 9 - var( --secondary-axes-width )), var(--secondary-axes-color) calc( 100% / 9), transparent calc( 100% / 9 + var( --secondary-axes-width )), transparent calc( 100% / 9 + var( --secondary-axes-width )));
  z-index: 2;
}

.charts-css.radial.show-9-secondary-axes tbody::after, .charts-css.pie.show-9-secondary-axes tbody::after, .charts-css.polar.show-9-secondary-axes tbody::after, .charts-css.radar.show-9-secondary-axes tbody::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(closest-side, transparent 0, transparent calc( 100% / 10 - var( --secondary-axes-width )), var(--secondary-axes-color) calc( 100% / 10 - var( --secondary-axes-width )), var(--secondary-axes-color) calc( 100% / 10), transparent calc( 100% / 10 + var( --secondary-axes-width )), transparent calc( 100% / 10 + var( --secondary-axes-width )));
  z-index: 2;
}

.charts-css.radial.show-10-secondary-axes tbody::after, .charts-css.pie.show-10-secondary-axes tbody::after, .charts-css.polar.show-10-secondary-axes tbody::after, .charts-css.radar.show-10-secondary-axes tbody::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-radial-gradient(closest-side, transparent 0, transparent calc( 100% / 11 - var( --secondary-axes-width )), var(--secondary-axes-color) calc( 100% / 11 - var( --secondary-axes-width )), var(--secondary-axes-color) calc( 100% / 11), transparent calc( 100% / 11 + var( --secondary-axes-width )), transparent calc( 100% / 11 + var( --secondary-axes-width )));
  z-index: 2;
}

/*
 * Chart legend
 */
 .charts-css.legend {
  padding: 1rem;
  border: var(--legend-border-width) var(--legend-border-style) var(--legend-border-color);
  list-style: none;
  font-size: 1rem;
}

.charts-css.legend li {
  line-height: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.charts-css.legend li::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  -webkit-margin-end: .5rem;
          margin-inline-end: .5rem;
  border-width: 2px;
  border-style: solid;
}

.charts-css.legend li:nth-child(1)::before {
  background-color: var(--color-1, transparent);
  border-color: var(--border-color-1, var(--border-color, #000));
}

.charts-css.legend li:nth-child(2)::before {
  background-color: var(--color-2, transparent);
  border-color: var(--border-color-2, var(--border-color, #000));
}

.charts-css.legend li:nth-child(3)::before {
  background-color: var(--color-3, transparent);
  border-color: var(--border-color-3, var(--border-color, #000));
}

.charts-css.legend li:nth-child(4)::before {
  background-color: var(--color-4, transparent);
  border-color: var(--border-color-4, var(--border-color, #000));
}

.charts-css.legend li:nth-child(5)::before {
  background-color: var(--color-5, transparent);
  border-color: var(--border-color-5, var(--border-color, #000));
}

.charts-css.legend li:nth-child(6)::before {
  background-color: var(--color-6, transparent);
  border-color: var(--border-color-6, var(--border-color, #000));
}

.charts-css.legend li:nth-child(7)::before {
  background-color: var(--color-7, transparent);
  border-color: var(--border-color-7, var(--border-color, #000));
}

.charts-css.legend li:nth-child(8)::before {
  background-color: var(--color-8, transparent);
  border-color: var(--border-color-8, var(--border-color, #000));
}

.charts-css.legend li:nth-child(9)::before {
  background-color: var(--color-9, transparent);
  border-color: var(--border-color-9, var(--border-color, #000));
}

.charts-css.legend li:nth-child(10)::before {
  background-color: var(--color-10, transparent);
  border-color: var(--border-color-10, var(--border-color, #000));
}

.charts-css:not(.legend-inline) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.charts-css.legend-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.charts-css.legend-inline li {
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
}

.charts-css.legend-circle li::before {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

.charts-css.legend-ellipse li::before {
  width: 2rem;
  height: 1rem;
  border-radius: 50%;
}

.charts-css.legend-square li::before {
  width: 1rem;
  height: 1rem;
  border-radius: 3px;
}

.charts-css.legend-rhombus li::before {
  width: 1rem;
  height: 1rem;
  border-radius: 3px;
  -webkit-transform: rotate(45deg) scale(0.85);
          transform: rotate(45deg) scale(0.85);
}

.charts-css.legend-rectangle li::before {
  width: 2rem;
  height: 1rem;
  border-radius: 3px;
}

.charts-css.legend-line li::before {
  width: 2rem;
  height: 3px;
  border-radius: 2px;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}

/*
 * Chart tooltips
 */
 .charts-css .tooltip {
  position: absolute;
  z-index: 1;
  bottom: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 5px 10px;
  border-radius: 6px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  background-color: #555;
  color: #fff;
  text-align: center;
  font-size: .9rem;
}

.charts-css .tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent;
}

.charts-css td:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/*
 * Bar Chart
 */
.charts-css.bar tbody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  width: 100%;
  height: calc(100% - var(--heading-size));
}

.charts-css.bar tbody tr {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  overflow-wrap: anywhere;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.charts-css.bar tbody tr th {
  position: absolute;
  top: 0;
  bottom: 0;
}

.charts-css.bar tbody tr td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: calc(100% * var(--end, var(--size, 1)));
  height: 100%;
  position: relative;
}

.charts-css.bar:not(.reverse) tbody tr {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-margin-start: var(--labels-size);
          margin-inline-start: var(--labels-size);
}

.charts-css.bar:not(.reverse) tbody tr th {
  left: calc(-1 * var(--labels-size) - var(--primary-axis-width));
  width: var(--labels-size);
}

.charts-css.bar:not(.reverse) tbody tr td {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.charts-css.bar.reverse tbody tr {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-margin-end: var(--labels-size);
          margin-inline-end: var(--labels-size);
}

.charts-css.bar.reverse tbody tr th {
  right: calc(-1 * var(--labels-size) - var(--primary-axis-width));
  width: var(--labels-size);
}

.charts-css.bar.reverse tbody tr td {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.charts-css.bar:not(.stacked) tbody tr td {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
}

.charts-css.bar.stacked tbody tr td {
  -webkit-box-flex: unset;
      -ms-flex-positive: unset;
          flex-grow: unset;
  -ms-flex-negative: unset;
      flex-shrink: unset;
  -ms-flex-preferred-size: unset;
      flex-basis: unset;
}

.charts-css.bar.stacked.reverse-datasets tbody tr {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.charts-css.bar:not(.reverse-data) tbody {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.charts-css.bar.reverse-data tbody {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.charts-css.bar:not(.reverse-datasets):not(.stacked) tbody tr {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.charts-css.bar:not(.reverse-datasets).stacked:not(.reverse) tbody tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.charts-css.bar:not(.reverse-datasets).stacked.reverse tbody tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.charts-css.bar.reverse-datasets:not(.stacked) tbody tr {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.charts-css.bar.reverse-datasets.stacked:not(.reverse) tbody tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.charts-css.bar.reverse-datasets.stacked.reverse tbody tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.charts-css.bar.data-spacing-1 tbody tr {
  -webkit-padding-before: 1px;
          padding-block-start: 1px;
  -webkit-padding-after: 1px;
          padding-block-end: 1px;
}

.charts-css.bar.data-spacing-2 tbody tr {
  -webkit-padding-before: 2px;
          padding-block-start: 2px;
  -webkit-padding-after: 2px;
          padding-block-end: 2px;
}

.charts-css.bar.data-spacing-3 tbody tr {
  -webkit-padding-before: 3px;
          padding-block-start: 3px;
  -webkit-padding-after: 3px;
          padding-block-end: 3px;
}

.charts-css.bar.data-spacing-4 tbody tr {
  -webkit-padding-before: 4px;
          padding-block-start: 4px;
  -webkit-padding-after: 4px;
          padding-block-end: 4px;
}

.charts-css.bar.data-spacing-5 tbody tr {
  -webkit-padding-before: 5px;
          padding-block-start: 5px;
  -webkit-padding-after: 5px;
          padding-block-end: 5px;
}

.charts-css.bar.data-spacing-6 tbody tr {
  -webkit-padding-before: 6px;
          padding-block-start: 6px;
  -webkit-padding-after: 6px;
          padding-block-end: 6px;
}

.charts-css.bar.data-spacing-7 tbody tr {
  -webkit-padding-before: 7px;
          padding-block-start: 7px;
  -webkit-padding-after: 7px;
          padding-block-end: 7px;
}

.charts-css.bar.data-spacing-8 tbody tr {
  -webkit-padding-before: 8px;
          padding-block-start: 8px;
  -webkit-padding-after: 8px;
          padding-block-end: 8px;
}

.charts-css.bar.data-spacing-9 tbody tr {
  -webkit-padding-before: 9px;
          padding-block-start: 9px;
  -webkit-padding-after: 9px;
          padding-block-end: 9px;
}

.charts-css.bar.data-spacing-10 tbody tr {
  -webkit-padding-before: 10px;
          padding-block-start: 10px;
  -webkit-padding-after: 10px;
          padding-block-end: 10px;
}

.charts-css.bar.data-spacing-11 tbody tr {
  -webkit-padding-before: 11px;
          padding-block-start: 11px;
  -webkit-padding-after: 11px;
          padding-block-end: 11px;
}

.charts-css.bar.data-spacing-12 tbody tr {
  -webkit-padding-before: 12px;
          padding-block-start: 12px;
  -webkit-padding-after: 12px;
          padding-block-end: 12px;
}

.charts-css.bar.data-spacing-13 tbody tr {
  -webkit-padding-before: 13px;
          padding-block-start: 13px;
  -webkit-padding-after: 13px;
          padding-block-end: 13px;
}

.charts-css.bar.data-spacing-14 tbody tr {
  -webkit-padding-before: 14px;
          padding-block-start: 14px;
  -webkit-padding-after: 14px;
          padding-block-end: 14px;
}

.charts-css.bar.data-spacing-15 tbody tr {
  -webkit-padding-before: 15px;
          padding-block-start: 15px;
  -webkit-padding-after: 15px;
          padding-block-end: 15px;
}

.charts-css.bar.data-spacing-16 tbody tr {
  -webkit-padding-before: 16px;
          padding-block-start: 16px;
  -webkit-padding-after: 16px;
          padding-block-end: 16px;
}

.charts-css.bar.data-spacing-17 tbody tr {
  -webkit-padding-before: 17px;
          padding-block-start: 17px;
  -webkit-padding-after: 17px;
          padding-block-end: 17px;
}

.charts-css.bar.data-spacing-18 tbody tr {
  -webkit-padding-before: 18px;
          padding-block-start: 18px;
  -webkit-padding-after: 18px;
          padding-block-end: 18px;
}

.charts-css.bar.data-spacing-19 tbody tr {
  -webkit-padding-before: 19px;
          padding-block-start: 19px;
  -webkit-padding-after: 19px;
          padding-block-end: 19px;
}

.charts-css.bar.data-spacing-20 tbody tr {
  -webkit-padding-before: 20px;
          padding-block-start: 20px;
  -webkit-padding-after: 20px;
          padding-block-end: 20px;
}

.charts-css.bar.datasets-spacing-1 tbody tr td {
  -webkit-margin-before: 1px;
          margin-block-start: 1px;
  -webkit-margin-after: 1px;
          margin-block-end: 1px;
}

.charts-css.bar.datasets-spacing-2 tbody tr td {
  -webkit-margin-before: 2px;
          margin-block-start: 2px;
  -webkit-margin-after: 2px;
          margin-block-end: 2px;
}

.charts-css.bar.datasets-spacing-3 tbody tr td {
  -webkit-margin-before: 3px;
          margin-block-start: 3px;
  -webkit-margin-after: 3px;
          margin-block-end: 3px;
}

.charts-css.bar.datasets-spacing-4 tbody tr td {
  -webkit-margin-before: 4px;
          margin-block-start: 4px;
  -webkit-margin-after: 4px;
          margin-block-end: 4px;
}

.charts-css.bar.datasets-spacing-5 tbody tr td {
  -webkit-margin-before: 5px;
          margin-block-start: 5px;
  -webkit-margin-after: 5px;
          margin-block-end: 5px;
}

.charts-css.bar.datasets-spacing-6 tbody tr td {
  -webkit-margin-before: 6px;
          margin-block-start: 6px;
  -webkit-margin-after: 6px;
          margin-block-end: 6px;
}

.charts-css.bar.datasets-spacing-7 tbody tr td {
  -webkit-margin-before: 7px;
          margin-block-start: 7px;
  -webkit-margin-after: 7px;
          margin-block-end: 7px;
}

.charts-css.bar.datasets-spacing-8 tbody tr td {
  -webkit-margin-before: 8px;
          margin-block-start: 8px;
  -webkit-margin-after: 8px;
          margin-block-end: 8px;
}

.charts-css.bar.datasets-spacing-9 tbody tr td {
  -webkit-margin-before: 9px;
          margin-block-start: 9px;
  -webkit-margin-after: 9px;
          margin-block-end: 9px;
}

.charts-css.bar.datasets-spacing-10 tbody tr td {
  -webkit-margin-before: 10px;
          margin-block-start: 10px;
  -webkit-margin-after: 10px;
          margin-block-end: 10px;
}

.charts-css.bar.datasets-spacing-11 tbody tr td {
  -webkit-margin-before: 11px;
          margin-block-start: 11px;
  -webkit-margin-after: 11px;
          margin-block-end: 11px;
}

.charts-css.bar.datasets-spacing-12 tbody tr td {
  -webkit-margin-before: 12px;
          margin-block-start: 12px;
  -webkit-margin-after: 12px;
          margin-block-end: 12px;
}

.charts-css.bar.datasets-spacing-13 tbody tr td {
  -webkit-margin-before: 13px;
          margin-block-start: 13px;
  -webkit-margin-after: 13px;
          margin-block-end: 13px;
}

.charts-css.bar.datasets-spacing-14 tbody tr td {
  -webkit-margin-before: 14px;
          margin-block-start: 14px;
  -webkit-margin-after: 14px;
          margin-block-end: 14px;
}

.charts-css.bar.datasets-spacing-15 tbody tr td {
  -webkit-margin-before: 15px;
          margin-block-start: 15px;
  -webkit-margin-after: 15px;
          margin-block-end: 15px;
}

.charts-css.bar.datasets-spacing-16 tbody tr td {
  -webkit-margin-before: 16px;
          margin-block-start: 16px;
  -webkit-margin-after: 16px;
          margin-block-end: 16px;
}

.charts-css.bar.datasets-spacing-17 tbody tr td {
  -webkit-margin-before: 17px;
          margin-block-start: 17px;
  -webkit-margin-after: 17px;
          margin-block-end: 17px;
}

.charts-css.bar.datasets-spacing-18 tbody tr td {
  -webkit-margin-before: 18px;
          margin-block-start: 18px;
  -webkit-margin-after: 18px;
          margin-block-end: 18px;
}

.charts-css.bar.datasets-spacing-19 tbody tr td {
  -webkit-margin-before: 19px;
          margin-block-start: 19px;
  -webkit-margin-after: 19px;
          margin-block-end: 19px;
}

.charts-css.bar.datasets-spacing-20 tbody tr td {
  -webkit-margin-before: 20px;
          margin-block-start: 20px;
  -webkit-margin-after: 20px;
          margin-block-end: 20px;
}

/*
 * Column Chart
 */
 .charts-css.column tbody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  width: 100%;
  height: calc(100% - var(--heading-size));
}

.charts-css.column tbody tr {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  overflow-wrap: anywhere;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.charts-css.column tbody tr th {
  position: absolute;
  right: 0;
  left: 0;
}

.charts-css.column tbody tr td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: calc(100% * var(--end, var(--size, 1)));
  position: relative;
}

.charts-css.column:not(.reverse) tbody tr {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-margin-after: var(--labels-size);
          margin-block-end: var(--labels-size);
}

.charts-css.column:not(.reverse) tbody tr th {
  bottom: calc(-1 * var(--labels-size) - var(--primary-axis-width));
  height: var(--labels-size);
}

.charts-css.column:not(.reverse) tbody tr td {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.charts-css.column.reverse tbody tr {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-margin-before: var(--labels-size);
          margin-block-start: var(--labels-size);
}

.charts-css.column.reverse tbody tr th {
  top: calc(-1 * var(--labels-size) - var(--primary-axis-width));
  height: var(--labels-size);
}

.charts-css.column.reverse tbody tr td {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.charts-css.column:not(.stacked) tbody tr td {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
}

.charts-css.column.stacked tbody tr td {
  -webkit-box-flex: unset;
      -ms-flex-positive: unset;
          flex-grow: unset;
  -ms-flex-negative: unset;
      flex-shrink: unset;
  -ms-flex-preferred-size: unset;
      flex-basis: unset;
}

.charts-css.column.stacked.reverse-datasets tbody tr {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.charts-css.column:not(.reverse-data) tbody {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.charts-css.column.reverse-data tbody {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.charts-css.column:not(.reverse-datasets):not(.stacked) tbody tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.charts-css.column:not(.reverse-datasets).stacked:not(.reverse) tbody tr {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.charts-css.column:not(.reverse-datasets).stacked.reverse tbody tr {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.charts-css.column.reverse-datasets:not(.stacked) tbody tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.charts-css.column.reverse-datasets.stacked:not(.reverse) tbody tr {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.charts-css.column.reverse-datasets.stacked.reverse tbody tr {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.charts-css.column.data-spacing-1 tbody tr {
  -webkit-padding-start: 1px;
          padding-inline-start: 1px;
  -webkit-padding-end: 1px;
          padding-inline-end: 1px;
}

.charts-css.column.data-spacing-2 tbody tr {
  -webkit-padding-start: 2px;
          padding-inline-start: 2px;
  -webkit-padding-end: 2px;
          padding-inline-end: 2px;
}

.charts-css.column.data-spacing-3 tbody tr {
  -webkit-padding-start: 3px;
          padding-inline-start: 3px;
  -webkit-padding-end: 3px;
          padding-inline-end: 3px;
}

.charts-css.column.data-spacing-4 tbody tr {
  -webkit-padding-start: 4px;
          padding-inline-start: 4px;
  -webkit-padding-end: 4px;
          padding-inline-end: 4px;
}

.charts-css.column.data-spacing-5 tbody tr {
  -webkit-padding-start: 5px;
          padding-inline-start: 5px;
  -webkit-padding-end: 5px;
          padding-inline-end: 5px;
}

.charts-css.column.data-spacing-6 tbody tr {
  -webkit-padding-start: 6px;
          padding-inline-start: 6px;
  -webkit-padding-end: 6px;
          padding-inline-end: 6px;
}

.charts-css.column.data-spacing-7 tbody tr {
  -webkit-padding-start: 7px;
          padding-inline-start: 7px;
  -webkit-padding-end: 7px;
          padding-inline-end: 7px;
}

.charts-css.column.data-spacing-8 tbody tr {
  -webkit-padding-start: 8px;
          padding-inline-start: 8px;
  -webkit-padding-end: 8px;
          padding-inline-end: 8px;
}

.charts-css.column.data-spacing-9 tbody tr {
  -webkit-padding-start: 9px;
          padding-inline-start: 9px;
  -webkit-padding-end: 9px;
          padding-inline-end: 9px;
}

.charts-css.column.data-spacing-10 tbody tr {
  -webkit-padding-start: 10px;
          padding-inline-start: 10px;
  -webkit-padding-end: 10px;
          padding-inline-end: 10px;
}

.charts-css.column.data-spacing-11 tbody tr {
  -webkit-padding-start: 11px;
          padding-inline-start: 11px;
  -webkit-padding-end: 11px;
          padding-inline-end: 11px;
}

.charts-css.column.data-spacing-12 tbody tr {
  -webkit-padding-start: 12px;
          padding-inline-start: 12px;
  -webkit-padding-end: 12px;
          padding-inline-end: 12px;
}

.charts-css.column.data-spacing-13 tbody tr {
  -webkit-padding-start: 13px;
          padding-inline-start: 13px;
  -webkit-padding-end: 13px;
          padding-inline-end: 13px;
}

.charts-css.column.data-spacing-14 tbody tr {
  -webkit-padding-start: 14px;
          padding-inline-start: 14px;
  -webkit-padding-end: 14px;
          padding-inline-end: 14px;
}

.charts-css.column.data-spacing-15 tbody tr {
  -webkit-padding-start: 15px;
          padding-inline-start: 15px;
  -webkit-padding-end: 15px;
          padding-inline-end: 15px;
}

.charts-css.column.data-spacing-16 tbody tr {
  -webkit-padding-start: 16px;
          padding-inline-start: 16px;
  -webkit-padding-end: 16px;
          padding-inline-end: 16px;
}

.charts-css.column.data-spacing-17 tbody tr {
  -webkit-padding-start: 17px;
          padding-inline-start: 17px;
  -webkit-padding-end: 17px;
          padding-inline-end: 17px;
}

.charts-css.column.data-spacing-18 tbody tr {
  -webkit-padding-start: 18px;
          padding-inline-start: 18px;
  -webkit-padding-end: 18px;
          padding-inline-end: 18px;
}

.charts-css.column.data-spacing-19 tbody tr {
  -webkit-padding-start: 19px;
          padding-inline-start: 19px;
  -webkit-padding-end: 19px;
          padding-inline-end: 19px;
}

.charts-css.column.data-spacing-20 tbody tr {
  -webkit-padding-start: 20px;
          padding-inline-start: 20px;
  -webkit-padding-end: 20px;
          padding-inline-end: 20px;
}

.charts-css.column.datasets-spacing-1 tbody tr td {
  -webkit-margin-start: 1px;
          margin-inline-start: 1px;
  -webkit-margin-end: 1px;
          margin-inline-end: 1px;
}

.charts-css.column.datasets-spacing-2 tbody tr td {
  -webkit-margin-start: 2px;
          margin-inline-start: 2px;
  -webkit-margin-end: 2px;
          margin-inline-end: 2px;
}

.charts-css.column.datasets-spacing-3 tbody tr td {
  -webkit-margin-start: 3px;
          margin-inline-start: 3px;
  -webkit-margin-end: 3px;
          margin-inline-end: 3px;
}

.charts-css.column.datasets-spacing-4 tbody tr td {
  -webkit-margin-start: 4px;
          margin-inline-start: 4px;
  -webkit-margin-end: 4px;
          margin-inline-end: 4px;
}

.charts-css.column.datasets-spacing-5 tbody tr td {
  -webkit-margin-start: 5px;
          margin-inline-start: 5px;
  -webkit-margin-end: 5px;
          margin-inline-end: 5px;
}

.charts-css.column.datasets-spacing-6 tbody tr td {
  -webkit-margin-start: 6px;
          margin-inline-start: 6px;
  -webkit-margin-end: 6px;
          margin-inline-end: 6px;
}

.charts-css.column.datasets-spacing-7 tbody tr td {
  -webkit-margin-start: 7px;
          margin-inline-start: 7px;
  -webkit-margin-end: 7px;
          margin-inline-end: 7px;
}

.charts-css.column.datasets-spacing-8 tbody tr td {
  -webkit-margin-start: 8px;
          margin-inline-start: 8px;
  -webkit-margin-end: 8px;
          margin-inline-end: 8px;
}

.charts-css.column.datasets-spacing-9 tbody tr td {
  -webkit-margin-start: 9px;
          margin-inline-start: 9px;
  -webkit-margin-end: 9px;
          margin-inline-end: 9px;
}

.charts-css.column.datasets-spacing-10 tbody tr td {
  -webkit-margin-start: 10px;
          margin-inline-start: 10px;
  -webkit-margin-end: 10px;
          margin-inline-end: 10px;
}

.charts-css.column.datasets-spacing-11 tbody tr td {
  -webkit-margin-start: 11px;
          margin-inline-start: 11px;
  -webkit-margin-end: 11px;
          margin-inline-end: 11px;
}

.charts-css.column.datasets-spacing-12 tbody tr td {
  -webkit-margin-start: 12px;
          margin-inline-start: 12px;
  -webkit-margin-end: 12px;
          margin-inline-end: 12px;
}

.charts-css.column.datasets-spacing-13 tbody tr td {
  -webkit-margin-start: 13px;
          margin-inline-start: 13px;
  -webkit-margin-end: 13px;
          margin-inline-end: 13px;
}

.charts-css.column.datasets-spacing-14 tbody tr td {
  -webkit-margin-start: 14px;
          margin-inline-start: 14px;
  -webkit-margin-end: 14px;
          margin-inline-end: 14px;
}

.charts-css.column.datasets-spacing-15 tbody tr td {
  -webkit-margin-start: 15px;
          margin-inline-start: 15px;
  -webkit-margin-end: 15px;
          margin-inline-end: 15px;
}

.charts-css.column.datasets-spacing-16 tbody tr td {
  -webkit-margin-start: 16px;
          margin-inline-start: 16px;
  -webkit-margin-end: 16px;
          margin-inline-end: 16px;
}

.charts-css.column.datasets-spacing-17 tbody tr td {
  -webkit-margin-start: 17px;
          margin-inline-start: 17px;
  -webkit-margin-end: 17px;
          margin-inline-end: 17px;
}

.charts-css.column.datasets-spacing-18 tbody tr td {
  -webkit-margin-start: 18px;
          margin-inline-start: 18px;
  -webkit-margin-end: 18px;
          margin-inline-end: 18px;
}

.charts-css.column.datasets-spacing-19 tbody tr td {
  -webkit-margin-start: 19px;
          margin-inline-start: 19px;
  -webkit-margin-end: 19px;
          margin-inline-end: 19px;
}

.charts-css.column.datasets-spacing-20 tbody tr td {
  -webkit-margin-start: 20px;
          margin-inline-start: 20px;
  -webkit-margin-end: 20px;
          margin-inline-end: 20px;
}

/*
 * Area Chart
 */
 .charts-css.area tbody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  width: 100%;
  height: calc(100% - var(--heading-size));
}

.charts-css.area tbody tr {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  overflow-wrap: anywhere;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.charts-css.area tbody tr th {
  position: absolute;
  right: 0;
  left: 0;
}

.charts-css.area tbody tr td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.charts-css.area tbody tr td::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.charts-css.area tbody tr td::after {
  content: "";
  width: 100%;
}

.charts-css.area:not(.reverse) tbody tr {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-margin-after: var(--labels-size);
          margin-block-end: var(--labels-size);
}

.charts-css.area:not(.reverse) tbody tr th {
  bottom: calc(-1 * var(--labels-size) - var(--primary-axis-width));
  height: var(--labels-size);
}

.charts-css.area:not(.reverse) tbody tr td {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.charts-css.area.reverse tbody tr {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-margin-before: var(--labels-size);
          margin-block-start: var(--labels-size);
}

.charts-css.area.reverse tbody tr th {
  top: calc(-1 * var(--labels-size) - var(--primary-axis-width));
  height: var(--labels-size);
}

.charts-css.area.reverse tbody tr td {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.charts-css.area:not(.reverse-data) tbody {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.charts-css.area.reverse-data tbody {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.charts-css.area:not(.reverse-datasets) tbody tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.charts-css.area.reverse-datasets tbody tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.charts-css.area:not(.reverse):not(.reverse-data) tbody tr td {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.charts-css.area:not(.reverse):not(.reverse-data) tbody tr td::before {
  -webkit-clip-path: polygon(0% calc(100% * (1 - var(--start, var(--end, var(--size))))), 100% calc(100% * (1 - var(--end, var(--size)))), 100% 100%, 0% 100%);
          clip-path: polygon(0% calc(100% * (1 - var(--start, var(--end, var(--size))))), 100% calc(100% * (1 - var(--end, var(--size)))), 100% 100%, 0% 100%);
}

.charts-css.area:not(.reverse):not(.reverse-data) tbody tr td .data {
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}

.charts-css.area:not(.reverse):not(.reverse-data) tbody tr td::after {
  height: calc(100% * var(--end, var(--size)));
}

.charts-css.area:not(.reverse).reverse-data tbody tr td {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.charts-css.area:not(.reverse).reverse-data tbody tr td::before {
  -webkit-clip-path: polygon(0% calc(100% * (1 - var(--end, var(--size)))), 100% calc(100% * (1 - var(--start, var(--end, var(--size))))), 100% 100%, 0% 100%);
          clip-path: polygon(0% calc(100% * (1 - var(--end, var(--size)))), 100% calc(100% * (1 - var(--start, var(--end, var(--size))))), 100% 100%, 0% 100%);
}

.charts-css.area:not(.reverse).reverse-data tbody tr td .data {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.charts-css.area:not(.reverse).reverse-data tbody tr td::after {
  height: calc(100% * var(--end, var(--size)));
}

.charts-css.area.reverse:not(.reverse-data) tbody tr td {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.charts-css.area.reverse:not(.reverse-data) tbody tr td::before {
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% * var(--end, var(--size))), 0% calc(100% * var(--start, var(--end, var(--size)))));
          clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% * var(--end, var(--size))), 0% calc(100% * var(--start, var(--end, var(--size)))));
}

.charts-css.area.reverse:not(.reverse-data) tbody tr td .data {
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}

// .charts-css.area.reverse:not(.reverse-data) tbody tr td::after {
//   height: calc(100% * (1 -var(--end, var(--size))));
// }

.charts-css.area.reverse.reverse-data tbody tr td {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.charts-css.area.reverse.reverse-data tbody tr td::before {
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% * var(--start, var(--end, var(--size)))), 0% calc(100% * var(--end, var(--size))));
          clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% * var(--start, var(--end, var(--size)))), 0% calc(100% * var(--end, var(--size))));
}

.charts-css.area.reverse.reverse-data tbody tr td .data {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.charts-css.area.reverse.reverse-data tbody tr td::after {
  height: calc(100% * (1 - var(--end, var(--size))));
}

.charts-css.area.data-spacing-1 tbody tr td::before, .charts-css.area.datasets-spacing-1 tbody tr td::before {
  -webkit-margin-start: 1px;
          margin-inline-start: 1px;
  -webkit-margin-end: 1px;
          margin-inline-end: 1px;
}

.charts-css.area.data-spacing-2 tbody tr td::before, .charts-css.area.datasets-spacing-2 tbody tr td::before {
  -webkit-margin-start: 2px;
          margin-inline-start: 2px;
  -webkit-margin-end: 2px;
          margin-inline-end: 2px;
}

.charts-css.area.data-spacing-3 tbody tr td::before, .charts-css.area.datasets-spacing-3 tbody tr td::before {
  -webkit-margin-start: 3px;
          margin-inline-start: 3px;
  -webkit-margin-end: 3px;
          margin-inline-end: 3px;
}

.charts-css.area.data-spacing-4 tbody tr td::before, .charts-css.area.datasets-spacing-4 tbody tr td::before {
  -webkit-margin-start: 4px;
          margin-inline-start: 4px;
  -webkit-margin-end: 4px;
          margin-inline-end: 4px;
}

.charts-css.area.data-spacing-5 tbody tr td::before, .charts-css.area.datasets-spacing-5 tbody tr td::before {
  -webkit-margin-start: 5px;
          margin-inline-start: 5px;
  -webkit-margin-end: 5px;
          margin-inline-end: 5px;
}

.charts-css.area.data-spacing-6 tbody tr td::before, .charts-css.area.datasets-spacing-6 tbody tr td::before {
  -webkit-margin-start: 6px;
          margin-inline-start: 6px;
  -webkit-margin-end: 6px;
          margin-inline-end: 6px;
}

.charts-css.area.data-spacing-7 tbody tr td::before, .charts-css.area.datasets-spacing-7 tbody tr td::before {
  -webkit-margin-start: 7px;
          margin-inline-start: 7px;
  -webkit-margin-end: 7px;
          margin-inline-end: 7px;
}

.charts-css.area.data-spacing-8 tbody tr td::before, .charts-css.area.datasets-spacing-8 tbody tr td::before {
  -webkit-margin-start: 8px;
          margin-inline-start: 8px;
  -webkit-margin-end: 8px;
          margin-inline-end: 8px;
}

.charts-css.area.data-spacing-9 tbody tr td::before, .charts-css.area.datasets-spacing-9 tbody tr td::before {
  -webkit-margin-start: 9px;
          margin-inline-start: 9px;
  -webkit-margin-end: 9px;
          margin-inline-end: 9px;
}

.charts-css.area.data-spacing-10 tbody tr td::before, .charts-css.area.datasets-spacing-10 tbody tr td::before {
  -webkit-margin-start: 10px;
          margin-inline-start: 10px;
  -webkit-margin-end: 10px;
          margin-inline-end: 10px;
}

.charts-css.area.data-spacing-11 tbody tr td::before, .charts-css.area.datasets-spacing-11 tbody tr td::before {
  -webkit-margin-start: 11px;
          margin-inline-start: 11px;
  -webkit-margin-end: 11px;
          margin-inline-end: 11px;
}

.charts-css.area.data-spacing-12 tbody tr td::before, .charts-css.area.datasets-spacing-12 tbody tr td::before {
  -webkit-margin-start: 12px;
          margin-inline-start: 12px;
  -webkit-margin-end: 12px;
          margin-inline-end: 12px;
}

.charts-css.area.data-spacing-13 tbody tr td::before, .charts-css.area.datasets-spacing-13 tbody tr td::before {
  -webkit-margin-start: 13px;
          margin-inline-start: 13px;
  -webkit-margin-end: 13px;
          margin-inline-end: 13px;
}

.charts-css.area.data-spacing-14 tbody tr td::before, .charts-css.area.datasets-spacing-14 tbody tr td::before {
  -webkit-margin-start: 14px;
          margin-inline-start: 14px;
  -webkit-margin-end: 14px;
          margin-inline-end: 14px;
}

.charts-css.area.data-spacing-15 tbody tr td::before, .charts-css.area.datasets-spacing-15 tbody tr td::before {
  -webkit-margin-start: 15px;
          margin-inline-start: 15px;
  -webkit-margin-end: 15px;
          margin-inline-end: 15px;
}

.charts-css.area.data-spacing-16 tbody tr td::before, .charts-css.area.datasets-spacing-16 tbody tr td::before {
  -webkit-margin-start: 16px;
          margin-inline-start: 16px;
  -webkit-margin-end: 16px;
          margin-inline-end: 16px;
}

.charts-css.area.data-spacing-17 tbody tr td::before, .charts-css.area.datasets-spacing-17 tbody tr td::before {
  -webkit-margin-start: 17px;
          margin-inline-start: 17px;
  -webkit-margin-end: 17px;
          margin-inline-end: 17px;
}

.charts-css.area.data-spacing-18 tbody tr td::before, .charts-css.area.datasets-spacing-18 tbody tr td::before {
  -webkit-margin-start: 18px;
          margin-inline-start: 18px;
  -webkit-margin-end: 18px;
          margin-inline-end: 18px;
}

.charts-css.area.data-spacing-19 tbody tr td::before, .charts-css.area.datasets-spacing-19 tbody tr td::before {
  -webkit-margin-start: 19px;
          margin-inline-start: 19px;
  -webkit-margin-end: 19px;
          margin-inline-end: 19px;
}

.charts-css.area.data-spacing-20 tbody tr td::before, .charts-css.area.datasets-spacing-20 tbody tr td::before {
  -webkit-margin-start: 20px;
          margin-inline-start: 20px;
  -webkit-margin-end: 20px;
          margin-inline-end: 20px;
}

/*
 * Line Chart
 */
 .charts-css.line {
  --line-size: 3px;
}

.charts-css.line tbody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  width: 100%;
  height: calc(100% - var(--heading-size));
}

.charts-css.line tbody tr {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  overflow-wrap: anywhere;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.charts-css.line tbody tr th {
  position: absolute;
  right: 0;
  left: 0;
}

.charts-css.line tbody tr td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.charts-css.line tbody tr td::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.charts-css.line tbody tr td::after {
  content: "";
  width: 100%;
}

.charts-css.line:not(.reverse) tbody tr {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-margin-after: var(--labels-size);
          margin-block-end: var(--labels-size);
}

.charts-css.line:not(.reverse) tbody tr th {
  bottom: calc(-1 * var(--labels-size) - var(--primary-axis-width));
  height: var(--labels-size);
}

.charts-css.line:not(.reverse) tbody tr td {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.charts-css.line.reverse tbody tr {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-margin-before: var(--labels-size);
          margin-block-start: var(--labels-size);
}

.charts-css.line.reverse tbody tr th {
  top: calc(-1 * var(--labels-size) - var(--primary-axis-width));
  height: var(--labels-size);
}

.charts-css.line.reverse tbody tr td {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.charts-css.line:not(.reverse-data) tbody {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.charts-css.line.reverse-data tbody {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.charts-css.line:not(.reverse-datasets) tbody tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.charts-css.line.reverse-datasets tbody tr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.charts-css.line:not(.reverse):not(.reverse-data) tbody tr td {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.charts-css.line:not(.reverse):not(.reverse-data) tbody tr td::before {
  -webkit-clip-path: polygon(0% calc(100% * (1 - var(--start, var(--end, var(--size))))), 100% calc(100% * (1 - var(--end, var(--size)))), 100% calc(100% * (1 - var(--end, var(--size))) - var(--line-size)), 0% calc(100% * (1 - var(--start, var(--end, var(--size)))) - var(--line-size)));
          clip-path: polygon(0% calc(100% * (1 - var(--start, var(--end, var(--size))))), 100% calc(100% * (1 - var(--end, var(--size)))), 100% calc(100% * (1 - var(--end, var(--size))) - var(--line-size)), 0% calc(100% * (1 - var(--start, var(--end, var(--size)))) - var(--line-size)));
}

.charts-css.line:not(.reverse):not(.reverse-data) tbody tr td .data {
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}

.charts-css.line:not(.reverse):not(.reverse-data) tbody tr td::after {
  height: calc(100% * var(--end, var(--size)));
}

.charts-css.line:not(.reverse).reverse-data tbody tr td {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.charts-css.line:not(.reverse).reverse-data tbody tr td::before {
  -webkit-clip-path: polygon(0% calc(100% * (1 - var(--end, var(--size)))), 100% calc(100% * (1 - var(--start, var(--end, var(--size))))), 100% calc(100% * (1 - var(--start, var(--end, var(--size)))) - var(--line-size)), 0% calc(100% * (1 - var(--end, var(--size))) - var(--line-size)));
          clip-path: polygon(0% calc(100% * (1 - var(--end, var(--size)))), 100% calc(100% * (1 - var(--start, var(--end, var(--size))))), 100% calc(100% * (1 - var(--start, var(--end, var(--size)))) - var(--line-size)), 0% calc(100% * (1 - var(--end, var(--size))) - var(--line-size)));
}

.charts-css.line:not(.reverse).reverse-data tbody tr td .data {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.charts-css.line:not(.reverse).reverse-data tbody tr td::after {
  height: calc(100% * var(--end, var(--size)));
}

.charts-css.line.reverse:not(.reverse-data) tbody tr td {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.charts-css.line.reverse:not(.reverse-data) tbody tr td::before {
  -webkit-clip-path: polygon(0% calc(100% * var(--start, var(--end, var(--size))) - var(--line-size)), 100% calc(100% * var(--end, var(--size)) - var(--line-size)), 100% calc(100% * var(--end, var(--size))), 0% calc(100% * var(--start, var(--end, var(--size)))));
          clip-path: polygon(0% calc(100% * var(--start, var(--end, var(--size))) - var(--line-size)), 100% calc(100% * var(--end, var(--size)) - var(--line-size)), 100% calc(100% * var(--end, var(--size))), 0% calc(100% * var(--start, var(--end, var(--size)))));
}

.charts-css.line.reverse:not(.reverse-data) tbody tr td .data {
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}

// .charts-css.line.reverse:not(.reverse-data) tbody tr td::after {
//   height: calc(100% * (1 -var(--end, var(--size))));
// }

.charts-css.line.reverse.reverse-data tbody tr td {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.charts-css.line.reverse.reverse-data tbody tr td::before {
  -webkit-clip-path: polygon(0% calc(100% * var(--end, var(--size)) - var(--line-size)), 100% calc(100% * var(--start, var(--end, var(--size))) - var(--line-size)), 100% calc(100% * var(--start, var(--end, var(--size)))), 0% calc(100% * var(--end, var(--size))));
          clip-path: polygon(0% calc(100% * var(--end, var(--size)) - var(--line-size)), 100% calc(100% * var(--start, var(--end, var(--size))) - var(--line-size)), 100% calc(100% * var(--start, var(--end, var(--size)))), 0% calc(100% * var(--end, var(--size))));
}

.charts-css.line.reverse.reverse-data tbody tr td .data {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.charts-css.line.reverse.reverse-data tbody tr td::after {
  height: calc(100% * (1 - var(--end, var(--size))));
}

.charts-css.line.data-spacing-1 tbody tr td::before, .charts-css.line.datasets-spacing-1 tbody tr td::before {
  -webkit-margin-start: 1px;
          margin-inline-start: 1px;
  -webkit-margin-end: 1px;
          margin-inline-end: 1px;
}

.charts-css.line.data-spacing-2 tbody tr td::before, .charts-css.line.datasets-spacing-2 tbody tr td::before {
  -webkit-margin-start: 2px;
          margin-inline-start: 2px;
  -webkit-margin-end: 2px;
          margin-inline-end: 2px;
}

.charts-css.line.data-spacing-3 tbody tr td::before, .charts-css.line.datasets-spacing-3 tbody tr td::before {
  -webkit-margin-start: 3px;
          margin-inline-start: 3px;
  -webkit-margin-end: 3px;
          margin-inline-end: 3px;
}

.charts-css.line.data-spacing-4 tbody tr td::before, .charts-css.line.datasets-spacing-4 tbody tr td::before {
  -webkit-margin-start: 4px;
          margin-inline-start: 4px;
  -webkit-margin-end: 4px;
          margin-inline-end: 4px;
}

.charts-css.line.data-spacing-5 tbody tr td::before, .charts-css.line.datasets-spacing-5 tbody tr td::before {
  -webkit-margin-start: 5px;
          margin-inline-start: 5px;
  -webkit-margin-end: 5px;
          margin-inline-end: 5px;
}

.charts-css.line.data-spacing-6 tbody tr td::before, .charts-css.line.datasets-spacing-6 tbody tr td::before {
  -webkit-margin-start: 6px;
          margin-inline-start: 6px;
  -webkit-margin-end: 6px;
          margin-inline-end: 6px;
}

.charts-css.line.data-spacing-7 tbody tr td::before, .charts-css.line.datasets-spacing-7 tbody tr td::before {
  -webkit-margin-start: 7px;
          margin-inline-start: 7px;
  -webkit-margin-end: 7px;
          margin-inline-end: 7px;
}

.charts-css.line.data-spacing-8 tbody tr td::before, .charts-css.line.datasets-spacing-8 tbody tr td::before {
  -webkit-margin-start: 8px;
          margin-inline-start: 8px;
  -webkit-margin-end: 8px;
          margin-inline-end: 8px;
}

.charts-css.line.data-spacing-9 tbody tr td::before, .charts-css.line.datasets-spacing-9 tbody tr td::before {
  -webkit-margin-start: 9px;
          margin-inline-start: 9px;
  -webkit-margin-end: 9px;
          margin-inline-end: 9px;
}

.charts-css.line.data-spacing-10 tbody tr td::before, .charts-css.line.datasets-spacing-10 tbody tr td::before {
  -webkit-margin-start: 10px;
          margin-inline-start: 10px;
  -webkit-margin-end: 10px;
          margin-inline-end: 10px;
}

.charts-css.line.data-spacing-11 tbody tr td::before, .charts-css.line.datasets-spacing-11 tbody tr td::before {
  -webkit-margin-start: 11px;
          margin-inline-start: 11px;
  -webkit-margin-end: 11px;
          margin-inline-end: 11px;
}

.charts-css.line.data-spacing-12 tbody tr td::before, .charts-css.line.datasets-spacing-12 tbody tr td::before {
  -webkit-margin-start: 12px;
          margin-inline-start: 12px;
  -webkit-margin-end: 12px;
          margin-inline-end: 12px;
}

.charts-css.line.data-spacing-13 tbody tr td::before, .charts-css.line.datasets-spacing-13 tbody tr td::before {
  -webkit-margin-start: 13px;
          margin-inline-start: 13px;
  -webkit-margin-end: 13px;
          margin-inline-end: 13px;
}

.charts-css.line.data-spacing-14 tbody tr td::before, .charts-css.line.datasets-spacing-14 tbody tr td::before {
  -webkit-margin-start: 14px;
          margin-inline-start: 14px;
  -webkit-margin-end: 14px;
          margin-inline-end: 14px;
}

.charts-css.line.data-spacing-15 tbody tr td::before, .charts-css.line.datasets-spacing-15 tbody tr td::before {
  -webkit-margin-start: 15px;
          margin-inline-start: 15px;
  -webkit-margin-end: 15px;
          margin-inline-end: 15px;
}

.charts-css.line.data-spacing-16 tbody tr td::before, .charts-css.line.datasets-spacing-16 tbody tr td::before {
  -webkit-margin-start: 16px;
          margin-inline-start: 16px;
  -webkit-margin-end: 16px;
          margin-inline-end: 16px;
}

.charts-css.line.data-spacing-17 tbody tr td::before, .charts-css.line.datasets-spacing-17 tbody tr td::before {
  -webkit-margin-start: 17px;
          margin-inline-start: 17px;
  -webkit-margin-end: 17px;
          margin-inline-end: 17px;
}

.charts-css.line.data-spacing-18 tbody tr td::before, .charts-css.line.datasets-spacing-18 tbody tr td::before {
  -webkit-margin-start: 18px;
          margin-inline-start: 18px;
  -webkit-margin-end: 18px;
          margin-inline-end: 18px;
}

.charts-css.line.data-spacing-19 tbody tr td::before, .charts-css.line.datasets-spacing-19 tbody tr td::before {
  -webkit-margin-start: 19px;
          margin-inline-start: 19px;
  -webkit-margin-end: 19px;
          margin-inline-end: 19px;
}

.charts-css.line.data-spacing-20 tbody tr td::before, .charts-css.line.datasets-spacing-20 tbody tr td::before {
  -webkit-margin-start: 20px;
          margin-inline-start: 20px;
  -webkit-margin-end: 20px;
          margin-inline-end: 20px;
}

/*
 * Radial Chart
 */
.charts-css.radial tbody {
  display: block;
  width: 100%;
  height: 0;
  -webkit-padding-after: 100%;
          padding-block-end: 100%;
  border-radius: 50%;
  background-color: var(--chart-bg-color);
}

.charts-css.radial tbody tr {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  white-space: nowrap;
}

/*
 * Pie Chart
 */
.charts-css.pie tbody {
  display: block;
  width: 100%;
  height: 0;
  -webkit-padding-after: 100%;
          padding-block-end: 100%;
  border-radius: 50%;
  background-color: var(--chart-bg-color);
}

.charts-css.pie tbody tr th {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  white-space: nowrap;
}

.charts-css.pie tbody tr td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(transparent 0 calc( 1turn * var( --start )), var(--c, transparent) calc( 1turn * var( --start, 0 )) calc( 1turn * var( --end )), transparent calc( 1turn * var( --end )) 1turn);
}

.charts-css.pie tbody tr td::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.charts-css.pie tbody tr td::after {
  content: "";
  width: 100%;
}

.charts-css.pie tbody tr td .data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(calc( 0.5turn * var( --start, 0 ) + 0.5turn * var( --end, 0 )));
          transform: rotate(calc( 0.5turn * var( --start, 0 ) + 0.5turn * var( --end, 0 )));
}

/*
 * Polar Chart
 */
.charts-css.polar tbody {
  display: block;
  width: 100%;
  height: 0;
  -webkit-padding-after: 100%;
          padding-block-end: 100%;
  border-radius: 50%;
  background-color: var(--chart-bg-color);
}

.charts-css.polar tbody tr {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  white-space: nowrap;
}

/*
 * Radar Chart
 */
.charts-css.radar tbody {
  display: block;
  width: 100%;
  height: 0;
  -webkit-padding-after: 100%;
          padding-block-end: 100%;
  border-radius: 50%;
  background-color: var(--chart-bg-color);
}

.charts-css.radar tbody tr {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  white-space: nowrap;
}