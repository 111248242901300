@use 'src/scss/inc/vars';

.promo-form-body {
	.frm_error_main {
		width: 100%;
	}

	.frm_error {
		font-family: "Roboto", sans-serif;
	    font-style: normal;
	    font-weight: normal;
	    font-size: 12px;
	    line-height: 16px;
	    letter-spacing: 0.4px;
	    color: #E02020;
	    bottom: -20px;
			padding: 16px 0;
	}

	.promo-type {
		input[type="text"] {
			margin-bottom: 26px;
			@media (vars.$sm) {
				max-width: 50%;
			}

			&.last-child {
				margin-bottom: 0;
			}
		}
	}

	.spend-action {
		position: relative;
		margin-top: 16px;

		input { max-width: 100%; margin-bottom: 0; cursor: pointer; }

		.spend-val {
			position: relative;
			width: 200px;
			.mdi {
				position: absolute;
				top: 10px;
				right: 10px;
				font-size: 20px;
				cursor: pointer;
			}
		}

		.min-spend-main {
			position: absolute;
	    top: 40px;
			width: 100%;
	    background: vars.$white;
	    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
	    border-radius: 5px;
	    padding: 5px 8px;
	    z-index: 8;
	    right: 0;

			label { margin-bottom: 0; }
		}

		.min-spend-main-note {
		    font-size: 12px;
		    padding: 8px 0 0 0;
		    position: absolute;
		}

		.min-spend {

			input {
				-webkit-appearance: none;
				display: none;
			}

			label {
				width: 100%;
				position: relative;
				float: left;
				padding: 8px;
				border-radius: 4px;

				&:hover {
					cursor: pointer;
					background: #f3f3f3;
				}
			}
		}
	}

	.form-row-title {
		width: 100%;
		font-weight: 500;
		font-size: 13px;
		line-height: 20px;
		letter-spacing: 0.25px;
		color: #060B0D;
		margin-bottom: 8px;
	}

	.mov {

		margin-bottom: 46px;

		.frm_error {
			bottom: -36px;
		}
	}

	.date-range {
		@include vars.margin();

		span { display: inline-block; margin: 0 16px 8px; }
		label {
			position: relative;
			display: inline-block;
			margin: 0 0 8px;

			&.moz {
				display: none;
				@media not all and (min-resolution:.001dpcm) {
				    display: inline-block;
				}
			}
			&.none-moz {
				display: inline-block;
				@media not all and (min-resolution:.001dpcm) {
				    display: none;
				}
			}
			.mdi {
				position: absolute;
				top: 8px;
				right: 8px;
				font-size: 20px;
				&:hover {
					cursor: pointer;
				}
			}
		}
	} 

	input:not(.mat-start-date, .mat-end-date, .promo-search-input) {
		background:none;
		padding: 7px 12px;
		border-radius: 10px;
		border: 1px solid #C6CACC;
		box-sizing: border-box;
		border-radius: 4px;
		font-size: 16px;
		line-height: 24px;
		margin: 0;
		letter-spacing: 0.44px;
		color: #181818;
	} 

	.day-group {
		display: flex;

		.day {
			margin: 0 8px 0 0;
			input {
				display: none;
				-webkit-appearance: none;
				opacity: 0;
			}

			input:checked + label {
				background: vars.$btnBlue;
				border: 1px solid vars.$btnBlue;
				color: vars.$black;
			}

			label {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 32px;
				height: 32px;
				border-radius: 100%;
				border: 1px solid #D9D9D9;
			}
		}
	}

	.time-group {
		label {
			max-width: 140px;
			input { max-width: 100%; margin: 0; }
		}
		button {
			border: 1px solid #C6CACC;
			box-sizing: border-box;
			border-radius: 4px;
			padding: 8px 12px;
			margin: 0 8px 8px 0;

			&.active {
				background: vars.$btnBlue;
				border-color: vars.$btnBlue;
				color: #fff;
			}
		}
	}

	.h6 {
		// display: flex;
		// justify-content: flex-end;
		// padding: 8px 12px 8px 26px;
		input {
			display: none;
			-webkit-appearance: none;
		}

		input:checked + label {
			background:#181818;
			&:before {
				opacity: 1;
			}
		}

		label {
			width: 20px;
			height: 20px;
			border-radius: 3px;
			border: 1px solid #181818;
			display: flex;
			position: relative;
			transition: ease all 0.2s;

			&:before {
				content: "";
				width: 5px;
				height: 13px;
				border-bottom: 1px solid #fff;
				border-right: 1px solid #fff;
				transform: rotate(45deg);
				opacity: 0;
				transition: ease all 0.2s;
				position: absolute;
				left: 7px;
				top: 0;
			}
		}
	}

	.restaurant {
		box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
		border-radius: 5px;
		align-items: center;
		padding: 8px 12px;
		margin-bottom: 8px;
    position: relative;
		.action {
			margin-left: auto;
      > article {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
			input {
				display: none;
				-webkit-appearance: none;
			}

			input:checked + label {
				background:#181818;
				&:before {
					opacity: 1;
				}
			}

		}
	}

	.terms-row {
		// border-bottom: 0;
		@include vars.margin();

		h5 {
			padding-bottom: 10px;
		}

		label {
			display: flex;
			align-items: center;
		}

		.terms-optin { margin-right: 24px; }

		a {
			color: vars.$linkBlue;
			font-style: normal;
			font-weight: normal;
			font-size: 13px;
			line-height: 20px;
			letter-spacing: 0.25px;
		}

		input {
			display: none;
			-webkit-appearance: none;
		}

		input:checked + span {
			background:#181818;
			&:before {
				opacity: 1;
			}
		}
		span {
			width: 20px;
			height: 20px;
			min-width: 20px;
			border-radius: 3px;
			margin: 0 10px 0 0;
			border: 1px solid #181818;
			display: flex;
			position: relative;
			transition: ease all 0.2s;
			//top: 5px;

			&:before {
				content: "";
				width: 5px;
				height: 13px;
				border-bottom: 1px solid #fff;
				border-right: 1px solid #fff;
				transform: rotate(45deg);
				opacity: 0;
				transition: ease all 0.2s;
				position: absolute;
				left: 7px;
				top: 0;
			}
		}
	}

	.disabled {
		color: vars.$greyMedium;
		input { color: #868A8C; background-color: #FAFAFA; border-color: #E0E4E6; pointer-events: none; }
	}
}
