.ios-select {
  .absolute {
    display: none;
  }
}
@supports (-webkit-touch-callout: none) {
	/* CSS specific to iOS devices */
	html.ios {
		position: fixed;
		height: 100%;
		overflow: hidden;
	}
	.ios body {
		width: 100vw;
		height: 100vh;
		overflow-y: scroll;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
	}
  .ios-select {
    border: none;
    .absolute {
      inset: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 8px;
      border-radius: 20px;
      .mdi {
        font-size: 1.25rem;
        display: flex;
        align-items: center;
      }
    }
    select {
      opacity: 0;
    }
  }
}
