@use "../inc/vars";

.select {
	position: relative;
	display: inline-block;
	text-align: left;
	cursor: pointer;
	@include vars.margin();
	
	.button {
		background-color: #F3F3F3;
		padding: 10px 48px 10px 16px;
		border-radius: 50px;
		@include vars.transitionPrefix(background-color, 300ms);

		&:hover { background-color: darken(#F3F3F3, 3%); }
		&:active { background-color: #F3F3F3; transition: none; }
	}

	.mdi {
		position: absolute;
		color: #78909C;
		top: 10px;
		right: 16px;
		display: inline-block;
		font-size: 1.3rem;
	}
	.button:focus + .options,
	.button:focus-within + .options {
		display: block;
	}

	&:after {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	&:focus-within:after { content: ''; }
}
.options {
	display: none;
	position: absolute;
	left: 0;
	min-width: 180px;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0px 2px 6px rgba(125, 146, 156, 0.12), 0px 1px 2px rgba(8, 35, 48, 0.1);
	overflow: hidden;
	z-index: 3;

	&:active { display: block; }

	a, label {
		width: 100%;
		display: block;
		color: inherit;
		padding: 12px 16px;
		cursor: pointer;
		&:hover {
			text-decoration: none;
			background-color: #F5F6F9;
		}
		&:active { background-color: darken(#F5F6F9, 3%); }
		&.active { background-color: #F5F6F9; border-left: 5px solid vars.$blue; padding-left: 8px; }
	}
}
.select-mf {
	position: relative;
	display: inline-block;
	text-align: left;
	cursor: pointer;
	.options {
		display: block;
	}
	.select {
		margin-bottom: 0;
	}
	input {
		float: right;
		display: inline-block !important;
		-webkit-appearance: checkbox !important;
	}
}
