@use 'src/scss/inc/vars';

// ####################
// GRID LAYOUT
// ####################

// DISPLAY/DIRECTION

.grid {
	display: grid;	
}

.grid-3-col-auto {
	grid-template-columns: auto auto auto;
	@media (vars.$max-md) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media (vars.$max-smm) {
		grid-template-columns: repeat(1, 1fr);
	}	
}

.grid-2-col-auto {
	grid-template-columns: auto auto;
}

.gap { gap: 16px;}

.grid-col-gap { grid-column-gap: 16px;}

