@use 'src/scss/inc/vars';

table.table {
	thead {
		display: none;
		@media (vars.$sm) {
			display: table-row-group;
		}
		tr, tr:hover { background-color: transparent; }
	}

	&.show-header {
		thead {
			display: block !important;
		}
	}

	&.no-td-border {
		@media (vars.$max-smm) {
			td {
				border: none !important;
			}
		}
	}

	caption {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.4px;
		color: #060B0D;
		text-align: left;
		margin-bottom: 16px;
	}
	tr {
		@media (vars.$max-xs) {
			border-bottom: 1px solid #E3E5E5;
		}
	}
	td {
		display: none;
		&.show { display: table-cell; }
		// @media (vars.$max-sm) { display: table-cell; }
	
		@media (vars.$max-sm) {
			// &.avatar-order { display: inline-block; width: 64px; padding-right: 0; }
			&.small { display: inline-block; width: 8%; padding-right: 0; }
			&.name { display: inline-block; width: calc(100% - 80px); padding-right: 0; }
			&.nett { display: inline-block; width: 15%; padding-right: 0; }
			&.general { display: inline-block; width: 45%; padding: 0; line-height: 1.2em; }
			&.action {
				display: table-cell; display: inline-block; width: 80px; border-bottom: 0;
				.button { line-height: 24px; }
			}
			// &.issue_status { display: inline-block; width: 25%; padding-right: 0; }
		}
		@media (vars.$sm) { display: table-cell; }
	
		span { outline: none; }
		a {
			// display: block;
			// padding: 8px 0;
			&:hover { text-decoration: none; }
		}
		&.avatar-order {
			border: none;
			@media (vars.$max-xs) {
				display: none;
				width: 0;
			}
		}
		&.nett {
			font-weight: 500;
			em {
				font-style: normal;
			}
			@media all and (vars.$max-xs) {
				text-align: right;
			}
			@media all and (vars.$sm) {
				display: table-cell;
			}			
			@media all and (vars.$max-sm) {
				border-bottom: none;
			}
		}		
		&.duetoyou {
			font-weight: 500;
			em {
				font-style: normal;
			}
			@media (vars.$max-xs) {
				text-align: right;
			}
			@media (vars.$sm) {
				display: table-cell;
			}
			@media (vars.$max-sm) {
				border-bottom: none;
			}
		}
		&.issue_status {
			@media (vars.$max-sm) {
				border-bottom: none;
			}
		}
		&.name {
			height: auto;
			align-items: center;
			border: none;
			@media (vars.$sm) {
				display: table-cell;
				border-bottom: 1px solid #E3E5E6;
				padding: 0;
			}
			a {
				width: 100%;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: 0.44px;
				color: #181818;
				display: flex;
				align-items: center;
				@media (vars.$sm) {
					font-size: 12px;
				}
			}
			.role {
				font-size: 12px;
				color: rgba(0,0,0,0.7);
				@media (vars.$sm) {
					display: none;
				}
			}
			.mdi {
				margin-left: auto;
				color: #C6CACC;
				font-size: 20px;
				@media (vars.$sm) {
					display: none;
				}
			}
		}
		.cb {
			label {
				position: relative;
				float: left;
				width: 100%;
				display: flex;
			}
			span {
				right: 10px;
				width: 20px;
				height: 20px;
				border: 1px solid #000;
				border-radius: 2px;
			}
			input {
				display: none;
			}
			input:checked + span {
				background:#000;
	
				&:before {
				content: "";
				position: absolute;
				left: 7px;
				width: 6px;
				height: 11px;
				border-right: 2px solid #fff;
				border-bottom: 2px solid #fff;
				transform: rotate(45deg);
				top: 2px;
				}
			}
		}
	
		&.showMobileSmallCell { display: table-cell; }
		&.showDesktopCell,
		&.showDesktopSmallCell { display: none; }
	
		@media (vars.$sm) {
			&.showMobileSmallCell { display: none; }
			&.showDesktopSmallCell { display: table-cell; }
		}
		@media (vars.$md) {
			&.showMobileCell { display: none; }
			&.showDesktopCell { display: table-cell; }
		}
	}
	
	td .status {
		width: 8px;
		height: 8px;
		border-radius: 100%;
		position: relative;
	
		&:hover {
			&:before {
				visibility: visible;
			}
		}
	
		&.status-active { background:#3EA23D; }
		&.status-pending { background: #E02020; }
		&.status-inactive { background: #FAA91A; }
	
		&:before {
			content: attr(data-status);
			position: absolute;
			top: -10px;
			left: -85px;
			height: 24px;
			background:#fff;
			box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
			border-radius: 5px;
			visibility: hidden;
			padding: 0 15px;
			transition: visibility 0.15s cubic-bezier(.42, 0, .58, 1);
		}
	}
	
	.col-filter {
		width: 100%;
		display: flex;
		align-items: center;
		padding: 0 20px 0 0;
	
		.mdi {
			margin-left: auto;
			font-size: 20px;
		}

		&.small { max-width: 200px; }
	}
	
	.table-filter {
		position: absolute;
		top: 50px;
		background: #FFFFFF;
		box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
		border-radius: 5px;
		padding: 5px 8px;
		z-index: 8;
		width: 100%;

		&.small { max-width: 190px;}
	
		.search-type-inner {
			display: flex;
			flex-direction: column;
		}
	
		.search-type {
			width: 100%;
		}
	
		.loader {
			width: 100%;
			min-height: 200px;
			display: flex;
			align-items: center;
			justify-content: center;
			.lds-ellipsis {
				>div {
					background: #4d4d4d;
				}
			}
		}
	
		label {
			display: flex;
			align-items: center;
	
			&:hover {
				cursor: pointer;
			}
	
			input {
				width: 0;
				height: 0;
				opacity: 0;
				display: none;
			}
	
			input:checked + span {
				background:#F5F6F9;
			}
			span {
				width: 100%;
	
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 0.44px;
				color: #5E6366;
				background: #fff;
				padding: 7px 12px;
				border-radius: 5px;
			}
		}
	}
}

