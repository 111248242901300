@use 'src/scss/inc/vars';

// Google fonts with fallbacks in assets folder ===============================
// ============================================================================
// @use url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&family=Rubik:wght@400;500;700&display=swap');

// Roboto fallbacks
@font-face {
    font-family: 'Roboto Local';
    src: url('../../assets/fonts/roboto/roboto-thin-webfont.woff2') format('woff2'),
         url('../../assets/fonts/roboto/roboto-thin-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Local';
    src: url('../../assets/fonts/roboto/roboto-regular-webfont.woff2') format('woff2'),
         url('../../assets/fonts/roboto/roboto-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Local';
    src: url('../../assets/fonts/roboto/roboto-medium-webfont.woff2') format('woff2'),
         url('../../assets/fonts/roboto/roboto-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

// Mallory Font
@font-face {
  font-family: 'Mallory Local';
  src: url('../../assets/fonts/mallory/mallorympcmpct-bold-webfont.woff2') format('woff2'),
       url('../../assets/fonts/mallory/mallorympcmpct-bold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Mallory Local';
  src: url('../../assets/fonts/mallory/mallorympcmpct-book-webfont.woff2') format('woff2'),
       url('../../assets/fonts/mallory/mallorympcmpct-book-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Mallory Local';
  src: url('../../assets/fonts/mallory/mallorympcmpct-medium-webfont.woff2') format('woff2'),
       url('../../assets/fonts/mallory/mallorympcmpct-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}


// Gothic Font
@font-face {
  font-family: 'Gothic Local';
  src: url('../../assets/fonts/gothic/sunset-gothic-bold-pro.woff') format('woff'),
       url('../../assets/fonts/mallory/mallorympcmpct-medium-webfont.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

// Rubik fallbacks
@font-face {
    font-family: 'Rubik Local';
    src: url('../../assets/fonts/rubik/rubik-regular-webfont.woff2') format('woff2'),
         url('../../assets/fonts/rubik/rubik-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Rubik Local';
    src: url('../../assets/fonts/rubik/rubik-medium-webfont.woff2') format('woff2'),
         url('../../assets/fonts/rubik/rubik-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Rubik Local';
    src: url('../../assets/fonts/rubik/rubik-bold-webfont.woff2') format('woff2'),
         url('../../assets/fonts/rubik/rubik-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

// fixes inconsistencies from previous fonts implementation
.mdi {
	// font-size: 1.4rem;
	// line-height: 2rem;

	&:before {
	  display: inline-block;
	  speak: never;
	  font-size: inherit;
	  text-rendering: auto;
	  line-height: inherit;

	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
	}
}

html, body {
  font-family: 'Mallory Local', 'Rubik Local';
	font-size: 13px;
	font-weight: 400;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 500;
	@include vars.margin;

    strong { font-weight: 500; }
    .mdi {
        font-size: 0.9em; line-height: 0.8em;
        &:before { font-size: 0.9em; line-height: 0.8em; vertical-align: baseline; }
    }
}
h1, h2 {
    strong { font-weight: 600; }
}
h1 { font-size: 2.488rem;	line-height: 3.488rem; font-weight: 600; } /* font-family: 'Rubik Local', 'sans-serif'; */
h2 { font-size: 2.074rem;	line-height: 2.978rem; }
h3 { font-size: 1.728rem;	line-height: 2.728rem; }
h4 { font-size: 1.44rem;	line-height: 2.34rem; }
h5 { font-size: 1.2rem;		line-height: 2rem; }
h6 { font-size: 1rem;			line-height: 1.2rem; }

p { font-size: 1rem; line-height: 2rem; @include vars.margin; }
button { font-size: 1rem; line-height: 2rem; font-family: 'Mallory Local', 'Rubik Local'!important; }
small { font-size: 0.833rem; line-height: 1.7rem; }
th {
	// font-family: 'Roboto', 'Roboto Local', sans-serif;
	font-size: 0.833rem;
	line-height: 1.7rem;
}

.lineHeightSmall { line-height: 1.6rem; }

// @mixin roboto() { font-family: 'Roboto', 'Roboto Local', sans-serif; }

@media (vars.$sm) {
	html, body {
		font-size: 14px;
	}
}
@media (vars.$md) {
	html, body {
		font-size: 15px;
	}
}
