@use 'src/scss/inc/vars';

.action-menu {
	position: absolute;
	background: #fff;
	border-radius: 5px;
	padding: 8px;
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
	z-index: 3;
	transition-timing-function: ease-in;
	transition: 0.8s;

	table & {
		width: 180px;
		right: 0;
	}

  table td a & {
    display: block;
	}

	.action-body {
		display: flex;
		flex-direction: column;
	}

	.link {
		line-height: 24px;
		letter-spacing: 0.44px;
		color: #5E6366;
		background: none;
		margin: 0;
		padding: 0;
		border: none;
		box-shadow: none;
		outline: none;
		min-height: 35px;
		border-radius: 5px;
		text-align: left;
		padding: 0 12px;
		width: 100%;
		cursor: pointer;

		@include vars.transitionPrefix(all, 200ms);

		&:hover {
			background: #F5F6F9;
		}
	}
	a.link, button.link {
	    // font-size: 12px;
	    // line-height: 16px;
	    display: flex;
	    align-items: center;
	    letter-spacing: 0.4px;
	    color: #000000;
	    padding: 4px 15px;
	    width: 100%;
	    text-decoration: none;
	}

	.action-footer {
		border-top: 1px solid #D9D9D9;

		button {
			display: flex;
		}

		span {
			margin-right: 16px;
			font-size: 20px;
		}

	}

	&.user-role-assignment {
		left: 0;
		top: -25px !important; // 70px
		@media(vars.$max-md) {
			top: -35px !important;
		}
		.loader {
			width: 100%;
			min-height: 200px;
			display: flex;
			align-items: center;
			justify-content: center;
			.lds-ellipsis {
				>div {
					background: #4d4d4d;
				}
			}
		}



		label {
			position: relative;
			top: 0;
			left: 0;
			display: flex;
			align-items: center;

			input {
				display: none;
				height: 0;
				width: 0;
				opacity: 0;
			}
		}
	}

	&.type-filter {
		right: 0;
		top: 55px !important;
		width: 200px;

		input {
			display: none;
			height: 0;
			width: 0;
			opacity: 0;
		}
		input:checked + label {

			&:before {
				background: #000;
			}
			&:after {
				content: "";
			    position: absolute;
			    right: 17px;
			    width: 6px;
			    height: 11px;
			    border-right: 2px solid #C6CACC;
			    border-bottom: 2px solid #C6CACC;
			    transform: rotate(45deg);

			}
		}

		.link {
			display: flex;
			align-items: center;
		}

		label {
			transform: none !important;
			position: relative;
			left: 0;
			top: 0;
			display: flex;
			align-items: center;
			width: 100%;

			&:before {
			    content: "";
			    position: absolute;
			    right: 10px;
			    width: 20px;
			    height: 20px;
			    border: 1px solid #C6CACC;
			    border-radius: 2px;
			}
		}
	}

	&.promo-action {
		top: 28px;
	}
}
