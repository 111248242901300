@use 'src/scss/inc/vars';

.time-period {
	// flex-direction: column;
	@include vars.margin();

	@media (vars.$sm) {
		flex-direction: row;
		align-items: center;
	}

	h3 {
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 28px;
		letter-spacing: 0.15px;
		color: #000000;
		margin: 0;
	}

	.col-left{
		display: none;
		order: 2;
		// padding: 29px 17px 12px;
		@media (vars.$sm) {
			display: block;
			order: 1;
			padding: 0;
		}
	}

	.col-right {
		margin-left: auto;
		position: relative;
		order: 1;
		width: 100%;
		// padding: 15px 17px;
		// border-bottom: 1px solid #C6CACC;
		@media (vars.$sm) {
			order: 2;
			// border-bottom: 0;
			width: auto;
			margin-left: auto;
		}
		// .action {  }
		.action-menu {
			top: 45px;
		}
	}
	button {
		position: relative;
		width: 100%;

		min-width: 203px;
		border: 1px solid #C6CACC;
		box-sizing: border-box;
		border-radius: 4px;
		height: 40px;
		padding: 4px 12px 7px;
		text-align: left;
		align-items: center;
		@media (vars.$sm) {
			max-width: 203px;
		}
	}

	.btn-label-wrapper {
		flex-direction: column;
	}

	.button-label {
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		line-height: 13px;
		display: flex;
		align-items: center;
		letter-spacing: 0.4px;
		color: #5E6366;
	}

	.selected-time {
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 17px;
		letter-spacing: 0.44px;
		color: #181818;
	}

	.mdi {
		font-size: 20px;
		margin-left: auto;
	}

	input {
		display: none;
		-webkit-appearance: none;
	    -moz-appearance: none;
	}

	.link {
		display: flex;
		align-items: center;
	}
}
