@use "../../inc/vars";
@use "../../helpers/px-rem" as px;

.full-screen-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(vars.$black, 0.15);
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: none;

  &__wrapper {
    display: block;
    max-height: 100%;
    max-width: 100%;
    height: 90vh;
    width: 90vw;
    max-width: px.toRem(1600);
    background-color: vars.$white;
    border: 1px rgba(vars.$separatorColor, 0.15) solid;
    border-radius: px.toRem(20);
    overflow: hidden;

    .form-control {
      padding: px.toRem(15) px.toRem(12);
      margin: 0;
      border-radius: px.toRem(14);
      border: 2px rgba(vars.$separatorColor, 0.15) solid;
      background-color: vars.$white;
      font-weight: 400;
      font-size: px.toRem(15);
      width: 100%;

      &--56 {
        width: px.toRem(56);
      }

      &--90 {
        width: px.toRem(90);
      }

      &--auto {
        width: auto;
      }
    }
  }

  .btn {
    background-color: vars.$black;
    color: vars.$white;
    line-height: 1;
    min-width: 0;
    border-radius: 20px;
    padding: 8px 15px;
    &--outlined {
      background-color: vars.$white;
      border: 2px rgba(vars.$black, 0.54) solid;
      color: vars.$black;
    }
    &--primary {
      background-color: vars.$blue;
      color: vars.$black;
    }
    &--secondary {
      background-color: rgba(vars.$separatorColor, 0.10);
      color: vars.$black;
    }
    &--small  {
      font-size: px.toRem(12);
    }
  }
}
