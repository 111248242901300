// breakpoints
$xs: min-width#{':'} 480px !default;
$sm: min-width#{':'} 768px !default;
$md: min-width#{':'} 1024px !default;
$mmdd: min-width#{':'} 1280px !default;
$lg: min-width#{':'} 1400px !default;
$xl: min-width#{':'} 1800px !default;

$max-xs: max-width#{':'} 480px !default;
$max-smm: max-width#{':'} 600px !default;
$max-sm: max-width#{':'} 768px !default;
$max-md: max-width#{':'} 1024px !default;
$max-mmdd: max-width#{':'} 1280px !default;
$max-lg: max-width#{':'} 1400px !default;
$max-xl: max-width#{':'} 1800px !default;

$primary-bg	: #DCF0FB;
$black: #181818;
$black2: #121212;
$coal: #585858;

$greyMedium: #5E6366;
$greyLight: #E3E5E5; // C6CACC
$greyLight2: #F3F3F3;
$greyWhite: #F5F6F9;
$greyDark: #596368;
$analyticsGrey: #828282;
$greyBoxBorder: #E0E0E0;
$btnBlue: #6ECFF5;
$linkBlue: #1EBEFE;
$orangeBg: #FAA91A;
$btn-border: #C6CACC;
$lowImportance: #05141C75;
$separator: #0F485E26;

// $blue: #1EBEFE;
$blue: #6ECFF5;
$red: #E02020; //#EC1C24
$error: #E94A1F;
$green: #3EA13D;
$white: #FFFFFF;
$success: #3F9047;
$btnHoverColor: #BDBDBD;

$separatorColor: #0F485E26;
$darkBlue: #0A84FF;

$mrd-blue-material: (
	50: #E1FFFF,
	100: #D4FFFF,
	200: #BBFFFF,
	300: #A1FFFF,
	400: #88E9FF,
	500: #6ECFF5,
	600: #55B6DC,
	700: #3B9CC2,
	800: #2283A9,
	900: #08698F,
	contrast: (
		50: #181818,
		100: #181818,
		200: #181818,
		300: #181818,
		400: #181818,
		500: #181818,
		600: #181818,
		700: white,
		800: white,
		900: white,
	)
);

@mixin padding($side: bottom, $moresides...) {
	padding-#{$side}: 24px;

	@media ($sm) { padding-#{$side}: 32px; }
	@media ($md) { padding-#{$side}: 48px; }
	// @media ($lg) { padding-#{$side}: 64px; }

	@each $this in $moresides {
		padding-#{$this}: 24px;
		@media ($sm) { padding-#{$this}: 32px; }
		@media ($md) { padding-#{$this}: 48px; }
		// @media ($lg) { padding-#{$this}: 64px; }
	}
}

@mixin paddingLarge($side: bottom, $moresides...) {
	padding-#{$side}: 24px;

	@media ($sm) { padding-#{$side}: 32px; }
	@media ($md) { padding-#{$side}: 48px; }
	@media ($lg) { padding-#{$side}: 64px; }

	@each $this in $moresides {
		padding-#{$this}: 24px;
		@media ($sm) { padding-#{$this}: 32px; }
		@media ($md) { padding-#{$this}: 48px; }
		@media ($lg) { padding-#{$this}: 64px; }
	}
}

@mixin margin($side: bottom, $moresides...) {
	margin: 0;
	margin-#{$side}: 24px;

	@media ($md) { margin-#{$side}: 32px; }

	@each $this in $moresides {
		margin-#{$this}: 24px;
		@media ($md) { margin-#{$this}: 32px; }
	}
}

@mixin marginLarge($side: bottom, $moresides...) {
	margin: 0;
	margin-#{$side}: 24px;

	@media ($sm) { margin-#{$side}: 32px; }
	@media ($md) { margin-#{$side}: 48px; }
	@media ($lg) { margin-#{$side}: 64px; }

	@each $this in $moresides {
		margin-#{$this}: 24px;
		@media ($sm) { margin-#{$this}: 32px; }
		@media ($md) { margin-#{$this}: 48px; }
		@media ($lg) { margin-#{$this}: 64px; }
	}
}

// transition vendor prefixes
@mixin transitionPrefix($property, $time, $easing: cubic-bezier(0.25, 1, 0.5, 1)) {
	-webkit-transition: $property $time $easing;
			 -o-transition: $property $time $easing;
					transition: $property $time $easing;
}

@mixin transitionPrefixMultiple($time, $properties...) {
	$transition: ();
	@each $property in $properties {
		$transition: append(
			$transition, ($property $time cubic-bezier(.42, 0, .58, 1)), $separator: comma
		);
	}
	-webkit-transition: $transition;
			 -o-transition: $transition;
					transition: $transition;
}

@mixin screenSpace($property, $negative: false) {
	$minus: 1;
	@if $negative { $minus: -1; }
	#{$property}: 16px * $minus;
	@media ($xs) { #{$property}: 32px * $minus; }
	@media ($md) { #{$property}: 48px * $minus; }
	@media ($lg) { #{$property}: 40px * $minus; }
	@media ($xl) { #{$property}: 80px * $minus; }
}
