@use "../../inc/vars";

.page-login {
	background: vars.$primary-bg;
	min-height: 100vh;

	h4 {

		font-style: normal;
		font-weight: bold;
		font-size: 28px;
		line-height: 33px;
		letter-spacing: 0.25px;
		color: #000;
		text-align: center;
	}

	h6 {

		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 20px;
		text-align: center;
		letter-spacing: 0.25px;
	}

	p {
		color: vars.$coal;

		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 21px;
		letter-spacing: 0.1px;
		text-align: center;
	}

	.landing-image {
		margin-bottom: 11px;
		img {
			width: 100%;
			height: auto;
			display: block;
		}
	}

	.col {
		width: 100%;

		&.left {
			@media (vars.$sm) {
				max-width: 312px;
			}
		}

		&.right {
			display: none;
			@media (vars.$sm) {
				max-width: calc(100% - 312px);
				margin-left: 64px;
				display: block;
				min-height: 100vh;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}

			@media (vars.$lg) {
				max-width: 65%;
				padding-left: 130px;
				margin-left: auto;

			}

			p {
				font-size: 14px;
				text-align: center;
			}
		}
	}
}
