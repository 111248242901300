@use 'src/scss/inc/vars';

.bottom-nav {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	background:#fff;
	padding: 12px;
	box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.0869482);
	z-index: 6;
	@media (vars.$sm) {
		display: none;
	}

	a {
		position: relative;
		flex-direction: column;
		align-items: center;

		font-style: normal;
		font-weight: 500;
		font-size: 9px;
		line-height: 16px;
		text-align: center;
		letter-spacing: 0.4px;
		color: #181818;
		text-decoration: none;
		margin: 0 4px;
		width: 100%;
		max-width: 20%;
		display: flex;

		&:first-child {
			margin: 0 4px 0 0;
		}

		&:last-child {
			margin: 0 0 0 4px;
		}

		.mdi {
			font-size: 25px;
			margin-bottom: 4px;
		}

		&.active {
			color: vars.$blue;
		}
	}

	.mobile-menu {
		justify-content: space-between;
	}

	// .nav-burger {
	// 	flex-direction: column;
	// 	align-items: center;

	// 	font-style: normal;
	// 	font-weight: 500;
	// 	font-size: 9px;
	// 	line-height: 16px;
	// 	text-align: center;
	// 	letter-spacing: 0.4px;
	// 	color: #181818;
	// 	text-decoration: none;
	// 	margin: 0 4px;
	// 	width: 100%;
	// 	max-width: 20%;
	// 	display: flex;
	// 	padding: 0;
	// 	background: none;
	// 	border: none;
	// 	box-shadow: 0;
	// 	//margin-left: auto;

	// 	.mdi {
	// 		font-size: 25px;
	// 		margin-bottom: 8px;
	// 	}
	// }

}
.mobile-menu-overlay {
	position: fixed;
	top: 0;
	right: 0;
	float: right;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: rgba(92,92,92,0.6);
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	z-index: 999;
	cursor: pointer;

	.mobile-menu-container {
		width: 100%;
		max-width: 80%;
		background: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    right: 0;
    z-index: 9999;

		.countBadge { top: 14px; right: 14px; }
	}

	.menu-header {
		display: flex;
		align-items: center;

		.close {
			margin-left: auto;

			.mdi {
				font-size: 25px;
			}
		}
	}

	.site-logo {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: center;
		padding: 35px 0;
		img {
			width: 100%;
			max-width: 55px;
			height: auto;
			display: block;
		}
	}

	.menu-body {
		padding: 0 16px;

		button, a {
		    width: 100%;
		    text-align: left;
		    padding: 14px 15px;
		    //border-bottom: 1px solid #E5E5E5;
		    border-left: 0;
		    border-top: 0;
		    border-right: 0;
		    background: none;
		    font-size: 16px;
		    letter-spacing: 0.25px;
		    line-height: 20px;
		    margin: 0 0 5px;
		    max-width: 100%;
		    flex-direction: row;
		    font-weight: normal;
		    border-radius: 11px;

		    .mdi {
		    	margin-right: 10px;
		    	margin-bottom: 0;
		    	font-size: 15px;
		    }

		    &.active {
		    	background: vars.$btnBlue;
		    	color: vars.$black;
		    }

		}
	}

	.menu-footer {
		margin-top: auto;
		border-top: 1px solid #E5E5E5;
		display: flex;
		flex-direction: column;
		padding: 16px 32px;

		.user-profile {
			align-items: center;
		}

		.avatar {
			width: 40px;
			height: 40px;
			margin-right: 14px;
			// background:#C4C4C4;
			border-radius: 100%;

			// img {
			// 	width: 100%;
			// 	height: auto;
			// 	display: block;
			// }

			font-style: normal;
			font-size: 22px;
			padding-top: 8px;
			text-align: center;
			color: #ffffff;
		}

		.profile-user {
			.name {

				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 16px;
				display: flex;
				align-items: center;
				letter-spacing: 0.4px;
				color: #000000;
				margin-bottom: 5px;
			}

			.role {

				font-style: normal;
				font-weight: normal;
				font-size: 11px;
				line-height: 14px;
				letter-spacing: 0.4px;
				color: #000000;
			}
		}

		button.link {
			margin: 15px 0 0;
		    padding: 0;
		    font-size: 16px;
		    display: flex;
		    align-items: center;
		    background: none;
		    border: none;
		    box-shadow: none;

		    .mdi {
		    	font-size: 20px;
		    	margin-right: 15px;
		    }
		}
	}
}

.mobile-menu-overlay span.mobile-expanded-menu-item-badge,
.bottom-nav span.mobile-nav-item-badge {
	background-color: vars.$success;
	color: vars.$white;
}

.bottom-nav span.mobile-nav-item-badge {
	padding: 0.01rem 0.25rem;
	border-radius: 12px;
	font-size: 7.5px;
	font-weight: 600;
	position: absolute;
	top: -10px;
	right: -2px;
}

.mobile-menu-overlay span.mobile-expanded-menu-item-badge {
	padding: 0.3rem 0.75rem;
	margin: 0 0.5rem;
	border-radius: 50px;
	font-size: 12px;
	font-weight: 700;
}

.mobile-menu-overlay a.active {
	span.mobile-expanded-menu-item-badge {
		background-color: vars.$white;
		color: vars.$success;
	}
}

