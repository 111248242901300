@use 'src/scss/inc/vars';
@use '../../plugins/charts-1.0.0';
@use '../../plugins/line-chart-correction'; // make line charts look better

.charts-css {
    tbody tr {
        &:hover { background: none; }
    }
    tbody th {
        letter-spacing: normal;
        color: inherit;
        white-space: nowrap;
        font-weight: normal;
    }
    &.column {
        height: 252px;
        padding: 0 20px 10px 30px;

        @media (vars.$xs) {
            height: 300px;
            padding: 0 40px 54px 50px;
        }

        tbody {
            th { background-color: #fff; z-index: 1; }
            td { animation: fade-in 600ms linear; }
        }
        .data {
            position: absolute;
            left: -6px;
            display: block;
            background: #fff;
            font-size: 0.7rem;
            line-height: 0.85rem;
            padding: 2px;
            margin-top: 8px;
            white-space: nowrap;
            -webkit-box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
                    box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
        }
        // .code { display: inline-block; width: 1rem; height: 1rem; border-radius: 3px; vertical-align: middle; margin: 0 8px 2px 0; }
    }
    &.area, &.line {
        height: 252px;
        padding: 0 20px 10px 30px;

        @media (vars.$xs) {
            height: 300px;
            padding: 0 40px 54px 50px;
        }

        tbody tr {
            th span {
                position: absolute;
                right: 0;
                transform: translateX(50%);
                white-space: nowrap;
                font-weight: normal;
            }
            td {
                animation: fade-in 600ms linear;
                z-index: initial;
            }
            &:first-child { max-width: 0; }
        }
        .data {
            position: relative;
            display: block;
            background: #fff;
            font-size: 0.7rem;
            line-height: 0.85rem;
            padding: 2px;
            white-space: nowrap;
            z-index: 6;
            -webkit-box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
                    box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
        }
        .code { display: inline-block; width: 1rem; height: 1rem; border-radius: 3px; vertical-align: middle; margin: 0 8px 2px 0; }
    }
    .tooltip {
        display: none;
        font-size: 0.85rem;
        line-height: 1.1rem;
        text-align: left;
        & > div {
            margin-bottom: 8px;
            &:last-child { margin-bottom: 0; }
        }
        @media (vars.$md) {
            display: block;
        }
    }
    // tbody tr:last-child td .tooltip { left: 0; }
}

.mini-chart {
    .charts-css.line {
        height: 60px;
        padding: 0;
    }
    .charts-css.column {
        height: 60px;
        padding: 0;
    }
    .tooltip,
    .white-box { display: none!important; }
}

@keyframes moving-bars {
	from  { opacity: 0; transform: translateY(30%); }
	to { opacity: 1; transform: translateY(0); }
}
@keyframes fade-in {
	from  { opacity: 0; }
	to { opacity: 1; }
}

.YAxis {
	background-color: #fff;
	position: absolute;
	min-width: 20px;
	left: 0;
	height: 224px;
	font-size: 0.8rem;
	text-align: right;
	padding-right: 8px;
	margin-top: -0.5rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

    @media (vars.$xs) {
        min-width: 50px;
    }
}
.wideChart .YAxis { position: absolute; }
.wideChart .charts-css {
	width: 100%;
	max-width: 100%;
	padding: 0 40px 54px 50px;

	tbody th span {
		position: absolute;
		right: 50%;
		width: 80px;
		font-size: 0.8rem;
		text-align: right;
		transform: translateX(-4px) rotate(-45deg);
		transform-origin: 100% 0;
		white-space: nowrap;
	}

    &.area, &.line {
        tbody th span { right: 0; transform: translateX(-4px) rotate(-45deg); }
    }
}

.charts-css.trimLabels tbody tr:nth-child(2n) th { display: none; }

.charts-css.legend {
	border: 0;
	padding: 0;

	li {
		display: inline-block;
		margin-right: 16px;
		&:last-child { margin-right: 0; }
	}

	@media (vars.$sm) {
		display: block;
		text-align: center;
	}
}

.charts-wrapper {
	position: relative;
	padding-top: 32px;
	width: 100%;
    // max-width: 700px;
	margin: 0 auto;
	// overflow-x: auto;

	// .charts-css { min-width: 600px; }
	&.wideChart { max-width: 100%; }
}
