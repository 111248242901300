.restaurant-select {
	.selectAll {
		font-size: 0.95rem;
		padding: 8px 12px;
		margin-bottom: 8px;

		span { display: inline-block; margin-right: 16px; }
		label { display: inline-block; vertical-align: middle; }
	}

	input[type="checkbox"] {
		display: none;
		-webkit-appearance: none;

		&:checked + label {
			background:#181818;
			&:before {
				opacity: 1;
			}
		}

		& + label {
			width: 20px;
			height: 20px;
			border-radius: 3px;
			border: 1px solid #181818;
			position: relative;
			transition: ease all 0.2s;

			&:before {
				content: "";
				width: 5px;
				height: 13px;
				border-bottom: 1px solid #fff;
				border-right: 1px solid #fff;
				transform: rotate(45deg);
				opacity: 0;
				transition: ease all 0.2s;
				position: absolute;
				left: 7px;
				top: 0;
			}
		}
	}

	label { width: 20px; }

}

.restaurant {
	-webkit-box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
	        box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;

	border-radius: 5px;
	-webkit-box-align: center;
		 -ms-flex-align: center;
				align-items: center;
	padding: 8px 12px;
	margin-bottom: 8px;

	.title {
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 0.44px;
		color: #181818;
	}

	.action { margin-left: auto; }
}
