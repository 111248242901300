@use 'src/scss/inc/vars';

#fixedHeader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 5;
	@media (vars.$max-sm) {
		position: absolute;
	};
}

#spaceGhost {
	visibility: hidden;
}