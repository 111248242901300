.no-content {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 50vh;
	flex-direction: column;

	.nc-image {
		max-width: 300px;
	}

	p, h4 { margin-bottom: 24px; }

	// h5 {
	// 	font-style: normal;
	// 	font-weight: 500;
	// 	font-size: 18px;
	// 	line-height: 28px;
	// 	text-align: center;
	// 	letter-spacing: 0.15px;
	// 	color: #060B0D;
	// 	margin-bottom: 8px;
	// }
	//
	// p {
	// 	font-style: normal;
	// 	font-weight: normal;
	// 	font-size: 16px;
	// 	line-height: 24px;
	// 	text-align: center;
	// 	letter-spacing: 0.44px;
	// 	color: #060B0D;
	// 	margin-bottom: 16px;
	// }

	&.promo-blank {
		min-height: 60vh;
		padding: 0 20px;
	}
}
