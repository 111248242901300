@use "../inc/vars";

.btn {
	position: relative;
	display: inline-block;
	text-decoration: none;
	outline: none;
	font-weight: 700;
	border: 0;
	border-radius: 5px;
	padding: 12px 16px;
	cursor: pointer;

	color: #192D35;
	background-color: vars.$btnBlue;
	background: vars.$btnBlue -o-radial-gradient(circle, transparent 1%, vars.$btnBlue 1%) center/15000%;
	background: vars.$btnBlue radial-gradient(circle, transparent 1%, vars.$btnBlue 1%) center/15000%;
	background-position: center;
	-webkit-transition: background 600ms;
		 -o-transition: background 600ms;
			transition: background 600ms;
	min-width: 132px;
	text-align: center;

	&.light { background: unset; border: 1px solid #C6CACC; }
	&.outline {
		color: vars.$btnBlue;
		background: #fff radial-gradient(circle, transparent 1%, #fff 1%) center/15000%;
		border: 1px solid lighten(vars.$btnBlue, 50%);
	}

	&:hover {
		text-decoration: none;
		background-color: lighten(vars.$btnBlue, 20%);
		box-shadow: 0px 2px 3px rgba(0,0,0,0.2);

		&.light { background: vars.$greyWhite radial-gradient(circle, transparent 1%, vars.$greyWhite 1%) center/15000%; }
		&.outline { background-color: vars.$greyWhite; }
	}
	&:active {
		background-color: lighten(vars.$btnBlue, 25%);
		background-size: 100%;
		transition: background 0s;
		&.light { background-color: darken(vars.$greyWhite, 5%); }
		&.outline { background-color: darken(vars.$greyWhite, 2%); }
	}

	&.secondary {
		border-radius: 100px;
		&:before {
			border-radius: 100px;
		}
	}

	&.grey {
		background-color: vars.$greyLight;
	}

	&.small {
		font-size: 0.85em;
		padding: 7.5px 12px;
		width: 100%;
    min-width: 100%;		
	}

	&.btn-link {
		background-color: transparent;
		&:before { content: none; display: none; }
	}

	.mdi {
    display: inline-block;
    margin-left: 6px;
    margin-top: 3px;
    vertical-align: top;
    line-height: 1rem;

    &:before { font-size: 1.2rem; vertical-align: middle; }
  }
}

.btn-inverted {
	color: vars.$white;
	background-color: vars.$black;
  padding: 2%;
	&:hover {
		color: vars.$black !important;
	}
}

.btn-group {
	position: relative;
	z-index: 1;

	.btn {
		margin: 0 16px 16px 0;
		&:last-child { margin-right: 0; }
	}
}

.page-toolbar .btn { margin-bottom: 0; }

.filters .btn { font-weight: 400; }
