@use 'src/scss/inc/vars';

.toast-flash {
	position: fixed;
	background: vars.$white;
	box-sizing: border-box;
	box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.24), 0px 2px 6px rgba(8, 35, 48, 0.16);
	border-radius: 6px;
	padding: 16px 16px 16px 55px;

	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.44px;
	width: 100%;
	max-width: calc(100% - 32px);
	margin: 0 16px;
	top: 40px;
	display: flex;
	align-items: center;
	z-index: 9999;

	@media (vars.$sm) {
		right: 40px;
		max-width: 400px;
	}

	.mdi {
		position: absolute;
		left: 20px;
		font-size: 1.2rem;
		color: vars.$white;
		background-color: vars.$blue;
		border-radius: 100%;
		padding: 0.5%;
	}

	&.error {
		border: 1px solid #E02020;
		color: #E02020;
	}

	&.success {
		border: 1px solid vars.$blue;
		// color: vars.$blue;
	}

	.toast-inner {
		&:before {
			font-family: "Material Design Icons";
			position: absolute;
			left: 22px;
			font-size: 25px;
		}
	}
}
