@use 'src/scss/inc/vars';

.page-overlay {
	// position: fixed !important;
	// top: 0;
	// left: 0;
	// width: 100%;
	// height: 100%;
	// background: #fff;
	// overflow: auto;

	position: fixed;
	background: #fff;
	transform: translate(-50%, -50%);

	.search-filter {
		.types-filter {
			position: absolute;
			right: 0;
			top: 17px;
			padding-right: 15px;
			display: flex;
			z-index: 3;

			.types {
				font-size: 14px;
				line-height: 24px;
				padding: 0 10px 0 0;
				display: none;
				@media (vars.$md) {
					display: flex;
				}
			}

			.mdi {
				transition: ease all 0.2s;
				font-size: 20px;
				&:hover {
					cursor: pointer;
				}
			}
		}
	}

	.form-result {
		border: none;
		box-shadow: none;
		padding: 0;

		.result-row {
			display: flex;
			margin-bottom: 8px;

			.list-row {
				background: none;
				border: none;
				box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.07), 0px 2px 6px rgba(8, 35, 48, 0.08);
				border-radius: 5px;

				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: 0.44px;
				color: #181818;
				text-align: left;
				position: relative;
				padding: 16px 64px 16px 10px;
				width: 100%;
				display: flex;
				flex-direction: column;

				&:before {
					content: "";
					position: absolute;
					right: 10px;
					width: 20px;
					height: 20px;
					border: 1px solid #C6CACC;
					border-radius: 2px;
					top: 25px;
				}

				.res-desc {
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 16px;
					letter-spacing: 0.4px;
					color: #5E6366;
				}

				&.checked {
					&:before {
						background:#000;
					}

					&:after {
						content: "";
						position: absolute;
						right: 17px;
						width: 6px;
						height: 11px;
						border-right: 2px solid #C6CACC;
						border-bottom: 2px solid #C6CACC;
						transform: rotate(45deg);
    					top: 27px;
					}
				}
			}
		}
	}

	.selected-pills {
		position: relative;
	    background: #fff;
	    border-radius: 4px;
	    border: 1px solid #C6CACC;
	    flex-wrap: wrap;
	    padding: 35px 10px 10px;

		.label {
			position: absolute;
		    left: 12px;
		    top: 17px;
		    transition: 0.2s;
		    transition-timing-function: ease;
		    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
		    font-style: normal;
		    font-weight: normal;
		    font-size: 16px;
		    line-height: 24px;
		    letter-spacing: 0.44px;
		    color: #5E6366;
		    z-index: 1;
		    opacity: 1;
		    transform: translateY(-60%) translateX(0px);
		    font-size: 12px;
		}

		.item-selected {
		    font-size: 12px;
		    border: 1px solid #C6CACC;
		    border-radius: 100px;
		    padding: 7px 15px;
		    margin: 0 10px 10px 0;
		    align-items: center;

		    .mdi {
		    	font-size: 20px;
		    	margin-right: 10px;
			}

			.hidden {
				color: #DCDCDC;
			}
		}

	}
	// .btn-group {
	// 	display: block;
	// 	border: none;
	// 	padding: 0;

	// 	@media (vars.$md) {
	// 		display: flex;
	// 		button ~ button { margin-left: 8px; } // excludes 1st button
	// 	}
	// }
}
