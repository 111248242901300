@use 'src/scss/inc/vars';

/*
 #596368
 #DDE2E5
 #E94A1F - red sold out text
*/

.accordion-custom {

		.unavailable {
			color: #E94A1F;
		}	

    .extra-bot-space {
        @media(vars.$max-sm){
            padding-bottom: 12%;
        }
    }

    .toolbar-mobile-bottom {
        position: fixed;
        bottom: 60px;
        width: 100%;
        left: 0;
        display: flex;
        justify-content: space-around;
        box-shadow: 0px -2px 4px rgb(0 0 0 / 9%);
        padding: 2.7% 0;
        z-index: 9;
        background: white;
        border-bottom: 1px solid vars.$greyLight;
        .btn {
            width: 35%;
            padding: 3.5% 1%;
        }
    }

    .no-results {
        margin-top: 5%;
    }

    form {
        section {
            padding-bottom: 0;
        }
    }

    .test-checkbox {
        top: 10px !important;
        left: 5px;
        position: absolute !important;
        input[type="checkbox"] {
            top: -2px;
            left: -2px;        
        }
    }

    input[type="checkbox"] {
        margin: 0;
        width: 20px;
        height: 20px;
        position: relative;
        top: 4px;
        border-radius: 4px;
        border: none;
        outline: none; 
    }

    .label-checkbox {
        position: relative;
        width: 20px;
        height: 20px;
        top: 0;
        //border: 2px solid #666;
        border-radius: 4px;
    }

    .bot-grey-border {
        border-bottom: 1px solid vars.$greyLight;
        .mdi {
            top: 8px;
        }
    }

    .menu-toolbar {
        display: flex;
        margin-bottom: 0;
    }

    .menu-toolbar-mobile {
        display: block;
        margin-bottom: 5%;
        .tm-header {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            .mobile-back-arrow {
                font-size: 2.5em;
                position: relative;
                top: 12px;
                margin-right: 3%;
            }
            h3 {
                font-weight: 600;
                position: relative;
                left: 2px;                
            }
        }
        .tm-icons {
            i {
                background: vars.$greyLight;
                border-radius: 50%;
                padding: 1px 6px;
                font-size: 1.8em;
            }
            label {
                margin: 0 4px;
            }
            .mdi:before {
                position: relative;
                top: 1px;                
            }
        }
    }

    .select-filter {
        padding: 1.5%;
        display: flex;
        align-items: center;
        .btn-color-black {
            background-color: #000 !important;
            color: #fff !important;
        }
        @media(vars.$max-sm){
            margin: 2% 0;
            flex-wrap: wrap;
        }
        .edit-btn {
            @media(vars.$max-sm){
                display: none;
            }
        }
        strong {
            @media(vars.$max-sm){
                width: 100%;
                margin-bottom: 10px;
            } 
        }
        .btn {
            @media(vars.$max-sm){
                flex: 1;
            }            
        }
    }

    .search-input {
        display: inline-block;
    }

    .btn {
        line-height: 1px;
    }

    .btn-filter {
        flex-direction: row;
        align-items: center;
        padding: 6px 16px;
        gap: 8px;
        font-weight: normal;
        min-width: 75px;
        height: 40px;

        background: rgba(6, 60, 85, 0.05);
        border-radius: 60px;

        flex: none;
        order: 4;
        align-self: stretch;
        flex-grow: 0;

        margin-left: 8px;
    }

    .btn-filter-inverse {
        flex-direction: row;
        align-items: center;
        padding: 6px 16px;
        gap: 8px;
        font-weight: normal;
        min-width: 75px;
        height: 40px;

        background: none;
        border: 1px solid rgba(6, 60, 85, 0.2);
        border-radius: 60px;

        flex: none;
        order: 4;
        align-self: stretch;
        flex-grow: 0;

        margin-left: 8px;

        &:focus {
            background: #6ECFF5 radial-gradient(circle, transparent 1%, #6ECFF5 1%) center/15000%;
        }
        @media(vars.$max-sm){
            padding: 1% 2%;
            min-width: auto;
            height: 32px;
            margin-left: 0;
            margin-right: 3%;          
        }
    }

    .accordion-custom-inner {
        .flex {
            justify-content: space-between;
        }
    }
}

.mat-expansion-panel-spacing {
    margin: 1% 0 !important;
}

.mat-expansion-panel-header:not([aria-disabled=true]) {
	cursor: default !important;
}

/* PARENT FOR ALL SEARCH RESULTS STUFF */
.accordion-search-results {
    margin-top: 35px;
    .variant-count {
        font-weight: 600;
        text-transform: capitalize;
        em {
					font-style: normal;
					color: #149DD3;
					font-weight: 600;
        }
    }
		em.keyword {
			font-style: normal;
			color: #149DD3;
			font-weight: 600;			
		}
		.mat-expansion-panel-header {
			@media(vars.$max-md){
				height: 5em;
			}
		}
}

.variant-count {
    display: block;
    font-size: 0.9em;
		font-weight: normal;
    @media(vars.$max-sm) {
        color: vars.$greyMedium;
    }
    em {
			font-style: normal;
			font-weight: 100;
    }
    .dot {
        margin: 0;
				display: block;
				visibility: hidden;
				line-height: 1px;				
    }
    .mdi-eye-off {
        color: vars.$red;
        position: relative;
        top: 1.5px;                    
    }
		.mdi {
			margin-right: 2.5px;
		}        
}

.mat-expansion-panel {
    box-shadow: none !important;

		.mat-expanded {
			// height: inherit !important;
		}

    &:not(.mat-expanded) {
        margin-bottom: 2%;
    }

    .mat-expansion-panel-header {
        font: inherit !important;
    }

    .mat-expansion-panel {
        border-bottom: 1px solid #DDE2E5;
        border-radius: unset !important;

        &:not(.mat-expanded) {
            margin-bottom: 0;
            //padding: 16px 0 16px 0;
        }        

        .mat-expansion-panel-header {
            font-family: inherit !important;
            font-weight: normal;
            padding: 1.5% 2%;
            margin-bottom: 0;
						height: 5em;
            &:hover {
                background: none !important;
            }
            strong {
                font-weight: 600;
            }
            @media(vars.$max-md){
							height: 6.5em;
							padding: 1.5% 13px;
            }						
        }
    }

    .accordion-main-header {
				@media(vars.$sm){ height: 64px !important; }
				@media(vars.$max-sm){ height: auto !important; padding: 8px 15px; }
        background: rgba(6, 60, 85, 0.05) !important;
        border-radius: 8px;
        &:focus {
            background: rgba(6, 60, 85, 0.05) !important;
        }
        &:hover {
            background: rgba(6, 60, 85, 0.05) !important;
        }

        strong {
            font-size: 1.3em;
						@media(vars.$max-sm){
							font-size: 0.9em;
							align-self: center;
						}
            em {
                font-style: normal;
                // color: #596368;
            }
        }

        .accordion-main-header {
            border-radius: unset;
        }
    }

    .mat-expansion-panel-content {
        font-family: inherit !important;
        border: none !important;
        border-radius: none;
        .mat-expansion-panel-content {
            font: inherit !important;
            background: rgba(6, 60, 85, 0.05) !important;
            border: unset !important;

            .subItem-price {
                // width: 9.45%;
            }
        }
    }

    .mat-content {
        justify-content: space-between;
				align-items: center;
    }

    .multi-filter-checkbox {
        flex-grow: 2;
        @media(vars.$max-sm){
            margin-right: 20px;
        }
    }

    .stretchCol {
        flex-grow: 20;        
    }

    .subItem-name {
        .menu-title {
            font-weight: 600;
            text-transform: capitalize;
            em {
                font-style: normal;
                color: #149DD3;
            }
        }
        // width: 25%;
        align-self: center;
        @media(vars.$max-sm){
            width: 90%;
            font-size: 0.9em;
        }
    }

    .subItem-price {
        // min-width: 92px;
        color: #596368;
        align-self: center;
    }

		.pencil {
			@media(vars.$max-sm){
				display: flex;
				margin: 0;
				padding: 0;
			}
			i {
				width: 30px;
				height: 30px;
				padding: 5px 10px;
				border-radius: 100%;
				&:hover {
					background-color: vars.$greyLight;
				}				
			}
			border-radius: 100%;
			cursor: pointer;
			align-content: center;
			width: 50px;
			height: 50px;
		}

		i.mdi {
			position: relative;
			font-size: 1.3em;
			@media(vars.$max-sm){ align-self: center; }
		}

		.mdi-pencil-outline { top: 3px; }
		.mdi-eye-off, .mdi-eye-outline { top: 3px; }

    .subItem-avail {
			align-self: center;
			text-transform: capitalize;
    }

    .mat-expansion-panel-body {
        padding: 0;
        .accordion-subchild-row {
            padding: 1.3% 3%;
            @media(vars.$max-sm){
                padding: 2% 3%;
            }
            border-top: 1px solid #DDE2E5;
            .subItem-name {
                font-weight: 600;
                @media(vars.$max-sm){
                    font-weight: 500;
                }
            }                       
        }
    }
}



