@use 'src/scss/inc/vars';

.terms-dialog {
	align-items: flex-start;

	.modal-wrapper {
		margin-top: 20px;
		margin-bottom: 20px;
		@media (vars.$sm) {
			max-width: 960px;
			margin-top: 60px;
			margin-bottom: 60px;
		}
	}

	ol {
		counter-reset: item;
		margin: 0px 0 0 28px;
		li {
			display: block;
			padding: 0 0 16px 0;
			margin: 0;
			position: relative;

			&:before {
				content: counters(item, ".") ". ";
				counter-increment: item;
				padding-right: 24px;
				position: absolute;
				left: -24px;
				min-width: 60px;
				background: none;
			}

			ol {
				padding-top: 24px;
				li {

					font-size: 16px;
					line-height: 160%;
					margin-left: 0;
					font-weight: normal;

					&:before {
						left: -36px;
						@media (vars.$md) {
							left: -60px;
						}
					}

					ol {
						li {

						}
					}
				}
			}
		}
	}
}
