@use 'src/scss/inc/vars';

table.table {
	// table-layout: fixed;
	border-collapse: collapse;
	width: 100%;
	text-align: left;
	@include vars.margin();


	th, td {
		position: relative;
		font-weight: 400;
		line-height: 24px;
		padding: 8px;
		border-bottom: 1px solid vars.$greyLight;

		&:first-child { padding-left: 0; }
		&:first-child { padding-right: 0; }
	}
	th {
		color: vars.$greyMedium;
		border-width: 2px;
		text-transform: uppercase;
		letter-spacing: 1px;
    white-space: nowrap;
	}

	tbody {
		tr:hover { background-color: #F5F6F9; }
	}

  .date { min-width: 160px; }
  // .actions { justify-content: flex-end; }

	h1, h2, h3, h4, h5, h6, p { margin-bottom: 0; }

	.date { min-width: 160px; }
	a { display: block; }


  .action > a {
    position: relative;
    display: inline-block;
    color: inherit;
    text-decoration: none;
    margin: 0 8px;
    white-space: nowrap;
    cursor: pointer;

    span {
      position: relative;
      top: 2px;
      font-size: 1.3rem;
      vertical-align: middle;


      &:before { margin-top: -2px; }
    }

    &:after {
      content: '';
      position: absolute;
      background-color: #181818;
      width: 0;
      height: 2px;
      right: 0;
      bottom: -2px;
      cursor: pointer;

      @include vars.transitionPrefix(width, 200ms);
    }
    &:hover:after { left: 0; width: 100%; }
    &:active:after { left: initial; width: 0; }
  }
  .near-bottom .action-menu { // prevents menu cut off
    top: initial;
    bottom: 34px;
  }

  @media (max-width: 960px) { .wrap { width: 80px; } }
}

.table-wrapper {
	width: 100%;
	overflow-x: auto;
}

.showDesktopCell,
.showDesktopSmallCell { display: none; }

@media (vars.$sm) {
	.showMobileSmallCell { display: none; }
	.showDesktopSmallCell { display: table-cell; }
}
@media (vars.$md) {
	.showMobileCell { display: none; }
	.showDesktopCell { display: table-cell; }
}
