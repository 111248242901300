@use 'src/scss/inc/vars';

/* 3rd party Ang Material Carousel styling here */
.carousel {
	background-color: vars.$blue;
	border-radius: 6px;
	box-shadow: 0px 1px 2px rgba(8, 35, 48, 0.24), 0px 2px 6px rgba(8, 35, 48, 0.16);
	margin-bottom: 3%;
	min-height: 12em;
	@media (vars.$max-md) {
		min-height: 16em;
	}
	> button:first-of-type {
		color: vars.$blue;
		visibility: hidden;
		&:before {
			display: inline-block;
			@media (vars.$md) {
				visibility: visible;
			}
			font-size: 2em;
			color: vars.$black;
			font-family: "mdi";
			content: "\f105";
		}
	}
	> button:last-of-type {
		color: vars.$blue;
		visibility: hidden;
		&:before {
 			@media (vars.$md) {
				visibility: visible;
				width: 0;
			}
			font-size: 2em;
			color: vars.$black;
			font-family: "mdi";
			content: "\f107";
		}		
	}
}

.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
	background-color: vars.$black;
}

.btn-inverted {
	border-radius: 50px;
	padding: 2% 0;
	min-width: 155px;
	line-height: 22px;
	@media (vars.$md) {
		padding: 2%;
	}
	.mdi {
		position: relative;
	}
}

.btn-desktop {
	@media (vars.$max-md) {
		display: none;
	}
}

.dashboardPage-banner {
	.btn-tablet {
		@media (vars.$md) {
			display: none;
		}
	}
}

.page-notice {
	position: relative;
	padding: 2% 1% 1% 1%;
	margin-bottom: 3%;
	border-radius: 4px;
	@media (vars.$max-md) {
		padding: 4% 1% 1% 6%;
	}
	overflow: hidden;
	z-index: 1;

	&.notice-blue {
		background: vars.$blue;
	}
	&.notice-green {
		background: #3EA13D33;
		border: solid 1px #3EA13D;
	}
	&.notice-red {
		border: solid 1px #F35357;
	}

	.notice-left {
		width: 100%;
		@media (vars.$sm) {
			max-width: calc(100% - 177px);
		}
	}
	.notice-right {
		width: 100%;
		@media (vars.$sm) {
			max-width: 177px;
		}
	}
	.notice-center {
		display: flex;
		justify-content: center;
		align-items: center;
		@media (vars.$max-md) {
			padding: 1%;
		}
		margin: 0 auto;
		img {
			align-self: center;
			width: 90px;
			display: inline-block;
		}
		a, button {
			align-self: center;
			position: relative;
			@media (vars.$lg) {
				top: 35%;
			}
		}
		.center-content {
			margin: 0 3%;
			display: inline-block;
			h1 {
				margin-bottom: 0;
				padding-bottom: 0;
			}
		}
	}

	.notice-dismis {
		position: absolute;
		right: 19px;
		top: 19px;
		background:#78909C;
		width: 20px;
		height: 20px;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 8;

		.mdi {
			color: #fff;
			font-weight: bold;
		}
	}

	h5 {
		font-weight: 500;
		font-size: 18px;
		line-height: 28px;
		letter-spacing: 0.15px;
		color: #060B0D;
		margin-bottom: 8px;
	}

	p {
		font-weight: normal;
		font-size: 1.2rem;
		line-height: 20px;
		letter-spacing: 0.44px;
		color: #060B0D;
		margin-bottom: 15px;
	}

	.btn.light {
		background: #fff;
		box-shadow: none;
	}
}

.confetti {
  width: 8px;
  height: 8px;
  background-color: #f2d74e;
  position: absolute;
	top: -30px;
  left: 50%;
  -webkit-animation: confetti 4000ms ease-in-out -2s infinite;
          animation: confetti 4000ms ease-in-out -2s infinite;
  -webkit-transform-origin: left top;
      -ms-transform-origin: left top;
          transform-origin: left top;
}
.confetti:nth-child(1) { background-color: #f2d74e; left: 10%; -webkit-animation-delay: 0; animation-delay: 0; }
.confetti:nth-child(2) { background-color: #95c3de; left: 20%; -webkit-animation-delay: -260ms; animation-delay: -260ms; top: -120px; }
.confetti:nth-child(3) { background-color: #ff9a91; left: 30%; -webkit-animation-delay: -760ms; animation-delay: -760ms; top: -100px; }
.confetti:nth-child(4) { background-color: #f2d74e; left: 40%; -webkit-animation-delay: -470ms; animation-delay: -470ms; top: -180px; }
.confetti:nth-child(5) { background-color: #95c3de; left: 50%; -webkit-animation-delay: -2220ms; animation-delay: -2220ms; top: -80px; }
.confetti:nth-child(6) { background-color: #ff9a91; left: 60%; -webkit-animation-delay: -150ms; animation-delay: -150ms; top: -50px; }
.confetti:nth-child(7) { background-color: #f2d74e; left: 70%; -webkit-animation-delay: -2650ms; animation-delay: -2650ms; top: -250px; }
.confetti:nth-child(8) { background-color: #95c3de; left: 80%; -webkit-animation-delay: -1300ms; animation-delay: -1300ms; }
.confetti:nth-child(9) { background-color: #ff9a91; left: 90%; -webkit-animation-delay: -3000ms; animation-delay: -3000ms; top: -100px; }
.confetti:nth-child(10) { background-color: #f2d74e; left: 100%; -webkit-animation-delay: -1750ms; animation-delay: -1750ms; top: -160px; }

@-webkit-keyframes confetti {
  0% { -webkit-transform: rotateZ(15deg) rotateY(0deg) translate(0,0); transform: rotateZ(15deg) rotateY(0deg) translate(0,0); }
  25% { -webkit-transform: rotateZ(5deg) rotateY(360deg) translate(-3vw,10vh); transform: rotateZ(5deg) rotateY(360deg) translate(-3vw,10vh); }
  50% { -webkit-transform: rotateZ(15deg) rotateY(720deg) translate(3vw,30vh); transform: rotateZ(15deg) rotateY(720deg) translate(3vw,30vh); }
  75% { -webkit-transform: rotateZ(5deg) rotateY(1080deg) translate(-7vw,40vh); transform: rotateZ(5deg) rotateY(1080deg) translate(-7vw,40vh); }
  100% { -webkit-transform: rotateZ(15deg) rotateY(1440deg) translate(5vw,55vh); transform: rotateZ(15deg) rotateY(1440deg) translate(5vw,55vh); }
}

@keyframes confetti {
  0% { -webkit-transform: rotateZ(15deg) rotateY(0deg) translate(0,0); transform: rotateZ(15deg) rotateY(0deg) translate(0,0); }
  25% { -webkit-transform: rotateZ(5deg) rotateY(360deg) translate(-3vw,10vh); transform: rotateZ(5deg) rotateY(360deg) translate(-3vw,10vh); }
  50% { -webkit-transform: rotateZ(15deg) rotateY(720deg) translate(3vw,30vh); transform: rotateZ(15deg) rotateY(720deg) translate(3vw,30vh); }
  75% { -webkit-transform: rotateZ(5deg) rotateY(1080deg) translate(-7vw,40vh); transform: rotateZ(5deg) rotateY(1080deg) translate(-7vw,40vh); }
  100% { -webkit-transform: rotateZ(15deg) rotateY(1440deg) translate(5vw,55vh); transform: rotateZ(15deg) rotateY(1440deg) translate(5vw,55vh); }
}
