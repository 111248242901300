@use "../inc/vars";

input[type="date"]::-webkit-calendar-picker-indicator {
	content: "";
	display: inline-block;
	width: 100%;
	height: 100%;
	position: absolute;
	inset: 0;
	padding: 12px 12px 12px calc(100% - 30px);
	margin: 0;
	box-sizing: border-box;
	cursor: pointer;
}

.input-range {
	@include vars.margin();
	padding-bottom: 8px;
	@media (vars.$md) {
		padding-bottom: 0;
	}
	input[type="date"] { padding-right: 22px }
	&:last-child { margin-bottom: 0; }
	span { display: block; margin: 0 0 8px; }
	label {
		position: relative;
		display: inline-block;
		// margin: -8px 0 8px;
    margin: 0 0 8px;

		&.moz {
			display: none;
			@media not all and (min-resolution:.001dpcm) {
					display: inline-block;
			}
      input {
        margin-bottom: 0;
      }
		}
		&.none-moz {
			display: inline-block;
			@media not all and (min-resolution:.001dpcm) {
					display: none;
			}
		}
		.mdi {
			width: 100%;
			text-align: right;
			position: absolute;
			top: 8px;
			right: 8px;
			font-size: 20px;
			&:hover {
				cursor: pointer;
			}
		}

	}
	@media (vars.$xs) {
		// label { display: inline-block; }
		span { display: inline-block; margin: 0 16px 8px; }
	}
}

.cofunded-input-range {
	input[type="date"] { padding-right: 40px }
}
