@use "../../inc/vars";
@use "../../helpers/px-rem" as px;

.custom-popover {
  background-color: vars.$white;
  padding: px.toRem(15);
  min-width: px.toRem(260);
  min-height: px.toRem(50);
  border-radius: px.toRem(14);
  border: 1px rgba(vars.$separatorColor, 0.15) solid;
  box-shadow: 0px 9px 15px 0px vars.$separatorColor;
  overflow: auto;
  max-height: px.toRem(320);
  display: flex;
  flex-direction: column;

  &__header {
    flex: 0 0 auto;
    margin-bottom: px.toRem(10);
  }

  &__list {
    padding: px.toRem(10) 0;
    flex: 1 1 100%;
    overflow: auto;
  }

  &__item {
    cursor: pointer;
    text-decoration: none !important;
    width: 100%;
    padding: px.toRem(10) 0;
    display: block;
  }

  &__footer {
    flex: 0 0 auto;
    padding-top: px.toRem(10);
    .btn {
      width: 100%;
    }
  }

  &--auto {
    min-height: 0;
    min-width: 0;
    padding: px.toRem(20);
  }

  h6 {
    font-size: px.toRem(15);
    line-height: px.toRem(18);
    font-weight: 700;
    margin-bottom: px.toRem(10);
  }

  .form-group {
    margin-bottom: px.toRem(15);
  }

  .form-control {
    padding: px.toRem(10) px.toRem(12);
    margin: 0;
    border-radius: px.toRem(8);
    border: 1px rgba(vars.$separatorColor, 0.15) solid;
    background-color: vars.$white;
    font-weight: 400;
    font-size: px.toRem(15);
    width: 100%;
  }
}
