@use 'src/scss/inc/vars';

.action-disabled {
	margin-right: 8px;
	.mdi {
		position: relative;
    font-size: 1.3rem;
    vertical-align: middle;		
	}
}

.promo-campaign-list {
	th {
		@media (vars.$max-sm) {
			display: none;
		}
	}

	td {
		@media (vars.$max-sm) {
			display: none;
			&.promos, &.status, &.action {
				display: table-cell;
			}
		}

		.promo {
			display: flex;
			flex-direction: column;

			h6 {
				font-weight: 500;
				font-size: 12px;
				line-height: 12px;
			}

			span {
				font-weight: normal;
				font-size: 9px;
				line-height: 12px;
			}
		}
	}
}
