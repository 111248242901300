@use 'src/scss/inc/vars';

.graph-card {
	width: 100%;
	background: #FFFFFF;
	box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16), 0px 1px 2px rgba(8, 35, 48, 0.24);
	border-radius: 6px;
	padding: 16px 24px;
	margin-bottom: 24px;

	// @media (vars.$max-sm) {
	// 	margin-left: 17px;
	// 	margin-right: 17px;
	// 	max-width: calc(100% - 43px);
	// }

	&.mobile {
		display: block;
		@media (vars.$sm) {
			display: none;
		}
	}

	&.desktop {
		display: none;
		@media (vars.$sm) {
			display: block;
		}
	}

	.card-total-overview {
		flex-direction: column;

		h5 {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.4px;
			text-transform: uppercase;
			color: #5E6366;
			margin-bottom: 9px;
		}

		h2 {
			font-style: normal;
			font-weight: normal;
			font-size: 24px;
			line-height: 26px;
			letter-spacing: 0.1px;
			color: #060B0D;
		}

		h6 {
			font-style: normal;
			font-weight: normal;
			font-size: 10px;
			line-height: 16px;
			letter-spacing: 0.1px;
			color: #939393;
		}
	}
}
