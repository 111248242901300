@use 'src/scss/inc/vars';

/* Mobile Sort and Filter control styles */
.body-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 1000;
}
.mobile_sort-filter {
	display: none;
	@media (vars.$max-sm) {
		display: block;
		background-color: #fff;
		position: fixed;
		bottom: 0;
		width: 100%;
		/* height: 455px; */
		height: auto;
		z-index: 1001;
		left: 0;
		margin-bottom: 0;
		padding-bottom: 8%;
	}
	.apply-btn {
		display: flex;
		justify-content: space-around;
		.btn {
			width: 92%;
			text-transform: uppercase;
		}
	}
	.sf-header {
		padding: 3% 4%;
		margin-bottom: 3%;
		border-bottom: 3px solid vars.$greyLight;
		display: flex;
		justify-content: space-between;
		align-items: center;
		button {
			color: vars.$blue;
			text-transform: capitalize;
			cursor: pointer;
		}
		h5 {
			margin: 0;
		}
		.mdi {
			font-size: 1.6em;
			cursor: pointer;
			color: #000;
			position: relative;
			top: 1px;
			left: 2px;
		}
	}
	.control-component {
		padding: 0 5%;
		h5 {
			margin-bottom: 1%;
		}
	}
}