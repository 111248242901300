@use "../inc/vars";

// ####################
// FLEX BOX
// ####################

// DISPLAY/DIRECTION

.gap-10 { gap: 10px; }
.gap-5 { gap: 5px;}
.gap-8 { gap: 8px; }
.gap-10 { gap: 10px;}
.gap-16 { gap: 16px;}

.grow-1 { flex-grow: 1;}

.flex-2-mobile {
	@media (vars.$max-smm) {
		flex: 2 !important;
	}
}

.flex-row {
  display: flex;
  flex-direction: row;
	.col {
		flex: 1;
	}
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}
.inline-flex-row {
  display: inline-flex;
  flex-direction: row;
}
.inline-flex-column {
  display: inline-flex;
  flex-direction: column;
}
.inline-flex-row-reverse {
  display: inline-flex;
  flex-direction: row-reverse;
}
.inline-flex-column-reverse {
  display: inline-flex;
  flex-direction: column-reverse;
}

// JUSTIFY CONTENT

.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-self-end {
  justify-content: end;
}


// ALIGN ITEMS

.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.align-stretch {
  align-items: stretch;
}

// ALIGN SELF

.self-auto {
  align-self: auto;
}
.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}
.self-baseline {
  align-self: baseline;
}
.self-stretch {
  align-self: stretch;
}

// WRAP

.flex-wrap {
  flex-wrap: wrap;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}
.flex-nowrap {
  flex-wrap: nowrap;
}

// GROW/SHRINK

@for $i from 1 through 15 {
	.flex-#{$i} {
		flex: #{$i};
	}
}

@for $i from 1 through 10 {
  .flex-g#{$i} {
    flex-grow: #{$i};
  }
}

@for $i from 1 through 10 {
  .flex-s#{$i} {
    flex-shrink: #{$i};
  }
}
