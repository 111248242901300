@use "../inc/vars";
@use "../helpers/px-rem" as px;

// https://getbootstrap.com/docs/5.0/forms/floating-labels/
// https://github.com/twbs/bootstrap/blob/main/scss/forms/_floating-labels.scss

$form-floating-height: 48px;
$form-floating-line-height: 1.25;
$form-floating-padding-x: 12px;
$form-floating-padding-y: 0.7rem;
$form-floating-input-padding-t: 1.25rem;
$form-floating-input-padding-b: 0.625rem;
$form-floating-label-opacity: 0.65;
$form-floating-label-transform: scale(0.75) translateY(-0.6rem)
  translateX(0.15rem);
$form-floating-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
$input-border-width: 2px;

.form-floating {
  position: relative;

  > .form-control,
  > .form-select {
    height: $form-floating-height;
    line-height: $form-floating-line-height;
  }

  > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%; // allow textareas
    padding: $form-floating-padding-y $form-floating-padding-x;
    pointer-events: none;
    border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
    transform-origin: 0 0;
    transition: $form-floating-transition;
    font-size: px.toRem(15);
    opacity: $form-floating-label-opacity;
  }

  // stylelint-disable no-duplicate-selectors
  > .form-control {
    padding: $form-floating-padding-y $form-floating-padding-x;

    &::placeholder {
      color: transparent;
    }

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    &:-webkit-autofill {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
  }

  > .form-select {
    padding-top: $form-floating-input-padding-t;
    padding-bottom: $form-floating-input-padding-b;
  }

  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-select {
    ~ label {
      opacity: 0.7 !important;
      transform: $form-floating-label-transform;
    }
  }
  // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
  > .form-control:-webkit-autofill {
    ~ label {
      opacity: 0.7 !important;
      transform: $form-floating-label-transform;
    }
  }
  // stylelint-enable no-duplicate-selectors
}
