@use 'src/scss/inc/vars';

.promo-page-header {
	// padding: 27px 0 0;
	// @include vars.margin();
	// @media (vars.$sm) {
	// 	padding: 80px 0 0 0;
	// }

	// .promo-title-bar {
	// 	// padding: 0 0 30px;
	// 	&:last-of-type { padding: 0; }
	// }

	h5 {
		font-weight: 500;
		font-size: 18px;
		line-height: 28px;
		letter-spacing: 0.15px;
		color: #000000;

		span {
			color: #bfc3c5;
		}
	}
}
