@use 'src/scss/inc/vars';

.beta-select {
	width: 180px;
    height: 40px;
    overflow: hidden;
    overflow: -moz-hidden-unscrollable;
    background: url('../../assets/icon/select-dd-arrow.png') no-repeat 94% 17px;
    background-size: 12px 6px;
    border: 1px solid #C6CACC;
    display: inline-block;
    position: relative;
    border-radius: 4px;
	select {
		background: transparent;
		-webkit-appearance: none;
		-moz-appearance: none;
		text-indent: 1px;
		text-overflow: "";
		width: 180px;
		font-size: 16px;
		border: 0;
		height: 40px;
		position: absolute;
		left: 0;
		top: 0;
		padding: 0 6%;
		&:focus {
			outline: none;
		}
		&::-ms-expand {
			display: none;
		}		
	}
}